import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { c_primary_red } from '../variables/colors';

export default function Loader() {
    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator color={c_primary_red} />
        </View>
    )
}