import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions,
} from 'react-native';
import moment from 'moment';
import {
    c_white,
    c_grey,
    c_second_grey,
    c_light_grey,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Entypo from '@expo/vector-icons/Entypo';
import MapModal from '../../modals/MapModal';
import { UPLOAD_URL } from '../../variables/constants';
import { get_time_diff } from '../../utils';

const width = Dimensions.get('window').width;

export default function MobileTicketDetail({ navigation, route }) {

    const [visibleMapModal, setVisibleMapModal] = useState(false);
    const { ticket } = route.params;
    const days = ['일', '월', '화', '수', '목', '금', '토'];

    useEffect(() => {
        navigation.setOptions({
            title: "모바일티켓",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
        });
    }, []);

    let ticketDate = ticket.product_id.schedule.detailed_time[ticket.round].date;
    const hour = ticket.product_id.schedule.detailed_time[ticket.round].hour;
    const minute = ticket.product_id.schedule.detailed_time[ticket.round].minute;
    const museum = ticket.product_id.location.name;
    const seat = ticket.seat_name.split(' ');
    ticketDate = new Date(ticketDate).setHours(hour);
    ticketDate = new Date(ticketDate).setMinutes(minute);

    const type1 = _.filter(seat, (o) => { return o.includes('층') });
    const type2 = _.filter(seat, (o) => { return o.includes('구역') });
    const type3 = _.filter(seat, (o) => { return o.includes('열') });
    const type4 = _.filter(seat, (o) => { return o.includes('번') });

    return (
        <View style={globalStyle.flex}>
            <ScrollView style={globalStyle.flex}>
                <View style={{ padding: perfectSize(15) }}>
                    <View style={styles.shadowContainer}>
                        <Image
                            source={{ uri: (UPLOAD_URL + ticket.product_id.poster_link) }}
                            style={{
                                height: perfectSize(400),
                                resizeMode: 'stretch',
                                borderTopLeftRadius: perfectSize(8),
                                borderTopRightRadius: perfectSize(8),
                            }}
                        />
                        <View style={{ padding: perfectSize(20) }}>
                            {/** title */}
                            <View style={{ marginBottom: perfectSize(15) }}>
                                <Text style={globalStyle.bigBoldText}>
                                    {ticket.product_id.title.name}
                                </Text>
                            </View>

                            {/** 티켓번호 */}
                            <View style={{ marginBottom: perfectSize(15) }}>
                                <Text style={globalStyle.greySmText}>
                                    {"티켓번호"}
                                </Text>
                                <Text style={styles.text}>
                                    {ticket._id.toUpperCase()}
                                </Text>
                            </View>

                            {/** 공연일시 */}
                            <View style={{ marginBottom: perfectSize(15) }}>
                                <Text style={globalStyle.greySmText}>
                                    {"공연일시"}
                                </Text>
                                <Text style={styles.text}>
                                    {moment(ticketDate).format('YYYY.MM.DD')}{`(${days[new Date(ticketDate).getDay()]}) ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`}
                                </Text>
                                <Text style={globalStyle.smText}>
                                    {`러닝타임 ${ticket.product_id.event_time.duration}분`}
                                    {ticket.product_id.event_time.intermission ? `(인터미션 ${ticket.product_id.event_time.intermission}분)` : ''}
                                </Text>
                            </View>

                            {/** 장소 */}
                            <View style={{ marginBottom: perfectSize(15) }}>
                                <Text style={globalStyle.greySmText}>
                                    {"장소"}
                                </Text>
                                <TouchableOpacity
                                    onPress={() => setVisibleMapModal(true)}
                                    style={globalStyle.flexRow}>
                                    <Text style={styles.text}>
                                        {museum}
                                    </Text>
                                    <Entypo
                                        name="chevron-thin-right"
                                        size={perfectSize(12)}
                                        color={c_grey}
                                    />
                                </TouchableOpacity>
                            </View>

                            {/** 좌석등급 */}
                            <View style={{ marginBottom: perfectSize(15) }}>
                                <Text style={globalStyle.greySmText}>
                                    {"좌석등급"}
                                </Text>
                                <Text style={styles.text}>
                                    {`${ticket.seat_grade}석 ${type1}`}
                                </Text>
                            </View>

                            {/** 티켓 */}
                            <View style={[globalStyle.flexRow, styles.ticket]}>
                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <Text style={[globalStyle.smGreyText, { marginBottom: perfectSize(10) }]}>
                                        {'구역'}
                                    </Text>
                                    <Text style={globalStyle.bigBoldText}>
                                        {type2}
                                    </Text>
                                </View>
                                <View style={styles.split} />
                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <Text style={[globalStyle.smGreyText, { marginBottom: perfectSize(10) }]}>
                                        {'줄'}
                                    </Text>
                                    <Text style={globalStyle.bigBoldText}>
                                        {type3}
                                    </Text>
                                </View>
                                <View style={styles.split} />
                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <Text style={[globalStyle.smGreyText, { marginBottom: perfectSize(10) }]}>
                                        {'좌석번호'}
                                    </Text>
                                    <Text style={globalStyle.bigBoldText}>
                                        {type4}
                                    </Text>
                                </View>
                            </View>

                            {/** 남은 시간 */}
                            <View style={{
                                alignItems: 'center',
                            }}>
                                <Text style={globalStyle.smGreyText}>
                                    {'입장시작까지 남은 시간'}
                                </Text>
                                <Text style={styles.leftTime}>
                                    {get_time_diff(ticketDate)}
                                </Text>
                            </View>

                            {/** qr code */}
                            <View style={{ alignItems: 'center', marginVertical: perfectSize(20) }}>
                                <Image
                                    source={{ uri: ticket.barcode }}
                                    style={{
                                        width: '80%',
                                        height: perfectSize(100),
                                        // resizeMode: 'contain'
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView>
            <MapModal
                data={ticket.product_id}
                isVisible={visibleMapModal}
                hideModal={() => setVisibleMapModal(false)}
            />
        </View>
    )
}

const styles = {
    shadowContainer: {
        elevation: 2,
        shadowColor: c_grey,
        shadowOffset: { x: 2, y: 6 },
        shadowRadius: 4,
        shadowOpacity: 0.3,
        backgroundColor: c_white,
        borderRadius: perfectSize(8)
    },
    text: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        marginVertical: perfectSize(5)
    },
    split: {
        width: 1,
        height: 28,
        backgroundColor: c_light_grey
    },
    ticket: {
        height: perfectSize(70),
        borderRadius: perfectSize(8),
        borderWidth: 1,
        borderColor: c_second_grey,
        marginBottom: perfectSize(20)
    },
    leftTime: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        color: c_primary_red,
        marginTop: perfectSize(4)
    },
}