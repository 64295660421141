import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    ActivityIndicator,
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    TextInput,
    Alert,
} from 'react-native';
import {
    c_primary_red,
    c_second_white,
    c_thrid_white,
    c_white,
    c_grey,
    c_second_grey,
    c_second_black,
    c_light_grey,
    c_primary_black
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Separator from '../../components/Separator';
import Footer from '../../components/Footer';
import { FAB } from 'react-native-paper';
import AntDesign from '@expo/vector-icons/AntDesign';
import LoadMoreButton from '../../components/LoadMoreButton';
import Comment from './Comment';
import { getCommunityDetail } from '../../store/actions';
import { formatDate, numberWithCommas } from '../../utils';
import { UPLOAD_URL } from '../../variables/constants';
import {
    likeCommunity,
    addComment,
    deleteComment,
    reportCommunity,
    reportComment,
    likeComment,
    dislikeComment
} from '../../store/actions';
import { oneAlert, twoAlert } from '../../utils';
import HTML from 'react-native-render-html';
import { RichEditor } from 'react-native-pell-rich-editor';

function CommunityDetail({ navigation, route, isAuthenticated, user }) {

    const [recommands, setRecommand] = useState('추천순');
    const [open, onStateChange] = useState(false);
    const [comment, setComment] = useState('');
    const [community, setCommunity] = useState();
    const [loading, setLoading] = useState(false);
    const [communityLike, setCommunityLike] = useState(false);
    const [communityReport, setCommunityReport] = useState(false);
    const [commentMore, setcommentMore] = useState(0);
    const [comments, setComments] = useState([]);
    const [countOfComment, setCountOfComment] = useState();
    const [likesCount, setLikesCount] = useState(0);
    const { id } = route.params;

    const COMMUNITYLIST_DATA = [
        {
            id: 0,
            image: require('../../../assets/images/sample1.png'),
            title: '제주도립교향악단 이번에 새로 취임 하시는 지휘자 누구인지 아시는분~',
            date: '2021. 07. 14',
            views: 2879,
            recommands: 1473,
            tags: ['#클래식', '#백건우', '#슈만', '#피아노']
        },
        {
            id: 1,
            image: require('../../../assets/images/sample2.png'),
            title: '오늘 7. 12. 백건우 리사이틀 - 슈만',
            date: '2021. 07. 14',
            views: 2879,
            recommands: 1473,
            tags: ['#클래식']
        },
        {
            id: 2,
            title: '비발디가 진짜로 의도한 사계줄거리를 알고 들어야 감동이다',
            date: '2021. 07. 14',
            views: 2879,
            recommands: 1473,
            tags: ['#클래식']
        },
        {
            id: 3,
            image: require('../../../assets/images/sample2.png'),
            title: '저는 실물 티켓이 아직 좋네요 클래식은 티켓도 클래식이죠',
            date: '2021. 07. 14',
            views: 2879,
            recommands: 1473,
            tags: ['#클래식']
        },
    ];

    useEffect(() => {
        navigation.setOptions({
            title: "커뮤니티",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        getDetail();
    }, []);

    useEffect(() => {
        if (community) {
            let _commnets = community.comments;
            _commnets = _.sortBy(_commnets, (o) => { return o.createdAt });
            _commnets.reverse();
            _commnets = _commnets.slice(0, 10 + commentMore * 10);
            setComments(_commnets);
            setCountOfComment(community.comments.length);
        }
    }, [commentMore, community]);

    const getDetail = async () => {
        setLoading(true);
        const res = await getCommunityDetail(id);
        if (isAuthenticated) {
            setCommunityLike(_.includes(res.likes, user._id));
            setCommunityReport(_.includes(res.reports, user._id));
        }
        setLikesCount(res.likes.length);
        setCommunity(res);
        setLoading(false);
    }

    const CommunityList = () => {
        return COMMUNITYLIST_DATA.map(item =>
            <TouchableOpacity
                onPress={() => navigation.navigate('CommunityDetail')}
                key={item.id}>
                <View style={styles.communityList}>
                    {
                        item.image &&
                        <Image
                            source={item.image}
                            style={styles.communityImage} />
                    }
                    <View style={{ flex: 1 }}>
                        <Text style={globalStyle.normalText}>{item.title}</Text>
                        <View style={styles.communityInfo}>
                            <Text style={styles.communityInfoText}>{item.date}</Text>
                            <Text style={styles.communityInfoText}>{'조회 ' + item.views}</Text>
                            <Text style={styles.communityInfoText}>{'추천 ' + item.recommands}</Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            {
                                item.tag &&
                                item.tags.map(tag => {
                                    return <Text key={tag} style={styles.communityInfoTag}>{tag}</Text>
                                })
                            }
                        </View>
                    </View>
                </View>
                <Separator
                    height={perfectSize(1)}
                    color={c_second_white}
                />
            </TouchableOpacity>
        )
    }

    if (loading) {
        return (
            <View style={[globalStyle.mainContainer, { alignItems: 'center', justifyContent: 'center' }]}>
                <ActivityIndicator color={c_primary_red} />
            </View>
        )
    }

    if (!community) {
        return (
            <View style={globalStyle.mainContainer}>
            </View>
        )
    }

    const checkAuth = () => {
        if (!isAuthenticated) {
            navigation.navigate('SignIn', {screenName: 'Home'});
        }
    }

    const onLike = async () => {
        checkAuth();
        const res = await likeCommunity(user._id, id);
        if (res) {
            communityLike ?
                setLikesCount(likesCount - 1)
                : setLikesCount(likesCount + 1)
            setCommunityLike(!communityLike);
        }
    }

    const onReport = async () => {
        checkAuth();
        if (community.user_id._id === user._id) {
            oneAlert('본인의 게시글을 신고하실수 없습니다.');
            return;
        }
        if (communityReport) {
            oneAlert('이미 신고한 게시글입니다.');
            return;
        }
        const res = await reportCommunity(user._id, id);
        if (res) {
            oneAlert('신고가 접수되었습니다.');
            setCommunityReport(!communityReport);
        }
    }

    const renderComments = () => {
        if (comments) {
            return comments.map((item, index) => {
                return (
                    <Comment
                        key={index}
                        data={item}
                        myId={isAuthenticated ? user._id : ''}
                        onDelete={() => onDelete(item._id, index)}
                        onReport={() => onCommentReport(item._id, index)}
                        onLike={() => onCommentLike(item._id, index)}
                        onDisLike={() => onCommentDislike(item._id, index)}
                        isLike={
                            item.likes.includes(isAuthenticated ? user._id : '')
                        }
                        isDisLike={
                            item.dislikes.includes(isAuthenticated ? user._id : '')
                        }
                    />
                )
            });
        }
    }

    const onComment = async () => {
        checkAuth();
        if (comment) {
            const req = {
                content: comment,
                user_id: user._id
            };
            const res = await addComment(req, id);
            if (res) {
                let temp = [];
                res.user_id = user;
                temp.push(res);
                temp = temp.concat(comments);
                setComments([...temp]);
                let _community = community;
                temp = [];
                temp.push(res);
                temp = temp.concat(community.comments);
                _community.comments = temp;
                setCommunity(_community);
                setCountOfComment(countOfComment + 1);
                setComment('');
            }
        } else {
            oneAlert('댓글을 입력해주세요.');
        }
    }

    const onDelete = async (id, index) => {
        checkAuth();
        twoAlert(
            '댓글을 삭제하시겠습니까?', () => {
                let _comments = comments;
                _comments.splice(index, 1);
                setComments([..._comments]);
                let _community = community;
                _community.comments = _comments;
                setCommunity(_community);
                setCountOfComment(countOfComment - 1);
                deleteComment(id, community._id, user._id);
            },
        );
    }

    const onCommentReport = async (id, index) => {
        checkAuth();
        let _comments = comments;
        if (_comments[index].user_id._id === user._id) {
            oneAlert('본인의 댓글을 신고하실수 없습니다.');
            return;
        }
        if (_comments[index].reports.includes(user._id)) {
            oneAlert('이미 신고한 댓글입니다.');
            return;
        }
        twoAlert(
            '댓글을 신고하시겠습니까?', () => {
                _comments[index].reports.push(user._id);
                setComments([..._comments]);
                let _community = community;
                _community.comments = _comments;
                setCommunity(_community);
                reportComment(user._id, id);
                oneAlert('신고가 접수되었습니다.')
            },
        );
    }

    const onCommentLike = async (id, index) => {
        likeComment(user._id, id);
        comments[index].likes.push(user._id);
        const idx = _.indexOf(comments[index].dislikes, user._id);
        comments[index].dislikes.splice(idx, 1);
        setComments([...comments]);
    }

    const onCommentDislike = async (id, index) => {
        dislikeComment(user._id, id);
        comments[index].dislikes.push(user._id);
        const idx = _.indexOf(comments[index].like, user._id);
        comments[index].likes.splice(idx, 1);
        setComments([...comments]);
    }

    const baseFontStyle = {
        fontSize: perfectSize(15),
        textAlign: 'center',
    };

    const containerStyle = {
        width: perfectSize(300),
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <View style={globalStyle.flex}>
            <ScrollView style={globalStyle.flex}>

                <View style={styles.section}>
                    <View style={globalStyle.flexRow}>
                        {
                            community.keywords &&
                            community.keywords.map((item, index) => {
                                return (
                                    <Text
                                        key={index}
                                        style={[
                                            globalStyle.regularRedText,
                                            {
                                                marginRight: perfectSize(10)
                                            }
                                        ]}>
                                        {`#${item.title}`}
                                    </Text>
                                )
                            })
                        }
                    </View>

                    <Text style={{
                        fontSize: perfectSize(20),
                        letterSpacing: -0.2,
                        marginVertical: perfectSize(10)
                    }}>
                        {community.title}
                    </Text>

                    <View style={globalStyle.flexRow}>
                        <Text style={styles.communityInfoText}>
                            {community.user_id.nickname}
                        </Text>
                        <Text style={styles.communityInfoText}>
                            {formatDate(community.createdAt)}
                        </Text>
                        <Text style={styles.communityInfoText}>
                            {`조회 ${numberWithCommas(community.views)}`}
                        </Text>
                    </View>

                    <View style={[globalStyle.flexRow, { marginTop: perfectSize(10) }]}>
                        <View style={[globalStyle.flexRow, { marginRight: perfectSize(15) }]}>
                            <Image
                                source={require('../../../assets/images/ic_write_reply.png')}
                                style={{
                                    width: perfectSize(13),
                                    height: perfectSize(13),
                                    resizeMode: 'contain',
                                    marginRight: perfectSize(5),
                                }}
                            />
                            <Text style={globalStyle.smText}>
                                {numberWithCommas(countOfComment)}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Image
                                source={require('../../../assets/images/ic_write_like.png')}
                                style={{
                                    width: perfectSize(13),
                                    height: perfectSize(13),
                                    resizeMode: 'contain',
                                    marginRight: perfectSize(5),
                                }}
                            />
                            <Text style={globalStyle.smText}>
                                {numberWithCommas(likesCount)}
                            </Text>
                        </View>
                    </View>


                    <Separator
                        height={perfectSize(1)}
                        color={c_second_white}
                        marginTop={perfectSize(15)}
                        marginBottom={perfectSize(20)}
                    />
                    <View style={{ marginHorizontal: perfectSize(0) }}>
                        <HTML
                            source={{ html: community.content }}
                            baseFontStyle={baseFontStyle}
                            containerStyle={containerStyle}
                        />
                        {/* <RichEditor
                            disabled={true}
                            initialContentHTML={community.content}
                            containerStyle={{ height: '100%' }}
                        /> */}
                    </View>

                    <View style={[
                        globalStyle.flexRow,
                        {
                            justifyContent: 'center',
                            marginTop: perfectSize(25)
                        }]}>
                        <TouchableOpacity
                            onPress={onReport}
                            style={[styles.roundBtn, { marginRight: perfectSize(10), borderColor: communityReport ? c_primary_red : c_light_grey }]}>
                            <Image
                                source={require('../../../assets/images/ic_report_black.png')}
                                style={[styles.roundBtnIcon, { tintColor: communityReport ? c_primary_red : c_primary_black }]}
                            />
                            <Text style={[styles.roundBtnLabel, { color: communityReport ? c_primary_red : c_primary_black }]}>
                                {'신고하기'}
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                            onPress={onLike}
                            style={[styles.roundBtn, { borderColor: communityLike ? c_primary_red : c_light_grey }]}>
                            <Image
                                source={require('../../../assets/images/ic_review_like.png')}
                                style={[styles.roundBtnIcon, { tintColor: communityLike ? c_primary_red : c_primary_black }]}
                            />
                            <Text style={[styles.roundBtnLabel, { color: communityLike ? c_primary_red : c_primary_black }]}>
                                {'추천하기'}
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>

                {/** 댓글 */}

                <View style={styles.section}>
                    <Text style={styles.sectionHeaderTitle}>
                        {`댓글 ${numberWithCommas(countOfComment)}`}
                    </Text>

                    <View style={styles.textInput}>
                        <TextInput
                            style={[
                                globalStyle.regularText,
                                globalStyle.flex,
                                {
                                    maxHeight: perfectSize(86)
                                }
                            ]}
                            value={comment}
                            onChangeText={text => setComment(text)}
                            placeholder={'댓글을 달아주세요'}
                            multiline={true}
                        />
                        <TouchableOpacity
                            onPress={onComment}
                            style={{
                                ...styles.registerBtn,
                                backgroundColor: comment ? c_primary_red : '#a7a7a7'
                            }}>
                            <Text style={styles.registerBtnText}>
                                {'등록'}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    {renderComments()}

                    <LoadMoreButton
                        onPress={() => {
                            if (comments.length + 10 * (commentMore - 2) < countOfComment)
                                setcommentMore(commentMore + 1);
                        }}
                        icon={require('../../../assets/images/ic_more_detail.png')}
                        label={`${numberWithCommas(comments.length)}/${numberWithCommas(countOfComment)} 댓글 더보기`}
                        borderColor={c_white}
                        color={c_second_grey}
                        buttonStyle={{ height: perfectSize(40) }}
                    />
                </View>

                {/** 다음글 */}
                {/* <View style={styles.section}>
                    <Text style={styles.sectionHeaderTitle}>
                        {'다음글'}
                    </Text>

                    {CommunityList()}

                    <LoadMoreButton
                        icon={require('../../../assets/images/ic_more_detail.png')}
                        label={'더보기'}
                        borderColor={c_white}
                        color={c_second_grey}
                        buttonStyle={{ height: perfectSize(40) }}
                    />

                    <View style={styles.footer}>
                        <Footer />
                    </View>
                </View> */}
            </ScrollView>

            <FAB.Group
                open={open}
                icon={open ? 'close' : 'plus'}
                actions={[
                    {
                        icon: (props) => {
                            return (
                                <View style={styles.fabIcon}>
                                    <AntDesign name={'form'} color={c_primary_red} size={perfectSize(23)} />
                                </View>
                            )
                        },
                        style: styles.action,
                        onPress: () => navigation.navigate('CommunityWrite'),
                        small: false,
                    },
                    {
                        icon: (props) => {
                            return (
                                <View style={styles.fabIcon}>
                                    <AntDesign name={'user'} color={c_primary_red} size={perfectSize(23)} />
                                </View>
                            )
                        },
                        style: styles.action,
                        onPress: () => navigation.navigate('MyCommunity'),
                        small: false,
                    },
                    {
                        icon: (props) => {
                            return (
                                <View style={styles.fabIcon}>
                                    <AntDesign name={'home'} color={c_primary_red} size={perfectSize(23)} />
                                </View>
                            )
                        },
                        style: styles.action,
                        onPress: () => navigation.navigate('Community'),
                        small: false,
                    },
                ]}
                color={c_white}
                onStateChange={() => onStateChange(!open)}
                onPress={() => {
                    if (open) {
                        // do something if the speed dial is open
                    }
                }}
                fabStyle={{
                    backgroundColor: c_primary_red,
                }}
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0
                }}
            />
        </View>
    )
}

const mapStateTopProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
});

export default connect(mapStateTopProps)(CommunityDetail);

const styles = {
    section: {
        marginTop: perfectSize(8),
        paddingHorizontal: perfectSize(15),
        paddingTop: perfectSize(10),
        paddingBottom: perfectSize(20),
        backgroundColor: c_white
    },
    sectionHeaderTitle: {
        fontSize: perfectSize(15),
        fontWeight: 'bold',
        letterSpacing: -0.15,
        color: c_primary_black
    },
    footer: {
        marginTop: perfectSize(20)
    },
    communityList: {
        flexDirection: 'row',
        marginVertical: perfectSize(15)
    },
    communityImage: {
        width: perfectSize(50),
        height: perfectSize(50),
        borderRadius: perfectSize(8),
        backgroundColor: c_grey,
        marginRight: perfectSize(15),
        backgroundColor: c_second_white
    },
    communityInfo: {
        flexDirection: 'row',
        marginVertical: perfectSize(8)
    },
    communityInfoText: {
        color: c_second_grey,
        fontSize: perfectSize(12),
        marginRight: perfectSize(12)
    },
    communityInfoTag: {
        color: c_grey,
        fontSize: perfectSize(13),
        marginRight: perfectSize(10)
    },
    fabIcon: {
        marginLeft: perfectSize(-12),
        marginRight: perfectSize(-5),
        alignItems: 'center',
        height: perfectSize(60),
        marginTop: perfectSize(-6)
    },
    action: {
        width: perfectSize(40),
        height: perfectSize(40),
        marginRight: perfectSize(5)
    },
    likeIcon: {
        width: perfectSize(13),
        height: perfectSize(13),
        resizeMode: 'contain',
        marginLeft: perfectSize(15),
        marginRight: perfectSize(5)
    },
    deleteBtn: {
        borderRadius: perfectSize(4),
        backgroundColor: '#a7a7a7',
        width: perfectSize(35),
        height: perfectSize(50),
        marginLeft: perfectSize(15),
        alignItems: 'center',
        justifyContent: 'center',
    },
    roundBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        width: perfectSize(95),
        height: perfectSize(35),
        borderWidth: perfectSize(1),
        borderColor: c_light_grey,
        borderRadius: perfectSize(30),
        justifyContent: 'center'
    },
    roundBtnIcon: {
        width: perfectSize(13),
        height: perfectSize(13),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
    roundBtnLabel: {
        fontSize: perfectSize(14),
        letterSpacing: -0.15,
        color: c_primary_black,
        fontSize: perfectSize(13),
        letterSpacing: -0.13
    },
    textInput: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginHorizontal: perfectSize(-15),
        paddingVertical: perfectSize(9),
        paddingHorizontal: perfectSize(15),
        borderWidth: 1,
        borderColor: c_light_grey,
        marginTop: perfectSize(15),
        alignItems: 'flex-end'
    },
    registerBtn: {
        borderRadius: perfectSize(6),
        backgroundColor: '#a7a7a7',
        marginLeft: perfectSize(15)
    },
    registerBtnText: {
        fontSize: perfectSize(13),
        color: c_white,
        marginHorizontal: perfectSize(10),
        marginVertical: perfectSize(6)
    },
}