import _ from "lodash";
import axios from "axios";
import {
    HOST,
    LOGIN,
    REGISTER,
    LOGOUT,
    GET_BANNER,
    GET_HASH_TAG,
    GET_SEARCH_WORD,
    GET_CATEGORY,
    GET_COMMUNITY,
    GET_PERFORMANCE_LIST,
    GET_REVIEW,
    ADD_REVIEW,
    DELETE_REVIEW,
    ADD_SEAT,
    DELET_SEAT,
    SET_TOTAL_PRICE,
    ADD_COUPON,
    UPDATE_PROFILE,
    SET_ORDER_DETAIL,
    RESET_SEAT,
    UPDATE_SHIPPING_ADDRESS,
    ADD_SHIPPING_ADDRESS,
    DELETE_SHIPPING_ADDRESS,
    SET_DEFAULT_ADDRESS,
    GET_NOTICE,
    ADD_EXPECTATION,
    GET_EXPECTATION,
    DELETE_EXPECTATION,
    UPDATE_SEAT_OFFSET,
    UPDATE_USER_WALLET,
    UPDATE_COIN_PRICES, CULTURE_MID, CULTURE_LICENSE,
} from "../../variables/constants";
import {storeData, getData, oneAlert} from "../../utils";

// axios.defaults.baseURL = `${HOST}`;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
const instance = axios.create({
    baseURL: HOST,
    timeout: 40000,
    headers: {"Content-Type": "application/json"},
});

// 회원 관리

export const registerPushToken = async (token) => {
    // storeData("@pushtoken", token);
    // instance
    //   .post("api/push-token/", { token })
    //   .then((res) => {})
    //   .catch((err) => {
    //     console.log("======err", err);
    // storeData('@loginEnable', false);
    // storeData('@user', null);
    // storeData('@token', null);
    // dispatch({ type: LOGOUT });
    // });
};

export const groupLogin = async (tomatoid) => {
    instance
        .post(`api/user/autoLogin?phonenum=${tomatoid}&nation=KR`)
        .then((res) => {
            if (res.data.data) {
                storeData("@loginEnable", true);
                storeData("@user", res.data.data);
                registerPushTokenWithId(res.data.data._id);
                window.location.replace("/Home");
            } else {
                return res.data.message.message;
            }
        })
        .catch((err) => {
            console.log("======err", err);
        });
};

const registerPushTokenWithId = async (id) => {
    // const token = await getData("@pushtoken");
    // instance
    //   .post(`api/user/registerPushToken/${id}`, { push_token: token })
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
};

export const autoLogin = (phonenum, countryCode) => async (dispatch) => {
    return instance
        .post(`api/user/autoLogin?phonenum=${phonenum}&nation=${countryCode}`)
        .then((res) => {
            if (res.data.status === 0) {
                return res.data
            }
            if (res.data.data) {
                storeData("@loginEnable", true);
                storeData("@user", res.data.data);
                dispatch({ type: LOGIN, payload: res.data.data });
                registerPushTokenWithId(res.data.data._id);
            } else {
                return res.data.message.message;
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export const login = (props) => async (dispatch) => {
    const { phonenum, passwd } = props;
    const req = {
        phonenum,
        passwd,
    };
    return instance
        .post("/api/user/login", req)
        .then((res) => {
            if (res.data.status === 0) {
                return res.data
            } else if (res.data.data) {
                storeData("@loginEnable", true);
                storeData("@user", res.data.data);
                registerPushTokenWithId(res.data.data._id);
                dispatch({ type: LOGIN, payload: res.data.data });
                return res.data
            } else {
                return res.data.message.message;
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export const join = (props) => async (dispatch) => {
    const { userkey, passwd, name } = props;
    const req = {
        userkey,
        passwd,
        name,
    };

    try {
        const res = await instance.post("/api/user/join", req);
        if (!res.data.data) return true;
        registerPushTokenWithId(res.data.data._id);
        dispatch({ type: REGISTER, payload: res.data.data });
        return false;
    } catch (error) {
        console.log(err);
        return false;
    }
};

export const logout = () => async (dispatch) => {
    storeData("@loginEnable", false);
    storeData("@user", null);
    storeData("@token", null);
    dispatch({ type: LOGOUT });
};

export const logoutMember = (id) => async (dispatch) => {
    return instance
        .delete(`api/user/${id}`)
        .then((res) => {
            storeData("@loginEnable", false);
            storeData("@user", null);
            dispatch({ type: LOGOUT });
        })
        .catch((err) => {
            return err;
        });
};

export const checkRegistered = (phonenum, countryCode) => {
    const nation = countryCode;
    const req = {
        phonenum,
        nation,
    };
    return instance
        .get("/api/user/isRegistered", { params: req })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data.message.message;
        });
};

export const certi = (phonenum, nationCode) => {
    const nation = nationCode;
    const agree = false;
    const req = {
        phonenum,
        nation,
        agree,
    };
    return instance
        .post("/api/user/join/certi", req)
        .then((res) => {
            return res.data.message;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const checkPasscode = (props) => {
    return instance
        .post("/api/user/join/check", props)
        .then((res) => {
            return res.data.message;
        })
        .catch((err) => {
            return err.response.data.message.message;
        });
};

export const resendPasscode = (props) => {
    return instance
        .post("/api/user/join/recerti", props)
        .then((res) => {
            return res.data.message;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const reqCertiForResetPassword = (phonenum, countryCode) => {
    const req = {
        phonenum,
        nation: countryCode,
    };
    return instance
        .post("/api/user/changepasswd/certi", req)
        .then((res) => {
            return true;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const checkResetPasscode = ({ code, phonenum, countryCode }) => {
    const req = {
        code,
        phonenum,
        nation: countryCode,
    };
    return instance
        .post("/api/user/changepasswd/certicheck", req)
        .then((res) => {
            return res.data.message.value;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const resetPassword = ( code, phonenum, newpasswd, countryCode ) => {
    const req = {
        phonenum,
        code,
        newpasswd,
        nation: countryCode,
    };
    return instance
        .post("/api/user/changepasswd/reset", req)
        .then((res) => {
            return res.data.message.value;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getUserDetail = (userId) => {
    return instance
        .get(`api/user/detail/${userId}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const updatePersonalInfo = (type, value, id) => async (dispatch) => {
    const field = type ? "email" : "nickname";
    const email_req = {
        email: value,
    };
    const nickname_req = {
        nickname: value,
    };
    const req = type ? email_req : nickname_req;
    instance
        .post(`api/user/update_info/${id}`, req)
        .then((res) => {
            dispatch({ type: UPDATE_PROFILE, payload: { type: field, value } });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const updatePassword = async (req) => {
    return instance
        .post("api/user/changepasswd", req)
        .then((res) => {
            console.log(res.data);
        })
        .catch((err) => {
            return true;
        });
};

export const updateShippingAddress = (req, id) => async (dispatch) => {
    return await instance
        .post(`api/user/update_delivery_address/${id}`, req)
        .then((res) => {
            let delivery_address = res.data.data;
            delivery_address._id = id;
            dispatch({
                type: UPDATE_SHIPPING_ADDRESS,
                payload: { delivery_address },
            });
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
};

export const addShippingAddress = (req, id) => async (dispatch) => {
    return await instance
        .post(`api/user/delivery_address/${id}`, req)
        .then((res) => {
            dispatch({ type: ADD_SHIPPING_ADDRESS, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err.response.data.message);
            return err.response.data.message;
        });
};

export const deleteShippingAddress =
    (userId, address_id) => async (dispatch) => {
        return await instance
            .delete(`api/user/delivery_address/${userId}`, {
                data: { delivery_address_id: address_id }
            })
            .then((res) => {
                dispatch({ type: DELETE_SHIPPING_ADDRESS, payload: res.data.data });
            })
            .catch((err) => {
                return err.response;
            });
    };

export const getShippingAddress = async (id) => {
    return await instance
        .get(`api/user/delivery_address/${id}`)
        .then((res) => {
            if(res.data.message){
                return res.data.message;
            }
        })
        .catch((err) => {
            console.log(err);
            return false;
        });
};

export const setDefaultShippingAddress = (address) => async (dispatch) => {
    dispatch({ type: SET_DEFAULT_ADDRESS, payload: address });
};

export const getInquiry = (userId) => {
    return instance
        .get(`/api/customer_center/getByUser/${userId}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const addInquiry = (data) => {
    return instance
        .post("api/customer_center", data)
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteInQueiry = (id) => {
    return instance
        .delete(`api/customer_center/${id}`)
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const updateInQueiry = (id, data) => {
    return instance
        .put(`api/customer_center/${id}`, data)
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const termsOfService = () => {
    return instance
        .get(`api/terms-of-service`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const searchUser = (search, userId) => {
    return instance
        .get(`api/user/search?search=${search}&&user_id=${userId}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

// 앱 관리

export const getBanner = () => async (dispatch) => {
    instance
        .get("/api/banner")
        .then((res) => {
            const carousel = res.data.data.filter((banner) => banner.isPublic);
            dispatch({ type: GET_BANNER, payload: carousel });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCategory = () => async (dispatch) => {
    instance
        .get("/api/category")
        .then((res) => {
            dispatch({ type: GET_CATEGORY, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getHashTag = () => async (dispatch) => {
    instance
        .get("/api/keyword")
        .then((res) => {
            dispatch({ type: GET_HASH_TAG, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err);
        });
    instance
        .get("/api/searchword")
        .then((res) => {
            dispatch({ type: GET_SEARCH_WORD, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCommunity = () => async (dispatch) => {
    instance
        .get("/api/noticeboard")
        .then((res) => {
            dispatch({ type: GET_COMMUNITY, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getMyCommunity = async (userId) => {
    return await instance
        .get(`/api/noticeboard/mycommunity?user_id=${userId}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getMyTempCommunity = async (userId) => {
    return await instance
        .get(`/api/noticeboard/temp-list?user_id=${userId}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteMyCommunity = (id) => {
    return instance
        .delete(`/api/noticeboard/${id}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCommunityDetail = (id) => {
    return instance
        .get(`/api/noticeboard/detail/${id}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const likeCommunity = (userId, id) => {
    return instance
        .put(`/api/noticeboard/like/${id}`, { user_id: userId })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const reportCommunity = (userId, id) => {
    return instance
        .put(`/api/noticeboard/report/${id}`, { user_id: userId })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCommunityWithParams = (req) => {
    return instance
        .get(`api/noticeboard/list-per-keyword?${req}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCommunityList = (req) => {
    return instance
        .get(`/api/noticeboard`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const reportComment = (userId, id) => {
    return instance
        .put(`/api/noticeboard/reportComment/${id}`, { user_id: userId })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const likeComment = (userId, id) => {
    return instance
        .put(`/api/noticeboard/likeComment/${id}`, { user_id: userId })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const dislikeComment = (userId, id) => {
    return instance
        .put(`/api/noticeboard/dislikeComment/${id}`, { user_id: userId })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const addComment = (req, id) => {
    return instance
        .post(`/api/noticeboard/addComment/${id}`, req)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteComment = (id, communityId, userId) => {
    return instance
        .delete(
            `/api/noticeboard/comment/${id}?noticeboard_id=${communityId}&user_id=${userId}`
        )
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const addNotice = (req) => {
    return instance
        .post(`/api/noticeboard`, req)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const updateNotice = (id, req) => {
    return instance
        .put(`/api/noticeboard/${id}`, req)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPerformance = async (id) => {
    return await instance
        .get(`/api/performance/product_detail/${id}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPerformanceList = async (req) => {
    return await instance
        .get(`api/performance/list?${req}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const searchPerformanceList = async (search_word) => {
    return await instance
        .get(`/api/performance/list?search_word=${search_word}`)
        .then((res) => {
            const filteredPerformance = _.filter(res.data.data, (o) => {
                return o.isPublic;
            });
            return filteredPerformance;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const addReview = (review) => async (dispatch) => {
    instance
        .post("api/review", review)
        .then((res) => {
            dispatch({ type: ADD_REVIEW, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const addExpectation = (review) => async (dispatch) => {
    instance
        .post("api/review", review)
        .then((res) => {
            dispatch({ type: ADD_EXPECTATION, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteReview = (id) => async (dispatch) => {
    return await instance
        .delete(`api/review/${id}`)
        .then((res) => {
            dispatch({ type: DELETE_REVIEW, payload: id });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteExpectation = (id) => async (dispatch) => {
    return await instance
        .delete(`api/review/${id}`)
        .then((res) => {
            dispatch({ type: DELETE_EXPECTATION, payload: id });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const likeReview = async (id, userId) => {
    return await instance
        .put(`api/review/like/${id}`, { user_id: userId })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const disLikeReview = async (id, userId) => {
    return await instance
        .put(`api/review/dislike/${id}`, { user_id: userId })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getReview = (req) => async (dispatch) => {
    instance
        .get("api/review", { params: req })
        .then((res) => {
            dispatch({ type: GET_REVIEW, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getExpectation = (req) => async (dispatch) => {
    instance
        .get("api/review", { params: req })
        .then((res) => {
            dispatch({ type: GET_EXPECTATION, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const reportReview = async (id, userId) => {
    return await instance
        .put(`api/review/report/${id}`, { user_id: userId })
        .then((res) => {
            return res.data.message;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getReviewBook = async (req) => {
    return await instance
        .get(`api/user/review_book/${req}`)
        .then((res) => {
            return res.data.message;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getSeatMap = (id) => {
    return instance
        .get(`api/performance/seatmap/${id}`)
        .then((res) => {
            return res.data.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const updateSeatOffset = (data) => async (dispatch) => {
    dispatch({ type: UPDATE_SEAT_OFFSET, payload: data });
};

export const performanceLike = async (user_id, performanceId) => {
    instance
        .post(`api/performance/likes/${performanceId}`, { user_id })
        .then((res) => {
            console.log(res.data.data);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const order = async (req) => {
    return await instance
        .post(`/api/order`, req)
        .then((res) => {
            console.log(res.data);
        })
        .catch((err) => {
            return err.response.data.message;
        });
};

export const cancelOrderTickets = async (ticket) => {
    return await instance
        .put(`api/order/ticket/${ticket}`, { isCancelled: 1 })
        .then((res) => {
            console.log(res.data);
        })
        .catch((err) => {
            console.log(err.response.data);
        });
};

export const cancelOrder = async (id, data) => {
    return await instance
        .put(`api/order/${id}`, data)
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getTicketBook = async (userId) => {
    return await instance
        .get(`/api/user/ticket_book/${userId}`)
        .then((res) => {
            return res.data.message;
        })
        .catch((err) => {
            console.log(err);
            return false;
        });
};

export const getFavoritePerformance = async (userId) => {
    return await instance
        .get(`api/user/liked_products/${userId}`)
        .then((res) => {
            return res.data.message;
        })
        .catch((err) => {
            console.log(err);
            return false;
        });
};

export const getOrders = async (userId) => {
    return await instance
        .get(`api/user/order/${userId}`)
        .then((res) => {
            return res.data.message.data;
        })
        .catch((err) => {
            console.log(err);
            return false;
        });
};

export const getAvailableOrders = async (userId) => {
    return await instance
        .get(`api/order/orderAvailableList/${userId}?per_page=20&page=1`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
            return false;
        });
};

export const getNotice = () => async (dispatch) => {
    await instance
        .get("api/notice")
        .then((res) => {
            dispatch({ type: GET_NOTICE, payload: res.data.data });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getNoticeDetail = async (id) => {
    return await instance
        .get(`api/notice/${id}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getFAQ = () => {
    return instance
        .get("api/faq?type=전체&isPublic=true")
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getEvent = () => {
    return instance
        .get("api/event?is_mobile=true")
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const uploadFile = async (formData) => {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    return axios
        .post(`${HOST}/api/upload`, formData)
        .then((res) => {
            return res.data.filename;
        })
        .catch((err) => {
            console.log(err);
        });
};

// 좌석 관리
export const updateSeat = (val, isSelect, seatNum) => async (dispatch) => {
    if (isSelect) {
        dispatch({ type: ADD_SEAT, payload: { val, seatNum } });
    } else {
        dispatch({ type: DELET_SEAT, payload: { val, seatNum } });
    }
};

export const resetSeat = () => async (dispatch) => {
    dispatch({ type: RESET_SEAT });
};

export const getRestSeat = (id, index) => {
    return instance
        .get(`api/performance/getRestSeats?id=${id}&round=${index}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err.response.data.message);
        });
};

export const sendGift = (id, req) => {
    return instance
        .put(`api/order/ticket/${id}`, req)
        .then((res) => {
            console.log(res.data);
        })
        .catch((err) => {
            return err.response.data;
        });
};

export const updateWallet = (req, userId, type) => {
    return instance
        .post(`api/user/update_wallet_info/${userId}`, req)
        .then((res) => {
            return true;
        })
        .catch((err) => {
            return false;
        });
};

export const getWalletBalance = (req) => async (dispatch) => {
    return instance
        .post(`api/tongtongcoin/wallet_account_all_balance`, req)
        .then((res) => {
            let data = res.data.data;
            data.wallet_name = req.wallet_name;
            dispatch({ type: UPDATE_USER_WALLET, payload: data });
        })
        .catch((err) => {
            return err.response.data;
        });
};

export const getUsableCoinList = (phonenum, wallet_name) => {
    return instance
        .post("api/tongtongcoin/usable_coin_list/", {
            phonenum: phonenum,
            wallet_name: wallet_name,
        })
        .then((res) => {
            const usableCoinList = res.data.data;
            const meni = _.find(usableCoinList, (o) => {
                return o.symbol === "TTM";
            });
            const mile = _.find(usableCoinList, (o) => {
                return o.symbol === "TTMI";
            });
            const coin = _.find(usableCoinList, (o) => {
                return o.symbol === "TTCOIN";
            });
            const ttr = _.find(usableCoinList, (o) => {
                return o.symbol === "TTR";
            });
            return {
                meni_price: meni.coin_price,
                mile_price: mile.coin_price,
                coin_price: coin.coin_price,
                ttr_price: ttr.coin_price,
            };
        })
        .catch((err) => {
            // oneAlert('지갑정보를 찾을수 없습니다.');
        });
};

export const getUsableCoinData = (phonenum, wallet_name) => {
    return instance
        .post("api/tongtongcoin/usable_coin_list/", {
            phonenum: phonenum,
            wallet_name: wallet_name,
        })
        .then((res) => {
            const usableCoinList = res.data.data;
            const meni = _.find(usableCoinList, (o) => {
                return o.symbol === "TTM";
            });
            const mile = _.find(usableCoinList, (o) => {
                return o.symbol === "TTMI";
            });
            const coin = _.find(usableCoinList, (o) => {
                return o.symbol === "TTCOIN";
            });
            const ttr = _.find(usableCoinList, (o) => {
                return o.symbol === "TTR";
            });
            return {
                meni_balance: meni.balance,
                mile_balance: mile.balance,
                coin_balance: coin.balance,
                ttr_balance: ttr.balance,
            };
        })
        .catch((err) => {
            // oneAlert('지갑정보를 찾을수 없습니다.');
        });
};

export const getCoinPrices = () => async (dispatch) => {
    return instance
        .post("api/tongtongcoin/usable_coin_list/", {
            phonenum: "01012347788",
            wallet_name: "a003aeb54caecb64c834f5cdca236622",
        })
        .then((res) => {
            const usableCoinList = res.data.data;
            const meni = _.find(usableCoinList, (o) => {
                return o.symbol === "TTM";
            });
            const mile = _.find(usableCoinList, (o) => {
                return o.symbol === "TTMI";
            });
            const coin = _.find(usableCoinList, (o) => {
                return o.symbol === "TTCOIN";
            });
            dispatch({ type: UPDATE_COIN_PRICES, payload: { meni, mile, coin } });
        })
        .catch((err) => {
            // oneAlert('지갑정보를 찾을수 없습니다.');
        });
};

export const getMobileTickets = (userId) => {
    return instance
        .get(`api/user/mobileTickets/${userId}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {});
};

export const getRewardPoint = (userId) => {
    return instance
        .get(`api/point?userid=${userId}&type=r`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err.response.data.message);
        });
};

//토마토리워드 조회
export const getRewardPoint_old = async (userid) => {
    axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
    try {
        if (!userid || userid.trim() === '') {
            throw new Error('userid가 null이거나 공백입니다.');
        }
        const apiUrl = 'https://api.tomato.co.kr/api/Point.aspx?type=r';
        const res = await axios.post(apiUrl,{userid:userid});
        return res.data;
    } catch (error) {
        throw error;
    }
};

//토마토리워드 보내기
export const passRewardPoint = async (userid, point) => {
    axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
    try {
        if (!userid || userid.trim() === '' || !point || point.trim() === '') {
            throw new Error('userid가 null이거나 공백입니다.');
        }
        const apiUrl = 'https://api.tomato.co.kr/api/Point.aspx?type=i';
        const res = await axios.post(apiUrl,{
            userid:userid,
            point:point,
            corpName:8,
            seq:'1'
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

//토마토 리워드 보내기 티켓통DB
export const passTicketRewardPoint = () => async (userid, amount) => {
    return instance
        .post("/api/point/use", {
            userid: userid,
            amount:amount,
            TID:'TID',
            type:'TICKET'
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            oneAlert('passTicketRewardPoint ERR = ' + err);
        });
};

//매니아
export const joinMania = async (phone, username, userid, subDate) => {
    axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
    try {
        if (!userid || userid.trim() === '' || !phone || phone.trim() === '') {
            throw new Error('userid가 null이거나 공백입니다.');
        }
        const apiUrl = 'https://bill.tomatopay.net/payrequest';
        const res = await axios.post(apiUrl,{
            mid:'ttticket0m',
            license:'c5JUuxOSEo0hxYtL7V+yGITDLIoYAD2/51yy4NYkL7t8p3Nas2z3cMf4b3kCYbLaOoJ8uE++r/3htdb2iQmYRA==',
            prodname:'[티켓통]매니아 회원 구독',
            phone:phone,
            username:username,
            userid:userid,
            prodprice:50000,
            returnurl:'',
            seq:'',
            subDate:subDate
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

//매니아 티켓통DB
export const setManiaData = () => async (userId) => {
    return instance
        .post("api/user/mania/join", {
            userId: userId,
            TID:'TID',
            productName:'[티켓통]매니아 회원 구독',
            productPrice:50000
        })
        .then((res) => {
        })
        .catch((err) => {
            oneAlert('setManiaData ERR = ' + err);
        });
};

// 가격 관리
export const setTotalPrice = (val) => async (dispatch) => {
    dispatch({ type: SET_TOTAL_PRICE, payload: val });
};

export const setOrderDetail = (val) => async (dispatch) => {
    dispatch({ type: SET_ORDER_DETAIL, payload: val });
};

export const getInvitation = async (code) => {
    return instance
        .get(`api/invitation/get_from_code?code=${code}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            return false;
        });
};

export const addMemoTicket = (id, memo) => {
    return instance
        .post(`api/order/ticket-add-memo/${id}`, { memo })
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const revisitTicket = (id) => {
    return instance
        .post(`api/order/ticket-review/${id}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export async function apiGetNFTHistoryListByNFTId(id, params) {
    try {
        const res = await instance.get(`api/nft/getNFTHistoryListByNFTId/${id}`, {
            params,
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error(res.data.statusText);
        }
    } catch (e) {
        console.log(e);
        throw new Error(e.message);
    }
}

export const getManiaData = (id) => {
    return instance
        .get(`api/user/subscribe/${id}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const setGift = async (userid, zoneCode, address, addressDetail, productName1, productName2) => {
    return instance
        .post(`api/user/mania/reward/${userid}`, {
            zoneCode: zoneCode,
            address: address,
            addressDetail: addressDetail,
            products: [productName1, productName2]
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};
// 결제통 카드리스트 불러오기
export const getUserCardList = async (userid, phone, username) => {
    return instance
        .post(`api/tongtongcoin/tomatopay/card/list`, {
            mid: CULTURE_MID,
            license: CULTURE_LICENSE,
            userid: userid,
            phone: phone,
            username: username
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

// 결제 리스트 카드삭제
export const DelCardInfo = async (userid, seq, phone, username) => {
    axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
    try {
        if (!userid || userid.trim() === '') {
            throw new Error('userid가 null이거나 공백입니다.');
        }
        const apiUrl = 'https://bill.tomatopay.net/cardDelete';
        const res = await axios.post(apiUrl, {
            mid: CULTURE_MID,
            license: CULTURE_LICENSE,
            userid: phone,
            cseq: seq,
            phone: phone,
            username: username
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

// 결제통 결제요청
export const requestPayCard = async (userid, prodname, cardnumber, card_expire, birth, passcv, price, cardSeq, username, moid, type, point) => {
    return instance
        .post(`api/tongtongcoin/tomatopay/request`, {
            mid: CULTURE_MID,
            license: CULTURE_LICENSE,
            userid: userid,
            prodname: prodname,
            cardnumber: cardnumber,
            card_expire: card_expire,
            birthday: birth,
            passcv: passcv,
            price: price,
            cardSeq: cardSeq,
            username: username,
            moid: moid,
            type: type,
            point: point
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

// 티켓통 유저조회
export const searchTicketUser = (phone) => {
    return instance
        .get(`api/user/search?search=${phone}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};
export const getPopupImg = () => {
    return instance
        .get(`api/banner?type=popup&public=true`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};
export const getAvailableTicket = (userid) => {
    return instance
        .get(`api/order/ticket/available/check?userId=${userid}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};
export const join_ticketTong = async (user_status, phonenum, passwd, nation) => {
    return instance
        .post(`api/user/join/tickettong`, {
            user_status:user_status,
            phonenum:phonenum,
            passwd:passwd,
            nation:nation
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const DeleteMember = (id, passwd) => async (dispatch) => {
    return instance
        .delete(`api/user/${id}`, {
            data: { passwd: passwd }
        })
        .then((res) => {
            if (res?.data?.status === 1) {
                storeData("@loginEnable", false);
                storeData("@user", null);
                dispatch({ type: LOGOUT });
                return res?.data
            } else {
                return res?.data
            }
        })
        .catch((err) => {
            return err;
        });
};
// ttr보내기 symbol 값 꼭 확인할것
export const sendWalletData = (to_address, amount_to_transfer, phonenum, wallet_name, wallet_pw, symbol) => {
    return instance
        .post(`api/tongtongcoin/wallet_transfer_to_address`, {
            to_address:to_address,
            amount_to_transfer:amount_to_transfer,
            phonenum:phonenum,
            wallet_name:wallet_name,
            wallet_pw:wallet_pw,
            symbol:symbol,
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};
// 토마토 클래식 QR로그인
export const setTvLogin = (phonenum, passwd,nation, classicTvParam) => async (dispatch) => {
    const req = {
        phonenum,
        passwd,
        nation,
        classicTvParam
    };
    return instance
        .post(`api/user/login/classictv`, req)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};