import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions,
    Platform,
    Linking,
    SafeAreaView
} from 'react-native';
import {c_light_grey, c_primary_black, c_primary_red, c_white} from "../variables/colors";
import {globalStyle, perfectSize} from "../variables/styles";
import Modal from "react-native-modal";
import React, {useEffect, useState} from "react";
import Seat from "../screens/order/tabs/seat_map/seat";
import {oneAlert} from "../utils";
import {getSeatMap, updateSeatOffset} from "../store/actions";
import ReactNativeZoomableView from "@openspacelabs/react-native-zoomable-view/src/ReactNativeZoomableView";
import {connect} from "react-redux";
import Level from "../screens/order/tabs/seat_map/level";
import Level_two from "../screens/order/tabs/seat_map/level_large";

const {height} = Dimensions.get('window');
const width = Dimensions.get('window').width;

export default function SeatMapModal({
                                         isVisible,
                                         hideModal,
                                         performance,
                                         roundIndex,
                                     }) {

    const [sitWidth, setSitWidth] = useState();
    const [sitMap, setSitMap] = useState([]);
    const [isLimited, setLimited] = useState(performance.limit ? performance.limit.isLimited : false);

    const [movePosition, setMovePosition] = useState({
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    });

    useEffect(() => {
        getSeatMaps();
    }, []);

    function AndroidBackButton() {
        hideModal();
    }

    const colors =
        {
            VIP: '#9778f1',
            R: '#f56464',
            S: '#ffd56a',
            A: '#72bff6',
            B: '#573ff2',
            C: '#57ff57',
            D: '#2ac4eb',
            E: '#AAC46C',
            '일반': '#FFCCDD'
        }

    const getSeatMaps = async () => {
        const res = await getSeatMap(performance.seat_map_data[roundIndex]);
        let maxLength = 0;
        for (let n = 0; n < res.length; n++) {
            if (maxLength < res[n].length) {
                maxLength = res[n].length;
            }
        }
        setSitWidth(Math.floor((width / (maxLength - 1))/2)+1.5);
        setSitMap(res);
    };

    const renderSeat = () => {
        let seatArray = [];
        for (let j = 0; j < sitMap.length; j++) {
            seatArray.push(
                <View style={{flexDirection: 'row'}} key={j}>
                    {renderSeatRow(j)}
                </View>
            )
        }
        return seatArray;
    }

    const renderSeatRow = (j) => {
        let tempArray = [];
        for (let i = 0; i < sitMap[j].length; i++) {
            let type = [];

            if (sitMap[j][i] && (typeof (sitMap[j][i]) !== 'number')) {
                type = sitMap[j][i].split(":");
                if (type[0] === sitMap[j][i]) {
                    tempArray.push(
                        <View
                            key={'' + i + j}
                            style={{
                                width: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                                height: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                                backgroundColor: 'transparent',
                                borderWidth: 0.4,
                                borderColor: 'transparent',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <Text style={{
                                color: 'black',
                                fontSize: sitWidth * 1.3,
                                position: 'absolute',
                                width: perfectSize(30),
                                textAlign: 'center'
                            }}>{sitMap[j][i]}</Text>
                        </View>
                    )
                } else {
                    // type[5] === "1" && console.log(sitMap[j][i])
                    const clr = (type[4] === '0' || type[5] === "1") ? '#a3a3a3' : colors[type[4]];
                    (type[4] === '0' || type[5] === "1") ?
                        tempArray.push(
                            <View
                                key={'' + i + j}
                                style={{
                                    width: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                                    height: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                                    backgroundColor: clr,
                                    borderWidth: 0.4,
                                    borderColor: 'white'
                                }}>
                            </View>
                        ) :
                        tempArray.push(
                            <Seat
                                isLimited={isLimited}
                                limitValue={performance.limit.limit_value}
                                onPress={selected => {
                                    if (performance.limit.isLimited) {
                                        if (selected) {
                                            maxSeatCount += 1;
                                        } else {
                                            maxSeatCount -= 1;
                                        }
                                        if (maxSeatCount === performance.limit.limit_value) {
                                            setLimited(false);
                                        } else {
                                            setLimited(true);
                                        }
                                    }
                                    // if (selected) {
                                    //     let temp = selectedSeat ? selectedSeat : {};
                                    //     temp[sitMap[j][i]] = true;
                                    //     setSelectSeat({ ...temp });
                                    // } else {
                                    //     let temp = selectedSeat ? selectedSeat : {};
                                    //     temp[sitMap[j][i]] = false;
                                    //     setSelectSeat({ ...temp });
                                    // }
                                }}
                                key={'' + i + j}
                                seatNum={'i' + i + 'j' + j}
                                clr={clr}
                                val={sitMap[j][i]}
                                sitWidth={Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3}
                            />
                        )
                }
            } else if (sitMap[j][i]) {
                tempArray.push(
                    <View
                        key={'' + i + j}
                        style={{
                            width: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                            height: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                            backgroundColor: 'transparent',
                            borderWidth: 0.4,
                            borderColor: 'transparent',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <Text style={{color: 'red', textAlign: 'left', fontSize: 15}}>{sitMap[j][i]}</Text>
                    </View>
                )
            } else {
                tempArray.push(
                    <View
                        key={'' + i + j}
                        style={{
                            width: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                            height: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                            backgroundColor: 'transparent',
                            borderWidth: 0.4,
                            borderColor: 'transparent',
                        }}>
                    </View>
                )
            }
        }
        ;
        return tempArray;
    }

    return (
        <Modal
            animationIn="slideInUp"
            animationOut="slideOutDown"
            style={styles.parentModal}
            isVisible={isVisible}
            onRequestClose={() => AndroidBackButton()}>
            <View style={[styles.swipeModal, {width: 2000, height: 1500}]}>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    <View style={styles.headerContainer} />
                    <ReactNativeZoomableView
                        maxZoom={3}
                        minZoom={1}
                        initialZoom={1}
                        style={styles.zoomView}
                    >
                        <View>
                            {renderSeat()}
                        </View>
                    </ReactNativeZoomableView>
                    <View style={styles.bottomContainer} />
                    <View style={{backgroundColor: '#00000099',}}>
                        <Level_two performance={performance} roundIndex={roundIndex}/>
                    </View>
                    <TouchableOpacity
                        onPress={() => {
                            hideModal(false);
                        }}
                        style={[styles.cardSection,
                            {
                                paddingVertical: perfectSize(15),
                                backgroundColor: c_primary_red,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }]}>
                        <Text style={styles.confirmText}>
                            {'확인'}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    )
};

const styles = {
    parentModal: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: perfectSize(-800)
    },
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        alignItems: 'stretch',
        paddingTop: 0,
        // paddingBottom: perfectSize(20),
        borderBottomRightRadius: perfectSize(10),
        borderBottomLeftRadius: perfectSize(10),
        borderTopRightRadius: perfectSize(10),
        borderTopLeftRadius: perfectSize(10),
    },
    headerContainer: {
        marginVertical: perfectSize(5),
        marginTop: perfectSize(10),
        flexDirection: 'row', justifyContent: 'flex-end'
    },
    bottomContainer: {
        marginVertical: perfectSize(10),
        marginTop: perfectSize(15),
        flexDirection: 'row', justifyContent: 'flex-end'
    },
    cardSection: {
        borderColor: c_primary_red,
        padding: perfectSize(15),
        paddingVertical: perfectSize(12),
        marginBottom: perfectSize(5),
        borderBottomRightRadius: perfectSize(10),
        borderBottomLeftRadius: perfectSize(10),
        borderTopRightRadius: perfectSize(10),
        borderTopLeftRadius: perfectSize(10),
    },
    confirmText: {
        fontSize: perfectSize(14),
        letterSpacing: -0.15,
        color: c_white,
        fontWeight: 'normal',
    },
    zoomView: {
        justifyContent: 'center',
        backgroundColor: c_white,
    },
}