import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';

export default function Button({
    onPress,
    icon,
    label,
    color,
    buttonStyle,
    disable
}) {
    return (
        <TouchableOpacity
            disabled={disable}
            onPress={onPress}
            style={[styles.loadMoreBtn, color && { borderColor: color }, buttonStyle]}>
            {
                icon &&
                <Image
                    source={icon}
                    style={styles.loadMoreIcon}
                />
            }
            <Text style={[globalStyle.normalText, color && { color }]}>{label}</Text>
        </TouchableOpacity>
    )
}

const styles = {
    loadMoreBtn: {
        height: perfectSize(45),
        borderWidth: 1,
        borderColor: '#e9e9e9',
        borderRadius: perfectSize(4),
        marginVertical: perfectSize(30),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadMoreIcon: {
        width: perfectSize(18),
        height: perfectSize(16),
        marginRight: perfectSize(10)
    },
}