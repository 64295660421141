import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions,
    Platform,
    Linking,
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';
import Modal from 'react-native-modal';
import WebModal from 'modal-react-native-web';
import {
    c_primary_black,
    c_primary_red,
    c_third_grey,
    c_white,
} from '../variables/colors';
// import MapView, { Marker } from 'react-native-maps';
import Separator from '../components/Separator';
import Footer from '../components/Footer';
import WebView from 'react-native-webview';
import Clipboard from 'expo-clipboard';

const height = Dimensions.get('window').height;

export default function MediaModal({
    link,
    isVisible,
    hideModal,
}) {

    const modalContent = () => {
        return (
            <View style={styles.swipeModal}>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    <View style={{ paddingHorizontal: perfectSize(15), alignSelf: 'stretch' }}>
                        <TouchableOpacity
                            onPress={hideModal}
                            style={styles.closeBtn}>
                            <Image
                                source={require('../../assets/images/ic_close.png')}
                                style={styles.closeIcon}
                            />
                        </TouchableOpacity>
                        <Separator height={1} color={c_third_grey} />
                    </View>
                    <View style={{ width: '100%', height: '100%' }}>
                        {
                            Platform.OS !== 'web' ?
                                <WebView
                                    source={{ uri: link }}
                                />
                                : <iframe
                                    src={link}
                                />
                        }
                    </View>
                </View>
            </View>
        )
    }

    return (
        Platform.OS !== 'web' ?
            <Modal
                animationIn="slideInUp"
                animationOut="slideOutDown"
                style={{ margin: 0 }}
                isVisible={isVisible}
                onSwipeComplete={hideModal}>
                {modalContent()}
            </Modal>
            :
            <WebModal
                animationIn="slideInUp"
                animationOut="slideOutDown"
                transparent={true}
                visible={isVisible}>
                <View style={{
                    flex: 1,
                    backgroundColor: '#00000099'
                }}>
                    {modalContent()}
                </View>
            </WebModal>
    )
}

const styles = {
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        paddingHorizontal: 0,
        alignItems: 'stretch',
        paddingTop: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: perfectSize(16),
        borderTopLeftRadius: perfectSize(16),
        height: '94%',
        backgroundColor: c_white
    },
    closeBtn: {
        alignSelf: 'flex-end',
        marginVertical: perfectSize(15)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
}