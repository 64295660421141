import _ from "lodash";
import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { ReviewItem } from "./ReviewItem";
import { instance } from "../../../utils";

export const ReviewList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getReviewList();
  }, []);

  const getReviewList = async () => {
    const req_body = {
      type: "review",
      page: 1,
      per_page: 3,
    };
    instance
      .get("api/review", { params: req_body })
      .then(res => {
        setData(res.data.data);
      })
      .catch(err => {
        console.error(err.response.data.message);
      });
  };

  if (_.isEmpty(data)) return null;

  return (
    <View style={styles.videoListWrap}>
      <View
        style={[
          globalStyle.flexSpaceBetween,
          { marginVertical: perfectSize(10) },
        ]}
      >
        <Text style={globalStyle.bigBoldText}>{"리뷰"}</Text>
      </View>
      {data.map(review => {
        return (
          <View key={review._id} style={{ marginBottom: perfectSize(10) }}>
            <ReviewItem review={review} />
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  videoListWrap: {
    marginBottom: perfectSize(60),
  },
  moreIcon: {
    width: perfectSize(14),
    height: perfectSize(14),
    resizeMode: "contain",
  },
});
