import { combineReducers } from 'redux';
import { reducer as formReducer} from 'redux-form';
import userReducer from './user';
import appReducer from './app';
import seatReducer from './seat';
import InvitationReducer from './invitation';
import CouponReducer from './coupon';
import PerformanceReducer from './performance';
import CoinReducer from './coin';
import AlarmReducer from './alarm';
import OrderReducer from './order';
import AppIconReducer from './appIcon';

const reducers = {
    auth: userReducer,
    app: appReducer,
    seat: seatReducer,
    InvitationReducer,
    CouponReducer,
    PerformanceReducer,
    CoinReducer,
    AlarmReducer,
    OrderReducer,
    AppIconReducer,
    form: formReducer
}

const allReducers = combineReducers(reducers);

export default allReducers;