import React, { useEffect, useState } from 'react';
import {
    View,
    Image,
    TouchableOpacity,
    Text,
    Dimensions
} from 'react-native';
import {
    c_white,
    c_primary_red,
    c_third_grey
} from '../../variables/colors';
import { perfectSize } from '../../variables/styles';
import { formatDate } from '../../utils';
import { UPLOAD_URL } from '../../variables/constants';

const { width } = Dimensions.get('window');

const Event = ({ data }) => {
    const [imageWidth, setWidth] = useState();
    const [imageHeight, setHeight] = useState();

    useEffect(() => {
        Image.getSize(UPLOAD_URL + data.thumbnail, (w, h) => {
            setWidth(width - perfectSize(30));
            setHeight(h / w * (width - perfectSize(30)));
        });
    }, []);
    
    return (
        <TouchableOpacity>
            <View style={styles.card}>
                <Image
                    source={{ uri: UPLOAD_URL + data.thumbnail }}
                    style={[styles.thumbnail, {
                        width: imageWidth,
                        height: imageHeight
                    }]}
                />
                <View style={{
                    borderRadius: perfectSize(30),
                    paddingHorizontal: perfectSize(14),
                    paddingVertical: perfectSize(4),
                    backgroundColor: c_primary_red,
                    position: 'absolute',
                    top: perfectSize(8),
                    right: perfectSize(8)
                }}>
                    <Text style={{
                        fontSize: perfectSize(12),
                        fontWeight: 'bold',
                        color: c_white
                    }}>
                        {'진행중'}
                    </Text>
                </View>
            </View>

            <Text style={styles.title}>
                {data.name}
            </Text>
            <Text style={styles.date}>
                {
                    `${formatDate(data.from)} ~ ${formatDate(data.to)}`
                }
            </Text>
        </TouchableOpacity>
    )
}

export default Event;

const styles = {
    card: {
        width: '100%',
    },
    thumbnail: {
        width: '100%',
        height: '100%',
        backgroundColor: c_third_grey,
        borderRadius: perfectSize(8)
    },
    title: {
        marginTop: perfectSize(10),
        fontSize: perfectSize(14),
        fontWeight: 'bold',
        marginLeft: perfectSize(5)
    },
    date: {
        fontSize: perfectSize(12),
        color: '#888888',
        marginTop: perfectSize(5),
        marginLeft: perfectSize(5),
        marginBottom: perfectSize(15)
    },
}