import _ from "lodash";
import React from "react";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { globalStyle, perfectSize } from "../../variables/styles";

const LoadMoreButton = props => {
  return (
    <TouchableOpacity onPress={props.onPress} style={styles.loadMore}>
      <View style={globalStyle.flexRow}>
        <Text style={styles.loadMoreTitle}>더보기</Text>
        <Image
          source={require("../../../assets/images/ic_more_detail.png")}
          style={styles.loadMoreIcon}
        />
      </View>
    </TouchableOpacity>
  );
};

export default LoadMoreButton;

const styles = StyleSheet.create({
  loadMore: {
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    alignItems: "center",
    paddingVertical: perfectSize(13),
  },
  loadMoreTitle: {
    fontSize: perfectSize(13),
    lineHeight: perfectSize(14.7),
    color: "#777777",
    marginRight: perfectSize(8),
  },
  loadMoreIcon: {
    width: perfectSize(10),
    height: perfectSize(5),
    resizeMode: "contain",
  },
});
