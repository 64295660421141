import React from 'react';
import {
    View,
    Text,
    TouchableOpacity
} from 'react-native';
import { c_light_grey } from '../variables/colors';
import { globalStyle, perfectSize } from '../variables/styles';

export default function Tag({ label, color, onPress, borderColor }) {
    return (
        <TouchableOpacity
            onPress={onPress}
            style={[globalStyle.tagGrey, { borderColor: borderColor ? borderColor : c_light_grey }]}>
            <Text style={[globalStyle.tagGreyText, { color: color }]}>{label}</Text>
        </TouchableOpacity>
    )
}