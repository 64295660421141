import {
    Platform,
    Dimensions,
    PixelRatio,
} from 'react-native';

const PLATFORM = Platform.OS;

const { width, height: HEIGHT } = Dimensions.get('window');

const scale = width / 360;

function SIZE(size) {
    const newSize = size * scale;
    if (Platform.OS === 'ios') {
        return Math.round(PixelRatio.roundToNearestPixel(newSize));
    } else if (Platform.OS === 'android') {
        return Math.round(PixelRatio.roundToNearestPixel(newSize));
    } else {
        return width > 1000 ? size * 1.5 : size;
    }
}

function FS(size) {
    const newSize = size * scale;
    if (Platform.OS === 'ios') {
        return Math.round(PixelRatio.roundToNearestPixel(newSize));
    } else if (Platform.OS === 'android') {
        return Math.round(PixelRatio.roundToNearestPixel(newSize)) + 1.8;
    } else {
        return width > 1000 ? size * 1.5 : size;
    }
}

const WIDTH = width > 1000 ? HEIGHT / 1.5 : width;

export {
    PLATFORM,
    WIDTH,
    HEIGHT,
    SIZE,
    FS,
};
