import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    Alert,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Keyboard,
    BackHandler,
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput,
    Dimensions,
    Platform,
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import {
    c_primary_red,
    c_white,
    c_third_grey
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { uploadFile, addNotice, updateNotice } from '../../store/actions';
import { getData, oneAlert, storeData, twoAlert } from '../../utils';
import { RichEditor } from 'react-native-pell-rich-editor';
// import ActionSheet from 'react-native-actionsheet';
import { UPLOAD_URL } from '../../variables/constants';
import { ScrollView } from 'react-native-gesture-handler';

const { width, height } = Dimensions.get('window');
const OPEN_CAMERA = 0;
const OPEN_GALLERY = 1;

function Community({
    navigation,
    route,
    user
}) {

    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const [hastags, setHastags] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState();
    const [initContent, setInitContent] = useState();
    const [id, setId] = useState();
    const editorRef = useRef(null);
    const actionSheetRef = useRef(null);

    useEffect(() => {
        navigation.setOptions({
            title: "글쓰기",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => {
                        if (title || content) {
                            twoAlert(
                                '수정된 내용을 저장하지 않고 이전화면으로 돌아갑니다.\n계속하시겠습니까?',
                                () => {
                                    navigation.goBack();
                                },
                            );
                        } else {
                            navigation.goBack();
                        }
                    }}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, [title, content]);

    useEffect(() => {
        (async () => {
            if (Platform.OS !== 'web') {
                const mediaPermission = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (mediaPermission.status !== 'granted') {
                    alert('Sorry, we need camera roll permissions to make this work!');
                }
                const cameraPermission = await ImagePicker.requestCameraPermissionsAsync();
                if (cameraPermission.status !== 'granted') {
                    alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
    }, []);

    useEffect(() => {
        if (route.params) {
            const { data } = route.params;
            let _hastags = '';
            if (data.hashtags) {
                setId(data._id);
                data.hashtags.map(item => _hastags = _hastags + `#${item}`);
                setHastags(_hastags);
                setTitle(data.title);
                setInitContent(data.content);
                setContent(data.content);
            }
        }
    }, [])

    const onLaunchPicker = () => {
        actionSheetRef.current.show();
    }

    const selectActionSheet = async (index) => {
        let result;

        if (index === OPEN_CAMERA) {
            result = await ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                quality: 1,
            });
        } else if (index === OPEN_GALLERY) {
            result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                quality: 1,
            });
        };
        if (!result.cancelled) {
            let imageUrl;
            const image = result.uri;
            if (image) {
                const filename = image.split('/').pop();
                let match = /\.(\w+)$/.exec(filename);
                let type = match ? `image/${match[1]}` : `image`;

                // Upload the image using the fetch and FormData APIs
                let formData = new FormData();
                // Assume "photo" is the name of the form field the server expects
                formData.append('file', { uri: image, name: filename, type });
                imageUrl = await uploadFile(formData);
            }
            // setImage(result.uri);
            editorRef.current.insertHTML('<div><br></div>');
            // const source = { uri: 'data:image/jpeg;base64,' + result.base64 };
            // editorRef.current.insertImage(source.uri);
            editorRef.current.insertImage(UPLOAD_URL + imageUrl, 'background: gray;',);
        }
    };

    const onRegister = async (shouldSave) => {
        if (!title) {
            oneAlert('제목을 입력해주세요.');
            return;
        }
        if (!content) {
            oneAlert('내용을 입력해주세요.');
            return;
        }
        let _hastags = hastags.split('#');
        if (_hastags.length > 6) {
            oneAlert('해쉬태그는 최대 5개 입력하실수 있습니다.');
            return;
        }
        _hastags = _hastags.slice(1, hastags.length);

        let req = {
            title,
            content,
            hashtags: _hastags,
            user_id: user._id,
            isTemp: shouldSave
        };

        if (shouldSave) {
            setLoading(true);
            setLoadingSave(true);
            let res;
            if (id) {
                req.isTemp = true;
                res = await updateNotice(id, req);
            } else {
                res = await addNotice(req);
            }
            if (res) {
                navigation.goBack();
            } else {
                oneAlert('오류가 발생하였습니다.')
            }
            setLoading(false);
            setLoadingSave(false);
        } else {
            setLoading(true);
            setLoadingRegister(true);
            let res;
            if (id) {
                res = await updateNotice(id, req);
            } else {
                res = await addNotice(req);
            }
            if (res) {
                navigation.goBack();
            } else {
                oneAlert('오류가 발생하였습니다.')
            }
            setLoading(false);
            setLoadingRegister(false);
        }
    }

    const onChange = (html) => {
        console.log(html);
        setContent(html);
    }

    return (
        <View style={[globalStyle.mainContainer, { flexDirection: 'column', justifyContent: 'space-between', }]}>
            {/** Main Content */}
            <KeyboardAwareScrollView
                enableOnAndroid
                style={styles.mainContent}>
                <View style={{
                    paddingHorizontal: perfectSize(15),
                }}>
                    {/** 해쉬태그 */}
                    <View style={styles.textInput}>
                        <TextInput
                            editable={!loading}
                            value={hastags}
                            onChangeText={text => setHastags(text.trim())}
                            style={globalStyle.regularRedText}
                            placeholder={'해쉬태그 입력(#로 구분, 최대5개)'} />
                    </View>
                    {/** 제목 */}
                    <View style={styles.textInput}>
                        <TextInput
                            editable={!loading}
                            value={title}
                            onChangeText={text => setTitle(text)}
                            style={[globalStyle.regularBoldText, { fontSize: perfectSize(16) }]}
                            placeholder={'제목'} />
                    </View>
                </View>
                <View style={{ paddingHorizontal: perfectSize(7) }}>
                    <RichEditor
                        disabled={loading}
                        initialContentHTML={initContent}
                        ref={editorRef}
                        placeholder={'탭하여 글 작성'}
                        onChange={onChange}
                        useContainer={true}
                    />
                </View>
            </KeyboardAwareScrollView>

            <View style={styles.bottomView}>
                <TouchableOpacity
                    onPress={onLaunchPicker}
                    style={[globalStyle.flexRow, styles.attachImage]}>
                    <Image
                        source={require('../../../assets/images/icon_camera_b.png')}
                        style={styles.cameraIcon}
                    />
                    <Text style={globalStyle.smText}>
                        {'  사진 첨부'}
                    </Text>
                </TouchableOpacity>
                <View style={globalStyle.flexRow}>
                    <View style={[globalStyle.flex, { marginRight: perfectSize(5) }]}>
                        <Button
                            onPress={() => onRegister(true)}
                            isBorder={true}
                            textColor={c_primary_red}
                            label={'임시저장'}
                            color={c_white}
                            disable={loading}
                            loading={loadingSave}
                        />
                    </View>
                    <View style={[globalStyle.flex, { marginLeft: perfectSize(5) }]}>
                        <Button
                            onPress={() => onRegister(false)}
                            textColor={c_white}
                            label={'등록'}
                            color={c_primary_red}
                            disable={loading}
                            loading={loadingRegister}
                        />
                    </View>
                </View>
            </View>
            {/*<ActionSheet*/}
            {/*    ref={actionSheetRef}*/}
            {/*    options={['카메라', '갤러리', '취소']}*/}
            {/*    cancelButtonIndex={2}*/}
            {/*    onPress={selectActionSheet}*/}
            {/*/>*/}
        </View >
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps)(Community);


const styles = {
    mainContent: {
        backgroundColor: c_white,
    },
    textInput: {
        height: perfectSize(35),
        borderColor: c_third_grey,
        borderBottomWidth: 1,
        justifyContent: 'center',
        marginTop: perfectSize(10)
    },
    multilineText: {
        marginTop: perfectSize(10)
    },
    bottomView: {
        backgroundColor: 'white',
        paddingHorizontal: perfectSize(15),
        paddingBottom: perfectSize(15)
    },
    attachImage: {
        marginBottom: perfectSize(15),
        height: perfectSize(45),
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: c_third_grey,
        borderBottomWidth: 1,
        borderTopWidth: 1
    },
    cameraIcon: {
        width: perfectSize(18),
        height: perfectSize(14),
        resizeMode: 'contain'
    },
}