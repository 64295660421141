import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput,
} from 'react-native';
import {
    c_white,
    c_primary_blue,
    c_second_grey,
    c_primary_red, c_fourth_black, c_fifth_grey, c_indigo, c_fourth_grey
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Button from '../../components/Button';
import {checkResetPasscode, reqCertiForResetPassword, resetPassword} from '../../store/actions';
import OneAlertModal from "../../modals/OneAlertModal";
import CustomTextInput from "../../components/CustomTextInput";
import {oneAlert} from "../../utils";

export default function PasswordCertiRequest({ navigation, route }) {
    const sUsrAg = navigator.userAgent;
    const [loading, setLoading] = useState(false);
    const [phonenum, setPhonenum] = useState(route.params.phonenum);
    const [visibleAlertModal, setVisibleAlertModal] = useState(false);
    const [isAlertText, setAlertText] = useState('');
    const { userkey } = route.params;
    const [userKey, setUserKey] = useState('');

    const [show, setShow] = useState(false);
    const [countryCode, setCountryCode] = useState("KR");
    const [countryNumber, setCountryNumber] = useState("+82");

    const [passCode, setPassCode] = useState("");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [equalErr, setEqualErr] = useState(false);
    const [strongErr, setStrongErr] = useState(false);
    const [remainingTime, setRemainingTime] = useState("00:00");
    const [passTime, setPassTime] = useState(false);
    const [passSucess, setPassSucess] = useState(false);

    const startTimer = () => {
        let seconds = 180; // 3분을 초로 설정
        setPassTime(true)
        const timer = setInterval(() => {
            seconds -= 1;
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            setRemainingTime(`${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`);
            if (seconds <= 0) {
                setPassTime(false)
                clearInterval(timer);
                setRemainingTime("00:00");
            }
        }, 1000);
    };

    const showAlertWithText = (text) => {
        setVisibleAlertModal(true);
        setAlertText(text);
    };

    useEffect(() => {
        navigation.setOptions({
            title: "비밀번호 재설정",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    const onSign = async () => {
        if (phonenum.length < 10) {
            showAlertWithText("전화번호를 입력해주세요");
            return
        }
        if (passTime) {
            showAlertWithText("이미 인증번호를 요청했습니다");
            return
        }
        setLoading(true);
        const res = await reqCertiForResetPassword(phonenum, countryCode);
        if (!res) {
            showAlertWithText("유효하지 않은 전화번호입니다.");
        } else {
            showAlertWithText('인증번호가 전송되었습니다.');
            startTimer();
            // navigation.navigate('PasswordPasscode', { phonenum, isUserkey });
        }
        setLoading(false);
    }

    const onPassCode = async () => {
        if (passCode.length < 1) {
            showAlertWithText("인증번호를 확인해주세요.");
            return
        }
        if (passSucess) {
            showAlertWithText("이미 인증되었습니다.");
            return
        }
        setLoading(true);
        const res = await checkResetPasscode({ code: passCode, phonenum, countryCode });
        if (!res) {
            showAlertWithText('유효하지 않은 인증번호입니다.');
        } else {
            showAlertWithText('인증이 완료되었습니다.');
            setPassSucess(true);
            // navigation.navigate('PasswordReset', { phonenum, code: passcode, countryCode });
        }
        setLoading(false);
    }

    const onReset = async () => {
        if(!passSucess) {
            showAlertWithText('인증번호확인 단계를 먼저 진행해주세요.');
            return;
        }
        if (password !== confirmPassword) {
            setEqualErr(true);
            showAlertWithText('비밀번호가 일치하지않습니다.');
            return;
        }
        const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/;
        if (!pattern.test(password)) {
            setStrongErr(true);
            return;
        }
        setLoading(true);
        await resetPassword({ newpasswd: password, phonenum, passCode, countryCode });
        setLoading(false);
        navigation.navigate('SignIn');
        oneAlert('비밀번호가 변경되었습니다.');
    }

    return (
        <View style={globalStyle.mainContainer}>
            <KeyboardAwareScrollView style={styles.mainContainer}>
                <View style={{ alignItems: 'center',justifyContent: 'center' }}>
                    <Text style={{
                        color: c_fourth_black,
                        fontSize: perfectSize(17),
                        fontWeight: 'normal'
                    }}>
                        {'비밀번호 재설정'}
                    </Text>
                    <Text style={{
                        color: c_fourth_black,
                        fontSize: perfectSize(14),
                        fontWeight: 'normal',
                        letterSpacing: -0.02,
                        marginTop: perfectSize(12),
                        marginBottom: perfectSize(25),
                        textAlign: "center"
                    }}>
                        {'핸드폰 인증 후 새로운 비밀번호를 등록해 주세요.'}
                    </Text>
                </View>
                <View style={styles.phoneInputContainer}>
                    <View style={{
                        width: '20%',
                        height: perfectSize(48),
                        borderWidth: 1,
                        borderColor: c_second_grey,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: perfectSize(6)
                    }}>
                        <Text style={globalStyle.normalText}>{countryNumber}</Text>
                    </View>
                    <View style={styles.phoneInput}>
                        <TextInput
                            value={phonenum}
                            placeholder={'휴대폰 번호'}
                            keyboardType={'phone-pad'}
                            maxLength={11}
                            style={{
                                width: '95%',
                                height: perfectSize(48),
                                paddingVertical: perfectSize(14),
                                borderWidth: 1,
                                borderColor: c_second_grey,
                                fontSize: perfectSize(15),
                                paddingHorizontal: perfectSize(15),
                                fontWeight: 'normal'
                            }}
                            onChangeText={text => setPhonenum(text)}
                            editable={passTime ? false : passSucess ? false : true}
                        />
                    </View>
                    <TouchableOpacity style={{
                        width: '28%',
                        height: perfectSize(48),
                        borderRadius: perfectSize(10),
                        backgroundColor: passTime || passSucess ? c_indigo : c_primary_red,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }} onPress={onSign}>
                        <Text style={{
                            color: c_white,
                            fontSize: perfectSize(12),
                            fontWeight: 'normal',
                            letterSpacing: -0.02,
                            textAlign: "center"
                        }}>{'인증번호 \n요청'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={[styles.phoneInputContainer, {marginTop: perfectSize(6)}]}>
                    <View style={styles.phoneInput}>
                        <View style={{
                            alignSelf: 'stretch'
                        }}>
                            <TextInput
                                value={passCode}
                                placeholder={'인증번호 입력'}
                                keyboardType={'phone-pad'}
                                maxLength={11}
                                style={{
                                    width: '97%',
                                    height: perfectSize(48),
                                    paddingVertical: perfectSize(14),
                                    borderWidth: 1,
                                    borderColor: c_second_grey,
                                    fontSize: perfectSize(15),
                                    paddingHorizontal: perfectSize(15),
                                    fontWeight: 'normal'
                                }}
                                onChangeText={text => setPassCode(text)}
                                editable={passSucess ? false : true}
                            />
                            <Text style={{
                                position: 'absolute',
                                right: perfectSize(22),
                                top: perfectSize(15),
                                color: c_fifth_grey,
                                fontSize: perfectSize(14),
                                fontWeight: 'normal',
                                letterSpacing: -0.02,
                            }}>
                                {passSucess ?
                                    '00:00' :
                                    remainingTime}
                            </Text>

                        </View>
                    </View>
                    <TouchableOpacity style={{
                        width: '28%',
                        height: perfectSize(48),
                        borderRadius: perfectSize(10),
                        backgroundColor: passSucess ? c_indigo : c_primary_red,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }} onPress={onPassCode}>
                        <Text style={{
                            color: c_white,
                            fontSize: perfectSize(12),
                            fontWeight: 'normal',
                            letterSpacing: -0.02,
                            textAlign: "center",
                        }}>{'확인'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={[styles.phoneInputContainer, {marginTop: perfectSize(6)}]}>
                    <View style={styles.phoneInput}>
                        <CustomTextInput
                            value={password}
                            placeholder={'비밀번호'}
                            style={{
                                width: '100%',
                                height: perfectSize(48),
                                fontSize: perfectSize(15),
                                fontWeight: 'normal'
                            }}
                            onChangeText={text => {
                                setEqualErr(false);
                                setPassword(text);
                            }}
                            isSecure={true}
                            isShowEye={true}
                        />
                    </View>
                </View>
                <Text style={{
                    color: c_fourth_black,
                    fontSize: perfectSize(14),
                    fontWeight: 'normal',
                    letterSpacing: -0.02,
                    marginTop: perfectSize(6),
                }}
                >영어 대문자,숫자,특수문자를 3개 이상 포함한</Text>
                <Text style={{
                    color: c_fourth_black,
                    fontSize: perfectSize(14),
                    fontWeight: 'normal',
                    letterSpacing: -0.02,
                }}
                >8자~12자를 입력하세요.</Text>
                <View style={[styles.phoneInputContainer, {marginTop: perfectSize(15)}]}>
                    <View style={styles.phoneInput}>
                        <CustomTextInput
                            warningText={'비밀번호가 일치하지 않습니다'}
                            isWarn={equalErr}
                            value={confirmPassword}
                            placeholder={'비밀번호 확인'}
                            style={{
                                width: '100%',
                                height: perfectSize(48),
                                fontSize: perfectSize(15),
                                fontWeight: 'normal'
                            }}
                            onChangeText={text => {
                                setEqualErr(false);
                                setConfirmPassword(text);
                            }}
                            isSecure={true}
                            isShowEye={true}
                        />
                    </View>
                </View>
                <Text style={{
                    color: c_primary_red,
                    fontSize: perfectSize(14),
                    fontWeight: 'normal',
                    letterSpacing: -0.02,
                    marginTop: perfectSize(6)
                }}>{}</Text>
                <View style={{
                    bottom: perfectSize(!sUsrAg.includes('Mobile') ? -60 : 0)
                }}>
                    <Button
                        onPress={onReset}
                        textColor={c_white}
                        label={'확인'}
                        color={c_primary_red}
                        disable={(!password || !confirmPassword) ? true : false}
                        loading={loading}
                    />
                </View>
            </KeyboardAwareScrollView>
            {visibleAlertModal &&
                <OneAlertModal
                    isVisible={visibleAlertModal}
                    hideModal={() => {
                        setVisibleAlertModal(false);
                    }}
                    contents={isAlertText}
                    txt_type={"nomal"}
                />
            }
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(60),
        paddingHorizontal: perfectSize(21)
    },
    marginV: {
        marginTop: perfectSize(18),
        marginBottom: perfectSize(30)
    },
    phoneInputContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        marginBottom: perfectSize(12)
    },
    countryCode: {
        width: perfectSize(72),
        height: perfectSize(48),
        borderWidth: 1,
        borderColor: c_second_grey,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: perfectSize(6)
    },
    phoneInput: {
        flex: 1,
    },
}