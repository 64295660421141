import _ from "lodash";
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {View, Text, Image, TouchableOpacity, Dimensions} from "react-native";
import {
    c_second_grey,
    c_primary_red,
    c_second_black,
    c_second_white,
    c_white,
    c_grey,
    c_primary_black,
    c_light_grey,
} from "../../../variables/colors";
import {globalStyle, perfectSize} from "../../../variables/styles";
import Button from "../../../components/Button";
import BorderButton from "../../../components/BorderButton";
import Separator from "../../../components/Separator";
import Badge from "../../../components/Badge";
import {useNavigation} from "@react-navigation/native";
import {deleteReview, deleteExpectation} from "../../../store/actions";
import Review from "../../../components/Reveiw";

const {width} = Dimensions.get("window");

function Reviews({
                     performance,
                     user,
                     reviews,
                     deleteReview,
                     deleteExpectation,
                     expectations,
                 }) {
    const navigation = useNavigation();

    const [reviewType, setReviewType] = useState(false);
    const [recommandType, setRecommandTyp] = useState(true);
    const [review, setReview] = useState(reviews);
    const [expectation, setExpectation] = useState(expectations);

    useEffect(() => {
        if (recommandType) {
            const _review = _.sortBy(reviews, o => {
                return o.likes.length;
            });
            _review.reverse();
            setReview(_review);
        } else {
            const _review = _.sortBy(reviews, o => {
                return o.createdAt;
            });
            _review.reverse();
            setReview(_review);
        }
    }, [recommandType, reviews]);

    useEffect(() => {
        if (recommandType) {
            const _expectation = _.sortBy(expectations, o => {
                return o.likes.length;
            });
            _expectation.reverse();
            setExpectation(_expectation);
        } else {
            const _expectation = _.sortBy(expectations, o => {
                return o.createdAt;
            });
            _expectation.reverse();
            setExpectation(_expectation);
        }
    }, [recommandType, expectations]);

    const renderReviews = () => {
        if (_.isEmpty(review)) return;
        // console.log(review[review.length - 1])
        return review.map((item, index) => {
            return (
                <Review
                    data={item}
                    key={item._id}
                    onDelete={() => deleteReview(item._id)}
                    type={false}
                />
            );
        });
    };

    const onCreateReview = () => {
        if (user) {
            navigation.navigate("CreateReview", {
                performance: performance,
                type: !reviewType ? "review" : "expectation",
                user: user,
            }, {replace: true});
        } else {
            navigation.navigate("SignIn", {screenName: 'Reviews'});
        }
    };

    return (
        <View style={styles.mainContainer}>
            {/** Review Header */}
            <View
                style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Text style={globalStyle.regularBoldText}>{"리뷰"}</Text>
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <TouchableOpacity
                        onPress={() => setRecommandTyp(true)}
                        style={styles.recommend}
                    >
                        <Image
                            source={
                                recommandType
                                    ? require("../../../../assets/images/ic_check_on.png")
                                    : require("../../../../assets/images/ic_check_off.png")
                            }
                            style={styles.checkIcon}
                        />
                        <Text
                            style={
                                recommandType
                                    ? globalStyle.regularText
                                    : globalStyle.regularGreyText
                            }
                        >
                            {"추천순"}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => setRecommandTyp(false)}
                        style={styles.recommend}
                    >
                        <Image
                            source={
                                !recommandType
                                    ? require("../../../../assets/images/ic_check_on.png")
                                    : require("../../../../assets/images/ic_check_off.png")
                            }
                            style={styles.checkIcon}
                        />
                        <Text
                            style={
                                !recommandType
                                    ? globalStyle.regularText
                                    : globalStyle.regularGreyText
                            }
                        >
                            {"최신순"}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>

            {/** No Review */}
            {!reviewType
                ? _.isEmpty(reviews) && (
                <Text style={styles.noReview}>{"리뷰가 없습니다."}</Text>
            )
                : _.isEmpty(expectation) && (
                <Text style={styles.noReview}>{"기대평이 없습니다."}</Text>
            )}

            {/** Review List */}
            {renderReviews()}

            {/* <BorderButton
        onPress={onCreateReview}
        color={c_primary_red}
        label={"리뷰작성"}
        marginRight={perfectSize(15)}
        buttonStyle={{
          width: "70%",
          marginTop: perfectSize(10),
          marginRight: perfectSize(5),
        }}
      /> */}
            <TouchableOpacity
                onPress={onCreateReview}
                style={{
                    width: "90%",
                    borderRadius: 5,
                    borderColor: c_primary_red,
                    borderWidth: 1,
                    paddingVertical: perfectSize(15),
                    alignItems: "center",
                    marginTop: perfectSize(30),
                    marginBottom: perfectSize(50),
                }}
            >
                <Text style={{
                    fontSize: perfectSize(15),
                    color: c_primary_red,
                    lineHeight: perfectSize(17),
                    textAlign: 'center',
                }}>
                    리뷰작성{'\n'}
                    <Text style={{
                        fontSize: perfectSize(13),
                    }}>
                        리뷰 작성 시 5TTR 지급(1일 1회 한정)
                    </Text>
                </Text>
                {/*<Text*/}
                {/*  style={{*/}
                {/*    fontSize: perfectSize(12),*/}
                {/*    color: c_primary_red,*/}
                {/*    lineHeight: perfectSize(13.6),*/}
                {/*    marginTop: perfectSize(6),*/}
                {/*  }}*/}
                {/*>*/}
                {/*  작성 시 50통통마일 리워드 지급*/}
                {/*</Text>*/}
            </TouchableOpacity>
        </View>
    );
}

const mapStateTopProps = state => ({
    user: state.auth.user,
    reviews: state.app.reviews,
    expectations: state.app.expectations,
});

export default connect(mapStateTopProps, {deleteReview, deleteExpectation})(
    Reviews
);

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(30),
        paddingHorizontal: perfectSize(15),
        alignItems: "center",
    },
    noReview: {
        ...globalStyle.greyNormalText,
        color: c_second_grey,
        textAlign: "center",
        marginTop: perfectSize(40),
    },
    checkIcon: {
        width: perfectSize(11),
        height: perfectSize(8),
        resizeMode: "contain",
        marginRight: perfectSize(5),
    },
    recommend: {
        flexDirection: "row",
        alignItems: "center",
        marginRight: perfectSize(15),
    },
    star: {
        width: perfectSize(12),
        height: perfectSize(12),
        resizeMode: "contain",
    },
    badge: {
        backgroundColor: c_white,
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(3),
        width: perfectSize(44),
        height: perfectSize(21),
        marginLeft: perfectSize(8),
    },
    reportIcon: {
        width: perfectSize(13),
        height: perfectSize(13),
        resizeMode: "contain",
        marginRight: perfectSize(5),
    },
};
