import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
} from 'react-native';
import {
    c_black,
    c_dark_white,
    c_primary_red,
    c_second_grey,
    c_second_white,
    c_third_grey,
    c_white
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import TicketCard from '../../components/TicketCard';
import { UPLOAD_URL } from '../../variables/constants';
import { makeTwoString, numberWithCommas } from '../../utils';
import moment from 'moment';

function TicketDetail({ navigation, route, user }) {

    const [isAllSelected, setAllSelect] = useState(false);
    const { data } = route.params;
    const [selectedCount, setSelectedCount] = useState(0);

    const d = new Date(data.product_id.schedule.detailed_time[data.round].date);
    d.setDate(d.getDate() - 1);

    useEffect(() => {
        navigation.setOptions({
            title: "상세내역",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    // useEffect(() => {
    //     selectedCount === data.tickets.length ?
    //         setAllSelect(true)
    //         : setAllSelect(false)
    // }, [selectedCount]);

    useEffect(() => {
        let count = 0;
        data.tickets.map(item => {
            !item.isCancelled && !item.isGift && count++;
            setSelectedCount(count);
        })
    }, []);

    return (
        <View style={styles.mainContainer}>
            <ScrollView>
                <View>
                    <View style={styles.item}>
                        <Image
                            source={{ uri: (UPLOAD_URL + data.product_id.poster_link) }}
                            style={{
                                width: '100%',
                                height: perfectSize(420),
                                resizeMode: 'stretch',
                                backgroundColor: c_second_white,
                                marginTop: perfectSize(15)
                            }}
                        />
                        <Text style={[globalStyle.bigBoldText, { marginTop: perfectSize(15) }]}>
                            {data.product_id.title.name}
                        </Text>
                        <Text style={[globalStyle.normalText, { marginTop: perfectSize(10) }]}>
                            {`${data.product_id.location.name} I ${moment(data.product_id.schedule.detailed_time[data.round].date).format('YYYY.MM.DD')} ${data.product_id.schedule.detailed_time[data.round].hour}:${makeTwoString(data.product_id.schedule.detailed_time[data.round].minute)}`}
                        </Text>
                        <Text style={[globalStyle.normalText, { marginVertical: perfectSize(10) }]}>
                            {`${data.tickets.length}매`}
                        </Text>
                    </View>

                    {/** 좌석정보 */}
                    <View style={[styles.item, { paddingBottom: perfectSize(30) }]}>
                        <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                            {'좌석정보'}
                        </Text>
                        {/* <TouchableOpacity
                            onPress={() => {
                                if (isAllSelected) {
                                    setSelectedCount(0);
                                } else {
                                    setSelectedCount(data.tickets.length);
                                }
                                data.tickets.map((ticket) => {
                                    ticket.isSelected = !isAllSelected;
                                });
                                setAllSelect(!isAllSelected);
                            }}
                            style={[globalStyle.flexRow, { marginBottom: perfectSize(6) }]}>
                            <Image
                                source={
                                    isAllSelected ?
                                        require('../../../assets/images/ic_ticket_all_check_on.png') :
                                        require('../../../assets/images/ic_ticket_all_check_off.png')}
                                style={{
                                    width: perfectSize(17),
                                    height: perfectSize(17),
                                    marginRight: perfectSize(8),
                                    resizeMode: 'contain'
                                }}
                            />
                            <Text style={globalStyle.regularText}>
                                {'전체선택'}
                            </Text>
                        </TouchableOpacity> */}
                        <View style={{ marginHorizontal: perfectSize(-15) }}>
                            {
                                data.tickets.map((ticket, index) => {
                                    return <TicketCard
                                        disableTouch={true}
                                        key={ticket._id}
                                        ticketLabel={true}
                                        selected={data.tickets[index].isSelected}
                                        onSelect={select => {
                                            data.tickets[index].isSelected = select;
                                            if (select) {
                                                setSelectedCount(selectedCount + 1);
                                            } else {
                                                setSelectedCount(selectedCount - 1);
                                            }
                                        }}
                                        isConfirm={ticket.isCancelled || ticket.isGift}
                                        status={'취소완료'}
                                        data={ticket}
                                        cancelledTime={moment(ticket.cancelledTime).format('YYYY.MM.DD HH:mm')}
                                    />
                                })
                            }
                            {/* <TicketCard
                                ticketLabel={true}
                                isConfirm={true}
                                status={'취소완료'}
                                selected={isAllSelected}
                            /> */}
                            <View style={{
                                marginHorizontal: perfectSize(15),
                                marginTop: perfectSize(10)
                            }}>
                                <Button
                                    onPress={() => {
                                        data.tickets.map((item, index) => {
                                            data.tickets[index].isSelected = false;
                                        });
                                        navigation.navigate('TicketCancel', { data });
                                    }}
                                    isBorder={false}
                                    textColor={c_white}
                                    label={'예매취소'}
                                    color={c_primary_red}
                                    disable={!selectedCount ? true : false}
                                />
                            </View>
                            {/* <Button
                                onPress={() => { }}
                                label={'예매취소'}
                                buttonStyle={{
                                    backgroundColor:
                                        c_primary_red,
                                    // isAllSelected ? c_primary_red : c_light_grey,
                                    marginHorizontal: perfectSize(15),
                                    marginTop: perfectSize(10),
                                    height: perfectSize(45)
                                }}
                            // disable={isAllSelected ? false : true}
                            /> */}
                        </View>
                    </View>

                    {/** 예매정보 */}
                    <View style={styles.item}>
                        <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                            {'예매정보'}
                        </Text>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'예매번호'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {data.order_id}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'예매자'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {data.orderer_name}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'예매일시'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {data.tickets.length && moment(data.tickets[0].createdAt).format('YYYY.MM.DD HH:mm')}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'예매상태'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {'예매성공'}
                            </Text>
                        </View>
                        <View style={[globalStyle.flexRow, { marginBottom: perfectSize(20) }]}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'취소마감'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {`${moment(d).format('YYYY.MM.DD')} 17:00 까지`}
                            </Text>
                        </View>
                    </View>

                    {/** 수령방법 */}
                    <View style={styles.item}>
                        <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                            {'수령방법'}
                        </Text>
                        <Text style={[globalStyle.normalText, { marginBottom: perfectSize(10) }]}>
                            {data.delivery_method}
                        </Text>
                        {
                            data.delivery_method === '모바일티켓' &&
                            <>
                                <Text style={[globalStyle.greyNormalText, { marginTop: perfectSize(10), marginBottom: perfectSize(20) }]}>
                                    {'[모바일티켓 안내 및 유의사항]'}
                                </Text>
                                <Text style={[globalStyle.greyNormalText, { marginBottom: perfectSize(20), fontWeight: 'normal' }]}>
                                    {'· 티켓 예매 시 티켓수령방법을 모바일티켓으로 하신 인터파크 회원만 인터파크 앱이나 인터파크 티켓 앱에서 이용 가능합니다.\n\n· 스포츠 시즌권 구매고객이거나 인터파크 외 예매처에서 예매한 모바일티켓은 별도의 모바일티켓 앱을 통해서만 이용 가능합니다.\n\n· 모바일티켓 캡쳐화면으로는 입장이 불가능합니다. 현장에 도착하면 빠른 입장을 위하여 앱을 실행하여 모바일티켓을 준비해주세요.\n\n· 예매를 취소한 경우, 모바일티켓 콘텐츠를 이용하실 수 없습니다.\n\n· 선물한 모바일티켓으로 입장은 가능하지만, 직접 예매를 취소할 수없습니다. 선물 거절을 요청하면, 원 예매자가 선물했던 모바일티켓을 다시 사용하거나 취소 처리할 수 있습니다.\n\n· 모바일티켓을 선물한 경우, 티켓 취소가 불가능하오니 선물하신 분께 등록한 선물 티켓을 거절 요청 후 취소하시기 바랍니다.\n\n· 공연관람 이후에도 모바일티켓은 사라지지 않습니다.\n\n· 모바일티켓으로 예매하실 경우 종이 티켓은 제공되지 않습니다.'}
                                </Text>
                            </>
                        }
                    </View>

                    {/** 인증정보 */}
                    <View style={styles.item}>
                        <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                            {'인증정보'}
                        </Text>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'인증자명'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {data.orderer_name}
                            </Text>
                        </View>
                        <View style={[globalStyle.flexRow, { marginBottom: perfectSize(20) }]}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'전화번호'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {data.orderer_phone}
                            </Text>
                        </View>
                    </View>

                    {/** 금액상세 */}
                    <View style={styles.item}>
                        <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                            {'금액상세'}
                        </Text>
                        <View style={globalStyle.flexSpaceBetween}>
                            <Text style={[globalStyle.regularRedBoldText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'총 금액'}
                            </Text>
                            <Text style={[globalStyle.regularRedBoldText, { marginBottom: perfectSize(10) }]}>
                                {`${numberWithCommas(data.total_price)}원`}
                            </Text>
                        </View>
                        <View style={globalStyle.flexSpaceBetween}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'티켓금액'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {`${numberWithCommas(data.main_price - data.discount + data.coupon_price + data.invitation_discount - data.point)}원`}
                            </Text>
                        </View>
                        <View style={{
                            paddingHorizontal: perfectSize(15),
                            paddingVertical: perfectSize(12),
                            backgroundColor: c_dark_white,
                            marginBottom: perfectSize(10)
                        }}>
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                    {'기본가'}
                                </Text>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>
                                    {`${numberWithCommas(data.main_price)}원`}
                                </Text>
                            </View>
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                    {'가격할인'}
                                </Text>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>
                                    {`${numberWithCommas(-data.discount)}원`}
                                </Text>
                            </View>
                            {/*<View style={globalStyle.flexSpaceBetween}>*/}
                            {/*    <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>*/}
                            {/*        {'쿠폰할인'}*/}
                            {/*    </Text>*/}
                            {/*    <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>*/}
                            {/*        {`${numberWithCommas(data.coupon_price)}원`}*/}
                            {/*    </Text>*/}
                            {/*</View>*/}
                            {/*<View style={globalStyle.flexSpaceBetween}>*/}
                            {/*    <Text style={[globalStyle.regularGreyText, { width: perfectSize(80) }]}>*/}
                            {/*        {'예매권할인'}*/}
                            {/*    </Text>*/}
                            {/*    <Text style={globalStyle.regularGreyText}>*/}
                            {/*        {`${numberWithCommas(data.invitation_discount)}원`}*/}
                            {/*    </Text>*/}
                            {/*</View>*/}
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                    {'TTCO할인 차감'}
                                </Text>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>
                                    {`${numberWithCommas(data.point)}원`}
                                </Text>
                            </View>
                        </View>
                        {
                            data.delivery_method === '배송' &&
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                    {'배송비'}
                                </Text>
                                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                    {`${numberWithCommas(data.delivery_fee)}원`}
                                </Text>
                            </View>
                        }
                        <View style={globalStyle.flexSpaceBetween}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(20), width: perfectSize(80) }]}>
                                {'예매수수료'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(20) }]}>
                                {`${numberWithCommas(data.fee)}원`}
                            </Text>
                        </View>
                        {
                            data.delivery_method === '배송' &&
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(20), width: perfectSize(80) }]}>
                                    {'배송비'}
                                </Text>
                                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(20) }]}>
                                    {`${numberWithCommas(data.delivery_fee)}원`}
                                </Text>
                            </View>
                        }
                    </View>

                    {/** 결제정보 */}
                    {/* {
                        data.total_price ?
                            <View style={styles.item}>
                                <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                                    {'결제정보'}
                                </Text>
                                <View style={globalStyle.flexSpaceBetween}>
                                    <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                        {'신용카드'}
                                    </Text>
                                    <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                        {'100,000원'}
                                    </Text>
                                </View>
                                <View style={{ marginBottom: perfectSize(20) }}>
                                    <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>
                                        {'NH(4652-****-****-**51) 일시불'}
                                    </Text>
                                    <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>
                                        {'2021.03.24   01:17:46'}
                                    </Text>
                                </View>
                            </View>
                            : null
                    } */}

                    {/** 취소규정 */}
                    <View style={[styles.item, { paddingBottom: perfectSize(30) }]}>
                        <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                            {'취소규정'}
                        </Text>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'취소마감'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {`${moment(d).format('YYYY.MM.DD')} 17:00 까지`}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'취소수수료'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {'0%~30%'}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>
                                {'※ 예매일 이후 취소시, 예매수수료는 환불되지 않습니다. \n※ 예매 후 7일 이내라도 취소시점이 공연/행사일로부터 10일 이내라면 그에 해당하는 취소수수료 부과됩니다.'}
                            </Text>
                        </View>
                        <View style={styles.tableContainer}>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.headerCell}>
                                    <Text style={globalStyle.regularText}>
                                        {'취소일시'}
                                    </Text>
                                </View>
                                <View style={styles.headerCell}>
                                    <Text style={globalStyle.regularText}>
                                        {'취소수수료'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'예매 후 7일 이내'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'없음'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'예매 후 8일 ~ 관람일 10일 전'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'2,000원'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'관람일 9일 전 ~ 7일 전'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'티켓금액의 10%'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'관람일 6일 전 ~ 3일 전'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'티켓금액의 20%'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'관람일 2일 전 ~ 전일 17시 전'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'티켓금액의 30%'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'관람일 전일 17시 이후'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'취소불가'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={styles.item}>
                        <Footer marginTop={perfectSize(20)} marginBottom={perfectSize(40)} />
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateTopProps)(TicketDetail);

const styles = {
    mainContainer: {
        flex: 1,
    },
    dotLine: {
        borderStyle: 'dashed',
        borderWidth: 1,
        marginBottom: perfectSize(15),
    },
    orderDetail: {
        flex: 1,
        marginBottom: perfectSize(4)
    },
    orderConfirmCard: {
        marginBottom: perfectSize(20),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
        shadowColor: c_black,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        elevation: 2,
    },
    noticeIcon: {
        width: perfectSize(17),
        height: perfectSize(17),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
    countDown: {
        color: c_primary_red,
        fontSize: perfectSize(22),
        fontWeight: 'bold',
        marginBottom: perfectSize(10),
        marginTop: perfectSize(5)
    },
    warningMsg: {
        borderRadius: perfectSize(2),
        borderWidth: 1,
        borderColor: c_second_grey,
        paddingHorizontal: perfectSize(6),
        paddingVertical: perfectSize(2),
        marginLeft: perfectSize(10)
    },
    item: {
        backgroundColor: c_white,
        paddingHorizontal: perfectSize(15),
        marginBottom: perfectSize(8),
    },
    tableContainer: {
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderColor: c_third_grey
    },
    headerCell: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: perfectSize(10),
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderColor: c_third_grey,
        backgroundColor: c_second_white
    },
    cell: {
        flex: 1,
        alignItems: 'center',
        height: perfectSize(40),
        justifyContent: 'center',
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderColor: c_third_grey,
        opacity: 0.6
    }
}