import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  TouchableOpacity,
  Image,
  ScrollView,
  Linking,
  Platform,
  Animated,
  StyleSheet,
} from "react-native";
import { c_primary_red } from "../../variables/colors";
import { perfectSize } from "../../variables/styles";
import AntDesign from "@expo/vector-icons/AntDesign";
import { useNavigation } from "@react-navigation/native";

const IconList = props => {
  const { toggleBar } = props;
  const { AppIconReducer, auth } = useSelector(state => state);
  const refVisibleAppBar = useRef(new Animated.Value(perfectSize(50))).current;
  const menuIconsRef = useRef(null);
  const navigation = useNavigation();

  const [currentPos, setCurrentPos] = useState(0);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    if (toggleBar) {
      Animated.timing(refVisibleAppBar, {
        toValue: perfectSize(50),
        duration: 300,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(refVisibleAppBar, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    }
  }, [toggleBar]);

  const onMenuIconClick = item => {
    if (Platform.OS === "android") {
      const uri = `${
        item.android_scheme_name
      }://m.etomato.com?open=fromapp&from=${"com.etomato.ttticket"}&phone=${
        auth.user?.decPhoneNum || ""
      }`;
      Linking.openURL(uri).catch(() =>
        Linking.openURL(
          `http://play.google.com/store/apps/details?id=${item.android_package_name}`
        )
      );
    }
    if (Platform.OS === "ios") {
      const uri = `${
        item.ios_scheme_name
      }://fromapp?from=tongtongticket&phone=${auth.user?.decPhoneNum || ""}`;
      Linking.openURL(uri).catch(err => {
        Linking.openURL(item.app_store_url);
      });
    } else {
      Linking.openURL(item.app_store_url);
    }
  };

  const onScroll = e => {
    const { contentOffset, layoutMeasurement, contentSize } = e.nativeEvent;
    setCurrentPos(contentOffset.x);
    setIsBottom(
      layoutMeasurement.width + contentOffset.x >= contentSize.width - 30
    );
  };

  return (
    <Animated.View
      style={[
        styles.animatedWrap,
        {
          transform: [{ translateY: refVisibleAppBar }],
        },
      ]}
    >
      <TouchableOpacity
        onPress={() => menuIconsRef.current.scrollTo({ x: currentPos - 120 })}
        style={styles.navButton}
      >
        {currentPos > 0 && (
          <AntDesign name="caretleft" size={perfectSize(20)} color="white" />
        )}
      </TouchableOpacity>
      <ScrollView
        ref={menuIconsRef}
        onScroll={onScroll}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        {AppIconReducer.appIcons.map((item, index) => {
          return (
            <TouchableOpacity key={index} onPress={() => onMenuIconClick(item)}>
              <Image source={{ uri: item.menu_icon }} style={styles.menuIcon} />
            </TouchableOpacity>
          );
        })}
        <TouchableOpacity
          key={"setting"}
          onPress={() => navigation.navigate("BottomIcons")}
        >
          <Image
            source={require("../../../assets/images/ic_setting.png")}
            style={styles.settingIcon}
          />
        </TouchableOpacity>
      </ScrollView>
      <TouchableOpacity
        onPress={() => menuIconsRef.current.scrollTo({ x: currentPos + 120 })}
        style={styles.navButton}
      >
        {!isBottom && (
          <AntDesign name="caretright" size={perfectSize(20)} color="white" />
        )}
      </TouchableOpacity>
    </Animated.View>
  );
};

export default IconList;

const styles = StyleSheet.create({
  animatedWrap: {
    backgroundColor: c_primary_red,
    height: perfectSize(50),
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  navButton: {
    height: perfectSize(50),
    justifyContent: "center",
    alignItems: "center",
    width: perfectSize(30),
  },
  menuIcon: {
    width: perfectSize(38),
    height: perfectSize(38),
    marginRight: perfectSize(28),
    marginTop: perfectSize(5),
  },
  settingIcon: {
    width: perfectSize(28),
    height: perfectSize(28),
    marginRight: perfectSize(28),
    marginTop: perfectSize(10),
    tintColor: "white",
  },
});
