import React from 'react';
import {
    View,
    Text,
    TouchableOpacity
} from 'react-native';
import {
    c_white,
    c_primary_blue,
    c_light_grey,
    c_primary_red
} from '../variables/colors';
import { perfectSize } from '../variables/styles';
import { ActivityIndicator } from 'react-native';

export default function Button({
    onPress,
    color,
    label,
    isBorder,
    disable,
    loading,
    textColor,
    borderColor,
    borderRadius
}) {
    return (
        <TouchableOpacity
            disabled={disable || loading}
            onPress={onPress}
            style={[
                isBorder ? styles.borderContainer : styles.container,
                { borderColor: borderColor ? borderColor : c_primary_red },
                // { backgroundColor: disable ? c_light_grey : color },
                { backgroundColor: color },
                { opacity: disable ? 0.2 : 1 },
                { borderRadius: borderRadius ? borderRadius : perfectSize(3) }
            ]}>
            {
                loading
                    ? <ActivityIndicator
                        size={'small'}
                        color={textColor}
                    />
                    : <Text style={[styles.label, { color: textColor }]}>{label}</Text>
            }
        </TouchableOpacity>
    )
}

const styles = {
    container: {
        backgroundColor: c_primary_blue,
        height: perfectSize(45),
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: perfectSize(3),
    },
    label: {
        color: c_white,
        fontSize: perfectSize(15)
    },
    borderContainer: {
        backgroundColor: 'transparent',
        height: perfectSize(45),
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: c_primary_red,
        borderRadius: perfectSize(3),
    },
}