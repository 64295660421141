import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  StyleSheet,
  ScrollView,
  TextInput,
  Text,
} from "react-native";
import { c_primary_red } from "../../../variables/colors";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { useNavigation } from "@react-navigation/native";
import NFTList from "./NFTList";

const NFTDetail = props => {
  const navigation = useNavigation();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    navigation.setOptions({
      title: "NFT",
      headerLeft: () => (
          <View style={globalStyle.flexRow}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Image
                  source={require("../../../../assets/images/ic_before.png")}
                  style={globalStyle.headerLeftBack}
              />
            </TouchableOpacity>
          </View>
      ),
      headerRight: () => (
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
                source={require("../../../../assets/images/ic_close.png")}
                style={globalStyle.navIcon}
            />
          </TouchableOpacity>
      ),
    });
  }, []);

  return (
      <View style={styles.mainContainer}>
        <View style={styles.searchWrapper}>
          <View style={styles.searchInput}>
            <TextInput
                value={searchText}
                onChangeText={text => setSearchText(text)}
                returnKeyType="search"
                style={[globalStyle.regularText, { flex: 1 }]}
                placeholder={"검색어를 입력해주세요"}
            />
            <View>
              <Image
                  source={require("../../../../assets/images/ic_search_b.png")}
                  style={globalStyle.searchIcon}
              />
            </View>
          </View>
          <TouchableOpacity
              onPress={() => navigation.navigate("MyNFT")}
              style={styles.nftSellbutton}
          >
            <Text style={styles.nftSellButtonLabel}>{"MY NFT"}</Text>
          </TouchableOpacity>
        </View>
        <ScrollView
            contentContainerStyle={{
              padding: perfectSize(15),
              paddingTop: perfectSize(0),
            }}
        >
          {/*<NFTList typeLabel="비디오" type="video" searchText={searchText} />*/}
          {/*<NFTList typeLabel="VR360" type="vr360" searchText={searchText} />*/}
          {/*<NFTList typeLabel="FLAC" type="flac" searchText={searchText} />*/}
          {/*<NFTList typeLabel="DSD" type="dsd" searchText={searchText} />*/}
        </ScrollView>
      </View>
  );
};

export default NFTDetail;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "white",
  },
  searchWrapper: {
    flexDirection: "row",
    paddingHorizontal: perfectSize(15),
    alignItems: "center",
  },
  searchInput: {
    flex: 1,
    height: perfectSize(40),
    borderColor: c_primary_red,
    borderWidth: 1,
    borderRadius: perfectSize(6),
    marginVertical: perfectSize(20),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: perfectSize(15),
    marginRight: perfectSize(5),
  },
  nftSellbutton: {
    height: perfectSize(40),
    paddingHorizontal: perfectSize(10),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: c_primary_red,
    borderRadius: perfectSize(10),
  },
  nftSellButtonLabel: {
    color: "white",
    fontSize: perfectSize(15),
  },
});