import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
} from 'react-native';
import {
    c_light_blue,
    c_white,
    c_primary_blue,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import CustomTextInput from '../../components/CustomTextInput';
import { join } from '../../store/actions';
import { oneAlert } from '../../utils';

function SetPassword({ navigation, route, join }) {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [equalErr, setEqualErr] = useState(false);
    const [strongErr, setStrongErr] = useState(false);

    useEffect(() => {
        navigation.setOptions({
            title: "비밀번호 설정",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    const onSetPassword = async () => {
        const { userkey, phonenum } = route.params;
        if (password !== confirmPassword) {
            setEqualErr(true);
            return;
        }
        const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/;
        if (!pattern.test(password)) {
            setStrongErr(true);
            return;
        }
        navigation.navigate('Agree', {user_status: 'join', phonenum: phonenum, passwd: password, userkey: userkey});
    }

    return (
        <View style={globalStyle.mainContainer}>
            <View style={styles.mainContainer}>
                <CustomTextInput
                    warningText={'영문+숫자+특수기호 조합 8~15자'}
                    isWarn={strongErr}
                    placeholder={'비밀번호 입력'}
                    value={password}
                    onChangeText={text => {
                        setEqualErr(false);
                        setStrongErr(false);
                        setPassword(text);
                    }}
                    style={{ marginBottom: perfectSize(6) }}
                    isSecure={true}
                />
                {
                    !strongErr &&
                    <Text style={[globalStyle.smText, styles.bottomText]}>
                        {'영문+숫자+특수기호 조합 8~15자'}
                    </Text>
                }

                <CustomTextInput
                    warningText={'비밀번호가 일치하지 않습니다'}
                    isWarn={equalErr}
                    placeholder={'비밀번호 확인'}
                    value={confirmPassword}
                    onChangeText={text => {
                        setEqualErr(false);
                        setStrongErr(false);
                        setConfirmPassword(text);
                    }}
                    style={{ marginBottom: perfectSize(6), marginTop: perfectSize(30) }}
                    isSecure={true}
                />
                {
                    !equalErr &&
                    <Text style={[globalStyle.smText, styles.bottomText]}>
                        {'비밀번호를 한 번 더 입력하세요'}
                    </Text>
                }

                <View style={styles.bottomView}>
                    <Button
                        onPress={onSetPassword}
                        textColor={c_white}
                        label={'다음'}
                        color={c_primary_red}
                        disable={(!password || !confirmPassword) ? true : false}
                        loading={loading}
                    />
                </View>
            </View>
        </View>
    )
}

const mapStateTopProps = state => ({

});

export default connect(mapStateTopProps, { join })(SetPassword);

const styles = {
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        marginTop: perfectSize(21),
        paddingHorizontal: perfectSize(21)
    },
    bottomView: {
        position: 'absolute',
        bottom: perfectSize(30),
        left: perfectSize(21),
        right: perfectSize(21)
    },
    bottomText: {
        alignSelf: 'stretch',
        textAlign: 'left',
        color: c_light_blue,
    },
}