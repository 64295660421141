import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Image, ScrollView } from "react-native";
import moment from "moment";

import {
  c_primary_black,
  c_primary_blue,
  c_primary_red,
  c_white,
} from "../../variables/colors";
import { globalStyle, perfectSize } from "../../variables/styles";
import { Calendar, LocaleConfig } from "react-native-calendars";
import RestSeat from "./RestSeat";

LocaleConfig.locales["kr"] = {
  monthNames: [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ],
  monthNamesShort: [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ],
  dayNames: [
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일",
  ],
  dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
  today: "오늘",
};
LocaleConfig.defaultLocale = "kr";

export default function SelectDate({ navigation, route }) {
  const { performance } = route.params;
  const { detailed_time, from, to } = performance.schedule;
  const [dateSelected, setDateSelect] = useState();
  const [selectedDate, selectDate] = useState();

  useEffect(() => {
    navigation.setOptions({
      title: performance.title.name,
      headerLeft: () => (
          <View style={globalStyle.flexRow}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Image
                  source={require("../../../assets/images/ic_before.png")}
                  style={globalStyle.headerLeftBack}
              />
            </TouchableOpacity>
          </View>
      ),
      headerRight: () => (
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
                source={require("../../../assets/images/ic_close.png")}
                style={globalStyle.navIcon}
            />
          </TouchableOpacity>
      ),
    });
  }, []);

  const renderHeader = (date) => {
    const newDate = new Date(date);
    return (
        <View style={[globalStyle.flexRow, styles.calendarHeader]}>
          <Text style={globalStyle.bigBoldText}>
            {newDate.getFullYear().toString() +
                ". " +
                parseInt(newDate.getMonth() + 1)}
          </Text>
        </View>
    );
  };

  const renderArrow = (direction) => {
    if (direction === "left") {
      return (
          <View style={styles.calendarLeft}>
            <Image
                source={require("../../../assets/images/ic_calendar_before.png")}
                style={styles.calendarArrowIcon}
            />
          </View>
      );
    } else {
      return (
          <View style={styles.calendarRight}>
            <Image
                source={require("../../../assets/images/ic_calendar_next.png")}
                style={styles.calendarArrowIcon}
            />
          </View>
      );
    }
  };

  const renderRestSeats = () => {
    if (!selectedDate) {
      return (
          <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                marginTop: perfectSize(5),
              }}
          >
            <Text style={{ fontSize: perfectSize(14) }}>
              {"관람일을 선택해주세요"}
            </Text>
          </View>
      );
    } else {
      return (
          <View>
            {detailed_time.map((item, index) => {
              const d1 = new Date(item.date);
              const d2 = new Date(selectedDate);
              if (d1.getTime() === d2.getTime()) {
                return (
                    <RestSeat
                        key={item._id}
                        performance={performance}
                        index={index}
                        item={item}
                    />
                );
              }
            })}
            <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: perfectSize(5),
                }}
            >
              <Text style={{ fontSize: perfectSize(14) }}>
                {"공연시간을 선택해주세요"}
              </Text>
            </View>
          </View>
      );
    }
  };

  let limitDate = new Date();
  limitDate.setDate(limitDate.getDate() + 1);
  let fromDate = new Date(from);
  fromDate.setHours(0);
  fromDate.setMinutes(0);

  return (
      <View style={globalStyle.flex}>
        <View style={styles.headerDate}>
          <Text style={[globalStyle.regularBoldText, { color: c_white }]}>
            {`${moment(from).format("YYYY.MM.DD")} ~ ${moment(to).format(
                "YYYY.MM.DD"
            )}`}
          </Text>
        </View>
        <ScrollView
            style={{
              flex: 1,
              marginTop: perfectSize(40),
            }}
        >
          <Calendar
              onDayPress={(day) => {
                setDateSelect({
                  [day.dateString]: {
                    selected: true,
                    selectedColor: c_primary_red,
                  },
                });
                selectDate(day.timestamp);
              }}
              markedDates={dateSelected}
              current={
                new Date(fromDate) > new Date() ? new Date(fromDate) : new Date()
              }
              minDate={
                new Date(fromDate) > new Date()
                    ? new Date(from)
                    : new Date(limitDate)
              }
              maxDate={moment(new Date(to)).format('YYYY-MM-DD')}
              hideExtraDays={true}
              theme={{
                "stylesheet.calendar.header": {
                  dayTextAtIndex0: {
                    color: c_primary_red,
                  },
                  dayTextAtIndex1: {
                    color: c_primary_black,
                  },
                  dayTextAtIndex2: {
                    color: c_primary_black,
                  },
                  dayTextAtIndex3: {
                    color: c_primary_black,
                  },
                  dayTextAtIndex4: {
                    color: c_primary_black,
                  },
                  dayTextAtIndex5: {
                    color: c_primary_black,
                  },
                  dayTextAtIndex6: {
                    color: c_primary_blue,
                  },
                },
              }}
              renderHeader={renderHeader}
              renderArrow={renderArrow}
              style={globalStyle.shadow}
          />

          <View style={{ paddingVertical: perfectSize(15) }}>
            {renderRestSeats()}
          </View>
        </ScrollView>
      </View>
  );
}

const styles = {
  headerDate: {
    backgroundColor: "#555555",
    alignItems: "center",
    paddingVertical: perfectSize(11),
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  calendarLeft: {
    width: perfectSize(30),
    height: perfectSize(30),
    marginLeft: perfectSize(80),
    alignItems: "center",
    justifyContent: "center",
  },
  calendarHeader: {
    marginVertical: perfectSize(10),
  },
  calendarRight: {
    width: perfectSize(30),
    height: perfectSize(30),
    marginRight: perfectSize(80),
    alignItems: "center",
    justifyContent: "center",
  },
  calendarArrowIcon: {
    width: perfectSize(15),
    height: perfectSize(15),
    resizeMode: "contain",
  },
};