import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions
} from 'react-native';
import moment from 'moment';
import { globalStyle, perfectSize } from '../variables/styles';
import { useNavigation } from '@react-navigation/native';
import Modal from 'react-native-modal';
import {
    c_grey,
    c_light_grey,
    c_primary_black,
    c_primary_red,
    c_white
} from '../variables/colors';
import Button from '../components/Button';
import TicketCard from '../components/TicketCard';

const height = Dimensions.get('window').height;

export default function TicketModal({
    isVisible,
    hideModal,
    data
}) {
    const navigation = useNavigation();

    const [selectedNumber, setNumber] = useState(0);
    const [selectedTicket, setSelectTicket] = useState();

    useEffect(() => {
        setNumber(0);
    }, [isVisible]);

    if (!data) return null;

    let ticketDate = data.product_id.schedule.detailed_time[data.round].date;
    const hour = data.product_id.schedule.detailed_time[data.round].hour;
    const minute = data.product_id.schedule.detailed_time[data.round].minute;
    const museum = data.product_id.location.name;
    // const seat = ticket.seat_name.split(' ');
    // ticketDate = new Date(ticketDate).setHours(hour);
    // ticketDate = new Date(ticketDate).setMinutes(minute);

    return (
        <Modal
            animationIn="slideInUp"
            animationOut="slideOutDown"
            style={{ margin: 0 }}
            isVisible={isVisible}>
            <View style={styles.swipeModal}>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    {/** close */}
                    <TouchableOpacity
                        onPress={() => {
                            setSelectTicket();
                            hideModal();
                        }}
                        style={styles.closeBtn}>
                        <Image
                            source={require('../../assets/images/ic_close.png')}
                            style={styles.closeIcon}
                        />
                    </TouchableOpacity>

                    {/** title */}
                    <Text style={styles.title}>
                        {data.product_id.title.name}
                    </Text>
                    <Text style={globalStyle.regularText}>
                        {`${museum} | ${moment(ticketDate).format('YYYY.MM.DD')} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`}
                    </Text>

                    {/** seperator */}
                    <View style={styles.dotLine} />

                    {/** count */}
                    <Text style={[globalStyle.normalText, { marginBottom: perfectSize(15) }]}>
                        {`총 ${data.tickets.length}매`}
                    </Text>

                    {/** ticket list */}
                    <ScrollView style={styles.scrollView}>
                        <View style={{ flex: 1, paddingBottom: perfectSize(50) }}>
                            {
                                data.tickets.map((ticket, index) => {
                                    return <TicketCard
                                        key={ticket._id}
                                        ticketLabel={true}
                                        selected={selectedTicket ? selectedTicket._id === ticket._id : false}
                                        onSelect={select => {
                                            setSelectTicket(ticket);
                                        }}
                                        data={ticket}
                                        status={ticket.isCancelled ? '취소완료' : '선물완료'}
                                        isConfirm={ticket.isCancelled || ticket.isGift}
                                    />
                                })
                            }
                        </View>
                    </ScrollView>

                    {/** button */}
                    <View style={styles.buttonContainer}>
                        <Button
                            onPress={() => {
                                setSelectTicket();
                                hideModal();
                                navigation.navigate('TicketGift', { ticket: selectedTicket, data: data });
                            }}
                            isBorder={false}
                            textColor={c_white}
                            label={'선물하기'}
                            color={c_primary_red}
                            disable={!selectedTicket}
                        />
                    </View>
                </View>
            </View>
        </Modal>
    )
}

const styles = {
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        alignItems: 'flex-start',
        paddingTop: 0,
        paddingBottom: perfectSize(20),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: perfectSize(16),
        borderTopLeftRadius: perfectSize(16),
        height: '90%',
    },
    closeBtn: {
        alignSelf: 'flex-end',
        marginVertical: perfectSize(15)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
    title: {
        fontSize: perfectSize(16),
        letterSpacing: 0.16,
        color: c_primary_black,
        fontWeight: 'bold',
        marginBottom: perfectSize(10)
    },
    dotLine: {
        alignSelf: 'stretch',
        borderStyle: 'dashed',
        borderColor: c_grey,
        borderWidth: 1,
        marginVertical: perfectSize(15),
    },
    scrollView: {
        alignSelf: 'stretch',
        marginHorizontal: perfectSize(-15),
        maxHeight: height * 0.7
    },
    buttonContainer: {
        height: perfectSize(75),
        alignSelf: 'stretch',
        padding: perfectSize(15),
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: c_white
    }
}