import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  TextInput,
  Dimensions,
} from "react-native";
import {
  c_primary_red,
  c_second_white,
  c_thrid_white,
  c_white,
  c_grey,
  c_second_grey,
  c_light_grey,
} from "../../variables/colors";
import { globalStyle, perfectSize } from "../../variables/styles";
import Separator from "../../components/Separator";
import ModalDropdown from "react-native-modal-dropdown";
import Footer from "../../components/Footer";
import Tag from "../../components/Tag";
import { FAB } from "react-native-paper";
import AntDesign from "@expo/vector-icons/AntDesign";
import { UPLOAD_URL } from "../../variables/constants";
import { formatDate } from "../../utils";
import {addReview, getCommunityWithParams, getCommunityList} from "../../store/actions";
import { useIsFocused } from "@react-navigation/native";

const { height } = Dimensions.get("window");

function Community({ navigation, hastags, isAuthenticated, user }) {
  const [indexTab, selectTab] = useState(4);
  const [sortType, setSortType] = useState(0);
  const [open, onStateChange] = useState(false);
  const [community, setCommunity] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [pageCount, setPageCount] = useState(2);
  const [totalCount, setTotalCount] = useState(0);

  const dropdownList = ["추천순", "최신순", "조회순"];
  const isFocusedHistory = useIsFocused();

  useEffect(() => {
    navigation.setOptions({
      title: "커뮤니티",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../assets/images/ic_before.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.openDrawer()}>
            <Image
              source={require("../../../assets/images/ic_menu_sub.png")}
              style={globalStyle.navIcon}
            />
          </TouchableOpacity>
        </View>
      ),
      headerRight: () => (
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Image
            source={require("../../../assets/images/ic_close.png")}
            style={globalStyle.navIcon}
          />
        </TouchableOpacity>
      ),
    });
  }, []);

  useEffect(() => {
    getInitCommunity();
  }, [indexTab, sortType, keyword, searchWord, isFocusedHistory]);

  const getInitCommunity = async () => {
      setPageCount(2);
      const req = `page=1&per_page=20&keyword=${keyword}&sort_type=${sortType}&range=${indexTab}&search_word=${searchWord}`;
      const res = await getCommunityWithParams(req);
      setTotalCount(res.total);
      if (res.data) {
          setCommunity(res.data);
      }
  };

  const getCommunity = async () => {
    const req = `page=${pageCount}&per_page=20&keyword=${keyword}&sort_type=${sortType}&range=${indexTab}&search_word=${searchWord}`;
    const res = await getCommunityWithParams(req);
    if (res.data) {
      setCommunity(community.concat(res.data));
    }
    setPageCount(pageCount + 1);
  };

  const onScroll = e => {
    const _height = e.nativeEvent.contentSize.height;
    const offset = e.nativeEvent.contentOffset.y;
    if (height + offset >= _height) {
      if (totalCount > (pageCount - 2) * 20) {
        getCommunity();
      }
    }
  };

  const CommunityList = () => {
    if (_.isEmpty(community)) {
      return (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginVertical: perfectSize(120),
          }}
        >
          <Text>{"게시글이 없습니다."}</Text>
        </View>
      );
    }

    return community.map((item, index) => {
      return (
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("CommunityDetail", { id: item._id })
          }
          key={index}
        >
          <View style={styles.communityList}>
            {item.imageUrl && (
              <Image
                source={{ uri: UPLOAD_URL + item.imageUrl }}
                style={styles.communityImage}
              />
            )}
            <View style={{ flex: 1 }}>
              <Text style={globalStyle.normalText}>{item.title}</Text>
              <View style={styles.communityInfo}>
                <Text style={styles.communityInfoText}>
                  {item.user_id.nickname || item.user_id.name}
                </Text>
                <Text style={styles.communityInfoText}>
                  {formatDate(item.createdAt)}
                </Text>
                <Text style={styles.communityInfoText}>
                  {"조회 " + item.views}
                </Text>
                <Text style={styles.communityInfoText}>
                  {"추천 " + item.likes.length}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                {item.hashtags &&
                  item.hashtags.map((item, index) => {
                    return (
                      <Text
                        key={index}
                        style={styles.communityInfoTag}
                      >{`#${item}`}</Text>
                    );
                  })}
              </View>
            </View>
          </View>
          <Separator height={perfectSize(1)} color={c_second_white} />
        </TouchableOpacity>
      );
    });
  };

  const handlePageChange = pageNumber => {
    selectTab(pageNumber);
  };

  const renderTabBar = () => {
    return (
      <View style={styles.tabBarContainer}>
        <TouchableOpacity
          onPress={() => handlePageChange(0)}
          style={{
            ...styles.tabBarItem,
            backgroundColor: indexTab != 0 ? "transparent" : c_primary_red,
          }}
        >
          <Text
            style={{
              ...globalStyle.regularBoldText,
              color: indexTab != 0 ? c_thrid_white : c_white,
            }}
          >
            {"오늘"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => handlePageChange(1)}
          style={{
            ...styles.tabBarItem,
            backgroundColor: indexTab != 1 ? "transparent" : c_primary_red,
          }}
        >
          <Text
            style={{
              ...globalStyle.regularBoldText,
              color: indexTab != 1 ? c_thrid_white : c_white,
            }}
          >
            {"일주일"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => handlePageChange(2)}
          style={{
            ...styles.tabBarItem,
            backgroundColor: indexTab != 2 ? "transparent" : c_primary_red,
          }}
        >
          <Text
            style={{
              ...globalStyle.regularBoldText,
              color: indexTab != 2 ? c_thrid_white : c_white,
            }}
          >
            {"1개월"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => handlePageChange(3)}
          style={{
            ...styles.tabBarItem,
            backgroundColor: indexTab != 3 ? "transparent" : c_primary_red,
          }}
        >
          <Text
            style={{
              ...globalStyle.regularBoldText,
              color: indexTab != 3 ? c_thrid_white : c_white,
            }}
          >
            {"6개월"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => handlePageChange(4)}
          style={{
            ...styles.tabBarItem,
            backgroundColor: indexTab != 4 ? "transparent" : c_primary_red,
          }}
        >
          <Text
            style={{
              ...globalStyle.regularBoldText,
              color: indexTab != 4 ? c_thrid_white : c_white,
            }}
          >
            {"전체"}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={globalStyle.mainContainer}>
      {/** Search Input */}
      <View style={styles.searchInput}>
        <TextInput
          value={searchWord}
          onChangeText={text => {
            setSearchWord(text);
          }}
          style={[globalStyle.regularText, globalStyle.flex]}
          placeholder={"검색어를 입력해주세요"}
        />
        <TouchableOpacity>
          <Image
            source={require("../../../assets/images/ic_search_b.png")}
            style={globalStyle.searchIcon}
          />
        </TouchableOpacity>
      </View>

      <ScrollView
        scrollEventThrottle={100}
        onScroll={onScroll}
        style={globalStyle.flex}
      >
        <View style={styles.mainContent}>
          {/** tag section */}
          <ScrollView
            style={{ marginHorizontal: perfectSize(-15) }}
            showsHorizontalScrollIndicator={false}
            horizontal={true}
          >
            <View style={[globalStyle.flexRow, styles.tagScrollView]}>
              {hastags &&
                hastags.map((item, index) => {
                  return (
                    <View style={globalStyle.tagsContainer} key={index}>
                      <Tag
                        onPress={() => {
                          if (item.title === keyword) {
                            setKeyword("");
                          } else {
                            setKeyword(item.title);
                          }
                        }}
                        label={`#${item.title}`}
                        color={keyword === item.title ? c_primary_red : c_grey}
                        borderColor={
                          keyword === item.title ? c_primary_red : c_light_grey
                        }
                      />
                    </View>
                  );
                })}
            </View>
          </ScrollView>

          {/** TabView */}
          {/* <View style={{ marginTop: perfectSize(10) }}>
                        {renderTabBar()}
                    </View> */}
          <View>
            <ModalDropdown
              onSelect={index => setSortType(index)}
              options={dropdownList}
              defaultIndex={0}
              defaultValue={dropdownList[0]}
              renderSeparator={() => <View />}
              dropdownStyle={{
                width: perfectSize(80),
                height: perfectSize(40 * dropdownList.length),
                marginTop: perfectSize(8),
              }}
              dropdownTextStyle={globalStyle.dropdownTextStyle}
              dropdownTextHighlightStyle={{ color: c_primary_red }}
              textStyle={globalStyle.regularText}
              renderRightComponent={() => (
                <Image
                  source={require("../../../assets/images/ic_list_order.png")}
                  style={globalStyle.dropdownRightImage}
                />
              )}
              style={globalStyle.dropdownStyle}
            />
          </View>

          <View style={{ marginTop: perfectSize(-10) }}>{CommunityList()}</View>
          <View style={styles.footer}>
            <Footer />
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          position: "absolute",
          bottom: perfectSize(6),
          right: perfectSize(60),
        }}
      >
        <Image
          source={require("../../../assets/images/floating_alt.png")}
          style={{
            width: perfectSize(240),
            height: perfectSize(55),
            resizeMode: "contain",
          }}
        />
      </View>

      <FAB.Group
        open={open}
        icon={open ? "close" : "plus"}
        actions={[
          {
            icon: props => {
              return (
                <View style={styles.fabIcon}>
                  <AntDesign
                    name={"form"}
                    color={c_primary_red}
                    size={perfectSize(23)}
                  />
                </View>
              );
            },
            style: styles.action,
            onPress: () => {
              if (!isAuthenticated) {
                navigation.navigate("SignIn", {screenName: 'Community'});
              } else {
                navigation.navigate("CommunityWrite", {
                  data: "",
                  userId: user._id,
                });
              }
            },
            small: false,
          },
          {
            icon: props => {
              return (
                <View style={styles.fabIcon}>
                  <AntDesign
                    name={"user"}
                    color={c_primary_red}
                    size={perfectSize(23)}
                  />
                </View>
              );
            },
            style: styles.action,
            onPress: () => {
                if (!isAuthenticated) {
                    navigation.navigate("SignIn", {screenName: 'Community'});
                } else {
                    navigation.navigate("MyCommunity");
                }
            },
            small: false,
          },
          {
            icon: props => {
              return (
                <View style={styles.fabIcon}>
                  <AntDesign
                    name={"home"}
                    color={c_primary_red}
                    size={perfectSize(23)}
                  />
                </View>
              );
            },
            style: styles.action,
            onPress: () => console.log("Pressed notifications"),
            small: false,
          },
        ]}
        color={c_white}
        onStateChange={() => onStateChange(!open)}
        onPress={() => {
          if (open) {
            // do something if the speed dial is open
          }
        }}
        fabStyle={{
          backgroundColor: c_primary_red,
        }}
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
        }}
      />
    </View>
  );
}

const mapStateToProps = state => ({
  hastags: state.app.hastags,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Community);

const styles = {
  mainContent: {
    backgroundColor: c_white,
    paddingHorizontal: perfectSize(15),
  },
  tabBarContainer: {
    flexDirection: "row",
    height: perfectSize(40),
    width: "100%",
    backgroundColor: c_second_white,
    borderRadius: perfectSize(6),
    padding: perfectSize(1),
  },
  tabBarItem: {
    flex: 1,
    borderRadius: perfectSize(6),
    backgroundColor: c_primary_red,
    alignItems: "center",
    justifyContent: "center",
  },
  footer: {
    marginTop: perfectSize(20),
  },
  searchInput: {
    height: perfectSize(40),
    borderColor: c_primary_red,
    borderWidth: 1,
    borderRadius: perfectSize(6),
    marginVertical: perfectSize(20),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: perfectSize(15),
    marginHorizontal: perfectSize(15),
  },
  tagScrollView: {
    paddingHorizontal: perfectSize(15),
  },
  communityList: {
    flexDirection: "row",
    marginVertical: perfectSize(15),
  },
  communityImage: {
    width: perfectSize(50),
    height: perfectSize(50),
    borderRadius: perfectSize(8),
    backgroundColor: c_grey,
    marginRight: perfectSize(15),
  },
  communityInfo: {
    flexDirection: "row",
    marginVertical: perfectSize(8),
  },
  communityInfoText: {
    color: c_second_grey,
    fontSize: perfectSize(12),
    marginRight: perfectSize(10),
  },
  communityInfoTag: {
    color: c_grey,
    fontSize: perfectSize(13),
    marginRight: perfectSize(10),
  },
  fabIcon: {
    marginLeft: perfectSize(-12),
    marginRight: perfectSize(-5),
    alignItems: "center",
    height: perfectSize(60),
    marginTop: perfectSize(-6),
  },
  action: {
    width: perfectSize(40),
    height: perfectSize(40),
    marginRight: perfectSize(5),
  },
};
