export default {
    WHITE1: '#ffffff',
    WHITE2: '#f5f5f5',
    BLUE1: '#4d92fb',
    BLUE2: '#6a87b4',
    BLUE3: '#f6f9fe',
    BLUE4: '#48576f',
    BLUE5: '#b4d1fd',
    BLUE6: '#1c2a64',
    BLACK1: '#222222',
    BLACK3: '#515151',
    BLACK2: '#555555',
    GREY1: '#888888',
    GREY2: '#eeeeee',
    GREY3: '#bfbfbf',
    GREY4: '#707783',
    RED1: '#fb4d4d',
};