import React, { useEffect, useState } from 'react';
import {
    TouchableOpacity,
    Text,
    View
} from 'react-native';
import { useNavigation } from '@react-navigation/core';
import { globalStyle, perfectSize } from '../../variables/styles';
import {
    c_primary_black,
    c_primary_blue,
    c_primary_red,
    c_white,
} from '../../variables/colors';
import { getRestSeat } from '../../store/actions';

const RestSeat = ({
    performance,
    index,
    item
}) => {
    const navigation = useNavigation();
    const [seatType, setSeatType] = useState([]);
    const [seatNumber, setSeatNumber] = useState([]);

    useEffect(() => {
        getSeats();
    }, []);

    const getSeats = async () => {
        const res = await getRestSeat(performance._id, index);
        if (res) {
            const _seatType = Object.keys(res);
            const _seatNumber = Object.values(res);
            setSeatType(_seatType);
            setSeatNumber(_seatNumber);
        }
    }

    return (
        <TouchableOpacity
            onPress={() => navigation.navigate('SeatMap', { performance, roundIndex: index })}
            style={[globalStyle.shadow, styles.timeCard]}>
            <Text style={globalStyle.normalRedBoldText}>
                {
                    (item.hour < 10 ? ('0' + item.hour) : item.hour)
                    + ':' +
                    (item.minute < 10 ? ('0' + item.minute) : item.minute)
                }
            </Text>
            <View style={styles.timeSection}>
                {
                    seatType &&
                    seatType.map((item, index) => {
                        if (item !== '0')
                            return (
                                <View style={[globalStyle.flexRow, styles.eachTime]} key={index}>
                                    <Text style={globalStyle.regularGreyText}>
                                        {`${item}석`}
                                    </Text>
                                    <Text style={[globalStyle.regularGreyText, styles.time]}>
                                        {`${seatNumber[index]}석`}
                                    </Text>
                                    {
                                        index % 4 !== 0 &&
                                        <View style={styles.border} />
                                    }
                                </View>
                            )
                    })
                }
            </View>
        </TouchableOpacity>
    )
}

export default RestSeat;

const styles = {
    timeCard: {
        backgroundColor: c_white,
        marginHorizontal: perfectSize(15),
        borderRadius: perfectSize(6),
        padding: perfectSize(15),
        marginBottom: perfectSize(15)
    },
    timeSection: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
    },
    eachTime: {
        marginTop: perfectSize(13),
        width: '25%'
    },
    time: {
        marginLeft: perfectSize(4),
        color: c_primary_black
    },
    border: {
        width: 1,
        height: perfectSize(12),
        backgroundColor: '#e7e7e7',
        marginLeft: perfectSize(10)
    }
}