import _ from 'lodash';
import React, { useState } from 'react';
import { View, Text } from 'react-native';

import {
    c_primary_red,
} from '../../../variables/colors';
import PriceCard from '../../../components/PriceCard';
import { globalStyle, perfectSize } from '../../../variables/styles';
import { numberWithCommas } from '../../../utils';
``
const DiscountPrice = ({
    count,
    seatType,
    performance,
    onChangePrices,
    onChangeRealPrices,
    onChangeTicket
}) => {
    const { discounts, prices } = performance;
    const mainPrice = _.filter(prices.prices, (o) => { return o.grade === seatType })[0].price;

    const [totalCount, setTotalCount] = useState(0);

    return (
        <View style={{ marginBottom: perfectSize(20) }}>
            <Text style={[globalStyle.regularGreyText, styles.sectionHeaderTitle]}>
                {`${seatType}석 ${count}매중 `}
                <Text style={{ color: c_primary_red }}>
                    {`총 ${totalCount}매`}
                </Text>
                {' 선택'}
            </Text>
            <PriceCard
                count={0}
                type={'기본가'}
                price={numberWithCommas(mainPrice)}
                max={count - totalCount}
                onChange={number => {
                    onChangeRealPrices(number * mainPrice);
                    onChangePrices(number * mainPrice);
                    setTotalCount(totalCount + number);
                    onChangeTicket(
                        {
                            discount: 0,
                            name: '기본가',
                            price: mainPrice
                        },
                        seatType,
                        number
                    );
                }}
            />
            {
                discounts.map((discount, index) => {
                    return (
                        <PriceCard
                            key={index}
                            max={count - totalCount}
                            count={0}
                            type={discount.name}
                            price={numberWithCommas(mainPrice - (mainPrice / 100 * discount.discount))}
                            discount={discount.discount}
                            details={discount.description}
                            onChange={number => {
                                onChangeTicket(
                                    {
                                        discount: discount.discount,
                                        name: discount.name,
                                        price: mainPrice - (mainPrice / 100 * discount.discount)
                                    },
                                    seatType,
                                    number
                                );
                                onChangeRealPrices(number * mainPrice);
                                onChangePrices(number * (mainPrice - (mainPrice / 100 * discount.discount)));
                                setTotalCount(totalCount + number);
                            }}
                        />
                    )
                })
            }
            <Text style={[globalStyle.regularGreyText, { marginTop: perfectSize(10), textAlign: 'center' }]}>
                {'할인 매수를 선택하세요. (없으면 기본가 선택)'}
            </Text>
        </View >
    )
}

export default DiscountPrice;

const styles = {

    sectionHeaderTitle: {
        marginBottom: perfectSize(7)
    },
}