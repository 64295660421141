import { createStore, compose, applyMiddleware } from 'redux';
import Reducer from './reducers';
import thunk from 'redux-thunk';

// eslint-disable-next-line no-underscore-dangel
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    Reducer,
    {},
    composeEnhancers(applyMiddleware(thunk))
);

export default store;