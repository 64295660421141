import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    ImageBackground,
} from 'react-native';
import Collapsible from 'react-native-collapsible';
import moment from 'moment';

import {
    c_light_grey,
    c_primary_red,
    c_second_white,
    c_white,
    c_grey,
    c_primary_black
} from '../../../variables/colors';
import { UPLOAD_URL } from '../../../variables/constants';
import { globalStyle, perfectSize } from '../../../variables/styles';
import { numberWithCommas, oneAlert } from '../../../utils';
import { setTotalPrice, setOrderDetail } from '../../../store/actions';
import CouponModal from '../../../modals/CouponModal';
import InvitationModal from '../../../modals/InvitationModal';
import DiscountPrice from './DiscountPrice';
import Button from '../../../components/Button';
import RadioButton from '../../../components/RadioButton';
import Separator from '../../../components/Separator';
import { useIsFocused } from '@react-navigation/native';

const Price = ({
    navigation,
    route,
    selectedSeat,
    setTotalPrice,
    setOrderDetail,
    user,
    totalPrice
}) => {
    const { performance, roundIndex } = route.params;
    const isFocusedHistory = useIsFocused();
    const { InvitationReducer, CouponReducer } = useSelector(state => state);

    const [isCollapsed, setCollapsed] = useState(true);
    const [isNavUp, setNavUp] = useState(false);
    const [coupon, setCoupon] = useState();
    const [initInvitations, setInitInvitations] = useState([...InvitationReducer.invitations]);
    const [visibleCouponModal, setVisibleCouponModal] = useState(false);
    const [visibleInvitationModal, setVisibleInvitationModal] = useState(false);
    const [ticketPrice, setTicketPrice] = useState(0);
    const [realPrice, setRealPrice] = useState(0);
    const [deliveryType, setDeliveryType] = useState(performance.delivery_method.isInPlace ? 2 : 0);
    const [couponPrice, setCouponPrice] = useState(0);
    const [error, setError] = useState(false);
    const [code, setCode] = useState();
    const [loading, setLoading] = useState(false);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [invitations, setInvitations] = useState([]);
    const [invitationPrice, setInvitationPrice] = useState(0);
    const [countTicket, setCountTicket] = useState(0);
    const [selectedDiscount, setSelectedDiscount] = useState({});
    const [orderFee, setOrderFee] = useState(0);

    const DELIVERY_TYPE = ['모바일티켓', '현장수령', '배송']
    const detailedTime = performance.schedule.detailed_time[roundIndex];
    // console.log(InvitationReducer.invitations[0].isSelected = false)

    useEffect(() => {
        if (performance.fee)
            setOrderFee(performance.fee * countTicket);
    }, [countTicket]),

        useEffect(() => {
            setTotalPrice(ticketPrice + couponPrice + deliveryFee + invitationPrice + orderFee);
            setOrderDetail({
                realPrice,
                ticketPrice,
                couponPrice,
                deliveryFee,
                deliveryType: DELIVERY_TYPE[deliveryType - 1],
                couponId: coupon && coupon._id,
                invitation: invitations,
                invitationPrice,
                fee: orderFee,
                ticketDiscounts: selectedDiscount
            });
        }, [
            ticketPrice,
            couponPrice,
            deliveryFee,
            deliveryType,
            realPrice,
            deliveryType,
            coupon,
            selectedDiscount,
            invitationPrice,
            orderFee
        ]);

    useEffect(() => {
        let fee = 0;
        let _invitationPrice = 0;
        if (performance.fee)
            fee = performance.fee * countTicket;
        if (invitations.length) {
            invitations.map((invitation, index) => {
                if (countTicket === index) {
                    return;
                }
                if (invitation.seat !== 'specific') {
                    const discounts = Object.values(selectedDiscount);
                    let allDiscount = [];
                    for (let i = 0; i < discounts.length; i++)
                        for (let j = 0; j < discounts[i].length; j++)
                            allDiscount.push(discounts[i][j]);
                    if (allDiscount.length) {
                        allDiscount = _.sortBy(allDiscount, (o) => { return o.price });
                        allDiscount.reverse();
                        _invitationPrice -= allDiscount[index].price;
                    }
                } else {
                    const seatTypes = Object.keys(selectedDiscount);
                    const findIndex = seatTypes.indexOf(invitation.seat_grade);
                    if (findIndex !== -1) {
                        const discounts = Object.values(selectedDiscount);
                        let allDiscount = [];
                        for (let j = 0; j < discounts[findIndex].length; j++)
                            allDiscount.push(discounts[findIndex][j]);
                        if (allDiscount.length) {
                            allDiscount = _.sortBy(allDiscount, (o) => { return o.price });
                            allDiscount.reverse();
                            _invitationPrice -= allDiscount[index].price;
                        }
                    }
                }
                console.log(invitation.fee);
                if (!invitation.fee && fee) {
                    fee -= performance.fee;
                }
            });
        }
        setInvitationPrice(_invitationPrice);
        setOrderFee(fee);
    }, [ticketPrice, invitations, selectedDiscount, countTicket]);

    const renderCollapsibleView = () => {
        return (
            <View style={styles.collapsedView}>
                <ImageBackground
                    source={{ uri: UPLOAD_URL + performance.poster_link }}
                    blurRadius={4}
                    style={styles.imageBackground}>
                    <View style={styles.blurContainer} />
                    <View style={globalStyle.flexSpaceBetween}>
                        <View>
                            <Text style={globalStyle.normalWhiteBoldText}>
                                {
                                    moment(detailedTime.date).format('YYYY.MM.DD') + '   ' +
                                    (detailedTime.hour < 10 ? ('0' + detailedTime.hour) : detailedTime.hour) + ':' +
                                    (detailedTime.minute < 10 ? ('0' + detailedTime.minute) : detailedTime.minute)
                                }
                            </Text>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: perfectSize(8)
                            }}>
                                <Text style={[globalStyle.normalWhiteText, {
                                    marginRight: perfectSize(-12),
                                }]}>
                                    {performance.location.name}
                                </Text>
                                <Image
                                    source={require('../../../../assets/images/ic_before.png')}
                                    style={styles.optionNextIcon}
                                />
                            </View>
                            <Text style={[globalStyle.normalWhiteText, {
                                fontSize: perfectSize(13),
                                marginTop: perfectSize(15)
                            }]}>
                                {`총 ${selectedSeat.length}매 예매중`}
                            </Text>
                        </View>
                        <Image
                            source={{ uri: UPLOAD_URL + performance.poster_link }}
                            style={styles.banner}
                        />
                    </View>
                    <Collapsible
                        duration={400}
                        collapsed={isCollapsed}>
                        <View style={styles.collapsedContent}>
                            {
                                selectedSeat.map((item, index) => {
                                    const seatData = item.split(":");
                                    const name = `${seatData[4]}석 ` + (seatData[0] !== '0' ? `${seatData[0]}층 ` : '') +
                                        (seatData[1] !== '0' ? `${seatData[1]}구역 ` : '') +
                                        (seatData[2] !== '0' ? `${seatData[2]}열 ` : '') +
                                        `${seatData[3]}번`;
                                    return (
                                        <TouchableOpacity key={index} style={styles.sitNumber}>
                                            <Text style={styles.sitNumberLabel}>
                                                {name}
                                            </Text>
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </View>
                    </Collapsible>

                    <TouchableOpacity onPress={() => {
                        setNavUp(!isNavUp);
                        setCollapsed(!isCollapsed);
                    }}>
                        <Image
                            source={
                                !isNavUp ?
                                    require('../../../../assets/images/ic_ticket_more.png') :
                                    require('../../../../assets/images/ic_ticket_more_close.png')
                            }
                            style={styles.knob}
                        />
                    </TouchableOpacity>
                </ImageBackground>
            </View>
        )
    }

    const renderBillDoc = () => {
        return (
            <View style={styles.bill}>
                <View style={[globalStyle.flexSpaceBetween, { marginBottom: perfectSize(15) }]}>
                    <Text style={globalStyle.regularText}>
                        {'티켓금액'}
                    </Text>
                    <Text style={globalStyle.regularText}>
                        {`${numberWithCommas(ticketPrice)}원`}
                    </Text>
                </View>

                <View style={[globalStyle.flexSpaceBetween, { marginBottom: perfectSize(15) }]}>
                    <Text style={globalStyle.regularText}>
                        {'기본가'}
                    </Text>
                    <Text style={[globalStyle.regularText, { color: c_grey }]}>
                        {`${numberWithCommas(realPrice)}원`}
                    </Text>
                </View>

                <View style={[globalStyle.flexSpaceBetween, { marginBottom: perfectSize(15) }]}>
                    <Text style={globalStyle.regularText}>
                        {'가격할인'}
                    </Text>
                    <Text style={[globalStyle.regularText, { color: c_grey }]}>
                        {`${numberWithCommas(ticketPrice - realPrice)}원`}
                    </Text>
                </View>

                <View style={[globalStyle.flexSpaceBetween, { marginBottom: perfectSize(15) }]}>
                    <Text style={globalStyle.regularText}>
                        {'초대권할인'}
                    </Text>
                    <Text style={[globalStyle.regularText, { color: c_grey }]}>
                        {`${numberWithCommas(invitationPrice)}원`}
                    </Text>
                </View>

                {/*<View style={[globalStyle.flexSpaceBetween, { marginBottom: perfectSize(15) }]}>*/}
                {/*    <Text style={globalStyle.regularText}>*/}
                {/*        {'쿠폰할인'}*/}
                {/*    </Text>*/}
                {/*    <Text style={[globalStyle.regularText, { color: c_grey }]}>*/}
                {/*        {`${numberWithCommas(couponPrice)}원`}*/}
                {/*    </Text>*/}
                {/*</View>*/}

                {/*<View style={[globalStyle.flexSpaceBetween, { marginBottom: perfectSize(15) }]}>*/}
                {/*    <Text style={globalStyle.regularText}>*/}
                {/*        {'배송비'}*/}
                {/*    </Text>*/}
                {/*    <Text style={globalStyle.regularText}>*/}
                {/*        {deliveryType === 3 ? `${numberWithCommas(deliveryFee)}원` : '0원'}*/}
                {/*    </Text>*/}
                {/*</View>*/}

                <View style={[globalStyle.flexSpaceBetween, { marginBottom: perfectSize(15) }]}>
                    <Text style={globalStyle.regularText}>
                        {'예매수수료'}
                    </Text>
                    <Text style={globalStyle.regularText}>
                        {`${numberWithCommas(orderFee)}원`}
                    </Text>
                </View>

                <Separator
                    height={perfectSize(1)}
                    color={c_second_white}
                    marginBottom={perfectSize(15)}
                />

                <View style={globalStyle.flexSpaceBetween}>
                    <Text style={[globalStyle.regularText, { color: c_primary_red }]}>
                        {'결제금액'}
                    </Text>
                    <Text style={[globalStyle.regularBoldText, { fontSize: perfectSize(16), color: c_primary_red }]}>
                        {`${numberWithCommas(totalPrice)}원`}
                    </Text>
                </View>
            </View>
        )
    }
    const onChangePrices = price => {
        setTicketPrice(ticketPrice + price);
    }

    const onChangeRealPrices = price => {
        setRealPrice(realPrice + price);
    }

    const onChangeTicket = (discount, seatType, number) => {
        setCountTicket(countTicket + number);
        let _selectedDiscount = selectedDiscount;
        if (number > 0) {
            let _tempDiscount = _selectedDiscount[seatType] ? _selectedDiscount[seatType] : [];
            _tempDiscount.push(discount);
            _selectedDiscount[seatType] = _tempDiscount;
        } else {
            let _seatTypeDiscount = selectedDiscount[seatType];
            const findIndex = _.findIndex(_seatTypeDiscount, discount);
            if (findIndex > -1) {
                _seatTypeDiscount.splice(findIndex, 1);
                _selectedDiscount[seatType] = _seatTypeDiscount;
            }
            let _invitations = invitations;
            let index = _.findIndex(_invitations, (o) => { return o.seat_grade === seatType });
            if (index !== -1) {
                // index = countTicket - 1;
                onRemoveInvitations(index);
            }
        }
        setSelectedDiscount(_selectedDiscount);
    }

    const renderPrices = () => {
        let count = {};
        selectedSeat.map((item, index) => {
            const seatData = item.split(":");
            count[seatData[4]] = count[seatData[4]] ? count[seatData[4]] + 1 : 1;
        });
        const keys = Object.keys(count);
        const vals = Object.values(count);
        return vals.map((item, index) => {
            return (
                <DiscountPrice
                    key={index}
                    count={item}
                    seatType={keys[index]}
                    performance={performance}
                    onChangePrices={onChangePrices}
                    onChangeRealPrices={onChangeRealPrices}
                    onChangeTicket={onChangeTicket}
                />
            )
        });
    }

    const renderOrderType = () => {
        return (
            <View style={styles.section} >
                <Text style={[globalStyle.regularBoldText, {
                    marginBottom: perfectSize(15)
                }]}>
                    {'수령방법'}
                </Text>

                <View style={globalStyle.flexRow}>
                    {
                        performance.delivery_method.isInPlace &&
                        <RadioButton
                            isSelected={deliveryType === 2}
                            label={'현장수령'}
                            style={{
                                marginRight: perfectSize(30)
                            }}
                            onSelect={val => {
                                setDeliveryType(val ? 2 : 0);
                                setDeliveryFee(0);
                            }}
                        />
                    }
                    {
                        performance.delivery_method.ismobile &&
                        <RadioButton
                            isSelected={deliveryType === 1}
                            label={'모바일티켓'}
                            style={{
                                marginRight: perfectSize(30)
                            }}
                            onSelect={val => {
                                setDeliveryType(val ? 1 : 0);
                                setDeliveryFee(0);
                            }}
                        />
                    }
                    {
                        performance.delivery_method.isDeliver &&
                        <RadioButton
                            isSelected={deliveryType === 3}
                            label={'배송'}
                            onSelect={val => {
                                setDeliveryType(val ? 3 : 0);
                                setDeliveryFee(val ? performance.delivery_method.fee : 0);
                            }}
                        />
                    }
                </View>
            </View>
        )
    }

    const renderCoupon = () => {
        return (
            <View style={styles.section}>
                <Text style={[globalStyle.regularBoldText, , {
                    marginBottom: perfectSize(15)
                }]}>
                    {'쿠폰'}
                </Text>
                {
                    coupon ?
                        <TouchableOpacity
                            onPress={() => setVisibleCouponModal(true)}
                            style={styles.selectedCoupon}>
                            <View style={globalStyle.flexRow}>
                                <Text style={[styles.smText, { color: c_primary_red, fontWeight: 'bold' }]}>
                                    {
                                        coupon.discount_type === '할인율'
                                            ? coupon.discount_amount + '%할인  '
                                            : numberWithCommas(coupon.discount_amount) + '원  '
                                    }
                                </Text>
                                <Text style={globalStyle.smText}>
                                    {coupon.name}
                                </Text>
                            </View>
                        </TouchableOpacity>
                        :
                        <View style={globalStyle.flexRow}>
                            <TouchableOpacity
                                onPress={() => setVisibleCouponModal(true)}
                                style={[styles.textInput, { justifyContent: 'center' }]}>
                                <Text style={[globalStyle.regularText, { color: c_light_grey }]}>
                                    {'적용 가능한 쿠폰을 확인해보세요.'}
                                </Text>
                            </TouchableOpacity>
                        </View>
                }
            </View>
        )
    }

    const onRemoveInvitations = index => {
        initInvitations[invitations[index].index].isSelected = false;
        setInitInvitations([...initInvitations]);
        invitations.splice(index, 1);
        setInvitations([...invitations]);
        setInvitationPrice(0);
    }

    const bottomView = () => {
        return (
            <View style={styles.bottomButtons}>
                <Separator
                    height={perfectSize(1)}
                    color={c_second_white}
                />
                <View style={[globalStyle.flexSpaceBetween, {
                    height: perfectSize(30),
                    paddingHorizontal: perfectSize(15)
                }]}>
                    <Text style={globalStyle.regularText}>
                        {`총 수량 ${selectedSeat.length}매`}
                    </Text>
                    <View style={globalStyle.flexRow}>
                        <Text style={globalStyle.regularText}>
                            {'총금액 '}
                        </Text>
                        <Text style={{
                            color: c_primary_red,
                            fontSize: perfectSize(18),
                            letterSpacing: -0.15,
                            fontWeight: 'bold',
                        }}>
                            {`${numberWithCommas(totalPrice)}원`}
                        </Text>
                    </View>
                </View>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingHorizontal: perfectSize(15),
                    paddingBottom: perfectSize(50),
                    backgroundColor: c_white,
                }}>
                    <View style={{ flex: 1, marginRight: perfectSize(5) }}>
                        <Button
                            onPress={() => {
                                navigation.goBack();
                            }}
                            textColor={c_primary_red}
                            label={'취소'}
                            color={c_white}
                            isBorder={true}
                            borderColor={c_primary_red}
                        />
                    </View>
                    <View style={{ flex: 1, marginLeft: perfectSize(5) }}>
                        <Button
                            onPress={() => {
                                navigation.navigate('checkout')
                            }}
                            textColor={c_white}
                            label={'다음'}
                            color={c_primary_red}
                            isBorder={false}
                            loading={loading}
                            disable={(deliveryType && (selectedSeat.length === countTicket)) ? false : true}
                        />
                    </View>
                </View>
            </View>
        )
    }

    const renderHeader = () => {
        return (
            <View style={styles.headerDate}>
                <View style={globalStyle.flexRow}>
                    <Text style={[
                        globalStyle.smText,
                        {
                            color: c_white,
                            opacity: 0.6
                        }
                    ]}>
                        {'좌석선택'}
                    </Text>
                    <Image
                        source={require('../../../../assets/images/ic_before.png')}
                        style={styles.optionNextIcon}
                    />
                    <Text style={[
                        globalStyle.normalBoldText,
                        {
                            color: c_white
                        }
                    ]}>
                        {'가격선택'}
                    </Text>
                    <Image
                        source={require('../../../../assets/images/ic_before.png')}
                        style={styles.optionNextIcon}
                    />
                    <Text style={[
                        globalStyle.smText,
                        {
                            color: c_white,
                            opacity: 0.6
                        }
                    ]}>
                        {'결제/배송'}
                    </Text>
                </View>
            </View >
        )
    }

    return (
        <View style={globalStyle.mainContainer}>
            {renderHeader()}
            <ScrollView>
                {renderCollapsibleView()}
                {/** 가격선택 */}
                <View style={styles.section}>
                    <Text style={globalStyle.regularBoldText}>
                        {'가격선택'}
                    </Text>
                    <View style={styles.sectionContent}>
                        {renderPrices()}
                    </View>
                </View>
                <Separator
                    height={perfectSize(8)}
                    color={c_second_white}
                />
                {renderOrderType()}
                <Separator
                    height={perfectSize(8)}
                    color={c_second_white}
                />
                {renderCoupon()}
                <Separator
                    height={perfectSize(8)}
                    color={c_second_white}
                />

                {/** 초대권 */}
                <View style={styles.section}>
                    <Text style={[globalStyle.regularBoldText, , {
                        marginBottom: perfectSize(15)
                    }]}>
                        {'초대권'}
                    </Text>
                    {
                        invitations &&
                        invitations.map((item, index) => {
                            return (
                                <View style={styles.selectedCoupon} key={index}>
                                    <View style={globalStyle.flexRow}>
                                        <Text style={[styles.smText, { color: c_primary_red, fontWeight: 'bold' }]}>
                                            {item.seat !== 'specific' ? '전체좌석 ' : `${item.seat_grade}석 `}
                                        </Text>
                                        <Text style={styles.smText}>
                                            {item.seat !== 'all' ? '전용 초대권' : '초대권'}
                                        </Text>
                                        {/* <Text style={styles.smText}>
                                            {` - ${item.code}`}
                                        </Text> */}
                                    </View>
                                    <TouchableOpacity onPress={() => onRemoveInvitations(index)}>
                                        <Image
                                            source={require('../../../../assets/images/ic_close.png')}
                                            style={{ width: perfectSize(11), height: perfectSize(11) }}
                                        />
                                    </TouchableOpacity>
                                </View>
                            )
                        })
                    }
                    <View style={globalStyle.flexRow}>
                        <TouchableOpacity
                            onPress={() => setVisibleInvitationModal(true)}
                            style={[styles.textInput, { justifyContent: 'center' }]}>
                            <Text style={[globalStyle.regularText, { color: c_light_grey }]}>
                                {'사용 가능한 초대권을 확인해보세요.'}
                            </Text>
                        </TouchableOpacity>
                    </View>

                </View>
                <Separator
                    height={perfectSize(8)}
                    color={c_second_white}
                />

                {/** 금액상세 */}
                <View style={styles.section}>
                    <Text style={[globalStyle.regularBoldText, , {
                        marginBottom: perfectSize(15)
                    }]}>
                        {'금액상세'}
                    </Text>
                    {renderBillDoc()}
                </View>
            </ScrollView>
            {bottomView()}

            <CouponModal
                isVisible={visibleCouponModal}
                hideModal={() => setVisibleCouponModal(false)}
                data={CouponReducer.coupons}
                performanceId={performance._id}
                totalPrice={ticketPrice}
                selectedCoupon={(item, index) => {
                    if (index !== null) {
                        item.discount_type === '할인율'
                            ? setCouponPrice(-realPrice / 100 * item.discount_amount)
                            : setCouponPrice(-item.discount_amount)
                        setCoupon(item);
                        setInvitations([]);
                        setInitInvitations([...InvitationReducer.invitations]);
                    } else {
                        setCoupon(null);
                        setCouponPrice(0);
                    }
                }}
            />
            <InvitationModal
                isVisible={visibleInvitationModal}
                hideModal={() => setVisibleInvitationModal(false)}
                data={initInvitations}
                performanceId={performance._id}
                totalPrice={totalPrice}
                countTicket={countTicket}
                roundIndex={roundIndex}
                selectedDiscount={selectedDiscount}
                onSelectInvitation={(item, index) => {
                    if (index !== null) {
                        item.index = index;
                        let list = [...initInvitations];
                        let temp = { ...list[index] };
                        setInvitations([...invitations, item]);
                        temp.isSelected = true;
                        list[index] = temp;
                        setInitInvitations(list);
                        setCode();
                        setCoupon();
                        setCouponPrice(0);
                    } else {
                        setInvitations([]);
                        setInitInvitations([...InvitationReducer.invitations]);
                        setInvitationPrice(0);
                    }
                }}
            />
        </View>
    );
}

const mapStateTopProps = state => ({
    user: state.auth.user,
    selectedSeat: state.seat.selectedSeat,
    totalPrice: state.seat.totalPrice,
})

export default connect(mapStateTopProps, { setTotalPrice, setOrderDetail })(Price);

const styles = {
    section: {
        paddingVertical: perfectSize(20),
        paddingHorizontal: perfectSize(15)
    },
    sectionContent: {
        marginTop: perfectSize(20),
        marginBottom: perfectSize(5)
    },
    sectionHeaderTitle: {
        fontSize: perfectSize(13),
        color: c_grey,
        letterSpacing: -0.15,
        marginBottom: perfectSize(7)
    },
    selectedCoupon: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: c_primary_red,
        borderRadius: perfectSize(6),
        padding: perfectSize(15),
        marginBottom: perfectSize(5)
    },
    closeIcon: {
        width: perfectSize(11),
        height: perfectSize(11),
        resizeMode: 'contain',
    },
    textInput: {
        fontSize: perfectSize(14),
        letterSpacing: -0.15,
        color: c_primary_black,
        flex: 1,
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(6),
        paddingHorizontal: perfectSize(15),
        height: perfectSize(46),
        marginRight: perfectSize(5),
    },
    button: {
        width: perfectSize(70),
        height: perfectSize(46),
        borderRadius: perfectSize(6),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#555555'
    },
    buttonLabel: {
        fontWeight: 'bold',
        fontSize: perfectSize(13),
        color: c_white
    },
    collapsedView: {
        backgroundColor: 'black',
        overflow: 'hidden',
        borderBottomLeftRadius: perfectSize(20),
        borderBottomRightRadius: perfectSize(20),
    },
    collapsedContent: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: perfectSize(-15),
    },
    imageBackground: {
        flex: 1,
        padding: perfectSize(15),
        paddingTop: perfectSize(20),
    },
    blurContainer: {
        backgroundColor: '#000000b3',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    knob: {
        width: perfectSize(34),
        height: perfectSize(11),
        resizeMode: 'contain',
        alignSelf: 'center',
        marginTop: perfectSize(20)
    },
    banner: {
        width: perfectSize(65),
        height: perfectSize(90),
        resizeMode: 'stretch',
        borderRadius: perfectSize(6),
        backgroundColor: c_light_grey
    },
    bill: {
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(6),
        paddingVertical: perfectSize(25),
        paddingHorizontal: perfectSize(15)
    },
    sitNumber: {
        borderWidth: 1,
        borderColor: c_white,
        width: '44%',
        height: perfectSize(24),
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: perfectSize(15),
        marginTop: perfectSize(15)
    },
    sitNumberLabel: {
        fontSize: perfectSize(11),
        color: c_white,
    },
    bottomButtons: {
        backgroundColor: c_white,
        justifyContent: 'flex-end'
    },
    headerDate: {
        backgroundColor: '#555555',
        alignItems: 'center',
        paddingVertical: perfectSize(12),
        height: perfectSize(40),
        justifyContent: 'flex-start'
    },
    optionNextIcon: {
        width: perfectSize(4),
        height: perfectSize(8),
        resizeMode: 'contain',
        tintColor: c_white,
        marginHorizontal: perfectSize(20),
        transform: [{ rotate: '180deg' }]
    },
}
