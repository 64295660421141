import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    Image,
    TouchableOpacity
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';
import {
    c_grey,
    c_primary_red,
    c_white
} from '../variables/colors';
import { numberWithCommas } from '../utils';

export default function TicketCard({
    isConfirm,
    status,
    ticketLabel,
    selected,
    onSelect,
    data,
    disableTouch,
    cancelledTime
}) {

    return (
        <View style={styles.card}>
            {/** header */}
            <View style={[
                globalStyle.flexSpaceBetween,
                styles.cardHeader,
                !isConfirm &&
                { backgroundColor: !selected ? c_grey : c_primary_red }
            ]}>
                <TouchableOpacity
                    onPress={() => {
                        onSelect(!selected);
                    }}
                    disabled={isConfirm || disableTouch}
                    style={globalStyle.flexRow}>
                    {
                        !disableTouch &&
                        <Image
                            source={
                                (isConfirm || !selected) ?
                                    require('../../assets/images/ic_ticket_select_confirmed.png') :
                                    require('../../assets/images/ic_ticket_select_on.png')
                            }
                            style={styles.radioIcon}
                        />
                    }
                    <Text style={[globalStyle.normalText, { color: c_white }]}>
                        {data.seat_name}
                    </Text>
                </TouchableOpacity>
                {
                    !ticketLabel &&
                    <Text style={[globalStyle.regularGreyText, { color: c_white }]}>
                        {'TT20210408002'}
                    </Text>
                }
            </View>

            {/** content */}
            <View style={styles.cardContent}>
                {
                    ticketLabel &&
                    <View style={[globalStyle.flexRow, { paddingBottom: perfectSize(12) }]}>
                        <Text style={[globalStyle.regularGreyText, {
                            color: isConfirm ? '#cfcfcf' : '#222222',
                            width: perfectSize(65)
                        }]}>
                            {'티켓번호'}
                        </Text>
                        <Text style={[globalStyle.regularGreyText, {
                            color: isConfirm ? '#cfcfcf' : '#222222',
                        }]}>
                            {data.ticket_id}
                        </Text>
                    </View>
                }

                <View style={[globalStyle.flexRow, { paddingBottom: perfectSize(12) }]}>
                    <Text style={[globalStyle.regularGreyText, {
                        color: isConfirm ? '#cfcfcf' : '#222222',
                        width: perfectSize(65)
                    }]}>
                        {'좌석등급'}
                    </Text>
                    <Text style={[globalStyle.regularGreyText, {
                        color: isConfirm ? '#cfcfcf' : '#222222',
                    }]}>
                        {`${data.seat_grade}석`}
                    </Text>
                </View>

                <View style={[globalStyle.flexRow, { paddingBottom: perfectSize(12) }]}>
                    <Text style={[globalStyle.regularGreyText, {
                        color: isConfirm ? '#cfcfcf' : '#222222',
                        width: perfectSize(65)
                    }]}>
                        {'가격등급'}
                    </Text>
                    <Text style={[globalStyle.regularGreyText, {
                        color: isConfirm ? '#cfcfcf' : '#222222',
                    }]}>
                        {'일반'}
                    </Text>
                </View>

                <View style={[globalStyle.flexRow, { paddingBottom: perfectSize(12) }]}>
                    <Text style={[globalStyle.regularGreyText, {
                        color: isConfirm ? '#cfcfcf' : '#222222',
                        width: perfectSize(65)
                    }]}>
                        {'가격'}
                    </Text>
                    <Text style={[globalStyle.regularGreyText, {
                        color: isConfirm ? '#cfcfcf' : '#222222',
                    }]}>
                        {`${numberWithCommas(data.price)}원`}
                    </Text>
                </View>

                {
                    isConfirm ?
                    <View style={[globalStyle.flexSpaceBetween, { paddingBottom: perfectSize(12) }]}>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularGreyText, {
                                color: '#cfcfcf',
                                width: perfectSize(65)
                            }]}>
                                {'상태'}
                            </Text>
                            <Text style={[globalStyle.regularGreyText, {
                                color: c_primary_red,
                                marginRight: perfectSize(10)
                            }]}>
                                {status}
                            </Text>
                            <Text style={[globalStyle.regularGreyText, {
                                color: '#cfcfcf'
                            }]}>
                                {cancelledTime}
                            </Text>
                        </View>
                        <View />
                        <Image
                            source={require('../../assets/images/ic_confirm.png')}
                            style={styles.confirmIcon}
                        />
                    </View>
                    : null
                }
            </View>
        </View>
    )
}

const styles = {
    card: {
        shadowColor: c_grey,
        shadowOffset: { x: 2, y: 6 },
        shadowRadius: 4,
        shadowOpacity: 0.3,
        elevation: 2,
        marginVertical: perfectSize(10),
        marginHorizontal: perfectSize(15),
        backgroundColor: c_white
    },
    cardHeader: {
        backgroundColor: '#cfcfcf',
        paddingHorizontal: perfectSize(15),
        paddingVertical: perfectSize(12),
        borderTopLeftRadius: perfectSize(6),
        borderTopRightRadius: perfectSize(6)
    },
    cardContent: {
        paddingHorizontal: perfectSize(15),
        paddingTop: perfectSize(12),
        backgroundColor: c_white,
        borderBottomLeftRadius: perfectSize(6),
        borderBottomRightRadius: perfectSize(6)
    },
    radioIcon: {
        width: perfectSize(17),
        height: perfectSize(17),
        resizeMode: 'contain',
        marginRight: perfectSize(10)
    },
    confirmIcon: {
        width: perfectSize(17),
        height: perfectSize(17),
        resizeMode: 'contain'
    }
}