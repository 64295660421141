import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, Image, Text, Switch } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { globalStyle, perfectSize } from "../../variables/styles";
import {
  c_primary_red,
  c_second_white,
  c_white,
  c_third_grey,
  c_primary_black,
} from "../../variables/colors";
import { storeData, getData } from "../../utils";
import Constants from "expo-constants";
import { useDispatch, useSelector } from "react-redux";

function Setting({ navigation, user }) {
  const [isEnabledLogin, setLoginEnable] = useState(true);
  const [isEnabledAlarm, setAlarmEnable] = useState(true);
  const [isEnabledMarket, setMarketEnable] = useState(false);
  const isFocusedHistory = useIsFocused();
  const dispatch = useDispatch();
  const { AppIconReducer } = useSelector((state) => state);

  useEffect(() => {
    navigation.setOptions({
      title: "설정",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../assets/images/ic_before.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.openDrawer()}>
            <Image
              source={require("../../../assets/images/ic_menu_sub.png")}
              style={globalStyle.headerNormalIcon}
            />
          </TouchableOpacity>
        </View>
      ),
      headerRight: () => (
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Image
            source={require("../../../assets/images/ic_close.png")}
            style={globalStyle.navIcon}
          />
        </TouchableOpacity>
      ),
    });
  }, []);

  const initData = async () => {
    const loginEnable = await getData("@loginEnable");
    const alarmEnable = await getData("@alarmEnable");
    const marketEnable = await getData("@marketEnable");
    setLoginEnable(loginEnable);
    setAlarmEnable(!alarmEnable);
    setMarketEnable(marketEnable);
  };

  useEffect(() => {
    initData();
  }, [isFocusedHistory]);

  const onToggleLogin = (val) => {
    setLoginEnable(val);
    storeData("@loginEnable", val);
    if (val) {
      storeData("@user", user);
    } else {
      storeData("@user", null);
    }
  };
  const onToggleAlarm = (val) => {
    setAlarmEnable(val);
    storeData("@alarmEnable", val);
  };
  const onToggleMarket = (val) => {
    setMarketEnable(val);
    storeData("@marketEnable", val);
  };

  return (
    <View style={globalStyle.mainContainer}>
      <View style={styles.topSection}>
        <Text style={styles.topTitle}>{"앱 설정"}</Text>
      </View>
      <View style={styles.item}>
        <View>
          <Text style={styles.firstTitle}>{"로그인 상태 유지"}</Text>
          <Text style={styles.secondTitle}>
            {"로그아웃 전까지 로그인 상태 유지됩니다."}
          </Text>
        </View>
        <View>
          <Switch
            trackColor={{ false: "#e8e8e8", true: c_primary_red }}
            thumbColor={c_white}
            ios_backgroundColor={"#e8e8e8"}
            onValueChange={onToggleLogin}
            value={isEnabledLogin}
            default
          />
        </View>
      </View>
      <View style={styles.item}>
        <View>
          <Text style={styles.firstTitle}>{"시스템 알림 동의"}</Text>
          <Text style={styles.secondTitle}>
            {"예매, 배송, 취소, 문의답변 등 알림"}
          </Text>
        </View>
        <View>
          <Switch
            trackColor={{ false: "#e8e8e8", true: c_primary_red }}
            thumbColor={c_white}
            ios_backgroundColor={"#e8e8e8"}
            onValueChange={onToggleAlarm}
            value={isEnabledAlarm}
          />
        </View>
      </View>
      <View style={styles.item}>
        <View>
          <Text style={styles.firstTitle}>{"마케팅 정보 수신 동의"}</Text>
        </View>
        <View>
          <Switch
            trackColor={{ false: "#e8e8e8", true: c_primary_red }}
            thumbColor={c_white}
            ios_backgroundColor={"#e8e8e8"}
            onValueChange={onToggleMarket}
            value={isEnabledMarket}
          />
        </View>
      </View>
      <View style={styles.topSection}>
        <Text style={styles.topTitle}>{"메뉴 설정"}</Text>
      </View>
      <TouchableOpacity
        onPress={() => navigation.navigate("BottomIcons")}
        style={styles.item}
      >
        <View>
          <Text style={styles.firstTitle}>
            {"홈화면 하단 메뉴 탭 순서 설정"}
          </Text>
        </View>
        <Image
          source={require("../../../assets/images/ic_notice_more.png")}
          style={globalStyle.navIcon}
        />
      </TouchableOpacity>
      <View
        style={{
          alignItems: "center",
          marginTop: perfectSize(50),
        }}
      >
        <Text
          style={{
            fontSize: perfectSize(12),
            color: "#777777",
            marginBottom: perfectSize(5),
          }}
        >
          {`현재버젼 v${Constants.expoConfig?.version}`}
        </Text>
        <Text
          style={{
            fontSize: perfectSize(12),
            color: "#777777",
            marginBottom: perfectSize(5),
          }}
        >
          {"현재 최신 버전입니다."}
        </Text>
      </View>
    </View>
  );
}

const mapStateTopProps = (state) => ({
  user: state.auth.user,
});

export default Setting;

const styles = {
  topSection: {
    backgroundColor: c_second_white,
    height: perfectSize(45),
    justifyContent: "center",
    paddingHorizontal: perfectSize(15),
  },
  topTitle: {
    fontSize: perfectSize(13),
    color: "#777777",
  },
  item: {
    backgroundColor: c_white,
    borderBottomWidth: 1,
    borderBottomColor: c_third_grey,
    justifyContent: "space-between",
    padding: perfectSize(15),
    flexDirection: "row",
    alignItems: "center",
  },
  firstTitle: {
    color: c_primary_black,
    fontSize: perfectSize(15),
    fontWeight: "bold",
  },
  secondTitle: {
    fontSize: perfectSize(11),
    color: "#777777",
    marginTop: perfectSize(8),
  },
};
