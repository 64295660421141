import {
    ALARM_LOAD_START,
    ALARM_LOAD_SUCCESS,
    ALARM_LOAD_FAIL,
    ALARM_LIST_RESET,
    ALARM_LIST_UPDATE,
    ALARM_LIST_UNREAD
} from '../../variables/constants';

const initialState = {
    alarms: [],
    alarmsLoading: false,
    alarmsUnreadList: [],
}

export default function PerformanceReducer(state = initialState, action) {
    switch (action.type) {

        case ALARM_LOAD_START:
            return state = { ...state, alarmsLoading: true };

        case ALARM_LOAD_SUCCESS:
            return state = {
                ...state,
                alarms: [...action.payload],
                alarmsLoading: false,
            };

        case ALARM_LIST_UPDATE:
            return state = { ...state, alarms: [...action.payload] };

        case ALARM_LIST_RESET:
            return state = { ...state, alarms: [] };

        case ALARM_LOAD_FAIL:
            return state = { ...state, alarmsLoading: false };

        case ALARM_LIST_UNREAD:
            return state = { ...state, alarmsUnreadList: [...action.payload] };

        default:
            return state;
    }
}