import React from 'react';
import { View, Text } from 'react-native';
import { globalStyle } from '../variables/styles';

export default function EmptyText(props) {
    return (
        <View style={[globalStyle.flex, { alignItems: 'center', justifyContent: 'center' }]}>
            <Text style={globalStyle.regularText}>
                {props.label}
            </Text>
        </View>
    )
}