import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    TouchableOpacity
} from 'react-native';
import {
    c_light_grey
} from '../variables/colors';
import { globalStyle, perfectSize } from '../variables/styles';

export default function PriceCard({
    count,
    type,
    price,
    details,
    max,
    onChange
}) {

    const [number, setNumber] = useState(count);
    return (
        <View style={styles.priceCard}>
            <View style={globalStyle.flexSpaceBetween}>
                <View>
                    <Text style={globalStyle.smGreyText}>
                        {type}
                    </Text>
                    <Text style={[globalStyle.normalText,
                    {
                        marginTop: perfectSize(5)
                    }]}>
                        {price}{'원'}
                    </Text>
                </View>

                <View style={globalStyle.flexRow}>
                    <TouchableOpacity
                        onPress={() => {
                            if (number > 0) {
                                onChange(-1);
                                setNumber(number - 1);
                            }
                        }}
                        style={styles.btn}>
                        <Text style={globalStyle.bigText}>
                            {'-'}
                        </Text>
                    </TouchableOpacity>

                    <Text style={[globalStyle.regularText, {
                        marginHorizontal: perfectSize(10)
                    }]}>
                        {number}{'매'}
                    </Text>

                    <TouchableOpacity
                        onPress={() => {
                            if (number < max + number) {
                                onChange(1);
                                setNumber(number + 1);
                            }
                        }}
                        style={styles.btn}>
                        <Text style={globalStyle.bigText}>
                            {'+'}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>

            {
                details &&
                <Text style={[globalStyle.greySmText, { marginTop: perfectSize(10) }]}>
                    {details}
                </Text>
            }
        </View >
    )
}

const styles = {
    priceCard: {
        marginTop: perfectSize(3),
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(6),
        paddingHorizontal: perfectSize(15),
        paddingVertical: perfectSize(12)
    },
    btn: {
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(13),
        width: perfectSize(25),
        height: perfectSize(25),
        alignItems: 'center',
        justifyContent: 'center'
    }
}