import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {
    View,
} from "react-native";
import { c_primary_red } from '../../../../variables/colors';

const MiniMap = (props) => {
    return (
        <View style={{
            position: 'absolute',
            top: (0 - props.offset.top) < 0 ? 0 : 0 - props.offset.top,
            bottom: (0 - props.offset.bottom) < 0 ? 0 : 0 - props.offset.bottom,
            left: (0 - props.offset.left) < 0 ? 0 : 0 - props.offset.left,
            right: (0 - props.offset.right) < 0 ? 0 : 0 - props.offset.right,
            borderWidth: 2,
            borderColor: c_primary_red,
            zIndex: 99
        }} />
    )
}

const mapStateTopProps = state => ({
    offset: state.seat.offset,
});

export default connect(mapStateTopProps)(MiniMap);