import React, {useRef} from 'react';
import Button from "./Button";
import {c_primary_red, c_white} from "../variables/colors";
import axios from "axios";
import {HOST} from "../variables/constants";
import {oneAlert} from "../utils";

export default function PayForm({
	data,
	type,
	label
}) {
	const currentTimeRef = useRef(null);

	const createCardPay = async () => {

		const params = {
			user: data.user,
			OID: getOid(data.mid),
			type: type,
			MID: data.mid,
			GoodsName: data.prodname,
			Amt: data.prodprice,
			mallUserID: data.userid,
			name: data.username,
		}

		await axios.post(`${HOST}/api/tongtongcoin/tomatopay`, params)
			.then(res => {
				data.seq = res.data.data;
				formSubmit();
			})
			.catch(err => {
				oneAlert(err.response.data.message);
			})
	}

	const getOid = (mid) => {
		const newDate = new Date().getTime();
		currentTimeRef.current = newDate;
		return mid + newDate;
	}

	const formSubmit = () => {
		const {
			mid,
			license,
			userid,
			prodname,
			phone,
			username,
			prodprice,
			returnurl,
			seq,
			subDate,
			point,
		} = data;

		const form = document.createElement('form');
		form.method = 'post';
		form.action = 'https://bill.tomatopay.net/payrequest';
		// form.action = `${HOST}/api/tongtongcoin/returnTomatopay`
		const params = {
			mid,
			license,
			userid,
			prodname,
			phone,
			username,
			prodprice,
			returnurl,
			seq,
			subDate,
			point,
		};

		for (const key in params) {
			if (params.hasOwnProperty(key)) {
				const input = document.createElement('input');
				input.type = 'hidden';
				input.name = key;
				input.value = params[key];
				form.appendChild(input);
			}
		}

		document.body.appendChild(form);
		form.submit();
	};

	return (
		<div>
			<Button
				onPress={createCardPay}
				isBorder={true}
				textColor={c_white}
				label={label}
				color={c_primary_red}
			/>
		</div>
	);
}