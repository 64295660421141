import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    Image,
    useWindowDimensions,
    TouchableOpacity,
    TextInput
} from 'react-native';
import {
    c_second_white,
    c_white,
    c_light_grey,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Separator from '../../components/Separator';
import BorderButton from '../../components/BorderButton';
import Button from '../../components/Button';
import { Rating, AirbnbRating } from 'react-native-ratings';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { addReview, addExpectation } from '../../store/actions';
import { UPLOAD_URL } from '../../variables/constants';
import { useDispatch } from 'react-redux';

function CreateReview({
    navigation,
    route,
    addExpectation
}) {

    const [rating, setRating] = useState(5);
    const [content, setContent] = useState();
    const [loading, setLoading] = useState(false);
    const { performance, type, user } = route.params;
    const dispatch = useDispatch();

    useEffect(() => {
        navigation.setOptions({
            title: type === 'review' ? "리뷰 작성" : "기대평 작성",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    const onReview = async () => {
        const data = {
            type: type,
            star: rating,
            content,
            user_id: user._id,
            product_id: performance._id
        };
        setLoading(true);
        dispatch(addReview(data));
        navigation.goBack();
        setLoading(false);
    }

    const onExpectation = async () => {
        const data = {
            type: type,
            content,
            user_id: user,
            product_id: performance._id
        };
        setLoading(true);
        await addExpectation(data);
        navigation.goBack();
        setLoading(false);
    }

    return (
        <View style={globalStyle.flex}>
            <KeyboardAwareScrollView style={styles.mainContents}>
                <View style={{
                    paddingVertical: perfectSize(20),
                    backgroundColor: c_second_white,
                    alignItems: 'center'
                }}>
                    <Image
                        source={{ uri: UPLOAD_URL + performance.poster_link }}
                        style={{
                            width: perfectSize(80),
                            height: perfectSize(80),
                            marginBottom: perfectSize(12)
                        }}
                    />
                    <Text style={globalStyle.regularBoldText}>
                        {performance.title.name}
                    </Text>
                </View>

                {
                    type === 'review' &&
                    <View style={{
                        paddingVertical: perfectSize(15),
                        alignItems: 'center'
                    }}>
                        <Text style={globalStyle.regularText}>
                            {'별점'}
                        </Text>
                        <View style={{
                            ...globalStyle.flexRow,
                            marginTop: perfectSize(15),
                        }}>
                            <AirbnbRating
                                showRating={false}
                                count={5}
                                reviews={[1, 2, 3, 4, 5]}
                                onFinishRating={val => setRating(val)}
                                defaultRating={11}
                                size={perfectSize(24)}
                                reviewSize={perfectSize(24)}
                            />
                        </View>
                    </View>
                }
                <Separator
                    height={perfectSize(1)}
                    color={c_second_white}
                    marginBottom={perfectSize(20)}
                />

                <TextInput
                    value={content}
                    onChangeText={text => setContent(text)}
                    style={[globalStyle.regularText, globalStyle.flex, { paddingBottom: 20 }]}
                    placeholder={'탭하여 리뷰 작성'}
                    multiline={true}
                />
            </KeyboardAwareScrollView>

            <View style={globalStyle.twoBottomButton}>
                <View style={{ flex: 1, marginRight: perfectSize(5) }}>
                    <Button
                        onPress={() => navigation.goBack()}
                        isBorder={true}
                        textColor={c_primary_red}
                        label={'취소'}
                        color={c_white}
                        borderColor={c_primary_red}
                    />
                </View>
                <View style={{ flex: 1, marginLeft: perfectSize(5) }}>
                    <Button
                        onPress={type === 'review' ? onReview : onExpectation}
                        isBorder={true}
                        textColor={c_white}
                        label={'등록'}
                        color={c_primary_red}
                        disable={!content ? true : false}
                        loading={loading}
                    />
                </View>
            </View>
        </View>
    )
}

const mapStateTopProps = state => ({

});

export default connect(mapStateTopProps)(CreateReview);

const styles = {
    mainContents: {
        flex: 1,
        backgroundColor: c_white,
        padding: perfectSize(10),
    },
}