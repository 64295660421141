import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
} from 'react-native';
import {
    c_white,
    c_light_grey,
    c_primary_blue,
    c_second_grey,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import CustomTextInput from '../../components/CustomTextInput';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { login } from '../../store/actions';
import { oneAlert } from '../../utils';

function Password({ navigation, route, login }) {

    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { phonenum, userkey, name, screenName } = route.params;

    useEffect(() => {
        navigation.setOptions({
            title: "비밀번호입력",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    const onLogin = async () => {
        setLoading(true);
        const passwd = password;
        const res = await login({ phonenum, passwd });
        if (res.status === 0) {
            if (res.code === 1015) {
                setError(true);
                showAlertWithText('비밀번호가 일치하지 않습니다')
            } else if (res.code === 404) {
                navigation.navigate('Agree', {user_status: 'login', phonenum: phonenum, passwd: password, countryCode: countryCode, screenName});
                return
            } else if (res.code === 1058) {
                setMethodAlertText('존재하지 않는 one-ID입니다.');
                setvisibleMethodModal(true);
                return
            }
        } else if (res.data) {
            navigation.navigate("Home", { screenName });
            oneAlert('로그인이 완료되었습니다.');
        } else {
            oneAlert('로그인시 오류가 발생하였습니다.');
        }
        setLoading(false);
    };

    const onForgotPassword = () => {
        navigation.navigate('PasswordCertiRequest', { phonenum, userkey });
    }

    return (
        <View style={globalStyle.mainContainer}>
            <KeyboardAwareScrollView style={{ flex: 1 }}>
                <View style={styles.mainContainer}>
                    <Image
                        source={require('../../../assets/images/ic_profile.png')}
                        style={styles.avatar}
                    />
                    <Text style={[globalStyle.normalText, styles.marginV]}>
                        {`${name ? name : route.params.phonenum % 10000}님`}
                    </Text>

                    <CustomTextInput
                        value={password}
                        warningText={'틀린 비밀번호 입니다.'}
                        isWarn={error}
                        placeholder={'비밀번호입력'}
                        value={password}
                        onChangeText={text => {
                            setPassword(text);
                            setError(false);
                        }}
                        isSecure={true}
                    />

                    <Text style={[globalStyle.greyNormalText, { marginTop: perfectSize(54) }]}>
                        {'비밀번호를 잊으셨나요?'}
                    </Text>
                    <TouchableOpacity onPress={onForgotPassword}>
                        <Text style={[globalStyle.greyNormalText, styles.comeBackPassword]}>
                            {'비밀번호재설정'}
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={{marginTop: perfectSize(130)}}>
                    <Button
                        onPress={onLogin}
                        textColor={c_white}
                        label={'로그인'}
                        color={c_primary_red}
                        disable={(!password) ? true : false}
                        loading={loading}
                    />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}

const mapStateTopProps = state => ({

})

export default connect(mapStateTopProps, { login })(Password);

const styles = {
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        marginTop: perfectSize(30),
        paddingHorizontal: perfectSize(21)
    },
    marginV: {
        marginTop: perfectSize(18),
        marginBottom: perfectSize(30)
    },
    bottomView: {
        position: 'absolute',
        bottom: perfectSize(30),
        left: perfectSize(21),
        right: perfectSize(21)
    },
    avatar: {
        width: perfectSize(84),
        height: perfectSize(84),
        resizeMode: 'contain'
    },
    comeBackPassword: {
        color: c_primary_red,
        marginTop: perfectSize(12)
    }
}