import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
    ActivityIndicator,
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Platform,
    Dimensions,
} from 'react-native';
import Carousel from 'react-native-snap-carousel';
import ModalDropdown from 'react-native-modal-dropdown';
import Entypo from '@expo/vector-icons/Entypo';
import moment from 'moment';
import { useIsFocused } from '@react-navigation/native';

import {
    c_white,
    c_primary_red,
    c_second_white,
    c_grey,
} from '../../variables/colors';
import { UPLOAD_URL } from '../../variables/constants';
import { globalStyle, perfectSize } from '../../variables/styles';
import FilterModal from '../../modals/FilterModal';
import Loader from '../../components/Loader';
import EmptyText from '../../components/EmptyText';
import { performanceListReset, performanceList } from '../../store/actions/performance';

const { width, height } = Dimensions.get('window');

const FILTER_DATA = [
    {
        id: 0,
        label: "전체",
        value: "",
    },
    {
        id: 1,
        label: "클래식",
        value: "classic"
    },
    {
        id: 2,
        label: "연극/뮤지컬",
        value: "musical"
    },
    {
        id: 3,
        label: "콘서트",
        value: "concert"
    },
    {
        id: 4,
        label: "무용",
        value: "dance"
    },
    {
        id: 5,
        label: "전시/행사",
        value: "event"
    },
    {
        id: 6,
        label: "스포츠",
        value: "sport"
    },
];

const dropdownList = ['추천순', '임박순', '오래된순'];

function Category({ navigation, banners, sword }) {

    const filteredBanners = _.filter(banners, o => o.type !== 'main' && o.type !== 'menu');

    const { PerformanceReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    const isFocusedHistory = useIsFocused();

    const [sortType, setSortType] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedIndex, setSelectionIndex] = useState(0);
    const [banner, setBanner] = useState(filteredBanners);

    useEffect(() => {
        navigation.setOptions({
            headerTitle: () => (
                <TouchableOpacity
                    onPress={() => setModalVisible(true)}
                    style={globalStyle.flexRow}>
                    <Text style={{ fontSize: 16, marginRight: perfectSize(4) }}>{FILTER_DATA[selectedIndex].label}</Text>
                    <View activeOpacity={1}>
                        <Entypo size={14} name='chevron-thin-down' />
                    </View>
                </TouchableOpacity>
            ),
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.navigate('Search', { sword: sword })}>
                        <Image
                            source={require('../../../assets/images/ic_search_b.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
        if (selectedIndex !== 0) {
            const _banner = _.filter(filteredBanners, o => o.type === FILTER_DATA[selectedIndex].value);
            setBanner(_banner);
        }
    }, [selectedIndex]);

    useEffect(() => {
        const req = {
            sortType,
            selectedIndex,
            searchWord: '',
            page: 1
        };
        dispatch(performanceListReset());
        dispatch(performanceList(req));
    }, [isFocusedHistory, selectedIndex, sortType]);

    const getPerformances = useCallback((req) => {
        dispatch(performanceList(req));
    }, []);

    const onScroll = e => {
        const _height = e.nativeEvent.contentSize.height;
        const offset = e.nativeEvent.contentOffset.y;
        if (height + offset >= _height) {
            if (PerformanceReducer.total !== PerformanceReducer.performances.length) {
                const req = {
                    sortType,
                    selectedIndex,
                    searchWord: '',
                    page: PerformanceReducer.performancesPage + 1
                };
                getPerformances(req);
            }
        }
    }

    const renderCategories = () => {
        if (PerformanceReducer.performancesLoading) return <Loader />;
        if (_.isEmpty(PerformanceReducer.performances)) return <EmptyText label='공연이 없습니다.' />;

        return PerformanceReducer.performances.map(item => {
            const detailedTimes = item.schedule.detailed_time;
            let limitDate = new Date(detailedTimes[detailedTimes.length - 1].date);
            limitDate.setDate(limitDate.getDate() - 1);
            let current = new Date();
            current.setHours(0);
            current.setMinutes(0);
            current.setSeconds(0);
            current.setMilliseconds(0);
            // limitDate.setHours(17);
            return (
                <TouchableOpacity
                    onPress={() => navigation.navigate('Detail', { id: item._id })}
                    style={[globalStyle.shadow, styles.searchResult]}
                    key={item._id}>
                    <Image
                        source={{ uri: item.poster_link ? UPLOAD_URL + item.poster_link : null }}
                        style={styles.categoryImage} />
                    <View style={{ flex: 1, paddingVertical: perfectSize(12), paddingRight: perfectSize(15) }}>
                        <Text style={[globalStyle.normalBoldText, { marginBottom: perfectSize(15) }]}>
                            {item.title.name}
                        </Text>
                        <Text style={[globalStyle.regularText, { marginBottom: perfectSize(8) }]}>
                            {item.location.name}
                        </Text>
                        <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(20) }]}>
                            {`${moment(item.schedule.from).format('YYYY.MM.DD')} ~ ${moment(item.schedule.to).format('YYYY.MM.DD')}`}
                        </Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={[styles.badge, item.isOnsale ?
                                new Date(limitDate) > new Date(current) ? { borderColor: c_primary_red } : { borderColor: c_grey }
                                : { borderColor: c_grey }]}>
                                <Text style={[styles.badgeText, item.isOnsale ?
                                    new Date(limitDate) > new Date(current) ? { color: c_primary_red } : { color: c_grey }
                                    : { color: c_grey }]}>
                                    {
                                        item.isOnsale ?
                                            new Date(limitDate) > new Date(current) ? '판매중' : '판매종료'
                                            : '판매중단'
                                    }
                                </Text>
                            </View>
                            {
                                item.event_list && item.event_list.length > 0 &&
                                <View style={[styles.badge, { marginLeft: perfectSize(5) }]}>
                                    <Text style={styles.badgeText}>
                                        {
                                            '이벤트'
                                        }
                                    </Text>
                                </View>
                            }
                        </View>
                    </View>
                </TouchableOpacity>
            )
        })
    }

    return (
        <View style={styles.container}>
            <ScrollView
                scrollEventThrottle={100}
                onScroll={onScroll}
                style={globalStyle.flex}>
                <View style={globalStyle.flex}>
                    {/** PagerView */}
                    <View style={styles.carouselPagerWrapper}>
                        <Carousel
                            layout={'default'}
                            data={banner}
                            sliderWidth={width}
                            itemWidth={width}
                            inactiveSlideOpacity={1}
                            inactiveSlideScale={1}
                            renderItem={({ item, index }) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => navigation.navigate('Detail', { id: item.performance._id })}
                                        style={styles.carouselPager}>
                                        <Image
                                            source={{ uri: item.image ? UPLOAD_URL + item.image : null }}
                                            style={styles.carouselImage}
                                        />
                                    </TouchableOpacity>
                                )
                            }}
                        />
                    </View>

                    <View style={styles.content}>
                        <View style={globalStyle.flexRow}>
                            <Text style={globalStyle.normalText}>
                                {'총 '}
                            </Text>
                            <Text style={globalStyle.normalBoldText}>
                                {PerformanceReducer.total}
                            </Text>
                            <Text style={globalStyle.normalText}>
                                {' 개'}
                            </Text>
                        </View>

                        <ModalDropdown
                            onSelect={index => setSortType(index)}
                            options={dropdownList}
                            defaultIndex={0}
                            defaultValue={dropdownList[0]}
                            renderSeparator={() => <View />}
                            dropdownStyle={{
                                width: perfectSize(80),
                                height: perfectSize(40 * dropdownList.length),
                                marginTop: perfectSize(8),
                            }}
                            dropdownTextStyle={globalStyle.dropdownTextStyle}
                            dropdownTextHighlightStyle={{ color: c_primary_red }}
                            textStyle={globalStyle.regularText}
                            renderRightComponent={() =>
                                <Image
                                    source={require('../../../assets/images/ic_list_order.png')}
                                    style={globalStyle.dropdownRightImage} />
                            }
                            style={globalStyle.dropdownStyle}
                        />
                    </View>

                    {/** search result list */}
                    {renderCategories()}
                </View>
            </ScrollView>
            <FilterModal
                isVisible={modalVisible}
                hideModal={() => setModalVisible(false)}
                filters={FILTER_DATA}
                selectedIndex={selectedIndex}
                setSelectionIndex={setSelectionIndex}
            />
        </View>
    )
}

const mapStateTopProps = state => ({
    banners: state.app.banners,
    sword: state.app.sword
});

export default connect(mapStateTopProps)(Category);

const styles = {
    container: {
        flex: 1,
        backgroundColor: c_white,
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: perfectSize(15)
    },
    categoryImage: {
        width: perfectSize(100),
        height: perfectSize(140),
        marginRight: perfectSize(15),
        borderRadius: perfectSize(4),
        backgroundColor: c_second_white
    },
    searchResult: {
        flexDirection: 'row',
        marginBottom: perfectSize(20),
        borderRadius: perfectSize(6),
        backgroundColor: c_white,
        marginHorizontal: perfectSize(15)
    },
    badge: {
        borderColor: c_primary_red,
        borderWidth: 1,
        borderRadius: perfectSize(3),
        padding: perfectSize(3),
        alignSelf: 'flex-start'
    },
    badgeText: {
        fontSize: perfectSize(11),
        color: c_primary_red
    },
    carouselPagerWrapper: {
        height: perfectSize(200),
        backgroundColor: c_second_white
    },
    carouselPager: {
        backgroundColor: c_second_white,
    },
    carouselImage: {
        width: width,
        height: perfectSize(200)
    },
}