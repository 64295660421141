import _ from 'lodash';
import { instance, oneAlert } from '../../utils';
import {
    ORDER_LOAD_START,
    ORDER_LOAD_SUCCESS,
    ORDER_LOAD_FAIL,
    ORDER_LIST_RESET,
    ORDER_PAGE_CHANGE,
} from '../../variables/constants';

export const orderLoadSuccess = (data) => ({
    type: ORDER_LOAD_SUCCESS,
    payload: data
});

export const orderLoadStart = () => ({
    type: ORDER_LOAD_START
});

export const orderLoadFail = () => ({
    type: ORDER_LOAD_FAIL
});

export const orderListReset = () => ({
    type: ORDER_LIST_RESET
});

export const orderPage = (data) => ({
    type: ORDER_PAGE_CHANGE,
    payload: data
});

export const orderList = (req) => {
    return (dispatch, getState) => {

        const { user } = getState().auth;

        const {
            range,
            page
        } = req;

        instance.get(`api/order/orderAvailableList/${user._id}?page=${page}&per_page=5&range=${range}`)
            .then(res => {
                dispatch(orderLoadSuccess(res.data));
            })
            .catch(err => {
                if (err.response) {
                    oneAlert(err.response);
                    dispatch(orderLoadFail());
                }
            });
    }
}