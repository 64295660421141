import {
    PERFORMANCE_LOAD_START,
    PERFORMANCE_LOAD_SUCCESS,
    PERFORMANCE_LOAD_FAIL,
    PERFORMANCE_LIST_RESET,
    PERFORMANCE_LIST_UPDATE,
    PERFORMANCE_PAGE_CHANGE,
} from '../../variables/constants';

const initialState = {
    performances: [],
    performancesLoading: false,
    performancesPage: 1,
    total: 0,
    filterList: {}
}

export default function PerformanceReducer(state = initialState, action) {
    switch (action.type) {

        case PERFORMANCE_LOAD_START:
            return state = { ...state, performancesLoading: true };

        case PERFORMANCE_LOAD_SUCCESS:
            return state = {
                ...state,
                performances: [...state.performances, ...action.payload.data],
                performancesLoading: false,
                total: action.payload.total,
                filterList: action.payload.count_filtered
            };

        case PERFORMANCE_LIST_UPDATE:
            return state = { ...state, performances: [...action.payload] };

        case PERFORMANCE_LIST_RESET:
            return state = { ...state, performances: [], performancesPage: 1 };

        case PERFORMANCE_LOAD_FAIL:
            return state = { ...state, performancesLoading: false };

        case PERFORMANCE_PAGE_CHANGE:
            return state = { ...state, performancesPage: action.payload };

        default:
            return state;
    }
}