import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions,
    Platform,
    KeyboardAvoidingView
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';
import Modal from 'react-native-modal';
import WebModal from 'modal-react-native-web';
import {
    c_primary_black,
    c_primary_red,
    c_third_grey,
    c_white,
} from '../variables/colors';
import Separator from '../components/Separator';
import Postcode from '@actbase/react-daum-postcode';

const { width } = Dimensions.get('window');

export default function PostCodeModal({
    isVisible,
    hideModal,
    onSelect
}) {

    const modalContent = () => {
        return (
            <View style={styles.modalContainer}>
                <TouchableOpacity
                    style={{ padding: Platform.OS !== 'web' ? perfectSize(0) : perfectSize(15) }}
                    onPress={hideModal}>
                    <Image
                        source={require('../../assets/images/ic_close.png')}
                        style={styles.closeIcon}
                    />
                </TouchableOpacity>
                <KeyboardAvoidingView
                    behavior={Platform.OS === "ios" ? "padding" : 'height'}>
                    <Postcode
                        style={{ width: '100%', height: '100%' }}
                        jsOptions={{ animation: true, hideMapBtn: true }}
                        onSelected={data => {
                            onSelect(data);
                            hideModal();
                        }}
                    />
                </KeyboardAvoidingView>
            </View>
        )
    }

    return (
        Platform.OS !== 'web' ?
            <Modal
                isVisible={isVisible}
                style={{ alignItems: 'center' }}>
                {modalContent()}
            </Modal>
            :
            <WebModal
                visible={isVisible}
                transparent={true}>
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    backgroundColor: '#00000050',
                }}>
                    {modalContent()}
                </View>
            </WebModal>
    )
}

const styles = {
    modalContainer: {
        backgroundColor: c_white,
        width: Platform.OS !== 'web' ? '100%' : width,
        height: '63%',
        borderRadius: perfectSize(6),
        padding: Platform.OS !== 'web' ? perfectSize(15) : perfectSize(0)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
        alignSelf: 'flex-end'
    },
}