import {
    INVITATION_LOAD_START,
    INVITATION_LOAD_SUCCESS,
    INVITATION_LOAD_FAIL,
    INVITATION_LIST_RESET,
    INVITATION_LIST_UPDATE
} from '../../variables/constants';

const initialState = {
    invitations: [],
    invitationsLoading: false,
}

export default function InvitationReducer(state = initialState, action) {
    switch (action.type) {

        case INVITATION_LOAD_START:
            return state = { ...state, invitationsLoading: true };

        case INVITATION_LOAD_SUCCESS:
            return state = { ...state, invitations: [...action.payload], invitationsLoading: false };

        case INVITATION_LIST_UPDATE:
            return state = { ...state, invitations: [...action.payload] };

        case INVITATION_LIST_RESET:
            return state = { ...state, invitations: [] };

        case INVITATION_LOAD_FAIL:
            return state = { ...state, invitationsLoading: false };

        default:
            return state;
    }
}