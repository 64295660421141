import React, { useEffect, useState, useRef } from "react";
import {
  Platform,
  AppState,
  Linking,
  View,
  useWindowDimensions,
} from "react-native";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavigationContainer } from "@react-navigation/native";
import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
// import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from "@react-navigation/drawer";
import { perfectSize } from "../variables/styles";
import DrawerContent from "./DrawerContent";
import MainScreen from "../screens/main";
import SearchScreen from "../screens/search";
import SearchResultScreen from "../screens/search/SearchResult";
import SignInScreen from "../screens/signin";
import PasswordCertiRequestScreen from "../screens/signin/PasswordCertiRequest";
import PasswordScreen from "../screens/signin/Password";
import PasswordPasscodeScreen from "../screens/signin/PasswordPasscode";
import PasswordResetScreen from "../screens/signin/PasswordReset";
import SignUpScreen from "../screens/signup";
import AgreeScreen from "../screens/signup/Agree";
import AddPhoneScreen from "../screens/signup/AddPhone";
import PasscodeScreen from "../screens/signup/Passcode";
import SetPasswordScreen from "../screens/signup/SetPassword";
import DetailScreen from "../screens/details";
import TicketConfirmScreen from "../screens/confirm_ticket";
import TicketDetail from "../screens/confirm_ticket/TicketDetail";
import MobileTicket from "../screens/mobile_ticket";
import MobileTicketDetail from "../screens/mobile_ticket/MobileTicket";
import CommunityScreen from "../screens/community";
import CommunityWrite from "../screens/community/CommunityWrite";
import CommunityDetailScreen from "../screens/community/CommunityDetail";
import MyCommunity from "../screens/community/MyCommunity";
import CategoryScreen from "../screens/category";
import CreateReview from "../screens/community/CreateReview";
import SelectDateScreen from "../screens/order/SelectDate";
import OrderScreen from "../screens/order";
import OrderCompleteScreen from "../screens/order/OrderComplete";
import TicketGiftScreen from "../screens/gift";
import TicketCancelScreen from "../screens/confirm_ticket/TicketCancel";
import TicketCancelCompleteScreen from "../screens/confirm_ticket/TicketCancelComplete";
import CouponScreen from "../screens/coupon";
import TongTongMoniScreen from "../screens/tontong_moni";
import TongTongMileScreen from "../screens/tontong_mile";
import FavoriteScreen from "../screens/favorite";
import ReviewBookScreen from "../screens/review_book";
import TicketBookScreen from "../screens/ticket_book";
import TicketBookDetailScreen from "../screens/ticket_book/TicketBookDetail";
import TicketRevisitScreen from "../screens/ticket_book/TicketRevisit";
import AlarmScreen from "../screens/alarm";
import LoginScreen from "../screens/login";
import CustomerServiceScreen from "../screens/customer_service";
import InquiryScreen from "../screens/inquiry";
import InquiryDetailScreen from "../screens/inquiry/InquiryDetail";
import InquiryRequestScreen from "../screens/inquiry/InquiryRequest";
import NoticeScreen from "../screens/notice";
import NoticeDetailScreen from "../screens/notice/NoticeDetail";
import FAQScreen from "../screens/faq";
import ProfileScreen from "../screens/profile";
import ShippingAddressScreen from "../screens/profile/ShippingAddress";
import AddShippingAddressScreen from "../screens/profile/AddShippingAddress";
import TermsScreen from "../screens/terms";
import AudioPlayerScreen from "../screens/audio_player";
import SettingScreen from "../screens/setting";
import EventScreen from "../screens/event";
import SeatMapScreen from "../screens/order/tabs/SeatMap";
import BottomIconScreen from "../screens/setting/BottomIcons";
import LeaveMemberScreen from "../screens/profile/LeaveMember";
import {
  storeData,
  getData,
  instance,
  getParameterByName,
  oneAlert,
} from "../utils";
import {
  getBanner,
  getCategory,
  getHashTag,
  getCommunity,
  registerPushToken,
  getCoinPrices,
} from "../store/actions";
import {
  invitationList,
  invitationListReset,
} from "../store/actions/invitation";
import { couponList } from "../store/actions/coupon";

import VideoDetail from "../screens/main/detail/VideoDetail";
import AudioDetail from "../screens/main/detail/AudioDetail";

import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import _ from "lodash";
import { appIconList, updateAppIcons } from "../store/actions/appIcon";
import NFTDetail from "../screens/main/detail/NFTDetail";
import VideoPlayer from "../screens/video_player";
import NFTMolDetail from "../screens/main/detail/NFTMolDetail";
import BuyNFT from "../screens/main/buyNFT";
import NFTPin from "../screens/main/buyNFT/NFTPin";
import NFTBuyComplete from "../screens/main/buyNFT/NFTBuyComplete";
import MyNFT from "../screens/main/sellNFT/MyNFT";
import SellNFT from "../screens/main/sellNFT/SellNFT";
import Mania_recommend from "../screens/mania/mania_recommend";
import Mania_benefits from "../screens/mania/mania_benefits";
import ManiaPayReturn from "../screens/mania/mania_pay_return";
import ManiaItemReturn from "../screens/mania/mania_item_return";
import ContentDetail from "../screens/main/ContentDetail"
import PolicyBook from "../screens/main/PolicyBook"
import BenefitsPage from "../screens/profile/BenefitsPage";
import Mania_gift from "../screens/mania/mania_gift";
import Mania_payInfo from "../screens/mania/mania_payinfo";
import Mania_buy_goods from "../screens/mania/mania_buy_goods"
import classtvIndex from "../screens/login/classtvIndex";
import classtvfinish from "../screens/login/classtvfinish";

const defaultOption = {
  headerStyle: {
    backgroundColor: "white",
  },
  headerBackTitleVisible: false,
  headerTitleAlign: "center",
  headerTitleStyle: {
    fontSize: 16,
    letterSpacing: 0.3,
  },
  headerTintColor: "black",
  cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
};

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const DrawerNav = () => {
  return (
    <Drawer.Navigator
      drawerContent={(props) => <DrawerContent {...props} />}
      drawerStyle={{ width: perfectSize(300) }}
      screenOptions={defaultOption}
    >
      <Drawer.Screen name="Home" component={MainScreen} />
      <Drawer.Screen
        name="ConfirmTicket"
        component={TicketConfirmScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="MobileTicket"
        component={MobileTicket}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="Community"
        component={CommunityScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="MyCommunity"
        component={MyCommunity}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="Category"
        component={CategoryScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="Coupon"
        component={CouponScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="TongtongMoni"
        component={TongTongMoniScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="TongtongMile"
        component={TongTongMileScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="Favorite"
        component={FavoriteScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="ReviewBook"
        component={ReviewBookScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="TicketBook"
        component={TicketBookScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="Alarm"
        component={AlarmScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
          name="GroupLogin"
          component={LoginScreen}
          options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="CustomerService"
        component={CustomerServiceScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="Profile"
        component={ProfileScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="Setting"
        component={SettingScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="Event"
        component={EventScreen}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
        name="ManiaPayReturn"
        component={ManiaPayReturn}
        options={{ headerShown: true }}
      />
      <Drawer.Screen
          name="Mania_benefits"
          component={Mania_benefits}
          options={{ headerShown: true }}
      />
      <Drawer.Screen
          name="classtvIndex"
          component={classtvIndex}
          options={{ headerShown: true }}
      />
    </Drawer.Navigator>
  );
};

const NavigationRoot = (props) => {
  const notificationListener = useRef();
  const responseListener = useRef();
  const appState = useRef(AppState.current);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(false);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const { AppIconReducer, auth } = useSelector((state) => state);

  const linking = {
    prefixes: ["tongtongticket://"],
    config: {
      screens: {
        Main: {
          path: "",
          screens: {
            Alarm: "Alarm",
            Event: "Event",
            GroupLogin: "GroupLogin",
          },
        },
        Detail: "Detail",
        Notice: "Notice",
        NoticeDetail: "NoticeDetail",
        ManiaPayReturn: "ManiaPayReturn",
        Mania_benefits: "Mania_benefits",
        Mania_recommend: "Mania_recommend",
        classtvIndex: "classtvIndex",

      },
    },
  };

  const handleOpenUrl = (ev) => {
    if (ev.url) {
      const data = getParameterByName(ev.url);
      if (data?.phone) {
        instance
          .post("/api/tongtongcoin/auto-login", {
            apptype: "tongtongticket",
            nation: "KR",
            phone: data.phone,
          })
          .then((res) => {
            if (res.data) {
              dispatch({ type: "LOGIN", payload: res.data });
              dispatch(couponList());
              dispatch(invitationList());
              oneAlert("로그인이 완료되었습니다.");
            } else {
              oneAlert("자동로그인이 실패하였습니다.");
            }
          })
          .catch((err) => {
            oneAlert("자동로그인이 실패하였습니다.");
          });
      }
      if (data?.from) {
        instance
          .get("api/tongtongcoin/getAppList")
          .then((res) => {
            const filteredList = _.filter(res.data.menuList, (item) => {
              return item.android_package_name !== "com.etomato.ttticket";
            });

            if (Platform.OS === "android") {
              filteredList.sort((x, y) => {
                return x.android_scheme_name === data.from
                  ? -1
                  : y.android_scheme_name === data.from
                  ? 1
                  : 0;
              });
              storeData("@AppIcons", filteredList);
              dispatch(updateAppIcons(filteredList));
            } else {
              filteredList.sort((x, y) => {
                return x.ios_scheme_name === data.from
                  ? -1
                  : y.ios_scheme_name === data.from
                  ? 1
                  : 0;
              });
              storeData("@AppIcons", filteredList);
              dispatch(updateAppIcons(filteredList));
            }
          })
          .catch((err) => dispatch(updateAppIcons([])));
      }
    }
  };

  useEffect(() => {
    Linking.addEventListener("url", handleOpenUrl);
  }, []);

  useEffect(() => {
    props.getBanner();
    props.getCategory();
    props.getHashTag();
    props.getCoinPrices();
    initData();
  }, []);

  const initData = async () => {
    const loginEnable = await getData("@loginEnable");
    if (loginEnable) {
      const user = await getData("@user");
      if (user) {
        dispatch({ type: "LOGIN", payload: user });
        dispatch(couponList());
        dispatch(invitationList());
      }
    }
    dispatch(appIconList());
  };

  ///////////////////////////////////////////////////////////

  useEffect(() => {
    const event = AppState.addEventListener("change", _handleAppStatechange);

    return () => {
      Platform.OS !== "web" && event.remove();
    };
  }, []);

  const _handleAppStatechange = (nextAppState) => {
    if (nextAppState === "active") {
      setBadgeNumber();
    }
  };

  // useEffect(() => {
  //   registerForPushNotificationsAsync().then((token) => {
  //     registerPushToken(token);
  //   });
  //
  //   // Notification을 받은다음 처리
  //   notificationListener.current =
  //     Notifications.addNotificationReceivedListener((notification) => {
  //       // setNotification(notification);
  //       handleNotification(notification);
  //     });
  //
  //   // Notification을 클릭하였을때 처리
  //   responseListener.current =
  //     Notifications.addNotificationResponseReceivedListener((response) => {
  //       Linking.openURL(`${linking.prefixes[0]}/Alarm`);
  //       handleNotification(notification);
  //     });
  //
  //   return () => {
  //     Notifications.removeNotificationSubscription(
  //       notificationListener.current
  //     );
  //     Notifications.removeNotificationSubscription(responseListener.current);
  //   };
  // }, []);

  const handleNotification = async (notification) => {
    // setBadgeNumber();
  };

  const setBadgeNumber = async () => {
    const count = await Notifications.getBadgeCountAsync();
    dispatch({ type: "SET_NOTIFICATION_COUNT", payload: count });
  };

  async function registerForPushNotificationsAsync() {
    let token;
    if (Constants.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        // alert('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
      alert("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    return token;
  }

  // Notification Handler설정
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      // shouldSetBadge: true,
    }),
  });

  ///////////////////////////////////////////////////////////

  const window = useWindowDimensions();

  return (
    <View style={{ flex: 1, alignItems: "center", backgroundColor: "#dddddd" }}>
      <View
        style={{
          flex: 1,
          width: Platform.OS === "web" && window.width > 1000 ? 800 : "100%",
        }}
      >
        <NavigationContainer linking={linking}>
          <Stack.Navigator screenOptions={defaultOption}>
            <Stack.Screen
              name="Main"
              component={DrawerNav}
              options={{ headerShown: false }}
            />
            <Drawer.Screen
              name="SignIn"
              component={SignInScreen}
              options={{ headerShown: true }}
            />
            <Stack.Screen
              name="Search"
              component={SearchScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen name="SearchResult" component={SearchResultScreen} />
            <Stack.Screen name="Password" component={PasswordScreen} />
            <Stack.Screen
              name="PasswordReset"
              component={PasswordResetScreen}
            />
            <Stack.Screen
              name="PasswordCertiRequest"
              component={PasswordCertiRequestScreen}
            />
            <Stack.Screen
              name="PasswordPasscode"
              component={PasswordPasscodeScreen}
            />
            <Stack.Screen name="SignUp" component={SignUpScreen} />
            <Stack.Screen name="Detail" component={DetailScreen} />
            <Stack.Screen name="Agree" component={AgreeScreen} />
            <Stack.Screen name="AddPhone" component={AddPhoneScreen} />
            <Stack.Screen name="Passcode" component={PasscodeScreen} />
            <Stack.Screen name="SetPassword" component={SetPasswordScreen} />
            <Stack.Screen name="TicketDetail" component={TicketDetail} />
            <Stack.Screen
              name="MobileTicketDetail"
              component={MobileTicketDetail}
            />
            <Stack.Screen name="CommunityWrite" component={CommunityWrite} />
            <Stack.Screen
              name="CommunityDetail"
              component={CommunityDetailScreen}
            />
            <Stack.Screen name="CreateReview" component={CreateReview} />
            <Stack.Screen name="SelectDate" component={SelectDateScreen} />
            <Stack.Screen name="Order" component={OrderScreen} />
            <Stack.Screen
              name="OrderComplete"
              component={OrderCompleteScreen}
            />
            <Stack.Screen name="TicketGift" component={TicketGiftScreen} />
            <Stack.Screen name="TicketCancel" component={TicketCancelScreen} />
            <Stack.Screen
              name="TicketCancelComplete"
              component={TicketCancelCompleteScreen}
            />
            <Stack.Screen name="Inquiry" component={InquiryScreen} />
            <Stack.Screen
              name="InquiryDetail"
              component={InquiryDetailScreen}
            />
            <Stack.Screen
              name="InquiryRequest"
              component={InquiryRequestScreen}
            />
            <Stack.Screen name="Notice" component={NoticeScreen} />
            <Stack.Screen name="NoticeDetail" component={NoticeDetailScreen} />
            <Stack.Screen name="FAQ" component={FAQScreen} />
            <Stack.Screen
              name="TicketBookDetail"
              component={TicketBookDetailScreen}
            />
            <Stack.Screen
              name="TicketRevisit"
              component={TicketRevisitScreen}
            />
            <Stack.Screen
              name="ShippingAddress"
              component={ShippingAddressScreen}
            />
            <Stack.Screen
              name="AddShippingAddress"
              component={AddShippingAddressScreen}
            />
            <Stack.Screen
                name="LeaveMember"
                component={LeaveMemberScreen}
            />
            <Stack.Screen name="Terms" component={TermsScreen} />
            <Stack.Screen name="AudioPlayer" component={AudioPlayerScreen} />
            <Stack.Screen name="SeatMap" component={SeatMapScreen} />
            <Stack.Screen name="BottomIcons" component={BottomIconScreen} />

            <Stack.Screen name="VideoDetail" component={VideoDetail} />
            <Stack.Screen name="AudioDetail" component={AudioDetail} />
            <Stack.Screen name="NFTDetail" component={NFTDetail} />
            <Stack.Screen name="VideoPlayer" component={VideoPlayer} />
            <Stack.Screen name="NFTMolDetail" component={NFTMolDetail} />
            <Stack.Screen name="BuyNFT" component={BuyNFT} />
            <Stack.Screen name="NFTPin" component={NFTPin} />
            <Stack.Screen name="NFTBuyComplete" component={NFTBuyComplete} />
            <Stack.Screen name="MyNFT" component={MyNFT} />
            <Stack.Screen name="SellNFT" component={SellNFT} />
            <Stack.Screen name="Mania_recommend" component={Mania_recommend} />
            <Stack.Screen name="Mania_benefits" component={Mania_benefits} />
            <Stack.Screen name="ContentDetail" component={ContentDetail} />
            <Stack.Screen name="PolicyBook" component={PolicyBook} />
            <Stack.Screen name="ManiaPayReturn" component={ManiaPayReturn} />
            <Stack.Screen name="ManiaItemReturn" component={ManiaItemReturn} />
            <Stack.Screen name="BenefitsPage" component={BenefitsPage} />
            <Stack.Screen name="Mania_gift" component={Mania_gift} />
            <Stack.Screen name="Mania_payInfo" component={Mania_payInfo} />
            <Stack.Screen name="Mania_buy_goods" component={Mania_buy_goods} />
            <Stack.Screen name="classtvIndex" component={classtvIndex} />
            <Stack.Screen name="classtvfinish" component={classtvfinish} />
          </Stack.Navigator>
        </NavigationContainer>
      </View>
    </View>
  );
};

const mapStateTopProps = (state) => ({});

export default connect(mapStateTopProps, {
  getBanner,
  getCategory,
  getHashTag,
  getCommunity,
  getCoinPrices,
})(NavigationRoot);
