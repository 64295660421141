import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, TouchableOpacity, Image, Text, Platform } from "react-native";
import { globalStyle, perfectSize } from "../../variables/styles";
import {
  c_primary_red,
  c_second_white,
  c_white,
  c_third_grey,
  c_primary_black,
} from "../../variables/colors";
import DraggableFlatList from "react-native-draggable-flatlist";
import { updateAppIcons } from "../../store/actions/appIcon";

function BottomIconsScreen(props) {
  const { navigation } = props;
  const dispatch = useDispatch();
  const { AppIconReducer } = useSelector(state => state);

  useEffect(() => {
    navigation.setOptions({
      title: "메뉴설정",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../assets/images/ic_before.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
    });
  }, []);

  const renderItem = (props) => {
    const { item, drag, isActive } = props;
    return (
      <TouchableOpacity
        onLongPress={drag}
        disabled={isActive}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: perfectSize(15),
          height: perfectSize(48),
          backgroundColor: 'white'
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image source={{uri: item.menu_icon}} style={{ width: perfectSize(38), height: perfectSize(38), marginRight: perfectSize(12) }} />
          <Text style={{ fontSize: perfectSize(14) }}>{item.menu_name}</Text>
        </View>
        <Image
            source={require('../../../assets/images/ic_menu_sub.png')}
            style={globalStyle.headerNormalIcon}
        />
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.appContainer}>
      <DraggableFlatList
        data={AppIconReducer.appIcons}
        onDragEnd={({ data }) => dispatch(updateAppIcons(data))}
        keyExtractor={(item) => `draggable-item-${item.menu_name}`}
        renderItem={renderItem}
        style={{ height: '100%'}}
      />
    </View>
  );
}

export default BottomIconsScreen;

const styles = {
  appContainer: {
    flex: 1,
    backgroundColor: "white",
  },

  topSection: {
    backgroundColor: c_second_white,
    height: perfectSize(45),
    justifyContent: "center",
    paddingHorizontal: perfectSize(15),
  },
  topTitle: {
    fontSize: perfectSize(13),
    color: "#777777",
  },
  item: {
    backgroundColor: c_white,
    borderBottomWidth: 1,
    borderBottomColor: c_third_grey,
    justifyContent: "space-between",
    padding: perfectSize(15),
    flexDirection: "row",
    alignItems: "center",
  },
  firstTitle: {
    color: c_primary_black,
    fontSize: perfectSize(15),
    fontWeight: "bold",
  },
  secondTitle: {
    fontSize: perfectSize(11),
    color: "#777777",
    marginTop: perfectSize(8),
  },
};
