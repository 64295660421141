import React, { useEffect, useMemo, useState } from "react";
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ToastAndroid,
  TextInput,
  SafeAreaView,
  ActivityIndicator,
} from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import {
  c_grey,
  c_light_grey,
  c_primary_blue,
  c_primary_red,
  c_second_grey,
  c_third_grey,
  c_white,
} from "../../../variables/colors";
import { UPLOAD_URL } from "../../../variables/constants";
import { instance, numberWithCommas, oneAlert } from "../../../utils";
import { useSelector } from "react-redux";
import EntypoIcon from "@expo/vector-icons/Entypo";

export default function NFTPin({ route, navigation }) {
  const [activityLoading, setActivityLoading] = useState(false);
  const { data, count, price, coinType } = route.params;
  const { user } = useSelector(state => state).auth;

  const [pin, setPin] = useState("");

  useEffect(() => {
    navigation.setOptions({
      title: "결제",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../../assets/images/ic_before_stroke.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
    });
  }, []);

  useEffect(() => {
    if (pin.length === 6) {
      setActivityLoading(true);
      const req_body = {
        user_key: user?.userKey,
        wallet_name: user?.wallet_name,
        app_type: "tongtongticket",
        pin_number: pin,
      };

      ToastAndroid.show("request_getPinInfo", ToastAndroid.SHORT);

      instance
        .post("api/tongtongcoin/getPinInfo", req_body)
        .then(res => {

          //toast
          ToastAndroid.show("response_getPinInfo", ToastAndroid.SHORT);

          if (res.data.result) {
            const req_body = {
              to_address: data.walletName,
              amount_to_transfer: price * count,
              phonenum: user?.decPhoneNum,
              wallet_name: user?.wallet_name,
              wallet_pw: user?.wallet_pw,
              symbol: coinType,
            };

            //toast
            ToastAndroid.show("request_transfer", ToastAndroid.SHORT);

            instance
              .post("api/tongtongcoin/wallet_transfer_to_address", req_body)
              .then(res => {
                //toast
                ToastAndroid.show("response_transfer", ToastAndroid.SHORT);

                if (res.data.code === "200") {
                  //toast
                  ToastAndroid.show("request_api/nft/buy", ToastAndroid.SHORT);

                  instance
                    .post(`api/nft/buy/${data._id}`, {
                      user_id: user?._id,
                      count,
                    })
                    .then(res => {
                      navigation.navigate("NFTBuyComplete");
                      setActivityLoading(false);

                      oneAlert("NFTBuyComplete");
                      //toast
                      ToastAndroid.show(
                        "response_api/nft/buy",
                        ToastAndroid.SHORT
                      );
                    })
                    .catch(err => {
                      oneAlert(res.data.message);
                      setActivityLoading(false);

                      oneAlert("catch_api/nft/buy");
                      //toast
                      ToastAndroid.show(
                        "catch_api/nft/buy",
                        ToastAndroid.SHORT
                      );
                    });
                } else {
                  oneAlert(res.data.message);
                  setActivityLoading(false);

                  //toast
                  ToastAndroid.show(
                    "transfer_api_res_code_not_200",
                    ToastAndroid.SHORT
                  );
                }
              })
              .catch(err => {
                setActivityLoading(false);
                oneAlert("catch_transfer_api");

                //toast
                ToastAndroid.show("catch_transfer_api", ToastAndroid.SHORT);
              });
          } else {
            oneAlert(res.data.msg);
            setActivityLoading(false);

            //toast
            ToastAndroid.show(
              "getPinInfo_api_result == false",
              ToastAndroid.SHORT
            );
          }
        })
        .catch(() => {
          setActivityLoading(true);

          //toast
          ToastAndroid.show("catch_getPinInfo_api", ToastAndroid.SHORT);
          oneAlert("catch_getPinInfo_api");
        });
    }
  }, [pin]);

  const renderPoints = () => {
    return (
      <View style={{ flexDirection: "row", justifyContent: "center" }}>
        <View
          style={{
            width: perfectSize(14),
            height: perfectSize(14),
            borderRadius: perfectSize(14),
            marginHorizontal: perfectSize(16),
            backgroundColor: pin.length >= 1 ? c_primary_blue : "grey",
          }}
        />
        <View
          style={{
            width: perfectSize(14),
            height: perfectSize(14),
            borderRadius: perfectSize(14),
            marginHorizontal: perfectSize(16),
            backgroundColor: pin.length >= 2 ? c_primary_blue : "grey",
          }}
        />
        <View
          style={{
            width: perfectSize(14),
            height: perfectSize(14),
            borderRadius: perfectSize(14),
            marginHorizontal: perfectSize(16),
            backgroundColor: pin.length >= 3 ? c_primary_blue : "grey",
          }}
        />
        <View
          style={{
            width: perfectSize(14),
            height: perfectSize(14),
            borderRadius: perfectSize(14),
            marginHorizontal: perfectSize(16),
            backgroundColor: pin.length >= 4 ? c_primary_blue : "grey",
          }}
        />
        <View
          style={{
            width: perfectSize(14),
            height: perfectSize(14),
            borderRadius: perfectSize(14),
            marginHorizontal: perfectSize(16),
            backgroundColor: pin.length >= 5 ? c_primary_blue : "grey",
          }}
        />
        <View
          style={{
            width: perfectSize(14),
            height: perfectSize(14),
            borderRadius: perfectSize(14),
            marginHorizontal: perfectSize(16),
            backgroundColor: pin.length >= 6 ? c_primary_blue : "grey",
          }}
        />
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, paddingTop: perfectSize(40) }}>
      <EntypoIcon
        name="wallet"
        color={c_primary_blue}
        size={perfectSize(80)}
        style={{ alignSelf: "center" }}
      />
      <Text
        style={{
          fontSize: perfectSize(16),
          textAlign: "center",
          marginVertical: perfectSize(20),
        }}
      >
        {"비밀번호를 입력해주세요."}
      </Text>
      {renderPoints()}
      <TextInput
        value={pin}
        keyboardType={"number-pad"}
        onChangeText={text => {
          if (text.length < 7) setPin(text);
        }}
        autoFocus
        style={{ fontSize: perfectSize(12), height: 1 }}
      />
      {activityLoading && (
        <View style={styles.activityLoading}>
          <ActivityIndicator size={"large"} color={c_primary_red} />
        </View>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: perfectSize(15),
  },
  activityLoading: {
    flex: 1,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000022",
  },
});
