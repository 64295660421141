import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions,
    Platform,
    Linking,
    SafeAreaView
} from 'react-native';
import {c_fourth_grey, c_light_grey, c_primary_black, c_primary_red, c_white} from "../variables/colors";
import {globalStyle, perfectSize} from "../variables/styles";
import Modal from "react-native-modal";
import React from "react";
import WebModal from "modal-react-native-web";
import {numberWithCommas, oneAlert, twoAlert} from "../utils";
import moment from "moment/moment";
import HTML from "react-native-render-html";

const {height} = Dimensions.get('window');
const width = Dimensions.get('window').width;

export default function OneAlertModal({
                                          isVisible,
                                          hideModal,
                                          contents,
                                          txt_type
                                      }) {
    function AndroidBackButton() {
        hideModal();
    }

    return (
        <Modal
            animationIn="slideInUp"
            animationOut="slideOutDown"
            style={styles.parentModal}
            isVisible={isVisible}
            onRequestClose={() => AndroidBackButton()}>
            <View style={[styles.swipeModal, {width: perfectSize(330), height: perfectSize(726)}]}>
                <TouchableOpacity
                    onPress={hideModal}
                    style={globalStyle.flex}>
                </TouchableOpacity>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    <View style={styles.headerContainer}>
                        {/** close */}
                        <TouchableOpacity
                            onPress={hideModal}>
                            <Image
                                source={require('../../assets/images/ic_close.png')}
                                style={styles.closeIcon}
                            />
                        </TouchableOpacity>
                    </View>

                    <ScrollView showsHorizontalScrollIndicator={false}
                                showsVerticalScrollIndicator={false}>
                        <View style={{marginBottom: perfectSize(30), alignItems: 'center', justifyContent: 'center'}}>
                            {
                                txt_type === "nomal" ?
                                    <Text
                                        style={{
                                            fontSize: perfectSize(15),
                                            letterSpacing: -0.15,
                                            color: c_primary_black,
                                        }}
                                    >{contents}</Text> :
                                    <HTML
                                        source={{html: contents}}
                                        baseFontStyle={styles.baseFontStyle}
                                        tagsStyles={styles.tagsStyles}
                                        containerStyle={styles.containerStyle}
                                        contentWidth={width}
                                    />
                            }
                        </View>
                        <TouchableOpacity
                            onPress={() => {
                                hideModal(false);
                            }}
                            style={[styles.cardSection,
                                {
                                    paddingVertical: perfectSize(15),
                                    backgroundColor: c_primary_red,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }]}>
                            <Text style={styles.confirmText}>
                                {'확인'}
                            </Text>
                        </TouchableOpacity>
                    </ScrollView>
                </View>
            </View>
        </Modal>
    )
}

const styles = {
    parentModal: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        alignItems: 'stretch',
        paddingHorizontal: perfectSize(20),
        paddingTop: 0,
        paddingBottom: perfectSize(20),
        borderBottomRightRadius: perfectSize(10),
        borderBottomLeftRadius: perfectSize(10),
        borderTopRightRadius: perfectSize(10),
        borderTopLeftRadius: perfectSize(10),
        maxHeight: height / 4 * 3,
    },
    headerContainer: {
        marginVertical: perfectSize(15),
        marginTop: perfectSize(20),
        flexDirection: 'row', justifyContent: 'flex-end'
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
    cardSection: {
        borderColor: c_primary_red,
        padding: perfectSize(15),
        paddingVertical: perfectSize(12),
        marginBottom: perfectSize(5),
        borderBottomRightRadius: perfectSize(10),
        borderBottomLeftRadius: perfectSize(10),
        borderTopRightRadius: perfectSize(10),
        borderTopLeftRadius: perfectSize(10),
    },
    confirmText: {
        fontSize: perfectSize(14),
        letterSpacing: -0.15,
        color: c_white,
        fontWeight: 'normal',
    },
    baseFontStyle: {
        fontSize: 18,
        textAlign: 'center',
    },
    tagsStyles: {
        p: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    containerStyle: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}