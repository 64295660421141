import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  StyleSheet,
  ScrollView,
  TextInput,
  Text,
  SafeAreaView,
} from "react-native";
import { c_light_grey, c_primary_red } from "../../../variables/colors";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { useNavigation } from "@react-navigation/native";
import ModalDropdown from "react-native-modal-dropdown";
import { getNFTs } from "../../../store/actions/performance";
import { useSelector } from "react-redux";
import { UPLOAD_URL } from "../../../variables/constants";
import { instance, numberWithCommas, oneAlert } from "../../../utils";

const dropdownList = ["최신순", "가격 낮은순", "가격 높은순"];

const MyNFT = props => {
  const navigation = useNavigation();
  const [sortType, setSortType] = useState(0);
  const [myNft, setMyNft] = useState([]);
  const { user } = useSelector(state => state).auth;
  const { coinData } = useSelector(state => state).app;

  useEffect(() => {
    navigation.setOptions({
      title: "NFT 보유목록",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../../assets/images/ic_before.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
      headerRight: () => (
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Image
            source={require("../../../../assets/images/ic_close.png")}
            style={globalStyle.navIcon}
          />
        </TouchableOpacity>
      ),
    });
  }, []);

  useEffect(() => {
    if (user._id) {
      getNFTs({
        page: 1,
        limit: 10,
        sortType,
        owner: user._id,
      })
        .then(res => {
          setMyNft(res.data.nfts);
        })
        .catch(err => console.error(err));
    }
  }, [sortType]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.mainContainer}>
        <View style={styles.container}>
          <Text
            style={{ fontSize: perfectSize(15), marginLeft: perfectSize(15) }}
          >
            {"보유 목록"}
          </Text>
          <ModalDropdown
            onSelect={index => setSortType(index)}
            options={dropdownList}
            defaultIndex={0}
            defaultValue={dropdownList[0]}
            renderSeparator={() => <View />}
            dropdownStyle={{
              width: perfectSize(80),
              height: perfectSize(40 * dropdownList.length),
            }}
            dropdownTextStyle={globalStyle.dropdownTextStyle}
            dropdownTextHighlightStyle={{ color: c_primary_red }}
            textStyle={globalStyle.regularText}
            renderRightComponent={() => (
              <Image
                source={require("../../../../assets/images/ic_list_order.png")}
                style={globalStyle.dropdownRightImage}
              />
            )}
            style={globalStyle.dropdownStyle}
          />
        </View>
        <ScrollView
          contentContainerStyle={{
            padding: perfectSize(15),
            paddingTop: perfectSize(0),
          }}
        >
          {myNft.map(nft => {
            return (
              <View
                key={nft._id}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottomWidth: 1,
                  paddingVertical: perfectSize(10),
                  borderBottomColor: c_light_grey,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Image
                    source={{ uri: UPLOAD_URL + nft.content?.thumbnail }}
                    style={{
                      width: perfectSize(100),
                      height: perfectSize(60),
                      backgroundColor: c_light_grey,
                      marginRight: perfectSize(6),
                    }}
                  />
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{ fontSize: perfectSize(12) }}
                      numberOfLines={1}
                    >
                      {nft.content?.title}
                    </Text>
                    <Text style={{ fontSize: perfectSize(10) }}>
                      발행처: {nft.partner?.name}
                    </Text>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Text style={{ fontSize: perfectSize(10) }}>
                        구입 시세:{" "}
                      </Text>
                      <Image
                        source={{
                          uri: "https://twallet.tongtongchain.io/images/api/ttcoin.png",
                        }}
                        style={{
                          width: perfectSize(14),
                          height: perfectSize(14),
                        }}
                      />
                      <Text style={{ fontSize: perfectSize(10), flex: 1 }}>
                        {Number(numberWithCommas(nft.price)).toFixed(2)}(
                        {numberWithCommas(
                          String(
                            parseFloat(nft.price) *
                              parseFloat(coinData?.coin.coin_price)
                          )
                        )}
                        )원
                      </Text>
                    </View>
                  </View>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    if (!nft.isForSale) {
                      navigation.navigate("SellNFT", { data: nft });
                    } else {
                      const req_body = {
                        nftId: nft._id,
                        userId: user._id,
                      };
                      instance
                        .post(`api/nft/cancel-sale`, req_body)
                        .then(res => {
                          oneAlert("판매 취소가 완료되었습니다.", "");
                          if (user._id) {
                            getNFTs({
                              page: 1,
                              limit: 1000,
                              sortType,
                              owner: user._id,
                            })
                              .then(res => {
                                setMyNft(res.data.nfts);
                              })
                              .catch(err => console.error(err));
                          }
                        })
                        .catch(err => {
                          oneAlert("판매 취소가 실패하였습니다.");
                        });
                    }
                  }}
                  style={styles.nftSellbutton}
                >
                  <Text style={styles.nftSellButtonLabel}>
                    {!nft.isForSale ? "NFT 판매" : "판매취소"}
                  </Text>
                </TouchableOpacity>
              </View>
            );
          })}
          {myNft.length === 0 && (
            <Text
              style={{
                color: "grey",
                fontSize: perfectSize(14),
                marginTop: perfectSize(16),
                textAlign: "center",
              }}
            >
              {"보유중인 NFT가 없습니다."}
            </Text>
          )}
        </ScrollView>
        {myNft.length === 0 && (
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            style={{
              backgroundColor: c_primary_red,
              height: perfectSize(52),
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: perfectSize(16), color: "white" }}>
              {"NFT몰 메인으로 이동하기"}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </SafeAreaView>
  );
};

export default MyNFT;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainContainer: {
    flex: 1,
    backgroundColor: "white",
  },
  searchWrapper: {
    flexDirection: "row",
    paddingHorizontal: perfectSize(15),
    alignItems: "center",
  },
  searchInput: {
    flex: 1,
    height: perfectSize(40),
    borderColor: c_primary_red,
    borderWidth: 1,
    borderRadius: perfectSize(6),
    marginVertical: perfectSize(20),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: perfectSize(15),
    marginRight: perfectSize(5),
  },
  nftSellbutton: {
    height: perfectSize(34),
    paddingHorizontal: perfectSize(10),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: c_primary_red,
    borderRadius: perfectSize(10),
  },
  nftSellButtonLabel: {
    color: "white",
    fontSize: perfectSize(12),
  },
});
