import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput
} from 'react-native';
import {
    c_black,
    c_primary_black,
    c_second_white,
    c_third_grey,
    c_white,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { UPLOAD_URL } from '../../variables/constants';
import { makeTwoString } from '../../utils';
import { revisitTicket } from '../../store/actions';

export default function TicketRevisit({ navigation, route }) {

    const { data } = route.params;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        navigation.setOptions({
            title: "티켓북",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    const onRevisit = async () => {
        setLoading(true);
        const res = await revisitTicket(data._id);
        if (res) navigation.navigate('TicketBook');
        setLoading(false);
    }

    return (
        <View style={globalStyle.flex}>
            <KeyboardAwareScrollView>
                <View style={styles.scrollView}>
                    <View style={styles.orderConfirmCard}>
                        <View style={{ padding: perfectSize(15), paddingBottom: 0 }}>
                            <Image
                                source={{ uri: (UPLOAD_URL + data.product_id.poster_link) }}
                                style={{
                                    width: '100%',
                                    height: perfectSize(420),
                                    resizeMode: 'stretch',
                                    backgroundColor: c_second_white,
                                    marginBottom: perfectSize(15),
                                    borderRadius: perfectSize(6),
                                }}
                            />
                            <Text style={[globalStyle.bigBoldText, { textAlign: 'center' }]}>
                                {data.product_id.title.name}
                            </Text>

                            <View style={{ marginVertical: 15 }}>
                                <Button
                                    onPress={onRevisit}
                                    textColor={c_white}
                                    label={'직원 확인'}
                                    color={c_primary_red}
                                    isBorder={false}
                                    loading={loading}
                                />
                            </View>

                            <Text style={{ fontSize: perfectSize(13), letterSpacing: -0.13, color: '#999999', marginBottom: perfectSize(24) }}>
                                {'o 본 화면을 공연장 직원에게 제시해주세요.\no 직원할인을 누르시면 사용처리 됩니다.'}
                            </Text>
                        </View>
                    </View>

                    <Text style={{ marginBottom: perfectSize(15), fontSize: perfectSize(15), fontWeight: 'bold', color: '#777777' }}>
                        [유의사항]
                    </Text>

                    <Text style={{ fontSize: perfectSize(12), color: '#999999', marginBottom: perfectSize(30) }}>
                        {'직원할인 확인 시 재관람 할인은 사용여부에 상관없이 사용처리되며 해당 할인은 복구되지 않습니다.\n\n타 할인과 중복할인 불가합니다.\n\n반드시 공연장 현장에서 직원이 직접 확인처리 해주세요.\n\n재관람 할인의 경우, 관람완료 티켓 1장 당 1회의 할인이 적용됩니다.(재사용불가)\n\n현장에서는 할인 변경이 절대 불가합니다.'}
                    </Text>
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}

const styles = {
    orderConfirmCard: {
        marginBottom: perfectSize(20),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
        shadowColor: c_black,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        elevation: 2,
    },
    scrollView: {
        paddingHorizontal: perfectSize(17),
        paddingVertical: perfectSize(20)
    },
    textInput: {
        borderWidth: 1,
        borderColor: c_third_grey,
        borderRadius: perfectSize(6),
        height: perfectSize(94),
        marginTop: perfectSize(7),
        marginBottom: perfectSize(20),
        padding: perfectSize(12)
    },
}