import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import {
    c_grey,
    c_primary_black,
    c_primary_red,
    c_second_grey,
    c_third_grey,
    c_white,
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import FAQItem from '../../components/FAQItem';
import { getFAQ } from '../../store/actions';

export default function FAQScreen({ navigation }) {

    const [type, setType] = useState(0);
    const [FAQ, setFAQ] = useState([]);
    const [filteredData, setFilterData] = useState([]);
    const TYPE = [
        '',
        '회원가입',
        '티켓예매',
        '결재수단',
        '티켓인증',
        '티켓수령',
        '취소, 환불',
    ]

    useEffect(() => {
        navigation.setOptions({
            title: "FAQ",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        getFAQs();
    }, []);

    useEffect(() => {
        const _data = _.filter(FAQ, (o) => { return o.type.includes(TYPE[type]) });
        setFilterData(_data);
    }, [FAQ, type]);

    const getFAQs = async () => {
        const res = await getFAQ();
        console.log(res);
        setFAQ(res);
    }

    return (
        <ScrollView style={styles.mainContainer}>
            <View style={{
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                padding: perfectSize(16),
                paddingBottom: perfectSize(0)
            }}>
                <TouchableOpacity
                    onPress={() => setType(0)}
                    style={[styles.typeButton, { backgroundColor: type === 0 ? c_primary_red : c_white }]}>
                    <Text style={{
                        fontSize: perfectSize(13),
                        fontWeight: type === 0 ? 'bold' : 'normal',
                        color: type === 0 ? c_white : c_primary_black
                    }}>
                        {'전체'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => setType(1)}
                    style={[styles.typeButton, { backgroundColor: type === 1 ? c_primary_red : c_white, borderLeftWidth: 0 }]}>
                    <Text style={{
                        fontSize: perfectSize(13),
                        fontWeight: type === 1 ? 'bold' : 'normal',
                        color: type === 1 ? c_white : c_primary_black
                    }}>
                        {'회원가입'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => setType(2)}
                    style={[styles.typeButton, { backgroundColor: type === 2 ? c_primary_red : c_white, borderLeftWidth: 0 }]}>
                    <Text style={{
                        fontSize: perfectSize(13),
                        fontWeight: type === 2 ? 'bold' : 'normal',
                        color: type === 2 ? c_white : c_primary_black
                    }}>
                        {'티켓예매'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => setType(3)}
                    style={[styles.typeButton, { backgroundColor: type === 3 ? c_primary_red : c_white, borderTopWidth: 0 }]}>
                    <Text style={{
                        fontSize: perfectSize(13),
                        fontWeight: type === 3 ? 'bold' : 'normal',
                        color: type === 3 ? c_white : c_primary_black
                    }}>
                        {'결재수단'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => setType(4)}
                    style={[styles.typeButton, { backgroundColor: type === 4 ? c_primary_red : c_white, borderLeftWidth: 0, borderTopWidth: 0 }]}>
                    <Text style={{
                        fontSize: perfectSize(13),
                        fontWeight: type === 4 ? 'bold' : 'normal',
                        color: type === 4 ? c_white : c_primary_black
                    }}>
                        {'티켓인증'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => setType(5)}
                    style={[styles.typeButton, { backgroundColor: type === 5 ? c_primary_red : c_white, borderLeftWidth: 0, borderTopWidth: 0 }]}>
                    <Text style={{
                        fontSize: perfectSize(13),
                        fontWeight: type === 5 ? 'bold' : 'normal',
                        color: type === 5 ? c_white : c_primary_black
                    }}>
                        {'티켓수령'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => setType(6)}
                    style={[styles.typeButton, { backgroundColor: type === 6 ? c_primary_red : c_white, borderTopWidth: 0 }]}>
                    <Text style={{
                        fontSize: perfectSize(13),
                        fontWeight: type === 6 ? 'bold' : 'normal',
                        color: type === 6 ? c_white : c_primary_black
                    }}>
                        {'취소/환불'}
                    </Text>
                </TouchableOpacity>
                {/* <TouchableOpacity
                    onPress={() => setType(7)}
                    style={[styles.typeButton, { backgroundColor: type === 7 ? c_primary_red : c_white, borderLeftWidth: 0, borderTopWidth: 0 }]}>
                    <Text style={{
                        fontSize: perfectSize(13),
                        fontWeight: type === 7 ? 'bold' : 'normal',
                        color: type === 7 ? c_white : c_primary_black
                    }}>
                        {'기타'}
                    </Text>
                </TouchableOpacity> */}
            </View>
            {/** List */}
            <View>
                {
                    filteredData.map((item, index) => {
                        return (
                            <FAQItem
                                key={index}
                                type={item.type}
                                title={item.title}
                                content={item.content}
                            />
                        )
                    })
                }
            </View>
        </ScrollView>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
        paddingBottom: perfectSize(40),
    },
    typeButton: {
        width: perfectSize(109),
        height: perfectSize(39),
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: c_third_grey
    },
}