import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
} from 'react-native';
import {
    c_black,
    c_dark_white,
    c_light_grey,
    c_primary_blue,
    c_primary_red,
    c_second_grey,
    c_second_white,
    c_third_grey,
    c_white
} from '../../variables/colors';
import { HOST, UPLOAD_URL } from '../../variables/constants';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import TicketCard from '../../components/TicketCard';
import Separator from '../../components/Separator';
import TicketCancelRuleModal from '../../modals/TicketCancelRuleModal';
import TicketDeliveryCancelModal from '../../modals/TicketDeliveryCancelModal';
import { makeTwoString, numberWithCommas, oneAlert } from '../../utils';
import { cancelOrderTickets, cancelOrder } from '../../store/actions';
import CardModal from '../../modals/CardModal';

function TicketCancel({ navigation, route, user }) {

    const [data, setData] = useState(route.params.data);
    const [availableTicket, setAvailableTicket] = useState([]);
    const [isAllSelected, setAllSelect] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);
    const [refundPrice, setRefundPrice] = useState(0);
    const [cancelFee, setCancelFee] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [mainPrice, setMainPrice] = useState(0);
    const [ticketPoint, setTicketPoint] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [ticketOrderFee, setTicketOrderFee] = useState(0);
    const [invitationPrice, setInvitationPrice] = useState(0);
    const [tickets, setSelectedTickets] = useState([]);
    const [isRefundCoupon, setRefundCoupon] = useState(false);
    const [visibleCancelRuleModal, setVisibleCancelRuleModal] = useState(false);
    const [visibleDeliveryCancelRuleModal, setVisibleDeliveryCancelRuleModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [visibleCardModal, setVisibleCardModal] = useState(false);
    const timerRef = useRef(null);

    const d = new Date(data.product_id.schedule.detailed_time[data.round].date);
    d.setDate(d.getDate() - 1);
    d.setHours(17);
    const current = new Date();

    useEffect(() => {
        data.tickets.map(item => {
            if (!item.isCancelled && !item.isGift)
                availableTicket.push(item);
        });
        setAvailableTicket(availableTicket);
    }, []);

    useEffect(() => {
        navigation.setOptions({
            title: "예매취소",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        let percent = 0;
        let priceFee = 0;
        if (current.getTime() < d.getTime()) {
            percent = 0.3;
        }
        d.setDate(d.getDate() - 3);
        // console.log(moment(d).format('YYYY.MM.DD HH:mm'))
        if (current.getTime() < d.getTime()) {
            percent = 0.2
        }
        d.setDate(d.getDate() - 3);
        // console.log(moment(d).format('YYYY.MM.DD HH:mm'))
        if (current.getTime() < d.getTime()) {
            percent = 0.1
        }
        d.setDate(d.getDate() - 2);
        // console.log(moment(d).format('YYYY.MM.DD HH:mm'))
        if (current.getTime() < d.getTime()) {
            percent = 0.1
        }
        d.setDate(d.getDate() - 2);
        // console.log(moment(d).format('YYYY.MM.DD HH:mm'))
        if (current.getTime() < d.getTime()) {
            priceFee = 2000;
        }
        if (current.getDate() === d.getDate() && current.getMonth() === d.getMonth()) {
            percent = 0;
            priceFee = 0;
        }
        const orderDate = new Date(data.tickets[0].createdAt);
        orderDate.setDate(orderDate.getDate() + 7);
        if ((current.getTime() < orderDate.getTime()) && (current.getTime() < d.getTime())) {
            priceFee = 0;
            percent = 0;
        }

        if (moment(data.createdAt).format('YYYYMMDD') === moment(current).format('YYYYMMDD')) { // 예매당일 쉬소시 취소수수료 없음.
            priceFee = 0;
            percent = 0;
        }

        console.log('=======percent', percent);
        console.log('=======price', priceFee);

        let cancelfee = 0;
        let returnPrice = 0;
        let nSelectedTicket = 0;
        let ticketMainPrice = 0;
        let ticketDiscount = 0;
        let selectedTickets = [];
        let orderFee = 0;
        let feeCount = 0;
        let _orderFee = 0;
        let invitationDiscount = 0;
        let ttcoPoint = 0;

        console.log(data.tickets);

        data.tickets.map(item => {
            if (!item.isInvitation) {
                feeCount++;
            }
        });

        _orderFee = data.fee / feeCount;

        data.tickets.map(item => {
            if (item.isSelected) {
                let invitationPrice = 0;
                let ticketPrice = item.price - (item.price / 100 * item.discount.discount);
                ticketMainPrice += item.price;
                ttcoPoint += item.point;
                ticketDiscount += item.price / 100 * item.discount.discount;
                returnPrice += ticketPrice + _orderFee - ttcoPoint;
                if (item.isInvitation) {
                    invitationPrice = item.discount.discount ? ticketPrice : item.price;
                } else {
                    invitationPrice = 0;
                }
                invitationDiscount += invitationPrice;
                if (priceFee) {
                    cancelfee += priceFee;
                } else {
                    cancelfee += (ticketPrice - invitationPrice) * percent;
                }
                if (!item.isFee) {
                    orderFee += _orderFee;
                }
                selectedTickets.push(item._id);
                nSelectedTicket++;
            }
        });
        let couponPrice = 0;
        if (data.coupon) {
            if ((data.total_price - returnPrice) < data.coupon.limit_amount) {
                couponPrice = data.coupon_price;
                setRefundCoupon(true);
            } else if (selectedCount === availableTicket.length) {
                setRefundCoupon(true);
                couponPrice = data.coupon_price;
            } else {
                setRefundCoupon(false);
            }
        }
        let delivery_fee = data.delivery_fee * nSelectedTicket;
        console.log('=====returnprice', returnPrice);
        console.log('=====fee', cancelfee);
        console.log('=====deliveryFee', delivery_fee);
        console.log('=====orderFee', orderFee);
        console.log('=====couponPrice', couponPrice);
        console.log('=====selectedTickets', selectedTickets);
        const total_price = ticketMainPrice - ticketDiscount - invitationDiscount + delivery_fee + orderFee - ttcoPoint;
        setSelectedTickets(selectedTickets);
        setMainPrice(ticketMainPrice); // 기본가
        setTicketPoint(ttcoPoint); // 사용한포인트
        setDiscount(-ticketDiscount); // 가격할인
        setDeliveryFee(delivery_fee); // 배송비
        setTicketOrderFee(orderFee); // 예매수수료
        setInvitationPrice(-invitationDiscount);
        setTotalPrice(total_price); // 총금액
        setRefundPrice(total_price - cancelfee - delivery_fee - orderFee + couponPrice); // 환불액
        setCancelFee(cancelfee); // 취소수수료
    }, [selectedCount])

    useEffect(() => {
        selectedCount === availableTicket.length ?
            setAllSelect(true)
            : setAllSelect(false)
    }, [selectedCount]);

    const onCancelOrder = async () => {
        if (current.getTime() > d.getTime()) {
            oneAlert(`취소마감시간은 ${moment(d).format('YYYY.MM.DD HH:mm')} 까지 입니다.`);
            return;
        }

        if (data.payment_method === '신용카드') {
            setVisibleCardModal(true);
            count = 0;
            onCheckCard();
        } else {
            handleCancelOrder();
        }
    }

    const handleCancelOrder = async () => {
        setLoading(true);
        for (let i = 0; i < tickets.length; i++)
            await cancelOrderTickets(tickets[i]);
        await cancelOrder(data._id, {
            cancel_fee: cancelFee,
            refund_method: '환불',
            refund_amount: refundPrice,
            isRefundCoupon: isRefundCoupon
        });
        navigation.navigate('TicketCancelComplete');
        setLoading(false);
    }

    let count = 0;

    const onCheckCard = () => {
        count++;
        axios.post(`${HOST}/api/tongtongcoin/checkCancelPay`, { TID: data.TID, })
            .then(res => {
                if (res.data.cancelStatus === 'pending') {
                    if (count > 250) {
                        setVisibleCardModal(false);
                        oneAlert('카드결제가 실패하였습니다.');
                        return;
                    } else {
                        timerRef.current = setTimeout(() => onCheckCard(), 6000);
                    }
                } else if (res.data.cancelStatus === 'success') {
                    setVisibleCardModal(false);
                    handleCancelOrder();
                } else {
                    setVisibleCardModal(false);
                    oneAlert('카드결제가 실패하였습니다.');
                }
            })
            .catch(err => {
                oneAlert('카드결제가 실패하였습니다.');
            })
    }

    return (
        <View style={styles.mainContainer}>
            <ScrollView>
                <View>

                    {/** 예매정보 */}
                    <View style={styles.item}>
                        <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                            {'예매정보'}
                        </Text>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'예매번호'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {data.order_id}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'예매자'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {data.orderer_name}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'예매일시'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {data.tickets[0] && moment(data.tickets[0].createdAt).format('YYYY.MM.DD HH:mm')}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'예매상태'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {'예매성공'}
                            </Text>
                        </View>
                        <View style={{ ...globalStyle.flexRow, marginBottom: perfectSize(20) }}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'취소마감'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {`${moment(d).format('YYYY.MM.DD HH:mm')} 까지`}
                            </Text>
                        </View>
                    </View>

                    {/** 금액상세 */}
                    <View style={styles.item}>
                        <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                            {'금액상세'}
                        </Text>
                        <View style={globalStyle.flexSpaceBetween}>
                            <Text style={[globalStyle.regularRedBoldText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'총 금액'}
                            </Text>
                            <Text style={[globalStyle.regularRedBoldText, { marginBottom: perfectSize(10) }]}>
                                {`${numberWithCommas(totalPrice)}원`}
                            </Text>
                        </View>
                        <View style={globalStyle.flexSpaceBetween}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'티켓금액'}
                            </Text>
                            {/* <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {`${numberWithCommas(data.main_price - data.discount + data.coupon_price - data.invitation_discount)}원`}
                            </Text> */}
                        </View>
                        <View style={{
                            paddingHorizontal: perfectSize(15),
                            paddingVertical: perfectSize(12),
                            backgroundColor: c_dark_white,
                            marginBottom: perfectSize(10)
                        }}>
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                    {'기본가'}
                                </Text>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>
                                    {`${numberWithCommas(mainPrice)}원`}
                                </Text>
                            </View>
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularGreyText, {
                                    marginBottom: perfectSize(10),
                                    width: perfectSize(80)
                                }]}>
                                    {'TTCO할인 차감'}
                                </Text>
                                <Text style={[globalStyle.regularGreyText, {marginBottom: perfectSize(10)}]}>
                                    {`${numberWithCommas(ticketPoint)}원`}
                                </Text>
                            </View>
                            <View style={{ ...globalStyle.flexSpaceBetween, marginBottom: perfectSize(10) }}>
                                <Text style={globalStyle.regularGreyText}>
                                    {'가격할인'}
                                </Text>
                                <Text style={globalStyle.regularGreyText}>
                                    {`${numberWithCommas(discount)}원`}
                                </Text>
                            </View>
                            {/* <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                    {'쿠폰할인'}
                                </Text>
                                <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>
                                    {`${numberWithCommas(data.coupon_price)}원`}
                                </Text>
                            </View> */}
                            {/*<View style={globalStyle.flexSpaceBetween}>*/}
                            {/*    <Text style={[globalStyle.regularGreyText, { width: perfectSize(80) }]}>*/}
                            {/*        {'예매권할인'}*/}
                            {/*    </Text>*/}
                            {/*    <Text style={globalStyle.regularGreyText}>*/}
                            {/*        {`${numberWithCommas(invitationPrice)}원`}*/}
                            {/*    </Text>*/}
                            {/*</View>*/}
                        </View>
                        {
                            data.delivery_method === '배송' &&
                            <View style={globalStyle.flexSpaceBetween}>
                                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                    {'배송비'}
                                </Text>
                                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                    {`${numberWithCommas(deliveryFee)}원`}
                                </Text>
                            </View>
                        }
                        <View style={globalStyle.flexSpaceBetween}>
                            <Text style={globalStyle.regularText}>
                                {'예매수수료'}
                            </Text>
                            <Text style={globalStyle.regularText}>
                                {`${numberWithCommas(ticketOrderFee)}원`}
                            </Text>
                        </View>
                        <Text style={{ ...globalStyle.smRedText, marginBottom: perfectSize(20), marginTop: perfectSize(5) }}>
                            {'예매일 이후 취소시, 예매수수료는 환불되지 않습니다.'}
                        </Text>

                        <Separator
                            height={1}
                            color={c_third_grey}
                            marginBottom={perfectSize(20)}
                        />

                        <View style={{ ...globalStyle.flexSpaceBetween, marginBottom: perfectSize(16) }}>
                            <Text style={globalStyle.regularRedBoldText}>
                                {'환불액'}
                            </Text>
                            <Text style={globalStyle.regularRedBoldText}>
                                {`${numberWithCommas(refundPrice)}원`}
                            </Text>
                        </View>
                        <View style={{ ...globalStyle.flexSpaceBetween, marginBottom: perfectSize(10) }}>
                            <Text style={globalStyle.regularText}>
                                {'취소수수료'}
                            </Text>
                            <View style={globalStyle.flexRow}>
                                <Text style={globalStyle.regularText}>
                                    {`${numberWithCommas(cancelFee)}원`}
                                </Text>
                                <TouchableOpacity onPress={() => setVisibleCancelRuleModal(true)}>
                                    <Image
                                        source={require('../../../assets/images/ic_q.png')}
                                        style={{
                                            width: perfectSize(17),
                                            height: perfectSize(17),
                                            resizeMode: 'contain',
                                            marginLeft: perfectSize(5)
                                        }}
                                    />
                                </TouchableOpacity>
                            </View>
                        </View>
                        {/* <View style={{ ...globalStyle.flexSpaceBetween, marginBottom: perfectSize(5) }}>
                            <Text style={globalStyle.regularText}>
                                {'환불예정금액'}
                            </Text>
                            <Text style={{ ...globalStyle.regularText, color: c_primary_blue }}>
                                {'3,000' + ' 통통머니'}
                            </Text>
                        </View>
                        <View style={{ alignItems: 'flex-end', marginBottom: perfectSize(30) }}>
                            <Text style={{ ...globalStyle.smText, marginBottom: perfectSize(15) }}>
                                {'≒ 53,000 KRW'}
                            </Text>
                            <Text style={globalStyle.smGreyText}>
                                {'1 통통머니 ≒ 1.00 KRW'}
                            </Text>
                            <Text style={globalStyle.smGreyText}>
                                {'※ 변환금액은 시세에 따라 변동합니다.(산정기준 2021.03.02  12:16)'}
                            </Text>
                        </View> */}
                    </View>

                    {/** 좌석정보 */}
                    <View style={[styles.item, { paddingBottom: perfectSize(30), marginBottom: perfectSize(0) }]}>
                        <Text style={[globalStyle.regularBoldText, { marginVertical: perfectSize(20) }]}>
                            {'취소예정좌석'}
                        </Text>
                        <TouchableOpacity
                            onPress={() => {
                                if (isAllSelected) {
                                    setSelectedCount(0);
                                } else {
                                    setSelectedCount(availableTicket.length);
                                }
                                availableTicket.map((ticket) => {
                                    ticket.isSelected = !isAllSelected;
                                });
                                setAllSelect(!isAllSelected);
                            }}
                            style={[globalStyle.flexRow, { marginBottom: perfectSize(6) }]}>
                            <Image
                                source={
                                    isAllSelected ?
                                        require('../../../assets/images/ic_ticket_all_check_on.png') :
                                        require('../../../assets/images/ic_ticket_all_check_off.png')}
                                style={{
                                    width: perfectSize(17),
                                    height: perfectSize(17),
                                    marginRight: perfectSize(8),
                                    resizeMode: 'contain'
                                }}
                            />
                            <Text style={globalStyle.regularText}>
                                {'전체선택'}
                            </Text>
                        </TouchableOpacity>
                        <View style={{ marginHorizontal: perfectSize(-15) }}>
                            {
                                data.tickets.map((ticket, index) => {
                                    return <TicketCard
                                        key={ticket._id}
                                        ticketLabel={true}
                                        selected={data.tickets[index].isSelected}
                                        onSelect={select => {
                                            data.tickets[index].isSelected = select;
                                            if (select) {
                                                setSelectedCount(selectedCount + 1);
                                            } else {
                                                setSelectedCount(selectedCount - 1);
                                            }
                                        }}
                                        data={ticket}
                                        status={ticket.isCancelled ? '취소완료' : '선물완료'}
                                        isConfirm={ticket.isCancelled || ticket.isGift}
                                        cancelledTime={moment(ticket.cancelledTime).format('YYYY.MM.DD HH:mm')}
                                    />
                                })
                            }
                        </View>
                    </View>

                    <View style={{ paddingHorizontal: perfectSize(15), backgroundColor: c_white }}>
                        <Footer marginTop={perfectSize(20)} marginBottom={perfectSize(40)} />
                    </View>
                </View>
            </ScrollView>
            <View style={globalStyle.oneBottomButton}>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={onCancelOrder}
                        isBorder={false}
                        textColor={c_white}
                        label={'예매취소하기'}
                        color={c_primary_red}
                        disable={selectedCount ? false : true}
                        loading={loading}
                    />
                </View>
            </View>
            <TicketCancelRuleModal
                isVisible={visibleCancelRuleModal}
                hideModal={() => setVisibleCancelRuleModal(false)}
                endlessDate={`${moment(d).format('YYYY.MM.DD HH:mm')} 까지`}
            />
            {/* <TicketDeliveryCancelModal
                isVisible={visibleDeliveryCancelRuleModal}
                hideModal={() => setVisibleDeliveryCancelRuleModal(false)}
            /> */}
            <CardModal
                isVisible={visibleCardModal}
                hideModal={() => {
                    setVisibleCardModal(false);
                    clearTimeout(timerRef.current);
                }}
                data={`https://admin.tickettong.net/cancel-pay?TID=${data.TID}&CancelAmt=${refundPrice}&PartialCancelCode=${data.total_price === refundPrice ? 0 : 1}`}
            />
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps)(TicketCancel);

const styles = {
    mainContainer: {
        flex: 1,
    },
    dotLine: {
        borderStyle: 'dashed',
        borderWidth: 1,
        marginBottom: perfectSize(15),
    },
    orderDetail: {
        ...globalStyle.flexRow,
        marginBottom: perfectSize(4)
    },
    orderConfirmCard: {
        marginBottom: perfectSize(20),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
        shadowColor: c_black,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        elevation: 2,
    },
    noticeIcon: {
        width: perfectSize(17),
        height: perfectSize(17),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
    countDown: {
        color: c_primary_red,
        fontSize: perfectSize(22),
        fontWeight: 'bold',
        marginBottom: perfectSize(10),
        marginTop: perfectSize(5)
    },
    warningMsg: {
        borderRadius: perfectSize(2),
        borderWidth: 1,
        borderColor: c_second_grey,
        paddingHorizontal: perfectSize(6),
        paddingVertical: perfectSize(2),
        marginLeft: perfectSize(10)
    },
    item: {
        backgroundColor: c_white,
        paddingHorizontal: perfectSize(15),
        marginBottom: perfectSize(8),
    },
    tableContainer: {
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderColor: c_third_grey
    },
    headerCell: {
        ...globalStyle.flex,
        alignItems: 'center',
        paddingVertical: perfectSize(15),
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderColor: c_third_grey,
        backgroundColor: c_second_white
    },
    cell: {
        ...globalStyle.flex,
        alignItems: 'center',
        height: perfectSize(50),
        justifyContent: 'center',
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderColor: c_third_grey,
        opacity: 0.6
    }
}