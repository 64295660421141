import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import {
    c_third_grey,
    c_white,
    c_primary_red,
    c_grey
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Entypo from '@expo/vector-icons/Entypo';
import { getNotice } from '../../store/actions';

function Notice({
    navigation,
    getNotice,
    notice
}) {

    useEffect(() => {
        navigation.setOptions({
            title: "공지사항",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
        getNotice();
    }, []);

    return (
        <ScrollView style={{ flex: 1, backgroundColor: c_white }}>
            <View style={styles.list}>
                {
                    notice &&
                    notice.map((item, index) => {
                        const date = moment(item.updatedAt).format('YYYY.MM.DD')
                        return (
                            <TouchableOpacity
                                style={[globalStyle.flexSpaceBetween, styles.item]} key={index}
                                onPress={() => navigation.navigate('NoticeDetail', { id: item._id })}>
                                <View>
                                    <Text
                                        numberOfLines={1}
                                        style={[globalStyle.regularText, { width: perfectSize(250) }]}>
                                        {item.name}
                                    </Text>
                                    <Text style={[globalStyle.smGreyText, { marginTop: perfectSize(5) }]}>
                                        {date}
                                    </Text>
                                </View>
                                <Entypo name="chevron-thin-right" size={perfectSize(20)} color={c_grey} />
                            </TouchableOpacity>
                        )
                    })
                }
            </View>
        </ScrollView>
    )
}

const mapStateTopProps = state => ({
    notice: state.app.notice
});

export default connect(mapStateTopProps, { getNotice })(Notice);

const styles = {
    mainContainer: {
        flex: 1,
        paddingBottom: perfectSize(40),
    },
    list: {
        borderTopWidth: perfectSize(1),
        borderColor: c_third_grey,
    },
    item: {
        borderBottomWidth: 1,
        borderBottomColor: c_third_grey,
        paddingVertical: perfectSize(22),
        paddingHorizontal: perfectSize(20)
    },
}