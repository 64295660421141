import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput,
    SafeAreaView
} from 'react-native';
import {
    c_grey,
    c_second_grey,
    c_light_grey,
    c_primary_blue,
    c_white,
    c_primary_red
} from '../../variables/colors';
import { certi } from '../../store/actions';
import { globalStyle, perfectSize } from '../../variables/styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Button from '../../components/Button';
import { oneAlert } from '../../utils';

function AddPhone({ navigation }) {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        navigation.setOptions({
            title: "전화번호입력",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    const onJoin = async () => {
        setLoading(true);
        const res = await certi(phoneNumber);
        if (res.message === 'INVALID_PHONE') {
            oneAlert('유효하지 않은 전화번호입니다.');
        } else if (res.message === 'REGISTERED_PHONE') {
            oneAlert('이미 회원가입 된 전화번호입니다.');
        } else {
            const userkey = res.value.userKey;
            navigation.navigate('Passcode', { userkey, phonenum: phoneNumber });
        }
        setLoading(false);
    }

    return (
        <View style={globalStyle.mainContainer}>
            <KeyboardAwareScrollView style={styles.mainContainer}>
                <View style={{ alignItems: 'center' }}>
                    <Text style={globalStyle.largeText}>
                        {'토마토 One-ID'}
                    </Text>
                    <Text style={[globalStyle.normalText, styles.marginV]}>
                        {'토마토 One-ID로 티켓통을 이용하세요!'}
                    </Text>
                </View>
                <View style={styles.phoneInputContainer}>
                    <View style={styles.countryCode}>
                        <Text style={globalStyle.normalText}>{'+82'}</Text>
                    </View>
                    <View style={styles.phoneInput}>
                        <TextInput
                            value={phoneNumber}
                            onChangeText={setPhoneNumber}
                            placeholder={'01012345678'}
                            keyboardType={'phone-pad'}
                            maxLength={11}
                            style={globalStyle.textInput}
                        />
                    </View>
                </View>

                <View style={{marginTop: perfectSize(130)}}>
                    <Text
                        style={[globalStyle.greyNormalText, {
                            marginBottom: perfectSize(30),
                            textAlign: 'center'
                        }]}>
                        {'인증한 휴대폰 번호는 아이디가 됩니다'}
                    </Text>
                    <Button
                        onPress={onJoin}
                        textColor={c_white}
                        label={'인증번호 발송'}
                        color={c_primary_red}
                        disable={(!phoneNumber) ? true : false}
                        loading={loading}
                    />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}

export default AddPhone;

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(60),
        paddingHorizontal: perfectSize(21)
    },
    marginV: {
        marginTop: perfectSize(18),
        marginBottom: perfectSize(30)
    },
    bottomView: {
        position: 'absolute',
        bottom: perfectSize(21),
        left: perfectSize(21),
        right: perfectSize(21)
    },
    phoneInputContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch'
    },
    countryCode: {
        width: perfectSize(72),
        height: perfectSize(48),
        borderWidth: 1,
        borderColor: c_second_grey,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: perfectSize(6)
    },
    phoneInput: {
        flex: 1,
    },
}