import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    Dimensions,
    Platform,
} from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import {
    c_primary_red,
    c_second_white,
    c_white,
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Separator from '../../components/Separator';
import Button from '../../components/Button';
import SeatMap from './tabs/SeatMap';
import Price from './tabs/Price';
import Checkout from './tabs/Checkout';
import { numberWithCommas } from '../../utils';
import { order } from '../../store/actions';

const { height } = Dimensions.get('window');

function Order({ navigation, route }) {

    const { performance, roundIndex } = route.params;

    const tabLabel = ['seat', 'price', 'checkout'];

    useEffect(() => {
        navigation.setOptions({
            title: performance.title.name,
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);



    const Tab = createMaterialTopTabNavigator();

    return (
        <View style={Platform.OS !== 'web' ? globalStyle.flex : { height: height }}>
            <Tab.Navigator tabBarOptions={{ style: { height: 0, zIndex: 0 } }} swipeEnabled={false}>
                {/* <Tab.Screen name="seat" component={SeatMap} initialParams={{ performance, roundIndex }} /> */}
                <Tab.Screen name="price" component={Price} initialParams={{ performance, roundIndex }} />
                <Tab.Screen name="checkout" component={Checkout} initialParams={{ performance, roundIndex }} />
            </Tab.Navigator>
        </View>
    )
}

const mapStateTopProps = state => ({
    selectedSeat: state.seat.selectedSeat,
    selectedNum: state.seat.selectedNum,
    totalPrice: state.seat.totalPrice,
    orderDetail: state.seat.orderDetail,
    user: state.auth.user
});

export default connect(mapStateTopProps, { order })(Order);

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
    },
    headerDate: {
        backgroundColor: '#555555',
        alignItems: 'center',
        paddingVertical: perfectSize(12),
        height: perfectSize(40),
        justifyContent: 'flex-start'
    },
    optionNextIcon: {
        width: perfectSize(4),
        height: perfectSize(8),
        resizeMode: 'contain',
        tintColor: c_white,
        marginHorizontal: perfectSize(20),
        transform: [{ rotate: '180deg' }]
    },
    button: {
        width: perfectSize(70),
        height: perfectSize(46),
        borderRadius: perfectSize(6),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#555555'
    },
    bottomButtons: {
        backgroundColor: c_white,
        justifyContent: 'flex-end'
    },
    price: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        color: c_primary_red,
        letterSpacing: -0.16,
        marginRight: perfectSize(10)
    },
}