import React, { useEffect } from 'react';
import {
    View,
    Text,
    Platform,
    Dimensions,
    TouchableOpacity,
    Image,
} from 'react-native';
import { globalStyle, perfectSize } from '../../../variables/styles';
import SeatMap from './seat_map';
import Level from './seat_map/level';
import moment from 'moment';
import { makeTwoString } from '../../../utils';

const { height } = Dimensions.get('window');

const Seatmap = ({ route, navigation }) => {
    const { performance, roundIndex } = route.params;
    const perDate = performance.schedule.detailed_time[roundIndex];

    useEffect(() => {
        navigation.setOptions({
            title: performance.title.name,
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    return (
        <View style={Platform.OS !== 'web' ? globalStyle.flex : { height: height }}>
            <View style={styles.headerDate}>
                <Text style={styles.date}>
                    {`${moment(perDate.date).format('YYYY.MM.DD')} ${makeTwoString(perDate.hour)}:${makeTwoString(perDate.minute)}`}
                </Text>
            </View>
            <SeatMap performance={performance} roundIndex={roundIndex} />
            <Level performance={performance} roundIndex={roundIndex} />
        </View>
    );
};

export default Seatmap;

const styles = {
    headerDate: {
        backgroundColor: '#555555',
        alignItems: 'center',
        paddingVertical: perfectSize(12),
        height: perfectSize(40),
        justifyContent: 'flex-start',
        zIndex: 999,
    },
    date: {
        color: 'white',
        fontSize: perfectSize(14),
        fontWeight: 'bold'
    }
}