import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions,
    Platform,
    FlatList,
} from 'react-native';
import moment from 'moment';
import { useIsFocused } from '@react-navigation/native';

import {
    c_black,
    c_grey,
    c_primary_red,
    c_second_grey,
    c_second_white,
    c_thrid_white,
    c_white
} from '../../variables/colors';
import { UPLOAD_URL } from '../../variables/constants';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import ModalDropdown from 'react-native-modal-dropdown';
import BorderButton from '../../components/BorderButton';
import TicketModal from '../../modals/TicketModal';
import { makeTwoString, numberWithCommas } from '../../utils';
import { orderListReset, orderList, orderLoadStart, orderPage } from '../../store/actions/order';
import Loader from '../../components/Loader';
import EmptyText from '../../components/EmptyText';

const { width, height } = Dimensions.get('window');

function TicketConfirm({ navigation, user }) {

    const { OrderReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    const isFocusedHistory = useIsFocused();

    const [range, setRange] = useState('전체');
    const [tab, setTab] = useState(true);

    const dropdownList = ['전체', '3개월', '6개월', '12개월'];

    useEffect(() => {
        navigation.setOptions({
            title: "예매확인/취소",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.navIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        const req = {
            range,
            page: 1
        };
        dispatch(orderLoadStart());
        dispatch(orderListReset());
        dispatch(orderList(req));
    }, [isFocusedHistory, range]);

    const getOrders = useCallback((req) => {
        dispatch(orderList(req));
    }, []);

    const renderOrderItem = ({ item, index }) => {
        const d = new Date(item.product_id.schedule.detailed_time[item.round].date);
        d.setDate(d.getDate() - 1);

        return (
            <View style={styles.orderConfirmCard}>
                <Image
                    source={{ uri: (UPLOAD_URL + item.product_id.poster_link) }}
                    style={{
                        width: '100%',
                        height: perfectSize(420),
                        resizeMode: 'stretch',
                        backgroundColor: c_second_white,
                        borderTopLeftRadius: perfectSize(6),
                        borderTopRightRadius: perfectSize(6)
                    }}
                />
                <View style={{ padding: perfectSize(15), paddingBottom: 0 }}>
                    <Text style={globalStyle.bigBoldText}>
                        {item.product_id.title.name}
                    </Text>
                    <Text style={[globalStyle.normalText, { marginVertical: perfectSize(14) }]}>
                        {`${item.product_id.location.name} I ${moment(item.product_id.schedule.detailed_time[item.round].date).format('YYYY.MM.DD')} ${item.product_id.schedule.detailed_time[item.round].hour}:${makeTwoString(item.product_id.schedule.detailed_time[item.round].minute)}`}
                    </Text>
                    <View style={styles.dotLine} />
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'예매번호: '}
                        </Text>
                        <Text style={globalStyle.regularBoldText}>
                            {/* {data.product_id.order_id.toUpperCase()} */}
                            {item.order_id}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'예매일시: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {item.tickets[0] && moment(item.tickets[0].createdAt).format('YYYY.MM.DD HH:mm')}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'매수: '}
                        </Text>
                        <Text style={[globalStyle.regularText, { color: c_primary_red }]}>
                            {item.tickets.length}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'취소마감: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {`${moment(d).format('YYYY.MM.DD')} 17:00 까지`}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'인증상태: '}
                        </Text>
                        <Text style={[globalStyle.regularText, { color: c_primary_red }]}>
                            {'인증완료'}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'예매상태: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {'예매성공'}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'수령방법: '}
                        </Text>
                        <Text style={[globalStyle.regularText, { color: c_primary_red }]}>
                            {item.delivery_method}
                        </Text>
                        <TouchableOpacity
                            onPress={() => { }}
                            style={styles.warningMsg}
                        >
                            <Text style={globalStyle.smText}>
                                {'주의사항 >'}
                            </Text>
                        </TouchableOpacity>
                    </View>

                    <View style={{ alignItems: 'center' }}>
                        <Image source={{ uri: `https://bwipjs-api.metafloor.com/?bcid=code128&text=${item.order_id}` }}
                            style={{ width: '90%', height: perfectSize(100), marginTop: perfectSize(20), marginBottom: perfectSize(10) }} />
                        <Text style={globalStyle.smText}>
                            {'현장수령 확인용 바코드'}
                        </Text>
                        {/* <View style={globalStyle.flexRow}>
                                <Text style={globalStyle.smText}>
                                    {'티켓인증까지 남은시간'}
                                </Text>
                                <Image
                                    source={require('../../../assets/images/ic_q.png')}
                                    style={{
                                        width: perfectSize(17),
                                        height: perfectSize(17),
                                        resizeMode: 'contain',
                                        marginLeft: perfectSize(4)
                                    }}
                                />
                            </View>
                            <Text style={styles.countDown}>
                                {'24:00:00'}
                            </Text>
                            <View style={globalStyle.flexRow}>
                                <Image
                                    source={require('../../../assets/images/ic_notice.png')}
                                    style={styles.noticeIcon}
                                />
                                <Text style={globalStyle.smRedText}>
                                    {'제한시간 내에 인증하지 않은 티켓은 자동으로 취소됩니다'}
                                </Text>
                            </View> */}
                    </View>

                    {/** Detail Button */}
                    <BorderButton
                        onPress={() => navigation.navigate('TicketDetail', { data: item })}
                        label={'상세보기'}
                    />
                </View>
            </View >
        )
    }

    const renderCancelItem = ({ item, index }) => {
        const d = new Date(item.product_id.schedule.detailed_time[item.round].date);
        d.setDate(d.getDate() - 1);

        return (
            <View style={styles.orderConfirmCard} key={index}>
                <Image
                    source={{ uri: (UPLOAD_URL + item.product_id.poster_link) }}
                    style={{
                        width: '100%',
                        height: perfectSize(420),
                        resizeMode: 'stretch',
                        backgroundColor: c_second_white,
                        borderTopLeftRadius: perfectSize(6),
                        borderTopRightRadius: perfectSize(6)
                    }}
                />
                <View style={{ padding: perfectSize(15), paddingBottom: 0 }}>
                    <Text style={globalStyle.bigBoldText}>
                        {item.product_id.title.name}
                    </Text>
                    <Text style={[globalStyle.normalText, { marginVertical: perfectSize(14) }]}>
                        {`${item.product_id.location.name} I ${moment(item.product_id.schedule.detailed_time[item.round].date).format('YYYY.MM.DD')} ${item.product_id.schedule.detailed_time[item.round].hour}:${makeTwoString(item.product_id.schedule.detailed_time[item.round].minute)}`}
                    </Text>
                    <View style={styles.dotLine} />
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'예매번호: '}
                        </Text>
                        <Text style={globalStyle.regularBoldText}>
                            {/* {data.product_id.order_id.toUpperCase()} */}
                            {item.order_id}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'예매일시: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {item.tickets[0] && moment(item.tickets[0].createdAt).format('YYYY.MM.DD HH:mm')}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'매수: '}
                        </Text>
                        <Text style={[globalStyle.regularText, { color: c_primary_red }]}>
                            {item.tickets.length}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'취소마감: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {`${moment(d).format('YYYY.MM.DD')} 17:00 까지`}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'인증상태: '}
                        </Text>
                        <Text style={[globalStyle.regularText, { color: c_primary_red }]}>
                            {'인증완료'}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'예매상태: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {'예매성공'}
                        </Text>
                    </View>
                    <View style={[styles.orderDetail, globalStyle.flexRow]}>
                        <Text style={globalStyle.regularText}>
                            {'수령방법: '}
                        </Text>
                        <Text style={[globalStyle.regularText, { color: c_primary_red }]}>
                            {item.delivery_method}
                        </Text>
                        <TouchableOpacity
                            onPress={() => { }}
                            style={styles.warningMsg}
                        >
                            <Text style={globalStyle.smText}>
                                {'주의사항 >'}
                            </Text>
                        </TouchableOpacity>
                    </View>

                    <View style={{ alignItems: 'center' }}>
                        {/* <View style={globalStyle.flexRow}>
                                            <BorderButton
                                                label={'인증완료'}
                                                color={c_light_grey}
                                                buttonStyle={{ height: perfectSize(34), paddingHorizontal: perfectSize(10) }}
                                                disable={true}
                                            />
                                            <View style={{ width: perfectSize(10) }} />
                                            {
                                                data.delivery_method === '모바일티켓' &&
                                                <BorderButton
                                                    onPress={() => {
                                                        setGiftData(data);
                                                        showGiftModal(true);
                                                    }}
                                                    label={'선물하기'}
                                                    buttonStyle={{ height: perfectSize(34), paddingHorizontal: perfectSize(10) }}
                                                />
                                            }
                                            <View style={{ width: perfectSize(10) }} />
                                            <BorderButton
                                                onPress={() => navigation.navigate('TicketCancel', { data: data })}
                                                label={'예매취소'}
                                                buttonStyle={{ height: perfectSize(34), paddingHorizontal: perfectSize(10) }}
                                            />
                                        </View> */}
                        {/* <View style={globalStyle.flexRow}>
                                <Text style={globalStyle.smText}>
                                    {'티켓인증까지 남은시간'}
                                </Text>
                                <Image
                                    source={require('../../../assets/images/ic_q.png')}
                                    style={{
                                        width: perfectSize(17),
                                        height: perfectSize(17),
                                        resizeMode: 'contain',
                                        marginLeft: perfectSize(4)
                                    }}
                                />
                            </View>
                            <Text style={styles.countDown}>
                                {'24:00:00'}
                            </Text>
                            <View style={globalStyle.flexRow}>
                                <Image
                                    source={require('../../../assets/images/ic_notice.png')}
                                    style={styles.noticeIcon}
                                />
                                <Text style={globalStyle.smRedText}>
                                    {'제한시간 내에 인증하지 않은 티켓은 자동으로 취소됩니다'}
                                </Text>
                            </View> */}
                    </View>

                    {/** Detail Button */}
                    <BorderButton
                        onPress={() => navigation.navigate('TicketDetail', { data: item })}
                        label={'상세보기'}
                    />
                </View>
            </View>
        )
    }

    const renderOrders = () => {
        if (OrderReducer.ordersLoading) return <Loader />;
        if (_.isEmpty(OrderReducer.availableOrders)) return <EmptyText label='예매된 공연이 없습니다.' />;


        return (
            <FlatList
                data={OrderReducer.availableOrders}
                onRefresh={() => {
                    const req = {
                        range,
                        page: 1
                    };
                    dispatch(orderLoadStart());
                    dispatch(orderListReset());
                    dispatch(orderList(req));
                }}
                refreshing={OrderReducer.ordersLoading}
                renderItem={renderOrderItem}
                onEndReached={() => {
                    const req = {
                        range,
                        page: OrderReducer.ordersPage + 1
                    };
                    getOrders(req);
                    dispatch(orderPage(OrderReducer.ordersPage + 1));
                }}
                onEndReachedThreshold={0.4}
                keyExtractor={(v, i) => "item_" + i}
            />
        )
    }

    const renderCanCelOrders = () => {
        if (OrderReducer.ordersLoading) return <Loader />;
        if (_.isEmpty(OrderReducer.cancelledOrders)) return <EmptyText label='취소된 공연이 없습니다.' />;

        return (
            <FlatList
                data={OrderReducer.cancelledOrders}
                onRefresh={() => {
                    const req = {
                        range,
                        page: 1
                    };
                    dispatch(orderLoadStart());
                    dispatch(orderListReset());
                    dispatch(orderList(req));
                }}
                refreshing={OrderReducer.ordersLoading}
                renderItem={renderCancelItem}
                onEndReached={() => {
                    const req = {
                        range,
                        page: OrderReducer.ordersPage + 1
                    };
                    getOrders(req);
                    dispatch(orderPage(OrderReducer.ordersPage + 1));
                }}
                onEndReachedThreshold={0.4}
                keyExtractor={(v, i) => "item_" + i}
            />
        )
    }

    return (
        <View style={styles.mainContainer}>
            <View style={[globalStyle.flexRow, { paddingHorizontal: perfectSize(17) }]}>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={() => setTab(true)}
                        isBorder={false}
                        textColor={tab ? c_white : c_thrid_white}
                        label={'예매확인'}
                        color={tab ? c_primary_red : c_second_white}
                    />
                </View>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={() => setTab(false)}
                        isBorder={false}
                        textColor={!tab ? c_white : c_thrid_white}
                        label={'취소내역'}
                        color={!tab ? c_primary_red : c_second_white}
                    />
                </View>
            </View>
            <View style={[
                globalStyle.flexSpaceBetween,
                {
                    backgroundColor: c_white,
                    paddingHorizontal: perfectSize(17),
                    paddingVertical: Platform.OS === 'web' ? perfectSize(15) : perfectSize(0)
                }
            ]}>
                <Text style={globalStyle.normalBoldText}>
                    {`${tab ? OrderReducer.totalAvailalbeOrders : OrderReducer.totalCancelledOrders}건`}
                </Text>

                <ModalDropdown
                    onSelect={index => setRange(index)}
                    options={dropdownList}
                    defaultIndex={0}
                    defaultValue={dropdownList[0]}
                    renderSeparator={() => <View />}
                    dropdownStyle={{
                        width: perfectSize(80),
                        height: perfectSize(40 * dropdownList.length),
                        marginTop: perfectSize(8)
                    }}
                    dropdownTextStyle={globalStyle.dropdownTextStyle}
                    dropdownTextHighlightStyle={{ color: c_primary_red }}
                    textStyle={globalStyle.regularText}
                    renderRightComponent={() =>
                        <Image
                            source={require('../../../assets/images/ic_list_order.png')}
                            style={globalStyle.dropdownRightImage} />
                    }
                    style={[globalStyle.dropdownStyle, { alignItems: 'flex-end' }]}
                />

            </View>
            <View style={globalStyle.flex}>
                {tab ? renderOrders() : renderCanCelOrders()}
            </View>
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateTopProps)(TicketConfirm);

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(15),
        backgroundColor: c_white
    },
    dotLine: {
        borderStyle: 'dashed',
        borderColor: c_grey,
        borderWidth: 1,
        marginBottom: perfectSize(15),
        marginHorizontal: perfectSize(1)
    },
    orderDetail: {
        marginBottom: perfectSize(4)
    },
    orderConfirmCard: {
        marginHorizontal: perfectSize(20),
        marginBottom: perfectSize(20),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
        shadowColor: c_black,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        elevation: 2,
    },
    noticeIcon: {
        width: perfectSize(17),
        height: perfectSize(17),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
    countDown: {
        color: c_primary_red,
        fontSize: perfectSize(22),
        fontWeight: 'bold',
        marginBottom: perfectSize(10),
        marginTop: perfectSize(5)
    },
    scrollView: {
        paddingHorizontal: perfectSize(17),
        paddingBottom: perfectSize(20)
    },
    warningMsg: {
        borderRadius: perfectSize(2),
        borderWidth: 1,
        borderColor: c_second_grey,
        paddingHorizontal: perfectSize(6),
        paddingVertical: perfectSize(2),
        marginLeft: perfectSize(10)
    }
}