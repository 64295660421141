import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    KeyboardAvoidingView,
    Platform,
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';
import Modal from 'react-native-modal';
import WebModal from 'modal-react-native-web';
import {
    c_third_grey,
    c_dark_white,
    c_primary_red,
    c_white
} from '../variables/colors';
import Button from '../components/Button';
import CustomTextInput from '../components/CustomTextInput';
import { addCoupon, couponWarnReset } from '../store/actions/coupon';

export default function CouponRegisterModal({
    isVisible,
    hideModal
}) {

    const { CouponReducer } = useSelector(state => state);
    const dispatch = useDispatch();

    const loading = CouponReducer.couponsAddLoading;
    const warningMessage = CouponReducer.couponsWarnMessage;
    const couponsAddSuccess = CouponReducer.couponsAddSuccess;

    const [code, setCode] = useState('');

    useEffect(() => {
        if (couponsAddSuccess) hideModal();
    }, [couponsAddSuccess]);

    const modalContent = () => {
        return (
            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : 'height'}
                style={styles.swipeModal}>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    <View style={[globalStyle.flexSpaceBetween, styles.headerContainer]}>
                        <Text style={styles.headerTitle}>
                            {'쿠폰등록'}
                        </Text>
                        <TouchableOpacity
                            onPress={hideModal}>
                            <Image
                                source={require('../../assets/images/ic_close.png')}
                                style={styles.closeIcon}
                            />
                        </TouchableOpacity>
                    </View>
                    <CustomTextInput
                        value={code}
                        onChangeText={text => {
                            dispatch(couponWarnReset());
                            setCode(text);
                        }}
                        numeric
                        warningText={warningMessage}
                        isWarn={warningMessage ? true : false}
                        placeholder={'쿠폰코드를 입력해주세요.'}
                        inputStyle={{
                            height: perfectSize(45),
                            marginBottom: perfectSize(6),
                            borderRadius: perfectSize(4),
                            borderColor: c_third_grey,
                        }}
                    />
                </View>
                <View style={globalStyle.oneBottomButton}>
                    <View style={globalStyle.flex}>
                        <Button
                            onPress={() => {
                                dispatch(addCoupon(code));
                            }}
                            textColor={c_white}
                            label={'쿠폰등록하기'}
                            color={c_primary_red}
                            isBorder={false}
                            loading={loading}
                            disable={!code}
                        />
                    </View>
                </View>
            </KeyboardAvoidingView>
        )
    }

    return (
        Platform.OS !== 'web' ?
            <Modal
                animationIn="slideInUp"
                animationOut="slideOutDown"
                style={{ margin: 0 }}
                isVisible={isVisible}
                swipeDirection={'up'}
                onSwipeComplete={hideModal}>
                {modalContent()}
            </Modal>
            :
            <WebModal
                animationIn="slideInUp"
                animationOut="slideOutDown"
                transparent={true}
                visible={isVisible}>
                <View style={{
                    flex: 1,
                    backgroundColor: '#00000099'
                }}>
                    {modalContent()}
                </View>
            </WebModal>
    )

}

const styles = {
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        alignItems: 'stretch',
        paddingHorizontal: perfectSize(20),
        paddingTop: 0,
        paddingBottom: perfectSize(20),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: perfectSize(16),
        borderTopLeftRadius: perfectSize(16),
    },
    headerContainer: {
        marginVertical: perfectSize(15),
        marginTop: perfectSize(25)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
    headerTitle: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        textAlign: 'center'
    },
    cancelRule: {
        height: perfectSize(334),
        paddingVertical: perfectSize(20),
        paddingHorizontal: perfectSize(15),
        borderWidth: 1,
        borderColor: c_third_grey,
        marginVertical: perfectSize(15)
    },
    deliveryAddress: {
        padding: perfectSize(15),
        borderRadius: perfectSize(6),
        backgroundColor: c_dark_white,
    },
}