import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
    ActivityIndicator,
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput,
    ScrollView
} from 'react-native';
import {
    c_white,
    c_primary_red,
    c_second_white
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import { useIsFocused } from '@react-navigation/native';
import { getTicketBook } from '../../store/actions';
import { UPLOAD_URL } from '../../variables/constants';
import { makeTwoString } from '../../utils';

function TicketBook({ navigation, user }) {

    const [ticketBook, setTicketBook] = useState([]);
    const [filteredTicketBook, setFilteredTicketBook] = useState(ticketBook);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const isFocusedHistory = useIsFocused();

    const days = ['일', '월', '화', '수', '목', '금', '토'];

    useEffect(() => {
        navigation.setOptions({
            title: "티켓북",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        getTickets();
    }, [isFocusedHistory]);

    const getTickets = async () => {
        setLoading(true);
        const res = await getTicketBook(user._id);
        if (res) {
            setTicketBook(res);
            setFilteredTicketBook(res);
        } else {
            setTicketBook([]);
        }
        setLoading(false);
    };

    const renderSearchResult = () => {
        if (loading) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator color={c_primary_red} />
                </View>
            )
        }
        if (_.isEmpty(filteredTicketBook)) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Text>{'티켓이 없습니다.'}</Text>
                </View>
            )
        }

        return (
            <KeyboardAwareScrollView style={styles.searchResultContainer}>
                {
                    filteredTicketBook.map(data => {
                        return (
                            <TouchableOpacity
                                onPress={() => navigation.navigate('TicketBookDetail', { data: data })}
                                style={styles.searchResult}
                                key={data._id}>
                                <Image
                                    source={{ uri: (UPLOAD_URL + data.product_id.poster_link) }}
                                    style={styles.categoryImage} />
                                <View style={{ flex: 1, paddingVertical: perfectSize(12), paddingRight: perfectSize(15) }}>
                                    <Text style={[globalStyle.normalBoldText, { marginBottom: perfectSize(12) }]}>
                                        {data.product_id.title.name}
                                    </Text>
                                    <View style={[globalStyle.flexRow, { marginBottom: perfectSize(14) }]}>
                                        <Text style={globalStyle.regularText}>
                                            {moment(data.product_id.schedule.detailed_time[data.round].date).format('YYYY.MM.DD')}
                                        </Text>
                                        <Text style={{ ...globalStyle.regularGreyText, marginLeft: perfectSize(10) }}>
                                            {`${days[new Date(data.product_id.schedule.detailed_time[data.round].date).getDay()]} ${data.product_id.schedule.detailed_time[data.round].hour}:${makeTwoString(data.product_id.schedule.detailed_time[data.round].minute)}`}
                                        </Text>
                                    </View>
                                    <Text style={[globalStyle.regularText, { marginBottom: perfectSize(5) }]}>
                                        {`${data.seat_grade}석 1매`}
                                    </Text>
                                    <Text style={globalStyle.regularGreyText}>
                                        {data.delivery_method}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        )
                    })
                }
            </KeyboardAwareScrollView>
        )
    }

    const onSearch = (text) => {
        const filteredData = _.filter(ticketBook, (o) => { return o.product.title.name.includes(text.trim()) });
        setFilteredTicketBook(filteredData);
    }

    return (
        <View style={styles.container}>

            <View style={styles.searchInput}>
                <TextInput
                    value={search}
                    onChangeText={text => {
                        onSearch(text);
                        setSearch(text);
                    }}
                    style={{ ...globalStyle.regularText, width: perfectSize(270) }}
                    placeholder={'검색어를 입력해주세요'} />
                <TouchableOpacity>
                    <Image
                        source={require('../../../assets/images/ic_search_b.png')}
                        style={globalStyle.searchIcon}
                    />
                </TouchableOpacity>
            </View>

            {/** search result list */}
            {renderSearchResult()}

        </View >
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateTopProps)(TicketBook);

const styles = {
    container: {
        flex: 1,
        backgroundColor: c_white,
        paddingVertical: perfectSize(15)
    },
    searchInput: {
        height: perfectSize(40),
        borderColor: c_primary_red,
        borderWidth: 1,
        borderRadius: perfectSize(6),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: perfectSize(15),
        marginHorizontal: perfectSize(15)
    },
    categoryImage: {
        width: perfectSize(100),
        height: perfectSize(140),
        marginRight: perfectSize(15),
        borderRadius: perfectSize(4),
        resizeMode: 'stretch',
        backgroundColor: c_second_white
    },
    searchResultContainer: {
        flex: 1,
        marginTop: perfectSize(20),
        margin: perfectSize(-15),
        paddingHorizontal: perfectSize(15),
        paddingTop: perfectSize(5)
    },
    searchResult: {
        ...globalStyle.shadow,
        flexDirection: 'row',
        marginBottom: perfectSize(20),
        borderRadius: perfectSize(6),
        backgroundColor: c_white,
        marginHorizontal: perfectSize(15)
    },
}