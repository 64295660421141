import {
    COUPON_LOAD_START,
    COUPON_LOAD_SUCCESS,
    COUPON_LOAD_FAIL,
    COUPON_LIST_RESET,
    COUPON_LIST_UPDATE,
    COUPON_ADD_START,
    COUPON_ADD_FAIL,
    COUPON_ADD_SUCCESS,
    COUPON_WARN_RESET
} from '../../variables/constants';

const initialState = {
    coupons: [],
    couponsLoading: false,
    couponsAddLoading: false,
    couponsWarnMessage: '',
    couponsAddSuccess: false
}

export default function CouponReducer(state = initialState, action) {
    switch (action.type) {

        case COUPON_LOAD_START:
            return state = { ...state, couponsLoading: true };

        case COUPON_LOAD_SUCCESS:
            return state = { ...state, coupons: action.payload, couponsLoading: false };

        case COUPON_LIST_RESET:
            return state = { ...state, coupons: [] };

        case COUPON_LOAD_FAIL:
            return state = { ...state, couponsLoading: false };

        case COUPON_ADD_START:
            return state = { ...state, couponsAddLoading: true, couponsWarnMessage: '', couponsAddSuccess: false };

        case COUPON_ADD_SUCCESS:
            return state = { ...state, coupons: [action.payload, ...state.coupons], couponsAddLoading: false, couponsAddSuccess: true };

        case COUPON_ADD_FAIL:
            return state = { ...state, couponsAddLoading: false, couponsWarnMessage: action.payload };

        case COUPON_WARN_RESET:
            return state = { ...state, couponsWarnMessage: '' };

        default:
            return state;
    }
}