import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { UPLOAD_URL } from "../../../variables/constants";
import { AudioItem } from "../audio/AudioItem";
import { VideoItem } from "../video";
import { useNavigation } from "@react-navigation/native";
import { getNFTs } from "../../../store/actions/performance";
import LoadMoreButton from "../../../components/LoadMoreButton";

export const NFTList = props => {
  const { hiddenPlus, smallTitle, showLoadMore, performanceId } =
    props;
  const {} = useSelector(state => state);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  const navigation = useNavigation();

  useEffect(() => {
    getData(1);
  }, []);

  const getData = page => {
    getNFTs({
      page,
      limit: smallTitle ? 5 : 3,
      isForSale: true,
      sortType: 0, // 최신: 0, 가격순(낮): 1 가격순(높): 2
      performanceId,
    })
      .then(res => {
        if (res.data.nfts) {
          setTotal(res.data.total);
          setData([...data, ...res.data.nfts]);
        }
      })
      .catch(err => console.error(err));
  };

  if (_.isEmpty(data)) return null;

  const onLoadMore = () => {
    getData(page + 1);
    setPage(page + 1);
  };

  return (
    <View style={styles.videoListWrap}>
      <View
        style={[
          globalStyle.flexSpaceBetween,
          { marginVertical: perfectSize(10) },
        ]}
      >
        <Text style={smallTitle ? styles.nftText : globalStyle.bigBoldText}>
          {"NFT"}
        </Text>
        {!hiddenPlus && (
          <TouchableOpacity onPress={() => navigation.navigate("NFTDetail")}>
            <Image
              source={require("../../../../assets/images/ic_more.png")}
              style={styles.moreIcon}
            />
          </TouchableOpacity>
        )}
      </View>
      {/*{data.map(item => {*/}
      {/*  return (*/}
      {/*    <View key={item._id} style={{ marginBottom: perfectSize(10) }}>*/}
      {/*      <TouchableOpacity*/}
      {/*        onPress={() => {*/}
      {/*          navigation.navigate("NFTMolDetail", { id: item._id });*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        {item.asset?.asset_type === ("flac" || "dsd") ? (*/}
      {/*          <AudioItem disable audio={item} type={item.asset?.asset_type} />*/}
      {/*        ) : (*/}
      {/*          <VideoItem disable video={item} type={item.asset?.asset_type} />*/}
      {/*        )}*/}
      {/*      </TouchableOpacity>*/}
      {/*    </View>*/}
      {/*  );*/}
      {/*})}*/}
      {/*{showLoadMore && total !== data.length && (*/}
      {/*  <LoadMoreButton onPress={onLoadMore} />*/}
      {/*)}*/}
    </View>
  );
};

const styles = StyleSheet.create({
  videoListWrap: {
    marginBottom: perfectSize(30),
  },
  moreIcon: {
    width: perfectSize(14),
    height: perfectSize(14),
    resizeMode: "contain",
  },
  nftText: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(16),
    color: "#222222",
    fontWeight: "bold",
  },
});
