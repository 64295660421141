import React, { useState } from "react";
import { StyleSheet, View, Image, Text, TouchableOpacity } from "react-native";
import { c_third_grey } from "../../../variables/colors";
import { globalStyle, perfectSize } from "../../../variables/styles";
import UrlPreview from "../../../components/UrlPreview";
import MediaModal from "../../../modals/MediaModal";
import { useNavigation } from "@react-navigation/native";
import { UPLOAD_URL } from "../../../variables/constants";

export const VideoItem = props => {
  const { video, type, disable } = props;
  const navigation = useNavigation();
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const playVideo = () => {
    // if (type === "video") {
    //   setIsVisibleModal(true);
    // } else {
    navigation.navigate("VideoPlayer", { video: video, type: type });
    // }
  };

  return (
    <View>
      {/* <UrlPreview
        key={video._id}
        text={video.link}
        containerStyle={styles.container}
        imageProps={styles.thumbnail}
        onPress={() => navigation.navigate('VideoPlayer')}
        titleStyle={styles.title}
      /> */}
      <TouchableOpacity
        disabled={disable}
        onPress={playVideo}
        style={globalStyle.flexRow}
      >
        <Image
          source={{ uri: UPLOAD_URL + video.content?.thumbnail }}
          style={styles.thumbnail}
        />
        <Text numberOfLines={2} style={styles.title}>
          {video.content?.title}
        </Text>
      </TouchableOpacity>
      <MediaModal
        isVisible={isVisibleModal}
        hideModal={() => setIsVisibleModal(false)}
        data={
          video.link_type === "upload" ? UPLOAD_URL + video.link : video.link
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  thumbnail: {
    width: perfectSize(110),
    height: perfectSize(70),
    marginRight: perfectSize(15),
    backgroundColor: c_third_grey,
    borderRadius: perfectSize(4),
  },
  title: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(19.6),
    letterSpacing: -0.2,
    flex: 1,
  },
});
