import {
  APPICON_LOAD_SUCCESS,
  APPICON_LIST_RESET,
} from '../../variables/constants';

const initialState = {
  appIcons: [],
}

export default function AppIconReducer(state = initialState, action) {
  switch (action.type) {

      case APPICON_LOAD_SUCCESS:
        return state = { ...state, appIcons: action.payload };

      case APPICON_LIST_RESET:
        return state = { ...state, appIcons: [] };

      default:
        return state;
  }
}