import _ from "lodash";
import {instance, oneAlert} from "../../utils";
import {
  PERFORMANCE_LOAD_START,
  PERFORMANCE_LOAD_SUCCESS,
  PERFORMANCE_LOAD_FAIL,
  PERFORMANCE_LIST_RESET,
} from "../../variables/constants";
import axios from "axios";
import {alarmUnreadList} from "./alarm";

export const performanceLoadSuccess = data => ({
  type: PERFORMANCE_LOAD_SUCCESS,
  payload: data,
});

export const performanceLoadStart = () => ({
  type: PERFORMANCE_LOAD_START,
});

export const performanceLoadFail = () => ({
  type: PERFORMANCE_LOAD_FAIL,
});

export const performanceListReset = () => ({
  type: PERFORMANCE_LIST_RESET,
});

export const performanceList = req => {

  return (dispatch, getState) => {
    dispatch(performanceLoadStart());

    const { sortType, selectedIndex, searchWord, page } = req;
    instance
        .get(
            `api/performance/list?page=${page}&per_page=11115&sort_type=${sortType}&filtered=${selectedIndex}&search_word=${searchWord}`
        )
        .then(res => {
          dispatch(performanceLoadSuccess(res.data));
        })
        .catch(err => {
          console.log(err);
          oneAlert(err.response);
          dispatch(performanceLoadFail());
        });
  };
};

export const performanceContentList = req => {
  return (dispatch, getState) => {
    dispatch(performanceLoadStart());

    const { contentType } = req;

    instance
        .get(
            `api/performance/main?content_type=${contentType}`
        )
        .then(res => {
          dispatch(performanceLoadSuccess(res.data));
        })
        .catch(err => {
          console.log(err);
          oneAlert(err.response);
          dispatch(performanceLoadFail());
        });
  };
};
export const performanceContentList2 =  req  => {
  return instance
      .get(
          `api/performance/main?content_type=${req}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
};

export const getAsset = (
    type,
    page,
    limit,
    enumType,
    performanceId,
    searchWord
) => {
  const req_body = {
    type,
    page,
    per_page: limit || 10,
  };
  if (enumType !== "전체") req_body.category = enumType;
  if (performanceId) req_body.performance = performanceId;
  if (searchWord) req_body.search_word = searchWord;
  return instance.get(`api/asset`, { params: req_body });
};

export const getNFTs = ({
                          page,
                          limit,
                          performanceId,
                          searchWord,
                          type,
                          sortType,
                          isForSale,
                          owner,
                        }) => {
  const req_body = {
    page,
    per_page: limit || 10,
    sortType: 0,
  };
  if (performanceId) req_body.performance = performanceId;
  if (searchWord) req_body.search_word = searchWord;
  if (type) req_body.type = type;
  if (sortType) req_body.sortType = sortType;
  if (isForSale) req_body.isForSale = isForSale;
  if (owner) req_body.owner = owner;
  return instance.get(`api/nft`, { params: req_body });
};

export const getContents = performanceId => {
  return instance.get(`api/content?performance=${performanceId}`);
};

export const getContentsDetail = performanceId => {
  return instance.get(`api/content/${performanceId}`);
};

export const sendSMS = ( userid, receiver, msg ) => {
    return instance
        .post("/api/user/mania/recommend", {
            userid: userid,
            receiver: receiver,
            msg: msg
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const setContentStatus = ( userid, content, price, moid) => {
    return instance
        .post("/api/contentpay", {
            userid: userid,
            content: content,
            point: price,
            moid: moid
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getContentStatus = ( userid, performance, content) => {
    return instance
        .get(`/api/user/check_buy/${userid}?performance=${performance}&content=${content}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getBuyContents = ( userid) => {
    return instance
        .get(`/api/contentpay/listByUser/${userid}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

