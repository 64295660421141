import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions
} from 'react-native';
import Modal from 'react-native-modal';
import moment from 'moment';

import { globalStyle, perfectSize } from '../variables/styles';
import {
    c_light_grey,
    c_primary_red,
} from '../variables/colors';
import { numberWithCommas, oneAlert } from '../utils';

const { height } = Dimensions.get('window');

export default function InvitationModal({
    isVisible,
    hideModal,
    data,
    onSelectInvitation,
    performanceId,
    countTicket,
    totalPrice,
    roundIndex,
    selectedDiscount
}) {

    return (
        <Modal
            animationIn="slideInUp"
            animationOut="slideOutDown"
            style={{ margin: 0 }}
            isVisible={isVisible}>
            <View style={styles.swipeModal}>
                <TouchableOpacity
                    onPress={hideModal}
                    style={globalStyle.flex}>
                </TouchableOpacity>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    <View style={[globalStyle.flexSpaceBetween, styles.headerContainer]}>
                        <Text style={[globalStyle.bigBoldText, globalStyle.flex]}>
                            {'초대권'}
                        </Text>
                        {/** close */}
                        <TouchableOpacity
                            onPress={hideModal}>
                            <Image
                                source={require('../../assets/images/ic_close.png')}
                                style={styles.closeIcon}
                            />
                        </TouchableOpacity>
                    </View>

                    <ScrollView>
                        <TouchableOpacity
                            onPress={() => {
                                hideModal(false);
                                onSelectInvitation(null, null);
                            }}
                            style={[styles.cardSection,
                            {
                                paddingVertical: perfectSize(15),
                                backgroundColor: '#f9f9f9'
                            }]}>
                            <Text style={globalStyle.regularText}>
                                {'사용안함'}
                            </Text>
                        </TouchableOpacity>
                        {
                            data.map((item, index) => {
                                if (item.isSelected) return;
                                const {
                                    name,
                                    discount_type,
                                    discount_amount,
                                    seat_grade,
                                    seat,
                                    from,
                                    to,
                                    round,
                                    round_num,
                                    product_id
                                } = item;

                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (!countTicket) {
                                                oneAlert(`선택된 티켓이 없습니다.`);
                                                return;
                                            }
                                            if (!totalPrice) {
                                                oneAlert(`초대권을 더 이상 사용하실수 없습니다.`);
                                                return;
                                            }
                                            if (performanceId !== product_id._id) {
                                                oneAlert('선택한 초대권은 이 공연에서 사용할수 없습니다.');
                                                return;
                                            }
                                            if (round !== 'all' && round_num !== roundIndex) {
                                                oneAlert(`${roundIndex}회차 공연에서 적용가능한 초대권입니다.`);
                                                return;
                                            }
                                            if (seat === 'specific') {
                                                const seatTypes = Object.keys(selectedDiscount);
                                                const findIndex = seatTypes.indexOf(seat_grade);
                                                if (findIndex === -1) {
                                                    oneAlert(`선택한 초대권은 ${seat_grade}석 티켓에만 적용하실수 있습니다.`);
                                                    return;
                                                }
                                            }
                                            hideModal(false);
                                            onSelectInvitation(item, index);
                                        }}
                                        style={styles.cardSection}
                                        key={index}>
                                        <View style={globalStyle.flexRow}>
                                            <Text style={styles.price}>
                                                {seat !== 'all' ? seat_grade + '석' : '전체좌석 '}
                                            </Text>
                                            <Text style={globalStyle.smText}>
                                                {seat !== 'all' ? '전용 초대권' : '초대권'}
                                            </Text>
                                        </View>
                                        <Text style={[globalStyle.smGreyText, { marginTop: perfectSize(8) }]}>
                                            {`유효기간 : ${moment(from).format('YYYY.MM.DD')} ~ ${moment(to).format('YYYY.MM.DD')}`}
                                        </Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </ScrollView>
                </View>
            </View>
        </Modal>
    )
}

const styles = {
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        alignItems: 'stretch',
        paddingHorizontal: perfectSize(20),
        paddingTop: 0,
        paddingBottom: perfectSize(20),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: perfectSize(16),
        borderTopLeftRadius: perfectSize(16),
        maxHeight: height / 4 * 3
    },
    headerContainer: {
        marginVertical: perfectSize(15),
        marginTop: perfectSize(25)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
    cardSection: {
        borderWidth: 1,
        borderRadius: perfectSize(4),
        borderColor: c_light_grey,
        padding: perfectSize(15),
        paddingVertical: perfectSize(12),
        marginBottom: perfectSize(5)
    },
    price: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        color: c_primary_red,
        letterSpacing: -0.16,
        marginRight: perfectSize(10)
    }
}