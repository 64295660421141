import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import moment from 'moment';

import {
    c_white,
    c_primary_red,
    c_second_white,
    c_grey
} from '../../variables/colors';
import { UPLOAD_URL } from '../../variables/constants';
import { globalStyle, perfectSize } from '../../variables/styles';
import { performanceLike } from '../../store/actions';

const favorite = ({ item, user, navigation }) => {

    const [like, setLike] = useState(true);

    return (
        <TouchableOpacity
            onPress={() => navigation.navigate('Detail', { id: item._id })}
            style={styles.searchResult}>
            <Image
                source={{ uri: UPLOAD_URL + item.poster_link }}
                style={styles.categoryImage} />
            <View style={{ flex: 1, paddingTop: perfectSize(5), paddingRight: perfectSize(15) }}>
                <Text style={[globalStyle.normalBoldText, { marginBottom: perfectSize(15) }]}>
                    {item.title.name}
                </Text>
                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(8) }]}>
                    {item.location.name}
                </Text>
                <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(20) }]}>
                    {`${moment(item.schedule.from).format('YYYY.MM.DD')} ~ ${moment(item.schedule.to).format('YYYY.MM.DD')}`}
                </Text>
                <View style={globalStyle.flexRow}>
                    <View style={[styles.badge, !item.isOnsale || new Date(item.schedule.to) < new Date() && { borderColor: c_grey }]}>
                        <Text style={[styles.badgeText, !item.isOnsale || new Date(item.schedule.to) < new Date() && { color: c_grey }]}>
                            {
                                item.isOnsale ?
                                    new Date(item.schedule.to) > new Date() ? '판매중' : '판매종료'
                                    : '판매중단'
                            }
                        </Text>
                    </View>
                    <TouchableOpacity
                        onPress={() => {
                            performanceLike(user._id, item._id);
                            setLike(!like);
                        }}>
                        <Image
                            source={
                                like ? require('../../../assets/images/book_ic_like_on.png')
                                    : require('../../../assets/images/book_ic_like_off.png')
                            }
                            style={styles.likeIcon}
                        />
                    </TouchableOpacity>
                </View>
            </View>
        </TouchableOpacity>
    );
}

export default favorite;

const styles = {
    container: {
        flex: 1,
        backgroundColor: c_white,
    },
    categoryImage: {
        width: perfectSize(100),
        height: perfectSize(140),
        marginRight: perfectSize(10),
        borderRadius: perfectSize(4),
        resizeMode: 'stretch',
        backgroundColor: c_second_white
    },
    searchResult: {
        ...globalStyle.shadow,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: perfectSize(20),
        borderRadius: perfectSize(6),
        backgroundColor: c_white,
        marginHorizontal: perfectSize(15)
    },
    badge: {
        borderColor: c_primary_red,
        borderWidth: 1,
        borderRadius: perfectSize(3),
        padding: perfectSize(3),
        alignSelf: 'flex-start'
    },
    badgeText: {
        fontSize: perfectSize(11),
        color: c_primary_red
    },
    likeIcon: {
        width: perfectSize(23),
        height: perfectSize(19),
        resizeMode: 'contain',
        marginLeft: perfectSize(15)
    },
}