import React from "react";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { globalStyle, perfectSize } from "../../variables/styles";

const Header = props => {
  const { navigation } = props;
  const { app } = useSelector(state => state);

  return (
    <View style={globalStyle.header}>
      <TouchableOpacity
        style={{ padding: perfectSize(15) }}
        onPress={() => navigation.openDrawer()}
      >
        <Image
          source={require("../../../assets/images/ic_menu.png")}
          style={globalStyle.headerIcon}
        />
      </TouchableOpacity>
      <Image
        source={require("../../../assets/images/logo.png")}
        style={styles.headerLogo}
      />
      <View style={globalStyle.flexRow}>
        <TouchableOpacity
            onPress={() => navigation.navigate("VideoPlayer", { isLive: true })}
          style={styles.tvButton}
        >
          <Text style={styles.tvText}>TV</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ padding: perfectSize(15) }}
          onPress={() => navigation.navigate("Search", { sword: app.sword })}
        >
          <Image
            source={require("../../../assets/images/ic_search.png")}
            style={globalStyle.headerIcon}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  tvButton: {
    width: perfectSize(31),
    height: perfectSize(26),
    borderRadius: perfectSize(10),
    borderWidth: 2,
    borderColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  tvText: {
    fontSize: perfectSize(14),
    fontWeight: "bold",
    color: "white",
  },
  headerLogo: {
    width: perfectSize(100),
    height: perfectSize(42),
    marginLeft: perfectSize(35),
  },
});
