import _ from 'lodash';
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    Dimensions,
    ToastAndroid
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';

import {
    c_second_white,
    c_white,
    c_primary_black,
    c_light_grey
} from '../variables/colors';
import { globalStyle, perfectSize } from '../variables/styles';
import Separator from './Separator';
import Badge from './Badge';
import { likeReview, disLikeReview, reportReview } from '../store/actions';
import { oneAlert, twoAlert } from '../utils';

function Review({
    data,
    user,
    onDelete,
    type
}) {

    const [isMoreNotice, showMoreNotice] = useState(false);
    const [likes, setLikes] = useState(data.likes);
    const [report, setReport] = useState();
    const [nLine, setNLine] = useState();
    const navigation = useNavigation();

    const renderStars = () => {
        let stars = [];
        for (let i = 0; i < data.star; i++)
            stars.push(
                <Image
                    key={i}
                    source={require('../../assets/images/ic_star.png')}
                    style={styles.star}
                />
            )
        return stars;
    }

    if (!data) return null;

    const onReport = async () => {
        if (!user) {
            navigation.navigate('SignIn', {screenName: 'Home'});
            return;
        }

        if (data.user_id._id === user._id) {
            oneAlert(`본인의 ${!type ? '리뷰를' : '기대평을'} 신고하실수 없습니다.`);
            return;
        }
        twoAlert(`${!type ? '리뷰를' : '기대평을'} 신고하시겠습니까?`, async () => {
            const res = await reportReview(data._id, user._id);
            if (res) {
                oneAlert('신고가 접수되었습니다.');
            } else {
                oneAlert(`이미 신고한 ${!type ? '리뷰' : '기대평'}입니다.`);
            }
        })
    }

    const onLike = async () => {
        if (!user) {
            navigation.navigate('SignIn', {screenName: 'Home'});
            return;
        }
        if (likes.includes(user._id)) {
            const res = await disLikeReview(data._id, user._id);
            if (res) {
                const index = likes.indexOf(user._id);
                likes.splice(index, 1);
                setLikes([...likes]);
            }
        } else {
            const res = await likeReview(data._id, user._id);
            if (res) {
                likes.push(user._id);
                setLikes([...likes]);
            }
        }
    }

    const onTextLayout = useCallback(
        (e) => {
            setNLine(e.nativeEvent.lines.length);
        },
        [])

    return (
        <View style={{ alignSelf: 'stretch' }} key={data._id}>
            <Separator
                height={perfectSize(1)}
                color={c_second_white}
                marginTop={perfectSize(10)}
                marginBottom={perfectSize(22)}
            />

            {/** ID */}
            <View style={globalStyle.flexSpaceBetween}>
                <View style={globalStyle.flexRow}>
                    <Text style={globalStyle.regularText}>
                        {data.user_id && data.user_id.nickname}
                    </Text>
                    <Badge
                        number={'예매자'}
                        style={styles.badge}
                        labelStyle={{
                            fontSize: perfectSize(11),
                            color: c_primary_black,
                        }}
                    />
                </View>
                <TouchableOpacity onPress={() => {
                    twoAlert(`${!type ? '리뷰를' : '기대평을'} 삭제하시겠습니까?`, () => onDelete());
                }}>
                    <Text style={globalStyle.regularText}>
                        {(user && data.user_id && user._id === data.user_id._id) && '삭제'}
                    </Text>
                </TouchableOpacity>
            </View>

            {/** Date and Rank */}
            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: perfectSize(8), marginBottom: perfectSize(15) }}>
                <Text style={[globalStyle.smGreyText, { marginRight: perfectSize(5) }]}>
                    {moment(data.updatedAt).format('YYYY.MM.DD')}
                </Text>
                {renderStars()}
            </View>

            {/** Comment */}
            <Text
                onTextLayout={onTextLayout}
                style={[globalStyle.regularText, { maxHeight: isMoreNotice ? null : 'wrap' }]}>
                {data.content}
            </Text>

            {/** Load More Btn */}
            {
                nLine > 2 &&
                <TouchableOpacity
                    onPress={() => showMoreNotice(!isMoreNotice)}
                    style={styles.loadMoreBtn}>
                    <Text style={globalStyle.regularGreyText}>
                        {'더보기'}
                    </Text>
                    <Image
                        source={require('../../assets/images/ic_more_detail.png')}
                        style={[styles.loadMoreBtnIcon, { transform: [{ rotate: !isMoreNotice ? '0deg' : '180deg' }] }]}
                    />
                </TouchableOpacity>
            }

            {/** Report */}
            <View style={[globalStyle.flexSpaceBetween, { marginTop: nLine < 3 ? perfectSize(10) : null }]}>
                <TouchableOpacity
                    onPress={onReport}
                    style={globalStyle.flexRow}>
                    <Image
                        source={require('../../assets/images/ic_report.png')}
                        style={styles.reportIcon}
                    />
                    <Text style={globalStyle.regularGreyText}>{'신고'}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={onLike}
                    style={globalStyle.flexRow}>
                    {
                        <Image
                            source={
                                user && likes.includes(user._id) ?
                                    require('../../assets/images/ic_review_like_on.png') :
                                    require('../../assets/images/ic_review_like.png')}
                            style={styles.reportIcon}
                        />
                    }
                    <Text style={globalStyle.regularText}>{likes ? likes.length : 0}</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps)(Review);

const styles = {
    star: {
        width: perfectSize(12),
        height: perfectSize(12),
        resizeMode: 'contain'
    },
    badge: {
        backgroundColor: c_white,
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(3),
        width: perfectSize(44),
        height: perfectSize(21),
        marginLeft: perfectSize(8),
    },
    loadMoreBtn: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        height: perfectSize(40)
    },
    loadMoreBtnIcon: {
        width: perfectSize(12),
        height: perfectSize(7),
        resizeMode: 'contain',
        marginLeft: perfectSize(8),
    },
    reportIcon: {
        width: perfectSize(13),
        height: perfectSize(13),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
}