import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    SafeAreaView,
    Linking
} from 'react-native';
import {
    c_white,
    c_primary_blue,
    c_primary_red, c_fourth_grey
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import AntDesign from '@expo/vector-icons/AntDesign';
import { ScrollView } from 'react-native-gesture-handler';
import { oneAlert } from '../../utils';
import {join_ticketTong, login, join} from "../../store/actions";
import OneAlertModal from "../../modals/OneAlertModal";
import {connect} from "react-redux";

function Agree({ navigation, route, login, join }) {

    const [terms, setTerms] = useState(false);
    const [countChecked, setCountChecked] = useState(0);
    const [term1, setTerm1] = useState(false);
    const [term2, setTerm2] = useState(false);
    const [term3, setTerm3] = useState(false);
    const {user_status, phonenum, passwd, countryCode, screenName, userkey} = route.params;

    const [visibleAlertModal, setVisibleAlertModal] = useState(false);
    const [isAlertText, setAlertText] = useState('');

    useEffect(() => {
        navigation.setOptions({
            title: "토마토 One-ID 약관동의",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    useEffect(() => {
        setTerms(countChecked === 3);
    }, [countChecked]);
    const showAlertWithText = (text) => {
        setVisibleAlertModal(true);
        setAlertText(text);
    };

    const onLogin = async () => {
        const res = await login({ phonenum, passwd, countryCode });
        console.log('login', res);
        if (res.status === 0) {
            if (res.code === 1015) {
                showAlertWithText('비밀번호가 일치하지 않습니다')
            }  else if (res.code === 1058) {
                showAlertWithText(<Text>
                    회원가입이 정상적이지 않습니다.
                    {"\n\n"}
                    {"   "}
                    <Text style={{ color: c_fourth_grey, fontSize: perfectSize(14), letterSpacing: -0.14}}>
                        문의: 02-2128-3887</Text>
                </Text>);
                return
            }
        } else if (res.data) {
            navigation.navigate("Home");
            // Toast.show('로그인이 완료되었습니다.', Toast.SHORT);
        } else {
            showAlertWithText('로그인시 오류가 발생하였습니다.')
        }
    };
    const onJoinMember = async () => {
        if(user_status === 'login') {
            await join_ticketTong(user_status, phonenum, passwd, countryCode).then(async res => {
                if (res.status === 0) {
                    showAlertWithText(res.message)
                } else if (res.status === 1) {
                    if (user_status === 'login') {
                        await onLogin();
                        // navigation.navigate('JoinTicketTong');
                    } else {
                        // await onAutoLogin();
                        // navigation.navigate('auto_login');
                        // Toast.show('회원가입이 완료되었습니다.', Toast.SHORT);
                    }
                } else {
                    // Toast.show(res.message, Toast.SHORT);
                }
            })
        }else{
            const res = await join({ userkey, passwd: passwd, name: phonenum });
            if(!res){
                await onLogin();
            }
        }
    }
    return (
        <View style={globalStyle.mainContainer}>
            <SafeAreaView style={globalStyle.flex}>
                <View style={[globalStyle.flex, { alignItems: 'center' }]}>
                    <ScrollView showsHorizontalScrollIndicator={false}
                                showsVerticalScrollIndicator={false}>
                        <View style={{ alignItems: 'center',
                            paddingTop: perfectSize(30),
                            paddingEnd: perfectSize(15),
                            paddingStart: perfectSize(15)}}>
                            <Text style={globalStyle.normalText}>
                                {'뉴스통, 증권통, 통통 등 토마토그룹의 다양한 서비스'}
                            </Text>

                            <Text style={[globalStyle.largeText, styles.marginV, { textAlign: 'center' }]}>
                                {'이제 토마토 One-ID 계정하나로\n간편하게 이용할수 있어요!'}
                            </Text>

                            <Image
                                source={require('../../../assets/images/img_one.png')}
                                style={{
                                    width: perfectSize(270),
                                    height: perfectSize(264),
                                    resizeMode: 'contain',
                                    marginBottom: perfectSize(30)
                                }}
                            />

                            <TouchableOpacity
                                style={[globalStyle.flexRow, { alignSelf: 'stretch', marginBottom: perfectSize(20) }]}
                                onPress={() => {
                                    setTerms(!terms);
                                    setTerm1(!term1);
                                    setTerm2(!term2);
                                    setTerm3(!term3);
                                    setCountChecked(terms ? 0 : 3)
                                }}>
                                <Image
                                    source={
                                        terms ? require('../../../assets/images/check_all_on.png')
                                            : require('../../../assets/images/check_all_off.png')
                                    }
                                    style={styles.checkbox}
                                />
                                <Text style={globalStyle.normalText}>
                                    {'약관 모두 동의'}
                                </Text>
                            </TouchableOpacity>
                            <View style={[styles.checkboxMargin, { flexDirection: 'row', justifyContent: 'space-between' }]}>
                                <TouchableOpacity
                                    onPress={() => {
                                        if (term1) {
                                            setCountChecked(countChecked - 1);
                                        } else {
                                            setCountChecked(countChecked + 1);
                                        }
                                        setTerm1(!term1);
                                    }}
                                    style={globalStyle.flexRow}>
                                    <Image
                                        source={
                                            term1 ? require('../../../assets/images/check_list_on.png')
                                                : require('../../../assets/images/check_list_off.png')
                                        }
                                        style={styles.check}
                                    />
                                    <Text style={globalStyle.smGreyText}>
                                        {'토마토 One-ID 이용약관 (필수)'}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => navigation.navigate('Terms', { type: 4 })}
                                    style={{ width: perfectSize(60) }}>
                                    <AntDesign name='right' size={16} style={{ flex: 1, textAlign: 'right' }} />
                                </TouchableOpacity>
                            </View>
                            <View style={[styles.checkboxMargin, { flexDirection: 'row', justifyContent: 'space-between' }]}>
                                <TouchableOpacity
                                    onPress={() => {
                                        if (term2) {
                                            setCountChecked(countChecked - 1);
                                        } else {
                                            setCountChecked(countChecked + 1);
                                        }
                                        setTerm2(!term2);
                                    }}
                                    style={globalStyle.flexRow}>
                                    <Image
                                        source={
                                            term2 ? require('../../../assets/images/check_list_on.png')
                                                : require('../../../assets/images/check_list_off.png')
                                        }
                                        style={styles.check}
                                    />
                                    <Text style={globalStyle.smGreyText}>
                                        {'개인정보 수집 및 이용에 대한 동의 (필수)'}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => navigation.navigate('Terms', { type: 2 })}
                                    style={{ width: perfectSize(60) }}>
                                    <AntDesign name='right' size={16} style={{ flex: 1, textAlign: 'right' }} />
                                </TouchableOpacity>
                            </View>
                            <View style={[styles.checkboxMargin, { flexDirection: 'row', justifyContent: 'space-between' }]}>
                                <TouchableOpacity
                                    onPress={() => {
                                        if (term3) {
                                            setCountChecked(countChecked - 1);
                                        } else {
                                            setCountChecked(countChecked + 1);
                                        }
                                        setTerm3(!term3);
                                    }}
                                    style={globalStyle.flexRow}>
                                    <Image
                                        source={
                                            term3 ? require('../../../assets/images/check_list_on.png')
                                                : require('../../../assets/images/check_list_off.png')
                                        }
                                        style={styles.check}
                                    />
                                    <Text style={globalStyle.smGreyText}>
                                        {'티켓통 이용약관 (필수)'}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => navigation.navigate('Terms', { type: 0 })}
                                    style={{ width: perfectSize(60) }}>
                                    <AntDesign name='right' size={16} style={{ flex: 1, textAlign: 'right' }} />
                                </TouchableOpacity>
                            </View>

                        </View>
                    </ScrollView>
                </View>
                <View style={styles.bottomView}>
                    <Button
                        onPress={onJoinMember}
                        textColor={c_white}
                        label={'약관동의 후 완료'}
                        color={c_primary_red}
                        disable={countChecked !== 3 ? true : false}
                    />
                </View>
            </SafeAreaView>
            {visibleAlertModal &&
                <OneAlertModal
                    isVisible={visibleAlertModal}
                    hideModal={() => {
                        setVisibleAlertModal(false);
                    }}
                    contents={isAlertText}
                    txt_type={"nomal"}
                />
            }
        </View>
    )
}

const mapStateTopProps = state => ({});

export default connect(mapStateTopProps, { login, join })(Agree);

const styles = {
    mainContainer: {
        flex: 1,
        paddingHorizontal: perfectSize(15)
    },
    marginV: {
        marginTop: perfectSize(18),
        marginBottom: perfectSize(21)
    },
    bottomView: {
        padding: perfectSize(15),
    },
    checkbox: {
        width: perfectSize(18),
        height: perfectSize(18),
        resizeMode: 'contain',
        marginRight: perfectSize(6)
    },
    check: {
        width: perfectSize(9),
        height: perfectSize(12),
        resizeMode: 'contain',
        marginRight: perfectSize(6)
    },
    checkboxMargin: {
        alignSelf: 'stretch',
        marginBottom: perfectSize(12)
    },
}