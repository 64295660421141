import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  StyleSheet,
  Text,
  FlatList,
} from "react-native";
import { c_primary_red } from "../../../variables/colors";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { useNavigation } from "@react-navigation/native";
import ModalDropdown from "react-native-modal-dropdown";
import { VideoItem } from "../video";
import EmptyText from "../../../components/EmptyText";
import { getAsset, getNFTs } from "../../../store/actions/performance";
import Loader from "../../../components/Loader";

const dropdownList = [
  "전체",
  "교향곡",
  "관현악곡",
  "협주곡",
  "실내악",
  "독주곡",
  "오페라",
  "발레",
  "국악",
  "기타",
];

const VideoDetail = props => {
  const { type } = props.route.params;
  const navigation = useNavigation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    navigation.setOptions({
      title: type === "video" ? "비디오" : "VR360",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../../assets/images/ic_before.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
      headerRight: () => (
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Image
            source={require("../../../../assets/images/ic_close.png")}
            style={globalStyle.navIcon}
          />
        </TouchableOpacity>
      ),
    });
  }, []);

  useEffect(() => {
    getData(1);
  }, [index]);

  const getData = page => {
    setLoading(true);
    getAsset(type, page, null, dropdownList[index])
      .then(res => {
        if (res.data.assets) {
          if (page === 1) {
            setTotal(res.data.total);
            setData(res.data.assets);
          } else {
            setTotal(res.data.total);
            setData([...data, ...res.data.assets]);
          }
          setPage(page);
        } else {
          if (page === 1) setData([]);
        }
        setLoading(false);
      })
      .catch(err => setLoading(false));
  };

  const renderItem = ({ item }) => {
    return (
      <View key={item._id} style={{ marginBottom: perfectSize(10) }}>
        <VideoItem type={type} video={item} />
      </View>
    );
  };

  const renderFooter = () => {
    if (!loading) return null;
    return <Loader />;
  };

  return (
    <View style={styles.mainContainer}>
      <View
        style={[globalStyle.flexSpaceBetween, { paddingLeft: perfectSize(15) }]}
      >
        <Text style={{ fontSize: perfectSize(14) }}>
          총 <Text style={{ fontWeight: "bold" }}>{total}</Text>개
        </Text>
        <ModalDropdown
          onSelect={idx => setIndex(idx)}
          options={dropdownList}
          defaultIndex={0}
          defaultValue={dropdownList[0]}
          renderSeparator={() => <View />}
          dropdownStyle={{
            width: perfectSize(80),
            height: perfectSize(40 * dropdownList.length),
            marginTop: perfectSize(8),
          }}
          dropdownTextStyle={globalStyle.dropdownTextStyle}
          dropdownTextHighlightStyle={{ color: c_primary_red }}
          textStyle={globalStyle.regularText}
          renderRightComponent={() => (
            <Image
              source={require("../../../../assets/images/ic_list_order.png")}
              style={globalStyle.dropdownRightImage}
            />
          )}
          style={globalStyle.dropdownStyle}
        />
      </View>
      <FlatList
        data={data}
        onRefresh={() => getData(1)}
        refreshing={loading}
        renderItem={renderItem}
        onEndReached={() => {
          data.length !== total && !loading && getData(page + 1);
        }}
        onEndReachedThreshold={0.4}
        keyExtractor={(v, i) => "item_" + i}
        contentContainerStyle={{ paddingHorizontal: perfectSize(15) }}
        ListFooterComponent={renderFooter}
        ListEmptyComponent={<EmptyText label={"내용이 없습니다."} />}
      />
    </View>
  );
};

export default VideoDetail;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "white",
  },
});
