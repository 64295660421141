import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    Platform,
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';
import Modal from 'react-native-modal';
import WebModal from 'modal-react-native-web';
import {
    c_third_grey,
    c_second_white,
    c_white
} from '../variables/colors';

export default function TicketCancelRuleModal({
    isVisible,
    hideModal,
    endlessDate
}) {

    const modalContent = () => {
        return (
            <View style={styles.swipeModal}>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    <View style={[globalStyle.flexSpaceBetween, styles.headerContainer]}>
                        <Text style={[globalStyle.regularBoldText, globalStyle.flex]}>
                            {'취소규정'}
                        </Text>
                        {/** close */}
                        <TouchableOpacity
                            onPress={hideModal}>
                            <Image
                                source={require('../../assets/images/ic_close.png')}
                                style={styles.closeIcon}
                            />
                        </TouchableOpacity>
                    </View>

                    <View style={styles.item}>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'취소마감'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {endlessDate}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10), width: perfectSize(80) }]}>
                                {'취소수수료'}
                            </Text>
                            <Text style={[globalStyle.regularText, { marginBottom: perfectSize(10) }]}>
                                {'0%~30%'}
                            </Text>
                        </View>
                        <View style={globalStyle.flexRow}>
                            <Text style={[globalStyle.regularGreyText, { marginBottom: perfectSize(10) }]}>
                                {'※ 예매일 이후 취소시, 예매수수료는 환불되지 않습니다. \n※ 예매 후 7일 이내라도 취소시점이 공연/행사일로부터 10일 이내라면 그에 해당하는 취소수수료 부과됩니다.'}
                            </Text>
                        </View>
                        <View style={styles.tableContainer}>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.headerCell}>
                                    <Text style={globalStyle.regularText}>
                                        {'취소일시'}
                                    </Text>
                                </View>
                                <View style={styles.headerCell}>
                                    <Text style={globalStyle.regularText}>
                                        {'취소수수료'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'예매 후 7일 이내'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'없음'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'예매 후 8일 ~ 관람일 10일 전'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'2,000원'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'관람일 9일 전 ~ 7일 전'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'티켓금액의 10%'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'관람일 6일 전 ~ 3일 전'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'티켓금액의 20%'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'관람일 2일 전 ~ 전일 17시 전'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'티켓금액의 30%'}
                                    </Text>
                                </View>
                            </View>
                            <View style={globalStyle.flexRow}>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'관람일 전일 17시 이후'}
                                    </Text>
                                </View>
                                <View style={styles.cell}>
                                    <Text style={globalStyle.regularText}>
                                        {'취소불가'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
    return (
        Platform.OS !== 'web' ?
            <Modal
                animationIn="slideInUp"
                animationOut="slideOutDown"
                style={{ margin: 0 }}
                isVisible={isVisible}>
                {modalContent()}
            </Modal>
            :
            <WebModal
                animationIn="slideInUp"
                animationOut="slideOutDown"
                transparent={true}
                visible={isVisible}>
                <View style={{
                    flex: 1,
                    backgroundColor: '#00000099'
                }}>
                    {modalContent()}
                </View>
            </WebModal>
    )
}

const styles = {
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        alignItems: 'stretch',
        paddingHorizontal: perfectSize(20),
        paddingTop: 0,
        paddingBottom: perfectSize(20),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: perfectSize(16),
        borderTopLeftRadius: perfectSize(16),
    },
    headerContainer: {
        marginVertical: perfectSize(15),
        marginTop: perfectSize(25)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
    item: {
        backgroundColor: c_white,
        marginBottom: perfectSize(8),
    },
    tableContainer: {
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderColor: c_third_grey
    },
    headerCell: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: perfectSize(10),
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderColor: c_third_grey,
        backgroundColor: c_second_white
    },
    cell: {
        flex: 1,
        alignItems: 'center',
        height: perfectSize(40),
        justifyContent: 'center',
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderColor: c_third_grey,
        opacity: 0.6
    }
}