import Loader from "../../components/Loader";
import _ from "lodash";
import EmptyText from "../../components/EmptyText";
import {Dimensions, FlatList, Image, Text, TouchableOpacity, View} from "react-native";
import {globalStyle, perfectSize} from "../../variables/styles";
import {UPLOAD_URL} from "../../variables/constants";
import moment from "moment/moment";
import {
    c_black,
    c_fourth_grey,
    c_grey,
    c_indigo, c_light_grey,
    c_primary_black,
    c_primary_red,
    c_second_white,
    c_white
} from "../../variables/colors";
import React, {useEffect, useState} from "react";
import {performanceContentList2} from "../../store/actions/performance";
import {useSelector} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import ModalDropdown from "react-native-modal-dropdown";

const {width, height} = Dimensions.get('window');

const dropdownList = ['전체보기', '4K Atmos', 'VR360', 'FLAC', 'DSD', 'VIDEO'];
export const ContentList = props => {
    const {PerformanceReducer} = useSelector(state => state);
    const navigation = useNavigation();

    const {banners, scrollMore} = props;
    const banner = _.filter(banners, ["type", "menu"])[0];

    const [isContent, setIsContent] = useState([]);
    const [contentType, setContentType] = useState('all');

    const [mainList, setMainList] = useState([]);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const limit = 5;

    const getContent = async () => {
        await performanceContentList2(contentType).then(
            res => {
                setIsContent(res.data);
                setTotal(res.data.length);
                setMainList(res.data.slice(0, limit));
                setOffset(offset + limit);                
            }
        )
    }

    useEffect(() => {
        getContent();
    }, [contentType]);

    const iterateFlatItems = ({item, index}) => {
        const detailedTimes = item.schedule.detailed_time;
        let limitDate = new Date(detailedTimes[detailedTimes.length - 1].date);
        limitDate.setDate(limitDate.getDate() - 1);
        let current = new Date();
        current.setHours(0);
        current.setMinutes(0);
        current.setSeconds(0);
        current.setMilliseconds(0);
        
        return (
            <View key={index}>
                <TouchableOpacity
                    onPress={() => navigation.navigate('Detail', {id: item._id})}
                    style={[globalStyle.shadow, styles.searchResult]}
                    key={item._id}>
                    <Image
                        source={{uri: item.poster_link ? UPLOAD_URL + item.poster_link : null}}
                        style={styles.categoryImage}/>
                    <View style={{flex: 1, paddingVertical: perfectSize(10), paddingRight: perfectSize(12)}}>
                        <Text style={[styles.titleText, {marginBottom: perfectSize(10)}]} numberOfLines={2}>
                            {item.title.name}
                        </Text>
                        <Text style={[globalStyle.regularText, {marginBottom: perfectSize(3)}]}>
                            {item.location.name}
                        </Text>
                        <Text style={[styles.dateText, {marginBottom: perfectSize(12)}]}>
                            {`${moment(item.schedule.from).format('YYYY.MM.DD')} ~ ${moment(item.schedule.to).format('YYYY.MM.DD')}`}
                        </Text>
                        {
                            item.isOnsale && new Date(limitDate) > new Date(current) ?
                                <View>
                                    <View>
                                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                            <View style={[styles.badge, {
                                                borderColor: c_primary_red,
                                                marginRight: perfectSize(3)
                                            }]}>
                                                <Text style={[styles.badgeText, {color: c_primary_red}]}>
                                                    티켓예매
                                                </Text>
                                            </View>
                                            <View style={[styles.badge, {
                                                borderColor: c_indigo,
                                                marginRight: perfectSize(3)
                                            }]}>
                                                <Text style={[styles.badgeText, {color: c_indigo}]}>
                                                    구독회원 최대 70% 할인
                                                </Text>
                                            </View>
                                            {
                                                item.event_list && item.event_list.length > 0 &&
                                                <View style={[styles.badge, {marginLeft: perfectSize(5)}]}>
                                                    <Text style={styles.badgeText}>
                                                        {
                                                            '이벤트'
                                                        }
                                                    </Text>
                                                </View>
                                            }
                                        </View>
                                    </View>
                                </View>
                                :
                                <View>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        {
                                            item.contents?.includes("atmos") ?
                                                <View style={[styles.badge, {
                                                    borderColor: c_indigo,
                                                    marginRight: perfectSize(3)
                                                }]}>
                                                    <Text style={[styles.badgeText, {color: c_indigo}]}>
                                                        4K
                                                    </Text>
                                                </View> :
                                                <View/>
                                        }
                                        {
                                            item.contents?.includes("vr360") ?
                                                <View style={[styles.badge, {
                                                    borderColor: c_indigo,
                                                    marginRight: perfectSize(3)
                                                }]}>
                                                    <Text style={[styles.badgeText, {color: c_indigo}]}>
                                                        VR360
                                                    </Text>
                                                </View> :
                                                <View/>
                                        }
                                        {
                                            item.contents?.includes("flac") ?
                                                <View style={[styles.badge, {
                                                    borderColor: c_indigo,
                                                    marginRight: perfectSize(3)
                                                }]}>
                                                    <Text style={[styles.badgeText, {color: c_indigo}]}>
                                                        FLAC
                                                    </Text>
                                                </View> :
                                                <View/>
                                        }
                                        {
                                            item.contents?.includes("dsd") ?
                                                <View style={[styles.badge, {
                                                    borderColor: c_indigo,
                                                    marginRight: perfectSize(3)
                                                }]}>
                                                    <Text style={[styles.badgeText, {color: c_indigo}]}>
                                                        DSD
                                                    </Text>
                                                </View> :
                                                <View/>
                                        }
                                        {
                                            item.contents?.includes("video") ?
                                                <View style={[styles.badge, {
                                                    borderColor: c_indigo,
                                                    marginRight: perfectSize(3)
                                                }]}>
                                                    <Text style={[styles.badgeText, {color: c_indigo}]}>
                                                        VIDEO
                                                    </Text>
                                                </View> :
                                                <View/>
                                        }
                                    </View>
                                </View>
                        }
                    </View>
                </TouchableOpacity>
                {/** PagerView */}
                {(index + 1) % 10 === 0 ?
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate("Detail", {id: banner?.performance?._id});
                        }}
                        style={{
                            backgroundColor: c_light_grey,
                            height: perfectSize(130),
                            marginHorizontal: perfectSize(-26),
                            alignItems: "center",
                            marginVertical: perfectSize(12),
                        }}
                    >
                        <Image
                            source={{uri: banner ? UPLOAD_URL + banner.image : null}}
                            style={{width: "100%", height: perfectSize(130)}}
                        />
                    </TouchableOpacity>
                    : <View/>
                }
            </View>
        );
    };

    const onEndReached = () => {
        if(total > offset){
            setMainList(mainList.concat(isContent.slice(offset, offset + limit)));
            setOffset(offset + limit);
        }
    }

    useEffect(() => {
        if(scrollMore){
            onEndReached();
        }
    }, [scrollMore]);

    const renderCategories2 = () => {
        if (PerformanceReducer.performancesLoading) return <Loader/>;
        if (_.isEmpty(isContent)) return <Loader/>;
        return <FlatList
            data={mainList}
            renderItem={iterateFlatItems}
            keyExtractor={(item, index) => item._id}
        />;
    }

    return (
        <View style={styles.container}>
            <View style={globalStyle.flex}>
                {/** PagerView */}
                <View style={[styles.content,
                    {
                        marginBottom: perfectSize(20)
                    }]}>
                    {/*<View style={styles.content_view}>*/}
                    {/*    <CheckBox*/}
                    {/*        isSelected={isContent}*/}
                    {/*        onSelect={() => {*/}
                    {/*            setIsContent(!isContent);*/}
                    {/*        }}*/}
                    {/*        label={'콘텐츠 등록 공연만 보기'}*/}
                    {/*        color={c_black}*/}
                    {/*    />*/}
                    {/*</View>*/}
                    <View>
                        <ModalDropdown
                            onSelect={index => {
                                switch (index) {
                                    case 0:
                                        setContentType('all');
                                        break;
                                    case 1:
                                        setContentType('atmos');
                                        break;
                                    case 2:
                                        setContentType('vr360');
                                        break;
                                    case 3:
                                        setContentType('flac');
                                        break;
                                    case 4:
                                        setContentType('dsd');
                                        break;
                                    case 5:
                                        setContentType('video');
                                        break;
                                    // 다른 case 추가
                                    default:
                                        setContentType('all');
                                        break;
                                }
                            }}
                            options={dropdownList}
                            defaultIndex={0}
                            defaultValue={dropdownList[0]}
                            renderSeparator={() => <View/>}
                            dropdownStyle={{
                                width: perfectSize(110),
                                height: perfectSize(40 * dropdownList.length),
                                marginTop: perfectSize(8),
                            }}
                            dropdownTextStyle={globalStyle.dropdownTextStyle}
                            dropdownTextHighlightStyle={{color: c_primary_red}}
                            textStyle={globalStyle.regularText}
                            renderRightComponent={() =>
                                <Image
                                    source={require('../../../assets/images/ic_list_order.png')}
                                    style={globalStyle.dropdownRightImage}/>
                            }
                            style={globalStyle.dropdownStyle}
                        />
                    </View>
                </View>
                {/** search result list */}
                {renderCategories2()}
            </View>
        </View>
    )
};

const styles = {
    container: {
        flex: 1,
        backgroundColor: c_white,
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        // alignItems: 'center',
        paddingTop: perfectSize(20),
        paddingRight: perfectSize(15)
    },
    content_view: {
        flexDirection: 'row',
    },
    viewDropdown: {
        paddingTop: perfectSize(20),
        width: perfectSize(80)
    },
    categoryImage: {
        width: perfectSize(95),
        marginRight: perfectSize(15),
        borderRadius: perfectSize(8),
        backgroundColor: c_second_white
    },
    searchResult: {
        flexDirection: 'row',
        marginBottom: perfectSize(12),
        borderRadius: perfectSize(6),
        backgroundColor: c_white,
        marginHorizontal: perfectSize(15),
        height: perfectSize(135),
    },
    badge: {
        borderColor: c_primary_red,
        borderWidth: 1,
        borderRadius: perfectSize(5),
        paddingTop: perfectSize(3),
        paddingBottom: perfectSize(3),
        paddingRight: perfectSize(6),
        paddingLeft: perfectSize(6),
        alignSelf: 'flex-start'
    },
    badgeText: {
        fontSize: perfectSize(10),
        color: c_primary_red,
        fontWeight: 'bold',
        letterSpacing: -0.02,
    },
    dateText: {
        fontSize: perfectSize(13),
        color: c_fourth_grey,
        letterSpacing: -0.01,
    },
    titleText: {
        fontSize: perfectSize(14),
        color: c_primary_black,
        letterSpacing: -0.01,
        fontWeight: 'bold',
    },
    dropdownText: {
        fontSize: perfectSize(12),
        color: c_primary_black,
    },
    carouselPagerWrapper: {
        height: perfectSize(200),
        backgroundColor: c_second_white
    },
    carouselPager: {
        backgroundColor: c_second_white,
    },
    carouselImage: {
        width: width,
        height: perfectSize(200)
    },
}