import React from 'react';
import {
    View,
    Text,
    TouchableOpacity
} from 'react-native';
import {
    c_grey
} from '../variables/colors';
import { globalStyle, perfectSize } from '../variables/styles';
import { useNavigation } from '@react-navigation/native';

export default function Footer({ marginTop, hideModal }) {
    const navigation = useNavigation();

    return (
        <View style={[styles.footer, { marginTop }]}>
            <View style={styles.footerMargin}>
                <Text style={globalStyle.normalText}>
                    {'(주)토마토클래식'}
                </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: perfectSize(6) }}>
                <Text style={globalStyle.secondGreySmText}>{'대표  '}</Text>
                <Text style={globalStyle.greySmText}>{'지성태      '}</Text>
                <Text style={globalStyle.secondGreySmText}>{'사업자등록번호  '}</Text>
                <Text style={globalStyle.greySmText}>{'105-87-51033'}</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: perfectSize(6) }}>
                <Text style={globalStyle.secondGreySmText}>{'주소  '}</Text>
                <Text style={globalStyle.greySmText}>{'서울시 마포구 양화진4길 32(합정동) 이토마토빌딩'}</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: perfectSize(6) }}>
                <Text style={globalStyle.secondGreySmText}>{'고객센터  '}</Text>
                <Text style={globalStyle.greySmText}>{'02-2128-3883      '}</Text>
                <Text style={globalStyle.secondGreySmText}>{'이메일  '}</Text>
                <Text style={globalStyle.greySmText}>{'ticket@etomato.com'}</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: perfectSize(6) }}>
                <Text style={globalStyle.secondGreySmText}>{'통신판매업신고  '}</Text>
                <Text style={globalStyle.greySmText}>{'2014-서울마포-0147      '}</Text>
            </View>
            <View style={{ marginTop: perfectSize(15), flexDirection: 'row' }}>
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate('Terms', { type: 0 });
                        hideModal && hideModal();
                    }}>
                    <Text style={{ color: c_grey, marginRight: perfectSize(10), fontSize: perfectSize(12) }}>
                        {'이용약관'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate('Terms', { type: 1 });
                        hideModal && hideModal();
                    }}>
                    <Text style={{ color: c_grey, marginRight: perfectSize(10), fontSize: perfectSize(12) }}>
                        {'전자금융거래약관'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate('Terms', { type: 2 });
                        hideModal && hideModal();
                    }}>
                    <Text style={{ color: c_grey, marginRight: perfectSize(10), fontSize: perfectSize(12) }}>
                        {'개인정보취급방침'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate('Terms', { type: 3 });
                        hideModal && hideModal();
                    }}>
                    <Text style={{ color: c_grey, marginRight: perfectSize(10), fontSize: perfectSize(12) }}>
                        {'청소년보호정책'}
                    </Text>
                </TouchableOpacity>
            </View>
            <View>
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate('Terms', { type: 5 });
                        hideModal && hideModal();
                    }}>
                    <Text style={{ color: c_grey, marginRight: perfectSize(10), fontSize: perfectSize(12) }}>
                        {'정기구독약관'}
                    </Text>
                </TouchableOpacity>
            </View>
            <Text style={styles.footerCopyRight}>
                {'Copyright ⓒ ㈜토마토클래식 All Rights Reserved.'}
            </Text>
        </View>
    )
}

const styles = {
    footer: {
        marginTop: perfectSize(20),
        marginBottom: perfectSize(40)
    },
    footerMargin: {
        marginBottom: perfectSize(11)
    },
    footerCopyRight: {
        color: c_grey,
        letterSpacing: -0.15,
        fontSize: perfectSize(12),
        marginTop: perfectSize(15),
        marginBottom: perfectSize(40)
    }
}