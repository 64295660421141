import _ from 'lodash';
import { instance, oneAlert } from '../../utils';
import {
    ALARM_LOAD_START,
    ALARM_LOAD_SUCCESS,
    ALARM_LOAD_FAIL,
    ALARM_LIST_RESET,
    ALARM_LIST_UPDATE,
    ALARM_LIST_UNREAD,
} from '../../variables/constants';

export const alarmLoadSuccess = (data) => ({
    type: ALARM_LOAD_SUCCESS,
    payload: data
});

export const alarmLoadStart = () => ({
    type: ALARM_LOAD_START
});

export const alarmLoadFail = () => ({
    type: ALARM_LOAD_FAIL
});

export const alarmListReset = () => ({
    type: ALARM_LIST_RESET
});

export const alarmListUpdate = () => ({
    type: ALARM_LIST_UPDATE,
    payload: data
});

export const alarmUnreadList = (data) => ({
    type: ALARM_LIST_UNREAD,
    payload: data
});

export const setAlarmRead = (unreadList, id) => {
    return (dispatch, getState) => {

        const { user } = getState().auth;
        if (!user) {
            return;
        }

        const req_body = {
            user_id: user._id,
            notification_id: id
        };

        instance.post(`/api/user/read_notification`, req_body)
            .then(res => {
                dispatch(alarmUnreadList(unreadList));
            })
            .catch(err => {
                if (err.response) {
                    oneAlert(err.response.data.message);
                }
            });
    }
}

export const readAllAlarm = () => {
    return (dispatch, getState) => {

        const { user } = getState().auth;
        if (!user) {
            return;
        }

        instance.post(`/api/user/read_all_notification/${user._id}`)
            .then(res => {
                dispatch(alarmUnreadList([]));
            })
            .catch(err => {
                if (err.response) {
                    oneAlert(err.response.data.message);
                }
            });
    }
}

export const alarmList = () => {
    return (dispatch, getState) => {

        dispatch(alarmLoadStart());

        const { user } = getState().auth;
        if (!user) {
            dispatch(alarmLoadFail());
            return;
        }

        instance.get(`/api/user/notifications/${user._id}`)
            .then(res => {
                dispatch(alarmUnreadList(res.data.data.unread));
                dispatch(alarmLoadSuccess(res.data.data.all));
            })
            .catch(err => {
                if (err.response) {
                    oneAlert(err.response.data.message);
                }
                dispatch(alarmLoadFail());
            });
    }
}