import { instance, oneAlert } from '../../utils';
import {
    COUPON_LOAD_START,
    COUPON_LOAD_SUCCESS,
    COUPON_LOAD_FAIL,
    COUPON_LIST_RESET,
    COUPON_ADD_START,
    COUPON_ADD_FAIL,
    COUPON_ADD_SUCCESS,
    COUPON_WARN_RESET
} from '../../variables/constants';

export const couponLoadSuccess = (data) => ({
    type: COUPON_LOAD_SUCCESS,
    payload: data
});

export const couponLoadStart = () => ({
    type: COUPON_LOAD_START
});

export const couponLoadFail = () => ({
    type: COUPON_LOAD_FAIL
});

export const couponListReset = () => ({
    type: COUPON_LIST_RESET
});

export const couponAddStart = () => ({
    type: COUPON_ADD_START
});

export const couponAddSuccess = (data) => ({
    type: COUPON_ADD_SUCCESS,
    payload: data
});

export const couponAddFail = (data) => ({
    type: COUPON_ADD_FAIL,
    payload: data
});

export const couponWarnReset = () => ({
    type: COUPON_WARN_RESET
});

export const couponList = () => {
    return (dispatch, getState) => {

        dispatch(couponLoadStart());

        const { user } = getState().auth;

        instance.get(`api/coupon/listByUser?decPhoneNum=${user.decPhoneNum}`)
            .then(res => {
                dispatch(couponLoadSuccess(res.data.data));
            })
            .catch(err => {
                oneAlert(err.response.data.message);
                dispatch(couponLoadFail());
            });
    }
}

export const addCoupon = (code) => {
    return (dispatch, getState) => {

        dispatch(couponAddStart());

        const { user } = getState().auth;

        instance.get(`api/coupon/get_from_code`, { params: { code: code } })
            .then(res => {

                const req_body = { coupon_id: res.data.data._id, };

                instance.post(`api/user/add_coupon/${user._id}`, req_body)
                    .then(response => {
                        dispatch(couponAddSuccess(res.data.data));
                        oneAlert('쿠폰이 등록되었습니다.');
                    })
                    .catch(err => {
                        dispatch(couponAddFail(err.response.data.message));
                    });
            })
            .catch(err => {
                dispatch(couponAddFail(err.response.data.message));
            });
    }
}