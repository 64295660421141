import React, {useEffect, useState} from "react";
import {
    Image,
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    ScrollView,
    Platform,
    ActivityIndicator,
} from "react-native";
import * as Sharing from "expo-sharing";
import {globalStyle, perfectSize} from "../../variables/styles";
import {
    c_primary_red,
    c_second_grey,
    c_third_grey,
    c_white,
} from "../../variables/colors";
import Loader from "../../components/Loader";
import LoadMoreButton from "../../components/LoadMoreButton";
import {UPLOAD_URL} from "../../variables/constants";
import * as FileSystem from "expo-file-system";
import Feather from "@expo/vector-icons/Feather";
import ReviewModal from "../../modals/ReviewModal";
import {instance, oneAlert} from "../../utils";
import {useSelector} from "react-redux";
import PopupInduction from "../../components/PopupInduction";
import axios from "axios";

export default function AudioPlayer({route, navigation}) {
    const {audio: audioData} = route.params;
    const [audio, setAudio] = useState(audioData);
    const [prevAudio, setPrevAudio] = useState({});
    const [nextAudio, setNextAudio] = useState({});
    const [isLoading, setIsLoading] = useState();
    const [isDownloading, setIsDownloading] = useState(false);
    const [isVisibleReviewModal, setIsVisibleReviewModal] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState();
    const [reveiewData, setReviewData] = useState([]);

    const {auth} = useSelector(state => state);
    const {StorageAccessFramework} = FileSystem;

    const track = {
        id: audio._id,
        audioUrl: UPLOAD_URL + audio.url,
        title: audio.content?.title,
        subtitle: audio.content?.category,
        albumArtUrl: UPLOAD_URL + audio.content?.thumbnail,
        description: audio.content?.description,
        category: audio.content?.category,
    };

    useEffect(() => {
        navigation.setOptions({
            title: audio.asset_type,
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require("../../../assets/images/ic_before_stroke.png")}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
        });
        getPrevNextAudio(audio._id);
    }, []);

    const getPrevNextAudio = id => {
        instance(`/api/asset/prevnext/${id}`).then(res => {
            setPrevAudio(res.data.prev);
            setNextAudio(res.data.next);
        });
    };

    const injectedJavascript = `(function() {
      window.postMessage = function(data) {
    window.ReactNativeWebView.postMessage(data);
  };
  })()`;

    const ensureDirAsync = async (dir, intermediates = true) => {
        const props = await FileSystem.getInfoAsync(dir);
        if (props.exist && props.isDirectory) {
            return props;
        }
        let _ = await FileSystem.makeDirectoryAsync(dir, {intermediates});
        return await ensureDirAsync(dir, intermediates);
    };

    const onDownload = (filePath, fileName) => {
        setIsDownloading(true);
        const fileUri = FileSystem.documentDirectory + encodeURI(fileName);
        console.log("native filePath", filePath, fileUri);
        if (Platform.OS === 'web') {
            downloadFile(filePath, fileName);
        } else {
            FileSystem.downloadAsync(encodeURI(filePath), fileUri)
                .then(({uri}) => {
                    if (Platform.OS === "android") {
                        saveAndroidFile(uri, fileName);
                        setIsDownloading(false);
                    } else {
                        if (
                            filePath.split(".").pop() == "jpg" ||
                            filePath.split(".").pop() == "png"
                        ) {
                            saveFile(uri);
                        } else {
                            saveIosFile(uri);
                        }
                    }
                })
                .catch(err => {
                    oneAlert("다운로드가 실패하였습니다.");
                    setIsDownloading(false);
                });
        }
    };

    const downloadFile = (filePath, fileName) => {
        setIsDownloading(true);
        const fileUri = FileSystem.documentDirectory + encodeURI(fileName);
        console.log("web filePath", filePath, fileUri);
        fetch(fileUri, { method: 'GET' })
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = filePath;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url);
                }, 60000);
                a.remove();
                setIsDownloading(false);
            })
            .catch((err) => {
                setIsDownloading(false);
                oneAlert("다운로드가 실패하였습니다.");
                console.error('downloadFile err: ', err);
            });
    };

    const saveAndroidFile = async (fileUri, fileName = "File") => {
        try {
            const fileString = await FileSystem.readAsStringAsync(fileUri, {
                encoding: FileSystem.EncodingType.Base64,
            });

            const permissions =
                await StorageAccessFramework.requestDirectoryPermissionsAsync();
            if (!permissions.granted) {
                return;
            }

            console.log('====fileString', fileString)
            console.log("======11", permissions.directoryUri, fileName);
            try {
                StorageAccessFramework.createFileAsync(
                    permissions.directoryUri,
                    fileName,
                    "application/dsd"
                )
                    .then(async uri => {
                        await FileSystem.writeAsStringAsync(uri, fileString, {
                            encoding: FileSystem.EncodingType.Base64,
                        });
                        oneAlert("파일이 다운로드되었습니다.");
                    })
                    .catch(e => {
                        console.log("====err", e);
                        oneAlert("다운로드가 실패하였습니다.");
                    });
            } catch (e) {
                throw new Error(e);
            }
        } catch (err) {
            oneAlert("다운로드가 실패하였습니다.");
        }
    };

    const saveFile = async fileUri => {
        const {status} = await Permissions.askAsync(Permissions.CAMERA_ROLL);
        if (status === "granted") {
            const asset = await MediaLibrary.createAssetAsync(fileUri);
            await MediaLibrary.createAlbumAsync("Download", asset, false)
                .then(() => oneAlert("파일이 다운로드되었습니다."))
                .catch(() => oneAlert("다운로드가 실패하였습니다."));
        }
    };

    const saveIosFile = async fileUri => {
        setIsDownloading(false);
        await Sharing.shareAsync(fileUri, {UTI: "public.item"});
    };

    useEffect(() => {
        getReview();
    }, [audio]);

    const getReview = async () => {
        const req_body = {
            type: "content",
            asset_id: audio._id,
        };
        instance.get("api/review", {params: req_body}).then(res => {
            setReviewData(res.data.data);
        });
    };

    const registerReview = text => {
        if (auth.user?._id) {
            const req_body = {
                type: "content",
                content: text,
                user_id: auth.user?._id,
                product_id: audio.performance._id,
                asset_id: audio._id,
            };
            instance
                .post("api/review", req_body)
                .then(res => {
                    if (res.data.status) {
                        setIsVisibleReviewModal(false);
                        getReview();
                    }
                })
                .catch(err => {
                    console.log("======Register Review errr", err.response.data);
                });
        }
    };
    const onMessage = event => {
        const msg = event.nativeEvent.data;
        if (msg === "prev") {
            if (prevAudio?._id) {
                getPrevNextAudio(prevAudio._id);
                setAudio(prevAudio);
            }
        } else {
            if (nextAudio?._id) {
                getPrevNextAudio(nextAudio._id);
                setAudio(nextAudio);
            }
        }
    };

    return (
        <View style={styles.container}>
            <ScrollView>
                <Text style={styles.title}>{track.title}</Text>
                <Text style={styles.subTitle}>{track.category}</Text>
                <Image source={{uri: track.albumArtUrl}} style={styles.thumbnail}/>
                {audio.asset_type !== "dsd" && (
                    <View style={{height: perfectSize(100)}}>
                        <iframe src={`https://admin.tickettong.net/audio-player?id=${track.id}`} width="100%" height="100%" style={styles.iframe_container}/>
                    </View>
                )}
                <View style={{padding: perfectSize(15)}}>
                    {audio.asset_type === "dsd" && (
                        <TouchableOpacity
                            onPress={() => {
                                onDownload("https://file.tickettong.net/" + audio.url, audio.url)
                            }}
                            style={styles.downloadButton}
                        >
                            {isDownloading ? (
                                <ActivityIndicator color={c_primary_red}/>
                            ) : (
                                <Text style={[styles.buttonTitle, {color: c_primary_red}]}>
                                    {"DSD 음원 다운로드"}
                                </Text>
                            )}
                        </TouchableOpacity>
                    )}
                    {audio?.nft ? (
                        <TouchableOpacity
                            onPress={() =>
                                navigation.navigate("NFTMolDetail", {id: audio.nft})
                            }
                            style={styles.button}
                        >
                            <Text style={styles.buttonTitle}>{"해당 음원의 NFT 정보"}</Text>
                        </TouchableOpacity>
                    ) : (
                        <View style={styles.noButton}>
                            <Text style={styles.buttonTitle}>{"해당 음원의 NFT 정보"}</Text>
                        </View>
                    )}
                    <Text style={styles.content}>{track.description}</Text>
                    <LoadMoreButton
                        onPress={() => {
                        }}
                        icon={require("../../../assets/images/ic_more_detail.png")}
                        label={"더보기"}
                        color={c_second_grey}
                        buttonStyle={{height: perfectSize(40)}}
                    />
                    <View style={{marginTop: perfectSize(20)}}>
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: perfectSize(5),
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: perfectSize(14),
                                    fontWeight: "bold",
                                }}
                            >
                                {"리뷰"}
                            </Text>
                            <TouchableOpacity onPress={() => setIsVisibleReviewModal(true)}>
                                <Feather
                                    name="edit"
                                    size={perfectSize(18)}
                                    color={c_primary_red}
                                />
                            </TouchableOpacity>
                        </View>
                        {reveiewData.map(item => {
                            return (
                                <View
                                    key={item._id}
                                    style={{
                                        flexDirection: "row",
                                        marginBottom: perfectSize(10),
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: perfectSize(14),
                                            lineHeight: perfectSize(22),
                                            fontWeight: "bold",
                                            marginRight: perfectSize(10),
                                        }}
                                    >
                                        {item.user_id?.nickname}
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: perfectSize(14),
                                            lineHeight: perfectSize(22),
                                        }}
                                    >
                                        {item.content}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                </View>
            </ScrollView>
            <ReviewModal
                isVisible={isVisibleReviewModal}
                hideModal={() => setIsVisibleReviewModal(false)}
                onRegister={registerReview}
            />
            {isLoading && (
                <View style={styles.loader}>
                    <Loader/>
                </View>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fafafa",
    },
    loader: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "#fafafa",
    },
    title: {
        fontSize: perfectSize(18),
        lineHeight: perfectSize(24),
        fontWeight: "bold",
        padding: perfectSize(15),
    },
    subTitle: {
        fontSize: perfectSize(12),
        lineHeight: perfectSize(13.6),
        color: "#777777",
        paddingLeft: perfectSize(15),
    },
    thumbnail: {
        width: perfectSize(160),
        height: perfectSize(160),
        resizeMode: "contain",
        backgroundColor: c_third_grey,
        borderRadius: perfectSize(4),
        alignSelf: "center",
        marginTop: perfectSize(15),
    },
    downloadButton: {
        borderWidth: 1,
        borderColor: c_primary_red,
        backgroundColor: "white",
        alignItems: "center",
        borderRadius: perfectSize(6),
        paddingVertical: perfectSize(10),
        marginBottom: perfectSize(10),
    },
    button: {
        backgroundColor: c_primary_red,
        alignItems: "center",
        borderRadius: perfectSize(6),
        paddingVertical: perfectSize(10),
    },
    buttonTitle: {
        color: "#fafafa",
        fontSize: perfectSize(13),
        lineHeight: perfectSize(14.75),
        fontWeight: "bold",
    },
    content: {
        fontSize: perfectSize(14),
        lineHeight: perfectSize(22),
        paddingVertical: perfectSize(15),
    },
    noButton: {
        backgroundColor: c_primary_red,
        alignItems: "center",
        borderRadius: perfectSize(6),
        paddingVertical: perfectSize(10),
        opacity: 0.4,
    },
    iframe_container: {
        border: '0',
        overflow: 'hidden',
    },
});
