import React, { useEffect, useState } from 'react';
import {
    Keyboard,
    TouchableWithoutFeedback,
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView,
    TextInput
} from 'react-native';
import {
    c_third_grey,
    c_white,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import { addInquiry } from '../../store/actions';

export default function InquiryDetails({ navigation, route }) {

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const { userId } = route.params;

    useEffect(() => {
        navigation.setOptions({
            title: "문의하기",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    const onRequest = async () => {
        if (!title) {
            alert('제목을 입력해주세요.');
            return;
        }
        if (!content) {
            alert('내용을 입력해주세요.');
        }
        const req = {
            title,
            content,
            user: userId
        }
        await addInquiry(req);
        navigation.goBack();
    }

    return (
        <TouchableWithoutFeedback>
            <View style={globalStyle.flex}>
                <View style={styles.mainContainer}>
                    <TextInput
                        value={title}
                        onChangeText={text => setTitle(text)}
                        placeholder={'제목'}
                        style={styles.title}
                    />
                    <TextInput
                        value={content}
                        onChangeText={text => setContent(text)}
                        placeholder={'문의내용을 입력해주세요'}
                        multiline={true}
                        style={{
                            ...globalStyle.regularText,
                            marginTop: perfectSize(20),
                            maxHeight: perfectSize(300),
                        }}
                    />
                </View>
                <View style={globalStyle.oneBottomButton}>
                    <View style={globalStyle.flex}>
                        <Button
                            onPress={onRequest}
                            textColor={c_white}
                            label={'문의 등록하기'}
                            color={c_primary_red}
                        />
                    </View>
                </View>
            </View>
        </TouchableWithoutFeedback>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        paddingHorizontal: perfectSize(15),
        paddingTop: perfectSize(25),
        backgroundColor: c_white
    },
    title: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        letterSpacing: -0.16,
        paddingBottom: perfectSize(10),
        borderBottomColor: c_third_grey,
        borderBottomWidth: 1,
    },
}