import React, {useEffect} from "react";
import {Image, Switch, Text, TouchableOpacity, View} from "react-native";
import {globalStyle, perfectSize} from "../../variables/styles";
import Footer from "../../components/Footer";


export default function PolicyBook({ navigation }) {

    useEffect(() => {
        navigation.setOptions({
            title: "사업자정보",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require("../../../assets/images/ic_before.png")}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require("../../../assets/images/ic_close.png")}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            ),
        });
    }, []);

    return (
        <View style={[globalStyle.mainContainer, {paddingLeft: perfectSize(13), paddingRight: perfectSize(13)}]}>
            <Footer/>
        </View>
    );
}