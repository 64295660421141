import React, {useEffect, useState} from "react";
import {StyleSheet, Image, TouchableOpacity, Platform, View} from 'react-native';
import {perfectSize} from "../variables/styles";
import {SIZE} from "../constants";
import {getData, storeData} from "../utils";
import moment from "moment";
import {useNavigation} from "@react-navigation/native";

export default function PopupInduction({}) {

    const navigation = useNavigation();
    const sUsrAg = navigator.userAgent;
    const [OneDayPopupVisible, setOneDayPopupVisible] = useState(true);
    const [InductionPopupOpen, setInductionPopupOpen] = useState(true);
    const openInductionPopup = () => {
        setInductionPopupOpen(true);
    };
    const closeInductionPopup = () => {
        setInductionPopupOpen(false);
    };


    useEffect(() => {
        openInductionPopup();
        checkHeaderPopup();
    }, []);

    const checkHeaderPopup = async () => {
        const hiddenDate = await getData("@hiddenDate");
        if (hiddenDate) {
            const currentDate = moment(new Date());
            const diffDays =
                hiddenDate &&
                moment.duration(moment(hiddenDate).diff(currentDate)).asDays();
            if (diffDays > -1) {
                setOneDayPopupVisible(false);
            } else {
                setOneDayPopupVisible(true);
            }
        } else {
            setOneDayPopupVisible(true);
        }
    };

    const hidePopup = async () => {
        storeData("@hiddenDate", new Date());
        setOneDayPopupVisible(false);
    };

    if (!InductionPopupOpen) return null;
    if (!OneDayPopupVisible) return null;
    return (
        Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                <div style={styles.div_main}>
                    <div style={styles.div_sub}>
                        <button style={styles.header_btn} onClick={closeInductionPopup}>
                            &times;
                        </button>
                    </div>
                    <div style={styles.div_sub2}>
                        <div style={styles.div_sub3}>
                            <p style={styles.content_p}>
                                공연 <span style={{color: '#D13E3B'}}>최대 70% 할인,</span> 콘텐츠 무제한 감상
                            </p>
                        </div>
                        <TouchableOpacity
                            style={styles.touch_button}
                            onPress={() => {
                                navigation.navigate("Mania_benefits");
                            }}
                        >
                            <Image
                                style={styles.insert_Img}
                                source={require("../../assets/images/app_btn_mania.png")}/>
                        </TouchableOpacity>
                    </div>
                    <div style={styles.div_sub2}>
                        <div style={styles.div_sub3}>
                            <p style={styles.content_p}>
                                티켓통 앱에서 더욱 편하게 이용해보세요!
                            </p>
                        </div>
                        <TouchableOpacity
                            style={styles.touch_button}
                            onPress={() => {
                                if (sUsrAg.match(/iPhone|iPod/i)) {
                                    var visiteTm = ( new Date() ).getTime();
                                    setTimeout( function () {
                                        if ( ( new Date() ).getTime() - visiteTm < 3000 ) {
                                            location.href = "https://itunes.apple.com/kr/app/id1577341224?mt=8";
                                        }
                                    } ,2500 );
                                    setTimeout( function () {  // 앱실행
                                        location.href = "tongtongticket://";
                                    } ,0 );
                                } else {
                                    location.href = 'intent://m.etomato.com#Intent;scheme=ttticket;package=com.etomato.ttticket;end';
                                }

                            }}
                        >
                            <Image
                                style={styles.insert_Img}
                                source={require("../../assets/images/app_btn_mc.png")}/>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.touch_button}
                            onPress={(hidePopup)}
                        >
                            <p style={styles.content_p2}>오늘 하루 웹으로 볼게요</p>
                        </TouchableOpacity>
                    </div>
                </div> :
                <View/> :
            <View/>
    );
}

const styles = StyleSheet.create({
    div_main: {
        width: '100%',
        maxWidth: '100%',
        position: 'sticky',
        bottom: SIZE(0),
        // borderTopLeftRadius: perfectSize(28),
        // borderTopRightRadius: perfectSize(28),
        // borderBottomRightRadius: perfectSize(0),
        // borderBottomLeftRadius: perfectSize(0),
        backgroundColor: '#fff',
        zIndex: '99',
        borderTopColor: "#fff",
        borderTopWidth: SIZE(1),
        borderTopStyle:'solid',
    },
    div_sub: {
        padding: '15px',
        fontSize: '16px',
        color: '#000',
        marginBottom: '25px',
    },
    div_sub2: {
        position: 'relative',
        padding: '15px',
        fontSize: '13px',
        color: '#000',
    },
    div_sub3: {
        display: 'flex',
        justifyContent: 'center',
    },
    header_btn: {
        position: 'absolute',
        top: '15px',
        right: '15px',
        width: '30px',
        fontSize: '30px',
        color: '#000',
        backgroundColor: 'transparent',
        border: '0',
    },
    logo_position: {
        width: '100px',
        height: '45px',
        marginRight: '10px',
    },
    content_p: {
        lineHeight: SIZE(1),
        margin: perfectSize(0),
        fontSize: SIZE(17),
        fontWeight: 'bold'
    },
    content_span: {
        lineHeight: SIZE(1),
        margin: perfectSize(0),
        display: 'block',
        fontSize: '15px',
        color: '#D13E3B',
        fontWeight: 'bold',
    },
    content_p2: {
        fontSize: '15px',
        fontWeight: 'normal',
        color: '#D13E3B',
        textDecorationLine: 'underline',
    },
    insert_Img: {
        width: perfectSize(190),
        height: perfectSize(40),
        marginEnd: perfectSize(20),
    },
    touch_button: {
        margin: '20px',
        alignItems: "center",
    },

});