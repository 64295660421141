import {
  StyleSheet,
  PixelRatio,
  Dimensions,
  Platform,
} from 'react-native';
import {
  c_primary_black,
  c_primary_red,
  c_grey,
  c_white,
  c_light_grey,
  c_black,
  c_second_grey,
  c_third_grey
} from './colors';
import { ifIphoneX } from 'react-native-iphone-x-helper';

const { width, height } = Dimensions.get('window');
const scale = width / 360;

export function perfectSize(size) {
  const newSize = size * scale;
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else if (Platform.OS === 'android') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
  } else {
    return width > 1000 ? size * 2 : size;
  }
}

export const globalStyle = StyleSheet.create({
  mainContainer: Platform.OS === 'android' ? {
    height: height - 80,
    backgroundColor: c_white
  } : {
    flex: 1,
    backgroundColor: c_white
  },
  headerIcon: {
    width: perfectSize(20),
    height: perfectSize(20),
    resizeMode: 'contain'
  },
  navIcon: {
    width: perfectSize(16),
    height: perfectSize(16),
    resizeMode: 'contain',
    paddingHorizontal: perfectSize(20),
  },
  header: {
    flexDirection: 'row',
    height: perfectSize(56),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  largeText: {
    color: c_primary_black,
    fontSize: perfectSize(24),
    letterSpacing: -0.15,
  },
  dotumText: {
    fontSize: perfectSize(22)
  },
  bigBoldText: {
    color: c_primary_black,
    fontSize: perfectSize(18),
    letterSpacing: -0.15,
    fontWeight: 'bold'
  },
  bigText: {
    color: c_primary_black,
    letterSpacing: -0.15,
    fontSize: perfectSize(18)
  },
  regularText: {
    fontSize: perfectSize(14),
    letterSpacing: -0.15,
    color: c_primary_black
  },
  regularBoldText: {
    fontSize: perfectSize(14),
    color: c_black,
    letterSpacing: -0.15,
    fontWeight: 'bold'
  },
  regularRedText: {
    fontSize: perfectSize(14),
    letterSpacing: -0.15,
    color: c_primary_red
  },
  regularRedBoldText: {
    fontSize: perfectSize(14),
    color: c_primary_red,
    letterSpacing: -0.15,
    fontWeight: 'bold'
  },
  normalRedText: {
    fontSize: perfectSize(15),
    letterSpacing: -0.15,
    color: c_primary_red
  },
  normalRedBoldText: {
    fontSize: perfectSize(15),
    color: c_primary_red,
    letterSpacing: -0.15,
    fontWeight: 'bold'
  },
  regularGreyText: {
    fontSize: perfectSize(13),
    color: c_grey,
    letterSpacing: -0.15,
  },
  regularWhiteText: {
    fontSize: perfectSize(13),
    color: c_white,
    letterSpacing: -0.15,
  },
  normalText: {
    fontSize: perfectSize(15),
    letterSpacing: -0.15,
    color: c_black
  },
  normalBoldText: {
    fontSize: perfectSize(15),
    letterSpacing: -0.15,
    fontWeight: 'bold',
    color: c_black
  },
  normalWhiteText: {
    fontSize: perfectSize(15),
    letterSpacing: -0.15,
    color: c_white
  },
  normalWhiteBoldText: {
    fontSize: perfectSize(15),
    letterSpacing: -0.15,
    fontWeight: 'bold',
    color: c_white
  },
  greyNormalText: {
    fontSize: perfectSize(15),
    letterSpacing: -0.15,
    fontWeight: 'bold',
    color: c_grey
  },
  smText: {
    fontSize: perfectSize(12),
    letterSpacing: -0.15,
    color: c_black
  },
  smRedText: {
    fontSize: perfectSize(12),
    letterSpacing: -0.15,
    color: c_primary_red
  },
  smGreyText: {
    fontSize: perfectSize(12),
    letterSpacing: -0.15,
    color: c_grey
  },
  greySmText: {
    fontSize: perfectSize(11),
    letterSpacing: -0.15,
    color: c_grey
  },
  secondGreySmText: {
    fontSize: perfectSize(11),
    letterSpacing: -0.15,
    color: c_second_grey
  },
  plusBtn: {
    width: perfectSize(13),
    height: perfectSize(13)
  },
  tagsContainer: {
    flexDirection: 'row',
  },
  tagGrey: {
    paddingVertical: perfectSize(4),
    paddingHorizontal: perfectSize(8),
    borderRadius: perfectSize(30),
    borderWidth: 1,
    borderColor: c_light_grey,
    marginRight: perfectSize(10)
  },
  tagGreyText: {
    color: c_grey,
    fontSize: perfectSize(13)
  },
  flex: {
    flex: 1,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  flexRowBetween: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  flexSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerLeftBack: {
    width: perfectSize(8),
    height: perfectSize(15),
    resizeMode: 'contain',
    paddingHorizontal: perfectSize(20),
  },
  headerNormalIcon: {
    width: perfectSize(15),
    height: perfectSize(15),
    resizeMode: 'contain',
    paddingHorizontal: perfectSize(15),
    marginRight: perfectSize(5),
  },
  searchIcon: {
    width: perfectSize(18),
    height: perfectSize(18),
    resizeMode: 'contain'
  },
  swipeModal: {
    flex: 1,
    justifyContent: 'flex-start'
  },
  swipeModalContainer: {
    backgroundColor: c_white,
    paddingHorizontal: perfectSize(15),
    paddingTop: perfectSize(28),
    borderBottomRightRadius: 32,
    borderBottomLeftRadius: 32,
    alignItems: 'center',
    ...ifIphoneX({ paddingTop: perfectSize(40) })
  },
  knob: {
    width: perfectSize(34),
    height: perfectSize(11),
    resizeMode: 'contain',
    marginVertical: perfectSize(20)
  },
  textInput: {
    height: Platform.OS !== 'web' ? perfectSize(48) : null,
    paddingVertical: Platform.OS === 'web' ? perfectSize(13) : null,
    borderWidth: 1,
    borderColor: c_second_grey,
    fontSize: perfectSize(15),
    paddingHorizontal: perfectSize(15),
  },
  shadow: {
    elevation: 2,
    shadowColor: c_grey,
    shadowOffset: { x: 4, y: 0 },
    shadowRadius: 4,
    shadowOpacity: 0.3,
  },
  oneBottomButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: perfectSize(15),
    paddingBottom: perfectSize(20),
    paddingTop: perfectSize(10),
    backgroundColor: c_white,
    borderTopWidth: 1,
    borderColor: c_third_grey
  },
  twoBottomButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: perfectSize(15),
    paddingVertical: perfectSize(10),
    paddingBottom: perfectSize(15),
    backgroundColor: c_white,
    borderTopWidth: 1,
    borderTopColor: c_third_grey,
  },
  dropdownTextStyle: {
    fontSize: perfectSize(14),
    letterSpacing: -0.15,
    color: c_primary_black,
    textAlign: 'center',
    height: perfectSize(40)
  },
  dropdownRightImage: {
    width: perfectSize(16),
    height: perfectSize(11),
    marginLeft: perfectSize(5)
  },
  dropdownStyle: {
    width: perfectSize(80),
    marginVertical: perfectSize(15),
  },
});