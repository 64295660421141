import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
  NativeModules,
  Platform,
  ActivityIndicator,
  Linking,
} from "react-native";
import {c_white, c_second_grey, c_primary_red, c_fourth_black, c_fifth_black} from "../../variables/colors";
import { globalStyle, perfectSize } from "../../variables/styles";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Button from "../../components/Button";
import {checkRegistered, autoLogin, login} from "../../store/actions";
import { oneAlert, twoAlert, getParameterByName } from "../../utils";
import * as IntentLauncher from "expo-intent-launcher";
import { connect } from "react-redux";
import CustomTextInput from "../../components/CustomTextInput";
import TwoAlertModal from "../../modals/TwoAlertModal";
import OneAlertModal from "../../modals/OneAlertModal";
import MethodAlertModal from "../../modals/MethodAlertModal";

const { ConnectModule } = NativeModules;

function SignIn({ navigation, autoLogin, route, login }) {
  const [loading, setLoading] = useState(false);
  const [phonenum, setPhonenum] = useState("");
  const [password, setPassword] = useState("");
  const [activityLoading, setActivityLoading] = useState(false);
  const {screenName} = route.params;

  const [visibleMethodModal, setvisibleMethodModal] = useState(false);
  const [isMethodAlertText, setMethodAlertText] = useState('');
  const [visibleTwoAlertModal, setvisibleTwoAlertModal] = useState(false);
  const [isTwoAlertText, setTwoAlertText] = useState('');
  const [visibleAlertModal, setVisibleAlertModal] = useState(false);
  const [isAlertText, setAlertText] = useState('');

  const [show, setShow] = useState(false);
  const [countryCode, setCountryCode] = useState("KR");
  const [countryNumber, setCountryNumber] = useState("+82");
  const [error, setError] = useState(false);
  const [isUserkey, setUserkey] = useState('');

  const showAlertWithText = (text) => {
    setVisibleAlertModal(true);
    setAlertText(text);
  };

  useEffect(() => {
    navigation.setOptions({
      title: "로그인",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../assets/images/ic_before_stroke.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
    });
  }, []);
  const onForgotPassword = () => {
    navigation.navigate('PasswordCertiRequest', { phonenum, isUserkey, countryCode, screenName });
  }

  const onSign = async () => {
    if (password.length < 1) {
      setMethodAlertText('비밀번호를 입력해주세요.')
    }
    setLoading(true);
    const res = await checkRegistered(phonenum);
    if (res === "INVALID_PHONE") {
      showAlertWithText("유효하지 않은 전화번호입니다.");
    } else if (res === "EMPTY_USER") {
      showAlertWithText("유효하지 않은 전화번호입니다.");
    } else {
      onLogin();
    }
    setLoading(false);
  };
  const goSignUp= () => {
    navigation.navigate("SignUp", { screenName });
  }
  const onLogin = async () => {
    setLoading(true);
    const passwd = password;
    const res = await login({ phonenum, passwd });
    if (res.status === 0) {
      if (res.code === 1015) {
        setError(true);
        showAlertWithText('비밀번호가 일치하지 않습니다')
      } else if (res.code === 404) {
        navigation.navigate('Agree', {user_status: 'login', phonenum: phonenum, passwd: password, countryCode: countryCode, screenName});
        return
      } else if (res.code === 1058) {
        setMethodAlertText('존재하지 않는 one-ID입니다.');
        setvisibleMethodModal(true);
        return
      }
    } else if (res.data) {
      navigation.navigate("Home", { screenName });
      oneAlert('로그인이 완료되었습니다.');
    } else {
      showAlertWithText('로그인시 오류가 발생하였습니다.')
    }
    setLoading(false);
  };


  return (
      <View style={globalStyle.mainContainer}>
        <KeyboardAwareScrollView style={styles.mainContainer}>
          <View style={{ alignItems: "center" }}>
            <Text style={{
              color: c_fourth_black,
              fontSize: perfectSize(17),
              fontWeight: 'normal'
            }}>{"토마토 One-ID"}</Text>
            <Text style={{
              color: c_fourth_black,
              fontSize: perfectSize(14),
              fontWeight: 'normal',
              letterSpacing: -0.02,
              marginTop: perfectSize(12),
              marginBottom: perfectSize(25)
            }}>
              {"토마토 One-ID로 티켓통을 이용하세요!"}
            </Text>
          </View>
          <View style={styles.phoneInputContainer}>
            <TouchableOpacity style={styles.countryCode} onPress={() => setShow(true)}>
              <Text style={globalStyle.normalText}>{countryNumber}</Text>
            </TouchableOpacity>
            <View style={styles.phoneInput}>
              <TextInput
                  value={phonenum}
                  placeholder={"휴대폰 번호"}
                  keyboardType={"phone-pad"}
                  maxLength={11}
                  style={globalStyle.textInput}
                  onChangeText={text => setPhonenum(text)}
              />
            </View>
          </View>
          <View style={styles.phoneInput}>
            <CustomTextInput
                value={password}
                warningText={'비밀번호를 잘못 입력하셨습니다.'}
                isWarn={error}
                placeholder={'비밀번호'}
                value={password}
                onChangeText={text => {
                  setPassword(text);
                  setError(false);
                }}
                isSecure={true}
            />
          </View>
          <Text style={{
            color: c_fifth_black,
            fontSize: perfectSize(14),
            fontWeight: 'normal',
            letterSpacing: -0.02,
            marginTop: perfectSize(20)
          }}>비밀번호를 잊으셨나요?</Text>
          <TouchableOpacity style={{
            marginTop: perfectSize(6)
          }} onPress={onForgotPassword}>
            <Text style={{
              color: c_primary_red,
              fontSize: perfectSize(14),
              fontWeight: 'normal',
              letterSpacing: -0.02
            }}>비밀번호 재설정</Text>
          </TouchableOpacity>

          <View style={{marginTop: perfectSize(40)}}>
            <Button
                onPress={onSign}
                textColor={c_white}
                label={"로그인"}
                color={c_primary_red}
                disable={phonenum.length > 0 ? false : true}
                loading={loading}
            />
          </View>

          <View style={styles.bottomView}>
            <Text
                style={{
                  color: c_fourth_black,
                  fontSize: perfectSize(14),
                  fontWeight: 'normal',
                  letterSpacing: -0.02,
                  textAlign: "center",
                }}>
              {"아직 계정이 없으신가요?"}
            </Text>
            <TouchableOpacity style={{
              marginTop: perfectSize(13)
            }} onPress={() => navigation.navigate("SignUp", { screenName })}>
              <Text style={{
                color: c_primary_red,
                fontSize: perfectSize(14),
                fontWeight: 'bold',
                letterSpacing: -0.02,
                textAlign: "center",
              }}>회원가입 ></Text>
            </TouchableOpacity>
          </View>
        </KeyboardAwareScrollView>
        {activityLoading && (
            <View style={styles.activityLoading}>
              <ActivityIndicator size={"large"} color={c_primary_red} />
            </View>
        )}
        {visibleAlertModal &&
            <OneAlertModal
                isVisible={visibleAlertModal}
                hideModal={() => {
                  setVisibleAlertModal(false);
                }}
                contents={isAlertText}
                txt_type={"nomal"}
            />
        }
        {visibleMethodModal &&
            <MethodAlertModal
                isVisible={visibleMethodModal}
                hideModal={() => {
                  setvisibleMethodModal(false);
                }}
                contents={isMethodAlertText}
                method = {goSignUp}
            />
        }
      </View>
  );
}

const mapStateTopProps = state => ({});

export default connect(mapStateTopProps, { autoLogin, login })(SignIn);

const styles = {
  mainContainer: {
    flex: 1,
    paddingTop: perfectSize(60),
    paddingHorizontal: perfectSize(21),
  },
  marginV: {
    marginTop: perfectSize(18),
    marginBottom: perfectSize(30),
  },
  bottomView: {
    alignItems: "center",
    justifyContent: "center",
    paddingTop: '30%',
  },
  phoneInputContainer: {
    flexDirection: "row",
    alignSelf: "stretch",
    marginBottom: perfectSize(12),
  },
  countryCode: {
    width: perfectSize(72),
    height: perfectSize(48),
    borderWidth: 1,
    borderColor: c_second_grey,
    alignItems: "center",
    justifyContent: "center",
    marginRight: perfectSize(6),
  },
  phoneInput: {
    flex: 1,
  },
  autoLogin: {
    marginTop: perfectSize(20),
    alignSelf: "flex-end",
    fontSize: perfectSize(14),
    color: c_primary_red,
  },
  activityLoading: {
    flex: 1,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000022",
  },
};
