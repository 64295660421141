import _ from 'lodash';
import React, { useEffect } from 'react';
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import {
    c_third_grey,
    c_white,
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import AlarmItem from '../../components/AlarmItem';
import { alarmList, readAllAlarm } from '../../store/actions/alarm';
import Loader from '../../components/Loader';
import EmptyText from '../../components/EmptyText';
import { useIsFocused } from '@react-navigation/native';

export default function AlarmScreen({ navigation }) {

    const { AlarmReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    const isFocusedHistory = useIsFocused();

    useEffect(() => {
        navigation.setOptions({
            title: "알림",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        dispatch(alarmList());
    }, [isFocusedHistory]);

    const renderAlarms = () => {
        if (AlarmReducer.alarmsLoading) return <Loader />;
        if (_.isEmpty(AlarmReducer.alarms)) return <EmptyText label={'알림이 없습니다.'} />;

        return (
            <ScrollView style={{ width: '100%' }}>
                {
                    AlarmReducer.alarms.map((item, index) => {
                        return (
                            <AlarmItem
                                key={index}
                                data={item}
                                unreadList={AlarmReducer.alarmsUnreadList}
                            />
                        )
                    })
                }
            </ScrollView>
        )
    }

    return (
        <View style={styles.mainContainer}>
            <TouchableOpacity
                onPress={() => {
                    if (AlarmReducer.alarmsUnreadList.length) {
                        dispatch(readAllAlarm([]));
                    }
                }}
                style={styles.headerTitle}>
                <Text style={globalStyle.smText}>
                    {'전부 읽음으로 하기'}
                </Text>
            </TouchableOpacity>
            {renderAlarms()}
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(15),
        alignItems: 'center',
        backgroundColor: 'white',
    },
    headerTitle: {
        alignItems: 'flex-end',
        alignSelf: 'stretch',
        paddingRight: perfectSize(15)
    },
    alarmList: {
        marginVertical: perfectSize(15),
        width: '100%',
        borderTopWidth: perfectSize(1),
        borderColor: c_third_grey,
    },
}