import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import {
    ActivityIndicator,
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
} from 'react-native';
import {
    c_white,
    c_purple,
    c_second_grey,
    c_third_grey,
    c_primary_black,
    c_primary_red,
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import AntDesign from '@expo/vector-icons/AntDesign';
import { numberWithCommas } from '../../utils';
import { getWalletBalance } from '../../store/actions';
import { coinList } from '../../store/actions/coin';
import Loader from '../../components/Loader';
import EmptyText from '../../components/EmptyText';
import { useIsFocused } from '@react-navigation/native';
import moment from 'moment';

function TongTongMile({ navigation, user, getWalletBalance }) {

    const { CoinReducer } = useSelector(state => state);
    const dispatch = useDispatch();
    const isFocusedHistory = useIsFocused();

    const [type, setType] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(coinList('TTMI', type ? 'TTMI' : 'TTMI_CHARGE'));
    }, [type, isFocusedHistory]);

    useEffect(() => {
        navigation.setOptions({
            title: "통통마일",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    const refreshWallet = async () => {
        setLoading(true);
        if (user.wallet) {
            const req_body = {
                phonenum: user.decPhoneNum,
                wallet_name: user.wallet_name
            }
            const res = await getWalletBalance(req_body);
            if (res) {
                oneAlert('지갑조회시 오류가 발생하였습니다.');
            }
        }
        setLoading(false);
    }

    const renderCoins = () => {
        if (CoinReducer.coinsLoading) return <Loader />;
        if (_.isEmpty(CoinReducer.ttmiCoins)) return <EmptyText label={type ? '사용내역이 없습니다.' : '적립내역이 없습니다.'} />;

        return (
            <ScrollView>
                {
                    CoinReducer.ttmiCoins.map((item, index) => {
                        return (
                            <View key={index} style={[globalStyle.flexSpaceBetween, styles.item]}>
                                <View>
                                    <Text style={globalStyle.smGreyText}>
                                        {moment(item.createdAt).format('YYYY')}
                                    </Text>
                                    <Text style={globalStyle.regularText}>
                                        {moment(item.createdAt).format('MM.DD')}
                                    </Text>
                                </View>
                                <View style={{ width: '70%', alignItems: 'flex-end' }}>
                                    <Text style={[globalStyle.regularText, { textAlign: 'right', marginBottom: perfectSize(10) }]}>
                                        {item.product_id.title.name}
                                    </Text>
                                    <Text style={globalStyle.regularGreyText}>
                                        {type ? '공연예매' : '예매적립'}
                                    </Text>
                                    <Text style={[globalStyle.bigBoldText, { color: c_purple, marginTop: perfectSize(5) }]}>
                                        {`${type ? '-' : '+'} ${Number(item.amount).toFixed(2)}TTMI`}
                                    </Text>
                                </View>
                            </View>
                        )
                    })
                }
            </ScrollView>
        )
    }

    return (
        <View style={{ flex: 1 }}>
            <View style={globalStyle.flex}>
                <View style={styles.headerContainer}>
                    <View style={globalStyle.flexSpaceBetween}>
                        <View style={globalStyle.flexRow}>
                            <Image
                                source={require('../../../assets/images/icon_coin_1_w.png')}
                                style={styles.coinIcon}
                            />
                            <Text style={globalStyle.normalWhiteText}>
                                {'보유중인 통통마일(TTMI)'}
                            </Text>
                        </View>
                        <TouchableOpacity
                            onPress={refreshWallet}
                            style={globalStyle.flexRow}>
                            <Text style={globalStyle.regularWhiteText}>
                                {'지갑 조회하기'}
                            </Text>
                            <AntDesign name="right" color={c_white} size={perfectSize(11)} />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={[globalStyle.shadow, styles.headerPriceContainer]}>
                    <View style={[globalStyle.flexRow, { justifyContent: 'flex-end' }]}>
                        <Text style={styles.headerPrice}>
                            {user.wallet ? numberWithCommas(Number(user.wallet.TTMI).toFixed(2)) : '-'}
                        </Text>
                        <Text style={[globalStyle.smText, { color: c_purple }]}>
                            {'통통마일'}
                        </Text>
                    </View>
                </View>

                <View style={styles.mainContents}>
                    <View style={[globalStyle.flexRow, styles.tabContainer]}>
                        <TouchableOpacity
                            onPress={() => setType(true)}
                            style={{ flex: 1, alignItems: 'center' }}>
                            <Text style={[globalStyle.regularBoldText, { fontSize: perfectSize(16), color: type ? c_primary_black : c_second_grey }]}>
                                {'사용내역'}
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => setType(false)}
                            style={{ flex: 1, alignItems: 'center' }}>
                            <Text style={[globalStyle.regularBoldText, { fontSize: perfectSize(16), color: !type ? c_primary_black : c_second_grey }]}>
                                {'적립내역'}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    {renderCoins()}
                </View>
            </View>
            {
                loading &&
                <View style={styles.activityLoading}>
                    <ActivityIndicator
                        size={'large'}
                        color={c_primary_red}
                    />
                </View>
            }
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps, { getWalletBalance })(TongTongMile);

const styles = {
    headerContainer: {
        height: perfectSize(90),
        paddingVertical: perfectSize(20),
        paddingHorizontal: perfectSize(15),
        backgroundColor: c_purple
    },
    coinIcon: {
        width: perfectSize(18),
        height: perfectSize(18),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
    headerPriceContainer: {
        paddingVertical: perfectSize(18),
        paddingHorizontal: perfectSize(15),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
        position: 'absolute',
        top: perfectSize(50),
        left: perfectSize(15),
        right: perfectSize(15),
        zIndex: 1,
    },
    headerPrice: {
        fontSize: perfectSize(30),
        color: c_purple,
        fontWeight: 'bold',
        marginRight: perfectSize(6)
    },
    mainContents: {
        paddingHorizontal: perfectSize(15),
        backgroundColor: c_white,
        flex: 1,
    },
    tabContainer: {
        borderBottomColor: c_third_grey,
        borderBottomWidth: 1,
        marginTop: perfectSize(50),
        paddingBottom: perfectSize(10)
    },
    item: {
        borderBottomWidth: 1,
        borderBottomColor: c_third_grey,
        paddingVertical: perfectSize(15)
    },
    activityLoading: {
        flex: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#00000022'
    },
}