import _ from "lodash";
import React from "react";
import { View, TouchableOpacity, Image, ScrollView } from "react-native";
import { c_second_white } from "../../variables/colors";
import { perfectSize } from "../../variables/styles";
import { UPLOAD_URL } from "../../variables/constants";
import { useNavigation } from "@react-navigation/native";

const BannerList = props => {
  const { banners } = props;
  const navigation = useNavigation();

  const onPerformance = item => {
    if (item) {
      navigation.navigate("Detail", { id: item.performance._id });
    }
  };

  if (!banners) return null;
  const carousel = banners.filter(banner => banner.type === "main");

  return (
    <View style={styles.carouselPagerWrapper}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {carousel.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => onPerformance(item)}
              style={[
                styles.carouselPager,
                index === 0 && { marginLeft: 10 },
                index === item.length && { marginRight: 10 },
              ]}
            >
              <Image
                source={{ uri: UPLOAD_URL + item.image }}
                style={styles.carouselImage}
              />
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default BannerList;

const styles = {
  carouselPagerWrapper: {
    position: "absolute",
    top: perfectSize(20),
    left: 0,
    right: 0,
    zIndex: 1,
  },
  carouselPager: {
    backgroundColor: c_second_white,
    borderRadius: perfectSize(6),
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 4,
    shadowOpacity: 0.4,
    marginBottom: perfectSize(20),
    elevation: 2,
    marginHorizontal: perfectSize(5),
  },
  carouselImage: {
    width: perfectSize(130),
    height: perfectSize(180),
    borderRadius: perfectSize(6),
  },
};
