import React, { useEffect, useMemo, useState } from "react";
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  Platform,
  NativeModules,
  Linking,
  ActivityIndicator,
} from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import {
  c_grey,
  c_light_grey,
  c_primary_red,
  c_second_grey,
  c_third_grey,
  c_white,
} from "../../../variables/colors";
import WebView from "react-native-webview";
import Loader from "../../../components/Loader";
import { UPLOAD_URL } from "../../../variables/constants";
import {
  instance,
  isCloseToBottom,
  numberWithCommas,
  oneAlert,
} from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import AntDesign from "@expo/vector-icons/AntDesign";
// import YoutubePlayer from "react-native-youtube-iframe";
import { isEmpty } from "lodash";
import BuyNFTModal from "../../../modals/BuyNFTModal";
import NoWalletModal from "../../../modals/NoWalletModal";
import {
  apiGetNFTHistoryListByNFTId,
  getWalletBalance,
  updateWallet,
} from "../../../store/actions";
import moment from "moment";
// import { Viro360Video } from "@viro-community/react-viro";

const dropdownList = [
  "https://twallet.tongtongchain.io/images/api/ttcoin.png",
  "https://twallet.tongtongchain.io/images/api/gtc.png",
];

export default function NFTMolDetail({ route, navigation }) {
  const { id } = route.params;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isExpend, setIsExpend] = useState(false);
  const [count, setCount] = useState(1);
  const [coinType, setCoinType] = useState(0);
  const [isVisibleCoinSelect, setIsVisiblecoinSelect] = useState(false);
  const [indexTab, setIndexTab] = useState(0);
  const [isVisibleBuyNFTModal, setIsVisibleBuyNFTModal] = useState(false);
  const [isVisibleNoWalletModal, setIsVisibleNoWalletModal] = useState(false);
  const [activityLoading, setActivityLoading] = useState(false);
  const [nftHistory, setNftHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const { coinData } = useSelector((state) => state).app;
  const { user, isAuthenticated } = useSelector((state) => state).auth;
  const dispatch = useDispatch();

  useEffect(() => {
    navigation.setOptions({
      title: "NFT몰",
      headerLeft: () => (
          <View style={globalStyle.flexRow}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Image
                  source={require("../../../../assets/images/ic_before_stroke.png")}
                  style={globalStyle.headerLeftBack}
              />
            </TouchableOpacity>
          </View>
      ),
    });
  }, []);

  useEffect(() => {
    instance.get(`api/nft/${id}`).then((res) => setData(res.data.data));
  }, []);

  const loadNftHistory = async (nftId, page) => {
    setLoading(true);
    const params = {
      page,
      per_page: 20,
      sortType: -1,
      sortField: "createdAt",
    };
    const res = await apiGetNFTHistoryListByNFTId(nftId, params);
    if (res.data) {
      setTotalCount(res.total);
      setNftHistory([...nftHistory, ...res.data]);
    }
    setLoading(false);
  };

  const onEndReached = () => {
    if (!loading && totalCount !== nftHistory.length) {
      setPage(page + 1);
      loadNftHistory(id, page + 1);
    }
  };

  useEffect(() => {
    loadNftHistory(id, 1);
  }, []);

  const runFirst = `
      document.body.style.backgroundColor = 'white';
      true; // note: this is required, or you'll sometimes get silent failures
    `;

  const type = data.asset?.asset_type;

  const uri = useMemo(() => {
    if (type === "video") {
      return data.asset?.type === "upload" ? UPLOAD_URL + data.url : data.url;
    }
    if (type === "vr360") {
      return `https://admin.tickettong.net/vr360-player?id=${data.asset._id}`;
    }
    if (type === "flac") {
      return `https://admin.tickettong.net/audio-player?id=${data.asset._id}`;
    }
    if (type === "dsd") {
      return `https://admin.tickettong.net/audio-player?id=${data.asset._id}`;
    }
  }, [data]);

  const coinPrice = useMemo(() => {
    return coinType
        ? numberWithCommas(
            String(
                (
                    (parseFloat(data.price * count) *
                        parseFloat(coinData?.coin.coin_price)) /
                    parseFloat(coinData?.mile.coin_price)
                ).toFixed(2)
            )
        )
        : Number(numberWithCommas(data.price * count)).toFixed(2);
  }, [data, coinData, coinType, count]);

  const coinWonPrice = useMemo(() => {
    return numberWithCommas(
        String(
            parseFloat(data.price * count) * parseFloat(coinData?.coin.coin_price)
        )
    );
  }, [data, count]);

  if (isEmpty(data)) return null;

  const buyNFT = () => {
    setIsVisibleBuyNFTModal(false);
    const price = coinType
        ? (parseFloat(data.price) * parseFloat(coinData?.coin.coin_price)) /
        parseFloat(coinData?.mile.coin_price)
        : data.price;
    const priceWon = data.price * coinData?.coin.coin_price;
    navigation.navigate("BuyNFT", {
      data,
      price,
      priceWon,
      coinType: coinType ? "TTMI" : "TTC",
      count,
    });
  };

  const { ConnectModule } = NativeModules;

  const handleOpenUrl = (ev) => {
    if (ev.url) {
      const data = getParameterByName(ev.url);
      oneAlert(`지갑정보${ev.url}`);
      if (data.walletId) {
        const _data = {
          walletId: data.walletId,
          walletPassword: data.walletPw,
        };
        connectWallet(_data);
      } else {
        oneAlert(`지갑정보가 없습니다.\n통통 앱에서 확인해주세요.${ev.url}`);
      }
    } else {
      // oneAlert('지갑정보가 없습니다.\n통통 앱에서 확인해주세요.');
    }
    setActivityLoading(false);
  };

  const connectWallet = async (data) => {
    if (!data.walletName && !data.walletId) {
      oneAlert("지갑정보가 없습니다.\n통통 앱에서 확인해주세요.");
      setActivityLoading(false);
      return;
    }
    const req = {
      wallet_id: data.walletId,
      wallet_name: data.walletName,
      wallet_pw: data.walletPassword,
      user_key: user?.userKey,
    };
    const wallet = await updateWallet(req, user?._id);
    if (wallet) {
      const req_body = {
        phonenum: user?.decPhoneNum,
        wallet_name: data.walletName,
        wallet_id: data.walletId,
        user_key: user?.userKey,
      };
      const res = await dispatch(getWalletBalance(req_body));
      if (res) {
        oneAlert("지갑조회시 오류가 발생하였습니다.");
      } else {
        oneAlert("지갑연동이 완료되었습니다.");
      }
      setActivityLoading(false);
    } else {
      oneAlert("지갑정보를 찾을수 없습니다.");
      setActivityLoading(false);
    }
  };

  const connectTongtong = () => {
    setIsVisibleNoWalletModal(false);
    if (Platform.OS === "android") {
      setActivityLoading(true);
      Linking.canOpenURL("tongtongwallet://m.etomato.com").then((supported) => {
        if (supported) {
          ConnectModule.getInfoFromWallet((res) => {
            if (res.isLoginWallet) {
              connectWallet(res);
            } else {
              oneAlert("지갑정보가 없습니다.\n통통 앱에서 확인해주세요.");
            }
          });
        } else {
          Linking.openURL(
              `https://play.google.com/store/apps/details?id=com.tongtong.wallet`
          );
          setActivityLoading(false);
        }
      });
    }
    if (Platform.OS === "ios") {
      Linking.openURL(
          `tongtongios://tongtongticket?userid=${user?.decPhoneNum}`
      )
          .then((res) => {
            Linking.addEventListener("url", handleOpenUrl);
          })
          .catch((err) => {
            oneAlert("지갑정보가 없습니다.\n통통 앱에서 확인해주세요.");
            setActivityLoading(false);
          });
    }
  };

  const onWalletConnect = () => {
    connectTongtong();
  };

  const getNftHistoryStatus = (status) => {
    switch (status) {
      case 0:
        return "구매 ";
      case 1:
        return "판매 ";
      case 2:
        return "판매등록 ";
      case 3:
        return "판매취소 ";
      case 4:
        return "생성 ";
    }
  };

  return (
      <View
          style={styles.container}
          activeOpacity={1}
          onPress={() => setIsVisiblecoinSelect(false)}
      >
        <ScrollView
            style={{ width: "100%" }}
            showsVerticalScrollIndicator={false}
            onScroll={({ nativeEvent }) => {
              if (isCloseToBottom(nativeEvent)) {
                onEndReached();
              }
            }}
            scrollEventThrottle={1}
        >
          {type !== "vr360" && type !== "video" && (
              <>
                <Text style={styles.title}>{data.content?.title}</Text>
                <Text style={styles.subTitle}>{data.content?.category}</Text>
                <Image
                    source={{ uri: UPLOAD_URL + data.content?.thumbnail }}
                    style={styles.thumbnail}
                />
              </>
          )}
          <View
              style={{
                height:
                    type === "vr360" || type === "video"
                        ? perfectSize(220)
                        : perfectSize(100),
              }}
          >
            {Platform.OS !== "web" ? (
                data.asset?.type === "유튜브" ? (
                    // <YoutubePlayer
                    //     height={300}
                    //     play={playing}
                    //     videoId={"iee2TATGMyI"}
                    //     onChangeState={onStateChange}
                    // />
                    <View />
                ) : (
                    <WebView
                        source={{
                          uri: uri,
                        }}
                        allowsFullscreenVideo
                        onMessage={(msg) => console.log("====")}
                        onLoadEnd={() => setIsLoading(false)}
                        onError={(err) => console.log(err)}
                        onHttpError={(err) => console.log(err)}
                        scrollEnabled={false}
                        domStorageEnabled
                        javaScriptEnabled
                        originWhitelist={["*"]}
                        injectedJavaScript={runFirst}
                    />
                )
            ) : (
                <iframe src={uri} height={perfectSize(220)} style={{ border: 0 }} />
            )}
            {/* <Viro360Video source={{ uri: UPLOAD_URL + video.url }} /> */}
          </View>
          <Text style={styles.title}>{data.content?.title}</Text>
          <View
              style={{
                paddingHorizontal: perfectSize(15),
                flexDirection: "row",
                justifyContent: "space-between",
              }}
          >
            <View>
              <Text style={styles.count}>
                {`판매수량 ${data.count - data.list?.length}`}
              </Text>
              <Text style={styles.count}>{`발행수량 ${data.count}`}</Text>
            </View>
            <View>
              <View style={globalStyle.flexRow}>
                <View>
                  <TouchableOpacity
                      onPress={() => setIsVisiblecoinSelect(true)}
                      style={[
                        globalStyle.flexRow,
                        { borderWidth: 1, borderColor: c_grey, padding: 3 },
                      ]}
                  >
                    <Image
                        source={{
                          uri: dropdownList[coinType],
                        }}
                        style={styles.icon}
                    />
                    <AntDesign name={"caretdown"} size={perfectSize(10)} />
                  </TouchableOpacity>
                </View>

                <View style={{ marginLeft: perfectSize(4) }}>
                  <Text style={styles.price}>{coinPrice}</Text>
                  <Text style={styles.price}>{coinWonPrice}원</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ padding: perfectSize(15) }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                  onPress={() => {
                    count > 1 && setCount(Number(count) - 1);
                  }}
                  style={{ width: perfectSize(22), height: perfectSize(22) }}
              >
                <AntDesign
                    name={"minuscircleo"}
                    size={perfectSize(22)}
                    color={c_grey}
                />
              </TouchableOpacity>
              <TextInput
                  value={String(count || 1)}
                  onChangeText={(text) => setCount(text)}
                  keyboardType="numeric"
                  style={styles.countInput}
              />
              <TouchableOpacity
                  onPress={() => {
                    data.count > count && setCount(Number(count) + 1);
                  }}
                  style={{ width: perfectSize(22), height: perfectSize(22) }}
              >
                <AntDesign
                    name={"pluscircleo"}
                    size={perfectSize(22)}
                    color={c_grey}
                />
              </TouchableOpacity>

              <TouchableOpacity
                  onPress={() => {
                    if (!isAuthenticated) {
                      navigation.navigate("SignIn", {screenName: 'Home'});
                      return;
                    }
                    if (user?.wallet) {
                      if (Platform.OS === "ios") {
                        oneAlert("NFT몰은 모바일웹에서만 작동합니다.");
                        return;
                      }
                      setIsVisibleBuyNFTModal(true);
                    } else {
                      setIsVisibleNoWalletModal(true);
                    }
                  }}
                  disabled={!data.list?.length || data.owner === user?._id}
                  style={[
                    styles.button,
                    {
                      opacity:
                          data.list.length && data.owner !== user?._id ? 1 : 0.4,
                    },
                  ]}
              >
                <Text style={styles.buttonTitle}>{"구매하기"}</Text>
              </TouchableOpacity>
            </View>
            {isVisibleCoinSelect && (
                <View
                    style={{
                      borderWidth: 1,
                      borderColor: c_grey,
                      alignItems: "center",
                      position: "absolute",
                      top: perfectSize(0),
                      right: perfectSize(78),
                      zIndex: 99,
                      backgroundColor: "white",
                      padding: 3,
                    }}
                >
                  <TouchableOpacity
                      onPress={() => {
                        setCoinType(0);
                        setIsVisiblecoinSelect(false);
                      }}
                      style={{ paddingVertical: perfectSize(2) }}
                  >
                    <Image
                        source={{
                          uri: "https://twallet.tongtongchain.io/images/api/ttcoin.png",
                        }}
                        style={styles.icon}
                    />
                  </TouchableOpacity>
                  {data.isEnabledTTM && (
                      <TouchableOpacity
                          onPress={() => {
                            setCoinType(1);
                            setIsVisiblecoinSelect(false);
                          }}
                          style={{ paddingVertical: perfectSize(2) }}
                      >
                        <Image
                            source={{
                              uri: "https://twallet.tongtongchain.io/images/api/gtc.png",
                            }}
                            style={styles.icon}
                        />
                      </TouchableOpacity>
                  )}
                </View>
            )}
          </View>
          <View>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                  onPress={() => setIndexTab(0)}
                  style={indexTab === 0 ? styles.tab : styles.tabUnSelect}
              >
                <Text
                    style={
                      indexTab === 0 ? styles.tabLabel : styles.tabLabelUnSelect
                    }
                >
                  {"NFT정보"}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                  onPress={() => setIndexTab(1)}
                  style={indexTab === 1 ? styles.tab : styles.tabUnSelect}
              >
                <Text
                    style={
                      indexTab === 1 ? styles.tabLabel : styles.tabLabelUnSelect
                    }
                >
                  {"콘텐츠 정보"}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                  onPress={() => setIndexTab(2)}
                  style={indexTab === 2 ? styles.tab : styles.tabUnSelect}
              >
                <Text
                    style={
                      indexTab === 2 ? styles.tabLabel : styles.tabLabelUnSelect
                    }
                >
                  {"판매 히스토리"}
                </Text>
              </TouchableOpacity>
            </View>
            {indexTab === 0 && (
                <View style={{ padding: perfectSize(15) }}>
                  <Text style={styles.boldTitle}>{"기본정보"}</Text>
                  <Text style={styles.normalTitle}>
                    {`NFT발행처: ${data.partner?.name}\n판매자: ${data.ownerName}`}
                  </Text>
                  <Text style={styles.boldTitle}>{"블록체인 정보"}</Text>
                  <Text style={styles.normalTitle}>
                    {`판매자 지갑주소: ${data.walletName}`}
                  </Text>
                  <Text style={styles.boldTitle}>{"구매약관"}</Text>
                  <Text style={styles.normalTitle}>{data.description}</Text>
                </View>
            )}
            {indexTab === 1 && (
                <View style={{ padding: perfectSize(15) }}>
                  <Text style={styles.boldTitle}>{"연주곡 정보"}</Text>
                  <Text style={styles.normalTitle}>{data.content?.title}</Text>
                  {/* <Text style={styles.boldTitle}>{"아티스트 정보"}</Text>
              <Text style={styles.normalTitle}>
                {`판매자 지갑주소: ${data.partner?.name}`}
              </Text> */}
                  <Text style={styles.boldTitle}>{"공연정보"}</Text>
                  <Text style={styles.normalTitle}>
                    {data.performance?.description}
                  </Text>
                </View>
            )}

            {indexTab === 2 && (
                <View style={styles.mainDetail}>
                  {nftHistory.map((item) => {
                    return (
                        <View
                            key={`nft_history_${item._id}`}
                            style={styles.historyItem}
                        >
                          <Text style={styles.historyItemText}>
                            {item.status === 4
                                ? `NFT가 생성되었습니다. (${item.total || 0}/${item.total || 0}개) `
                                : item.userPhone
                                    ? item.userPhone.substring(0, 5) + "****** 님이 "
                                    : ""}
                            {item.status !== 4 ? (
                                <>
                                  <Text style={styles.historyItemBoldText}>
                                    {getNftHistoryStatus(item.status)}
                                  </Text>
                                  하였습니다.
                                  {`(${item.count || 0}/${item.total || 0}개, ${Number(
                                      numberWithCommas(item.price || 0)
                                  ).toFixed(2)}TTC) ${moment(new Date(item.createdAt)).format('YYYY.MM.DD')}`}
                                </>
                            ) : (
                                ""
                            )}
                          </Text>
                        </View>
                    );
                  })}
                </View>
            )}
          </View>
        </ScrollView>
        {isLoading && (
            <View style={styles.loader}>
              <Loader />
            </View>
        )}
        <BuyNFTModal
            isVisible={isVisibleBuyNFTModal}
            hideModal={() => setIsVisibleBuyNFTModal(false)}
            data={data}
            coinIcon={dropdownList[coinType]}
            coinPrice={coinPrice}
            coinWonPrice={coinWonPrice}
            buyNFT={buyNFT}
        />
        <NoWalletModal
            isVisible={isVisibleNoWalletModal}
            hideModal={() => setIsVisibleNoWalletModal(false)}
            onPress={onWalletConnect}
        />
        {activityLoading && (
            <View style={styles.activityLoading}>
              <ActivityIndicator size={"large"} color={c_primary_red} />
            </View>
        )}
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafafa",
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "#fafafa",
  },
  title: {
    fontSize: perfectSize(18),
    lineHeight: perfectSize(24),
    fontWeight: "bold",
    padding: perfectSize(15),
  },
  subTitle: {
    fontSize: perfectSize(12),
    lineHeight: perfectSize(13.6),
    color: "#777777",
    paddingLeft: perfectSize(15),
  },
  thumbnail: {
    width: perfectSize(160),
    height: perfectSize(160),
    resizeMode: "contain",
    backgroundColor: c_third_grey,
    borderRadius: perfectSize(4),
    alignSelf: "center",
    marginTop: perfectSize(15),
  },
  button: {
    flex: 1,
    marginLeft: perfectSize(20),
    backgroundColor: c_primary_red,
    alignItems: "center",
    paddingVertical: perfectSize(10),
  },
  noButton: {
    backgroundColor: c_primary_red,
    alignItems: "center",
    borderRadius: perfectSize(6),
    paddingVertical: perfectSize(10),
    opacity: 0.4,
  },
  buttonTitle: {
    color: "#fafafa",
    fontSize: perfectSize(13),
    lineHeight: perfectSize(14.75),
    fontWeight: "bold",
  },
  content: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(22),
    paddingVertical: perfectSize(15),
  },
  reviewTitle: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(15.89),
    fontWeight: "bold",
    marginTop: perfectSize(20),
    marginBottom: perfectSize(5),
  },
  reviewName: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(22),
    fontWeight: "bold",
    marginRight: perfectSize(10),
  },
  reviewContent: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(22),
  },
  count: {
    color: c_grey,
    fontSize: perfectSize(10),
  },
  price: {
    fontSize: perfectSize(10),
    width: perfectSize(50),
  },
  icon: {
    width: perfectSize(20),
    height: perfectSize(20),
    resizeMode: "contain",
    marginRight: perfectSize(3),
  },
  countInput: {
    fontSize: perfectSize(16),
    borderWidth: 2,
    paddingVertical: perfectSize(4),
    marginHorizontal: perfectSize(10),
    minWidth: perfectSize(40),
    maxWidth: perfectSize(40),
    textAlign: "center",
    borderColor: c_grey,
  },
  tab: {
    height: perfectSize(40),
    justifyContent: "center",
    alignItems: "center",
    borderBottomColor: c_primary_red,
    borderBottomWidth: 2,
    flex: 1,
  },
  tabUnSelect: {
    height: perfectSize(40),
    justifyContent: "center",
    alignItems: "center",
    borderBottomColor: c_light_grey,
    borderBottomWidth: 2,
    flex: 1,
  },
  tabLabel: {
    color: "black",
    fontSize: perfectSize(14),
  },
  tabLabelUnSelect: {
    color: c_light_grey,
    fontSize: perfectSize(14),
  },
  boldTitle: {
    fontSize: perfectSize(14),
    fontWeight: "bold",
  },
  normalTitle: {
    fontSize: perfectSize(14),
    marginVertical: perfectSize(20),
    marginTop: perfectSize(10),
  },
  activityLoading: {
    flex: 1,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000022",
  },
  mainDetail: {
    padding: perfectSize(16),
    backgroundColor: "white",
  },
  historyItem: {
    paddingVertical: perfectSize(8),
    borderBottomColor: "#bfbfbf",
    borderBottomWidth: 1,
  },
  historyItemText: {
    fontSize: perfectSize(12),
    color: "black",
  },
  historyItemBoldText: {
    fontSize: perfectSize(12),
    color: c_primary_red,
    fontWeight: "bold",
  },
});