import React, {useEffect, useState} from 'react';
import {c_fourth_white, c_primary_red, c_thrid_white, c_white} from "../../variables/colors";
import {Image, ScrollView, TouchableOpacity, View} from "react-native";
import Button from "../../components/Button";
import axios from "axios";
import {HOST} from "../../variables/constants";
import {Text} from "react-native-web";
import {Title} from "react-native-paper";
import {globalStyle, perfectSize} from "../../variables/styles";

export default function ManiaItemReturn({
                                           route,
                                           navigation,
                                       }) {

    const {user} = route.params;
    useEffect(() => {
        navigation.setOptions({
            title: '콘텐츠 결제',
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    useEffect(() => {
        const params = route.params;
        if (!params) {
            return;
        }
        if (params.status === '1') {
            getUser(params.userid);
        }
    }, []);

    function getUser(userId) {
        axios.get(`${HOST}/api/user/detail/${userId}`)
            .then(res => res.data)
            .then(data => {
                if (data.status === 1) {
                    window.localStorage.setItem("@user", JSON.stringify(data.data));
                }
            });
    }

    const onItem = () => {
        navigation.navigate("Mania_buy_goods", {user: user});
    };
    const onHome = () => {
        navigation.navigate('Main');
    };

    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.scrollContainer}>
                    <View style={{
                        marginLeft: perfectSize(15),
                        marginRight: perfectSize(15),
                        backgroundColor: c_fourth_white
                    }
                    }>
                        <View>
                            <Image
                                style={styles.contentImage}
                                source={require('../../../assets/images/mania_item_complete.png')}
                            />
                        </View>

                    </View>
                </View>
            </ScrollView>
            <View
                style={[
                    globalStyle.flexRow,
                    {
                        height: perfectSize(65),
                    },
                ]}
            >
                <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                    <Button
                        onPress={onHome}
                        isBorder={true}
                        textColor={c_white}
                        label={"메인화면"}
                        color={c_primary_red}
                        borderRadius={8}
                    />
                </View>
                <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                    <Button
                        onPress={onItem}
                        isBorder={true}
                        textColor={c_white}
                        label={"구매한 콘텐츠 보관함"}
                        color={c_primary_red}
                        borderRadius={8}
                    />
                </View>
            </View>
        </View>
    )
}

const styles = {
    scrollContainer: {
        padding: perfectSize(15)
    },
    contentImage: {
        width: perfectSize(330),
        height: perfectSize(440),
    }
}
