import React, {useEffect, useState} from "react";
import {ActivityIndicator, Image, Text, TextInput, TouchableOpacity, View} from "react-native";
import {globalStyle, perfectSize} from "../../variables/styles";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {c_fifth_black, c_fourth_black, c_primary_red, c_second_grey, c_white} from "../../variables/colors";
import CustomTextInput from "../../components/CustomTextInput";
import Button from "../../components/Button";
import OneAlertModal from "../../modals/OneAlertModal";
import MethodAlertModal from "../../modals/MethodAlertModal";
import {connect} from "react-redux";
import {checkRegistered, login, setTvLogin} from "../../store/actions";


function classtvIndex({route, navigation, login, setTvLogin }) {
    const [loading, setLoading] = useState(false);
    const [phonenum, setPhonenum] = useState("");
    const [password, setPassword] = useState("");
    const [countryCode, setCountryCode] = useState("KR");
    const [countryNumber, setCountryNumber] = useState("+82");
    const [error, setError] = useState(false);
    const [activityLoading, setActivityLoading] = useState(false);
    const [visibleMethodModal, setvisibleMethodModal] = useState(false);
    const [isMethodAlertText, setMethodAlertText] = useState('');
    const [visibleAlertModal, setVisibleAlertModal] = useState(false);
    const [isAlertText, setAlertText] = useState('');
    const [show, setShow] = useState(false);
    const [isUserkey, setUserkey] = useState('');
    const {screenName} = 'classtvIndex';
    const {tvParams} = route.params;

    const showAlertWithText = (text) => {
        setVisibleAlertModal(true);
        setAlertText(text);
    };

    useEffect(() => {
        navigation.setOptions({
            title: "클래식TV 로그인",
        });
    }, []);

    const onSign = async () => {
        if (password.length < 1) {
            setMethodAlertText('비밀번호를 입력해주세요.')
        }
        const res = await checkRegistered(phonenum);
        if (res === "INVALID_PHONE") {
            showAlertWithText("유효하지 않은 전화번호입니다.");
        } else if (res === "EMPTY_USER") {
            showAlertWithText("유효하지 않은 전화번호입니다.");
        } else {
            onLogin();
        }
    };
    const onLogin = async () => {
        const passwd = password;
        const res = await login({ phonenum, passwd });
        if (res.status === 0) {
            if (res.code === 1015) {
                setError(true);
                showAlertWithText('비밀번호가 일치하지 않습니다')
            } else if (res.code === 404) {
                navigation.navigate('Agree', {user_status: 'login', phonenum: phonenum, passwd: password, countryCode: countryCode, screenName});
                return
            } else if (res.code === 1058) {
                setMethodAlertText('존재하지 않는 one-ID입니다.');
                setvisibleMethodModal(true);
                return
            }
        } else if (res.data) {
            await tvLogin(res.data.decPhoneNum, passwd, res.data.nationType, tvParams)

        } else {
            showAlertWithText('로그인시 오류가 발생하였습니다.')
        }
    };

    // setTvLogin
    const tvLogin = async (phonenum, passwd, nationType, id) => {
        const res = await setTvLogin(phonenum, passwd, nationType, id)
        if (res.status === 1) {
            navigation.navigate('classtvfinish');
        } else {
            showAlertWithText('로그인 실패하였습니다.')
        }
    }
    const goSignUp= () => {
        navigation.navigate("SignUp", { screenName });
    }
    const onForgotPassword = () => {
        navigation.navigate('PasswordCertiRequest', { phonenum, isUserkey, countryCode, screenName });
    }
    return (
        <View style={globalStyle.mainContainer}>
            <KeyboardAwareScrollView style={styles.mainContainer}>
                <View style={{ alignItems: "center" }}>
                    <Text style={{
                        color: c_fourth_black,
                        fontSize: perfectSize(17),
                        fontWeight: 'normal'
                    }}>{"토마토 One-ID"}</Text>
                    <Text style={{
                        color: c_fourth_black,
                        fontSize: perfectSize(14),
                        fontWeight: 'normal',
                        letterSpacing: -0.02,
                        marginTop: perfectSize(12),
                        marginBottom: perfectSize(25)
                    }}>
                        {"토마토 One-ID로 티켓통을 이용하세요!"}
                    </Text>
                </View>
                <View style={styles.phoneInputContainer}>
                    <TouchableOpacity style={styles.countryCode} onPress={() => setShow(true)}>
                        <Text style={globalStyle.normalText}>{countryNumber}</Text>
                    </TouchableOpacity>
                    <View style={styles.phoneInput}>
                        <TextInput
                            value={phonenum}
                            placeholder={"휴대폰 번호"}
                            keyboardType={"phone-pad"}
                            maxLength={11}
                            style={globalStyle.textInput}
                            onChangeText={text => setPhonenum(text)}
                        />
                    </View>
                </View>
                <View style={styles.phoneInput}>
                    <CustomTextInput
                        value={password}
                        warningText={'classic tv 인증 실패'}
                        isWarn={error}
                        placeholder={'비밀번호'}
                        value={password}
                        onChangeText={text => {
                            setPassword(text);
                            setError(false);
                        }}
                        isSecure={true}
                    />
                </View>
                <Text style={{
                    color: c_fifth_black,
                    fontSize: perfectSize(14),
                    fontWeight: 'normal',
                    letterSpacing: -0.02,
                    marginTop: perfectSize(20)
                }}>비밀번호를 잊으셨나요?</Text>
                <TouchableOpacity style={{
                    marginTop: perfectSize(6)
                }} onPress={onForgotPassword}>
                    <Text style={{
                        color: c_primary_red,
                        fontSize: perfectSize(14),
                        fontWeight: 'normal',
                        letterSpacing: -0.02
                    }}>비밀번호 재설정</Text>
                </TouchableOpacity>

                <View style={{marginTop: perfectSize(40)}}>
                    <Button
                        onPress={onSign}
                        textColor={c_white}
                        label={"로그인"}
                        color={c_primary_red}
                        disable={phonenum.length > 0 ? false : true}
                        loading={loading}
                    />
                </View>

                <View style={styles.bottomView}>
                    <Text
                        style={{
                            color: c_fourth_black,
                            fontSize: perfectSize(14),
                            fontWeight: 'normal',
                            letterSpacing: -0.02,
                            textAlign: "center",
                        }}>
                        {"아직 계정이 없으신가요?"}
                    </Text>
                    <TouchableOpacity style={{
                        marginTop: perfectSize(13)
                    }} onPress={() => navigation.navigate("SignUp", { screenName })}>
                        <Text style={{
                            color: c_primary_red,
                            fontSize: perfectSize(14),
                            fontWeight: 'bold',
                            letterSpacing: -0.02,
                            textAlign: "center",
                        }}>회원가입 ></Text>
                    </TouchableOpacity>
                </View>
            </KeyboardAwareScrollView>
            {activityLoading && (
                <View style={styles.activityLoading}>
                    <ActivityIndicator size={"large"} color={c_primary_red} />
                </View>
            )}
            {visibleAlertModal &&
                <OneAlertModal
                    isVisible={visibleAlertModal}
                    hideModal={() => {
                        setVisibleAlertModal(false);
                    }}
                    contents={isAlertText}
                    txt_type={"nomal"}
                />
            }
            {visibleMethodModal &&
                <MethodAlertModal
                    isVisible={visibleMethodModal}
                    hideModal={() => {
                        setvisibleMethodModal(false);
                    }}
                    contents={isMethodAlertText}
                    method = {goSignUp}
                />
            }
        </View>
    );

}

const mapStateTopProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateTopProps, { login, setTvLogin })(classtvIndex);

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(60),
        paddingHorizontal: perfectSize(21),
    },
    bottomView: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: '30%',
    },
    phoneInputContainer: {
        flexDirection: "row",
        alignSelf: "stretch",
        marginBottom: perfectSize(12),
    },
    countryCode: {
        width: perfectSize(72),
        height: perfectSize(48),
        borderWidth: 1,
        borderColor: c_second_grey,
        alignItems: "center",
        justifyContent: "center",
        marginRight: perfectSize(6),
    },
    phoneInput: {
        flex: 1,
    },
    activityLoading: {
        flex: 1,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00000022",
    },
};