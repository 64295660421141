import _ from 'lodash';
import { instance, storeData, getData, } from '../../utils';
import {
  APPICON_LOAD_SUCCESS,
  APPICON_LIST_RESET,
} from '../../variables/constants';

export const appIconLoadSuccess = (data) => ({
  type: APPICON_LOAD_SUCCESS,
  payload: data
});
export const appIconListReset = () => ({
  type: APPICON_LIST_RESET,
});

export const updateAppIcons = (data) => {
  return (dispatch) => {
    storeData('@AppIcons', data);
    dispatch(appIconLoadSuccess(data))
  }
};

export const appIconList = () => {
    return async (dispatch, getState) => {
    const appIcons = await getData('@AppIcons');

    instance.get('api/tongtongcoin/getAppList')
      .then(res => {
        if (appIcons && appIcons.length === res.data.menuList.length - 1) {
          dispatch(updateAppIcons(appIcons));
        } else {
          const filteredList = _.filter(res.data.menuList, item => { return item.android_package_name !== 'com.etomato.ttticket'});
          storeData('@AppIcons', filteredList);
          dispatch(appIconLoadSuccess(filteredList));
        }
      })
      .catch(err => dispatch(appIconLoadSuccess([])));
  }
};