import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    View,
    Text,
    ScrollView,
} from 'react-native';
import {
    c_white,
    c_grey,
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import InvitationCard from '../../components/InvitationCard';
import Loader from '../../components/Loader';
import EmptyText from '../../components/EmptyText';
import { invitationListReset, invitationList } from '../../store/actions/invitation';

export default function Invitation() {

    const { InvitationReducer } = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(invitationListReset());
        dispatch(invitationList());
    }, []);

    const renderInvitations = () => {
        if (InvitationReducer.invitationsLoading) return <Loader />;
        if (_.isEmpty(InvitationReducer.invitations)) return <EmptyText label='보유중인 초대권이 없습니다.' />

        return (
            <ScrollView>
                {
                    InvitationReducer.invitations.map((item, index) => {
                        return <InvitationCard key={`invitation${index}`} data={item} />
                    })
                }
            </ScrollView>
        )
    }

    return (
        <View style={styles.mainContainer}>
            <View style={[globalStyle.flexSpaceBetween, styles.headerTitle]}>
                <Text style={globalStyle.regularText}>
                    {'보유중인 초대권'}
                </Text>
                <Text style={globalStyle.regularText}>
                    {`총 ${InvitationReducer.invitations.length}개`}
                </Text>
            </View>
            {renderInvitations()}
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white
    },
    headerTitle: {
        padding: perfectSize(15),
        marginTop: perfectSize(5)
    },
    cardTopSection: {
        elevation: 2,
        shadowColor: c_grey,
        shadowOffset: { x: 2, y: 6 },
        shadowRadius: 4,
        shadowOpacity: 0.3,
        marginTop: perfectSize(10),
        marginHorizontal: perfectSize(15),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
    },
}