import React, { useState } from 'react';
import {
    View,
    Text,
    Image,
    Linking,
    TouchableOpacity,
    Dimensions,
    Platform
} from 'react-native';
import { UPLOAD_URL } from '../../../variables/constants';
import { globalStyle, perfectSize } from '../../../variables/styles';
import Separator from '../../../components/Separator';
import { c_third_grey } from '../../../variables/colors';
import UrlPreview from '../../../components/UrlPreview';
import { useNavigation } from '@react-navigation/native';
import MediaModal from '../../../modals/MediaModal';
// import { Thumbnail } from 'react-native-thumbnail-video';

const width = Dimensions.get('window').width;

export default function Contents({ performance }) {
    const navigation = useNavigation();
    const [visibleModal, setVisibleModal] = useState(false);
    const [mediaLink, setMediaLink] = useState('');

    return (
        <View style={styles.mainContainer}>
            {/** 관련영상 */}
            <Text style={globalStyle.regularBoldText}>
                {'관련영상'}
            </Text>
            <View style={{
                marginVertical: perfectSize(15)
            }}>
                {
                    performance.media_link.map(item =>
                        <UrlPreview
                            key={item._id}
                            text={item.link}
                            containerStyle={{ backgroundColor: 'white' }}
                            imageProps={{ width: perfectSize(130), height: perfectSize(90), backgroundColor: 'red' }}
                            onPress={() => {
                                setMediaLink(item.link);
                                setVisibleModal(true);
                            }}
                        />
                    )
                }
            </View>

            <Separator height={1} color={c_third_grey} />

            {/** 관련음악 */}
            <View style={styles.subTitle}>
                <Text style={globalStyle.regularBoldText}>
                    {'관련음악'}
                </Text>
            </View>
            <View>
                {
                    performance.audio_file ?
                    <TouchableOpacity
                        onPress={() => navigation.navigate('AudioPlayer', {
                            track: [{
                                audioUrl: UPLOAD_URL + performance.audio_file,
                                albumArtUrl: UPLOAD_URL + performance.audio_thumbnail,
                                subtitle: performance.audio_author,
                                title: performance.audio_title,
                            }]
                        })}
                        style={styles.musicSection}>
                        <View style={globalStyle.flexRow}>
                            <Image
                                source={{ uri: UPLOAD_URL + performance.audio_thumbnail }}
                                style={{
                                    width: perfectSize(45),
                                    height: perfectSize(45),
                                    marginRight: perfectSize(10)
                                }}
                            />
                            <View>
                                <Text style={globalStyle.regularBoldText}>
                                    {performance.audio_title}
                                </Text>
                                <Text style={{ ...globalStyle.regularGreyText, marginTop: perfectSize(4) }}>
                                    {performance.audio_author}
                                </Text>
                            </View>
                        </View>
                        <Image
                            source={require('../../../../assets/images/icon_mov_red.png')}
                            style={{
                                width: perfectSize(28),
                                height: perfectSize(28),
                            }}
                        />
                    </TouchableOpacity>
                    : null
                }
            </View>
            <MediaModal
                isVisible={visibleModal}
                hideModal={() => setVisibleModal(false)}
                link={mediaLink}
            />
        </View>
    )
}

const styles = {
    mainContainer: {
        width: width,
        paddingTop: perfectSize(30),
        paddingHorizontal: perfectSize(15)
    },
    priceInfo: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        marginBottom: perfectSize(30)
    },
    priceItem: {
        width: '50%',
        marginBottom: perfectSize(5)
    },
    subTitle: {
        marginTop: perfectSize(30),
        marginBottom: perfectSize(15)
    },
    subDescription: {
        marginTop: perfectSize(15),
        marginBottom: perfectSize(30)
    },
    showInfo: {
        height: perfectSize(120),
        backgroundColor: '#eeeeee',
        marginBottom: perfectSize(-30)
    },
    videoContainer: {
        flexDirection: 'row',
        marginBottom: perfectSize(15)
    },
    videoTitle: {
        paddingHorizontal: perfectSize(10),
        flex: 1
    },
    youtubeIcon: {
        width: perfectSize(55),
        height: perfectSize(12),
        marginBottom: perfectSize(8)
    },
    musicSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: perfectSize(15)
    },
}