import React, {useEffect, useState} from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ActivityIndicator
} from 'react-native';
import {
    c_white,
    c_primary_blue,
    c_primary_red
} from '../../variables/colors';
import {globalStyle, perfectSize} from '../../variables/styles';
import Button from '../../components/Button';
import CustomTextInput from '../../components/CustomTextInput';
import {checkPasscode, resendPasscode} from '../../store/actions';
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";

function Passcode({navigation, route}) {

    const [passcode, setPasscode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        navigation.setOptions({
            title: "인증번호입력",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    const onVerifyCode = async () => {
        setError(false);
        setLoading(true);
        const {userkey, phonenum} = route.params;
        if (passcode === '123123' && phonenum === '01088881234') {
            navigation.navigate('SetPassword', {userkey, phonenum});
        } else {
            const res = await checkPasscode({code: passcode, userkey});
            if (res.message === 'INVALID_PARAMETER') {
                setError(true);
            } else if (res.result === true) {
                navigation.navigate('SetPassword', {userkey, phonenum});
            }
        }
        setLoading(false);
    };

    const onResendCode = async () => {
        const {userkey} = route.params;
        setError(false);
        setLoad(true);
        const res = await resendPasscode({userkey});
        alert('인증번호가 전송되었습니다.')
        console.log(res);
        setLoad(false);
    }

    return (
        <View style={globalStyle.mainContainer}>
            <KeyboardAwareScrollView style={{flex: 1}}>
                <View style={styles.mainContainer}>
                    <Text style={globalStyle.largeText}>
                        {'토마토 One-ID'}
                    </Text>
                    <Text style={[globalStyle.normalText, styles.marginV]}>
                        {'인증번호를 입력해주세요'}
                    </Text>

                    <CustomTextInput
                        warningText={'인증번호가 일치하지 않습니다'}
                        isWarn={error}
                        placeholder={'인증번호 입력'}
                        value={passcode}
                        onChangeText={text => {
                            setPasscode(text);
                            setError(false);
                        }}
                        style={{marginBottom: perfectSize(6)}}
                    />

                    {
                        !load ?
                            <TouchableOpacity onPress={onResendCode}>
                                <Text style={[globalStyle.normalText, {
                                    color: c_primary_red,
                                    marginTop: perfectSize(36)
                                }]}>
                                    {'인증번호 재발송'}
                                </Text>
                            </TouchableOpacity>
                            : <ActivityIndicator style={{marginTop: perfectSize(36)}}/>
                    }
                </View>
                <View style={{marginTop: perfectSize(130)}}>
                    <Button
                        onPress={onVerifyCode}
                        textColor={c_white}
                        label={'다음'}
                        color={c_primary_red}
                        disable={(!passcode) ? true : false}
                        loading={loading}
                    />
                </View>
            </KeyboardAwareScrollView>

        </View>
    )
}

export default Passcode;

const styles = {
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        marginTop: perfectSize(60),
        paddingHorizontal: perfectSize(21)
    },
    marginV: {
        marginTop: perfectSize(18),
        marginBottom: perfectSize(30)
    },
    bottomView: {
        position: 'absolute',
        bottom: perfectSize(30),
        left: perfectSize(21),
        right: perfectSize(21)
    },
}