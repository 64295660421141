
import React, {useEffect, useState} from "react";
import {Image, Platform, ScrollView, Text, TouchableOpacity, View} from "react-native";
import {globalStyle, perfectSize} from "../../variables/styles";
import {c_fourth_white, c_light_grey, c_primary_red, c_second_grey, c_white} from "../../variables/colors";
import _ from "lodash";
import {getBuyContents, getContents} from "../../store/actions/performance";
import {getPerformance} from "../../store/actions";

export default function Mania_buy_goods({
                                            route,
                                            navigation,
                                        }) {
    const {user} = route.params;
    const [buyContents, setBuyContents] = useState();

    useEffect(() => {
        navigation.setOptions({
            title: '구매한 콘텐츠',
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    useEffect(() => {
        getBuyContents(user._id)
            .then(res => {
                setBuyContents(res.data);
            })
            .catch(err => {
            });
    }, []);

    const detailClick = async (index, id) => {
        try {
            const res = await getPerformance(id);
            const res2 = await getContents(id);

            navigation.navigate("ContentDetail", {
                performance: res,
                index: index,
                item: res2.data.data.contents[index],
                user: user
            });
        } catch (error) {
            navigation.navigate("Detail", {id: id});
            console.error(`Failed to fetch details: ${error}`);
        }
    }
    const renderContents = () => {
        if (_.isEmpty(buyContents)) return <Text style={styles.noReview}>{"컨텐츠가 없습니다."}</Text>;
        return buyContents.map((item, index) => {
            return (
                <View key={index} style={styles.mainView}>
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate("Detail", {id: buyContents[index]._id});
                        }}
                        style={styles.btn_touchable}
                    >
                        <Text style={{
                            fontWeight: 'bold', marginRight: perfectSize(9),
                            fontSize: perfectSize(14),
                            lineHeight: perfectSize(22),
                            color: "#222222",
                            overflow: "hidden", width: '85%'
                        }} numberOfLines={1}>
                            {item.title}
                        </Text>
                    </TouchableOpacity>
                    <View style={{marginTop: perfectSize(10)}}>
                        {
                            item.contents && item.contents.map((content, i) => {
                                return (
                                    <View key={i} style={styles.mainView}>
                                        <TouchableOpacity
                                            onPress={() => detailClick(i, buyContents[index]._id)}
                                            style={styles.btn_touchable}
                                        >
                                            <Text
                                                style={{
                                                    fontWeight: 'normal',
                                                    marginRight: perfectSize(9),
                                                    fontSize: perfectSize(14),
                                                    lineHeight: perfectSize(22),
                                                    color: "#222222",
                                                    overflow: "hidden"
                                                }}
                                            >
                                                {i + 1}
                                            </Text>
                                            <Text
                                                style={{
                                                    fontWeight: 'normal',
                                                    marginRight: perfectSize(9),
                                                    fontSize: perfectSize(14),
                                                    lineHeight: perfectSize(22),
                                                    color: "#222222",
                                                    overflow: "hidden",
                                                    width: '85%'
                                                }}
                                                numberOfLines={1}
                                            >
                                                {content.title}
                                            </Text>
                                            <Image
                                                source={require("../../../assets/images/btn_details_contents.png")}
                                                style={{
                                                    width: 10,
                                                    height: 16,
                                                    marginLeft: 'auto',
                                                    marginRight: perfectSize(15)
                                                }}
                                            />
                                        </TouchableOpacity>
                                        <View
                                            style={{
                                                backgroundColor: c_light_grey,
                                                height: perfectSize(1),
                                                marginTop: perfectSize(5),
                                                width: "99%"
                                            }}
                                        />
                                    </View>
                                );
                            })
                        }
                    </View>
                </View>
            )
        })
    }

    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.scrollContainer}>
                    <View style={{
                        backgroundColor: c_fourth_white
                    }
                    }>
                        <View style={{ marginTop: perfectSize(20)}}>
                            {renderContents()}
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const styles = {
    scrollContainer: {

    },
    noReview: {
        ...globalStyle.greyNormalText,
        color: c_second_grey,
        textAlign: "center",
        marginTop: perfectSize(40),
    },
    mainView: {
        justifyContent: 'space-between',
        marginTop: perfectSize(10),
        marginBottom: perfectSize(10),
        marginLeft: perfectSize(15),
        marginRight: perfectSize(15)
    },
    btn_touchable: {
        flexDirection: 'row',
        alignItems: 'center'
    },
}