import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
} from 'react-native';
import { formatDate, numberWithCommas } from '../../utils';
import {
    c_light_grey,
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';

export default function Comment({
    data,
    myId,
    onDelete,
    onLike,
    onDisLike,
    isLike,
    isDisLike,
    onReport
}) {

    const [like, setLike] = useState(isLike);
    const [dislike, setDislike] = useState(isDisLike);
    const [nLike, setNLike] = useState(data.likes.length);
    const [nDislike, setNDislike] = useState(data.dislikes.length);

    useEffect(() => {
        setLike(isLike);
        setDislike(isDisLike);
        setNLike(data.likes.length);
        setNDislike(data.dislikes.length);
    }, [isLike, isDisLike])

    return (
        <View style={styles.comment}>
            <View style={globalStyle.flexSpaceBetween}>
                <Text style={globalStyle.regularBoldText}>
                    {data.user_id ? data.user_id.nickname : ''}
                </Text>
                {
                    data.user_id && data.user_id._id === myId &&
                    <TouchableOpacity onPress={onDelete}>
                        <Text style={globalStyle.regularText}>
                            {'삭제'}
                        </Text>
                    </TouchableOpacity>
                }
            </View>
            <Text style={[globalStyle.smGreyText, styles.commentDate]}>
                {formatDate(data.createdAt)}
            </Text>
            <Text style={[globalStyle.normalText, styles.commentText]}>
                {data.content}
            </Text>
            <View style={globalStyle.flexSpaceBetween}>
                <TouchableOpacity
                    onPress={onReport}
                    style={globalStyle.flexRow}>
                    <Image
                        source={require('../../../assets/images/ic_report.png')}
                        style={styles.commentReportIcon}
                    />
                    <Text style={globalStyle.regularGreyText}>
                        {'신고'}
                    </Text>
                </TouchableOpacity>
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity
                        onPress={() => {
                            !like && onLike();
                        }}
                        style={globalStyle.flexRow}>
                        {

                            like ?
                                <Image
                                    source={
                                        require('../../../assets/images/ic_review_like_on.png')
                                    }
                                    style={styles.commentLike}
                                />
                                :
                                <Image
                                    source={
                                        require('../../../assets/images/ic_review_like.png')
                                    }
                                    style={styles.commentLike}
                                />
                        }
                        <Text style={globalStyle.smText}>
                            {numberWithCommas(nLike)}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            !dislike && onDisLike();
                        }}
                        style={globalStyle.flexRow}>
                        <Image
                            source={
                                dislike ?
                                    require('../../../assets/images/ic_review_dislike_on.png') :
                                    require('../../../assets/images/ic_review_dislike.png')
                            }
                            style={styles.commentLike}
                        />
                        <Text style={globalStyle.smText}>
                            {numberWithCommas(nDislike)}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

const styles = {
    comment: {
        borderBottomWidth: 1,
        borderColor: c_light_grey,
        paddingVertical: perfectSize(15)
    },
    commentDate: {
        color: '#b9b9b9',
        marginTop: perfectSize(8),
        marginBottom: perfectSize(10)
    },
    commentText: {
        fontSize: perfectSize(13),
        letterSpacing: -0.13,
        marginBottom: perfectSize(15),
    },
    commentReportIcon: {
        width: perfectSize(10),
        height: perfectSize(10),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
    commentLike: {
        width: perfectSize(13),
        height: perfectSize(13),
        resizeMode: 'contain',
        marginRight: perfectSize(5),
        marginLeft: perfectSize(15)
    },
}