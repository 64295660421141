import _ from "lodash";
import React, {useState, useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {
    c_third_grey,
    c_light_grey,
    c_primary_red,
    c_second_white,
    c_second_black,
    c_grey,
} from "../variables/colors";
import {globalStyle, perfectSize} from "../variables/styles";
import {
    View,
    NativeModules,
    Text,
    TouchableOpacity,
    Image,
    Platform,
    ScrollView,
    ActivityIndicator,
    Linking,
} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import Badge from "../components/Badge";
import {
    logout,
    updateWallet,
    getWalletBalance,
    getRewardPoint,
    getUsableCoinList,
    getUsableCoinData
} from "../store/actions";
import {UPLOAD_URL} from "../variables/constants";
import {numberWithCommas, oneAlert, getParameterByName} from "../utils";
import Collapsible from "react-native-collapsible";
import Entypo from "@expo/vector-icons/Entypo";
import Mania_gift from "../screens/mania/mania_gift";

const {ConnectModule} = NativeModules;

function DrawerContent({
                           navigation,
                           isAuthenticated,
                           user,
                           logout,
                           banners,
                           nNotification,
                           getWalletBalance,
                       }) {
    const {AlarmReducer} = useSelector(state => state);

    const [activityLoading, setActivityLoading] = useState(false);
    const [isPerformExtend, setIsPerformExtend] = useState(true);
    const [isManiaExtend, setIsManiaExtend] = useState(true);
    const [isCommunityExtend, setIsCommunityExtend] = useState(true);
    const [isMyPageExtend, setIsMyPageExtend] = useState(true);
    const [rewardPoint, setRewardPoint] = useState(0);
    const [coinData, setCoinData] = useState({});

    const banner = _.filter(banners, ["type", "menu"])[0];

    const handleOpenUrl = ev => {
        if (ev.url) {
            const data = getParameterByName(ev.url);
            // oneAlert(`지갑정보${ev.url}`);
            if (data.walletId) {
                const _data = {
                    walletId: data.walletId,
                    walletPassword: data.walletPw,
                };
                connectWallet(_data);
            } else {
                oneAlert(`지갑정보가 없습니다.\n통통 앱에서 확인해주세요.${ev.url}`);
            }
        } else {
            // oneAlert('지갑정보가 없습니다.\n통통 앱에서 확인해주세요.');
        }
        setActivityLoading(false);
    };

    const connectTongtong = screenName => {
        if (user.wallet) {
            navigation.navigate(screenName);
            return;
        }
        if (Platform.OS === "android") {
            setActivityLoading(true);
            Linking.canOpenURL("tongtongwallet://m.etomato.com").then(supported => {
                if (supported) {
                    ConnectModule.getInfoFromWallet(res => {
                        if (res.isLoginWallet) {
                            connectWallet(res);
                        } else {
                            oneAlert("지갑정보가 없습니다.\n통통 앱에서 확인해주세요.");
                        }
                    });
                } else {
                    Linking.openURL(
                        `https://play.google.com/store/apps/details?id=com.tongtong.wallet`
                    );
                    setActivityLoading(false);
                }
            });
        } else if (Platform.OS === "ios") {
            Linking.openURL(`tongtongios://tongtongticket?userid=${user.decPhoneNum}`)
                .then(res => {
                    Linking.addEventListener("url", handleOpenUrl);
                })
                .catch(err => {
                    oneAlert("지갑정보가 없습니다.\n통통 앱에서 확인해주세요.");
                    setActivityLoading(false);
                });
        } else if (Platform.OS === "web") {
            oneAlert("지갑정보가 없습니다.\n통통 앱에서 확인해주세요.");
        }
    };

    const connectWallet = async data => {
        if (!data.walletName && !data.walletId) {
            oneAlert("지갑정보가 없습니다.\n통통 앱에서 확인해주세요.");
            setActivityLoading(false);
            return;
        }
        const req = {
            wallet_id: data.walletId,
            wallet_name: data.walletName,
            wallet_pw: data.walletPassword,
            user_key: user.userKey,
        };
        const wallet = await updateWallet(req, user._id);
        if (wallet) {
            const req_body = {
                phonenum: user.decPhoneNum,
                wallet_name: data.walletName,
                wallet_id: data.walletId,
                user_key: user.userKey,
            };
            const res = await getWalletBalance(req_body);
            if (res) {
                oneAlert("지갑조회시 오류가 발생하였습니다.");
            }
            setActivityLoading(false);
        } else {
            oneAlert("지갑정보를 찾을수 없습니다.");
            setActivityLoading(false);
        }
    };

    const getRewardData = async () => { // user.decPhoneNum 01050556371
        await getRewardPoint(user._id).then(res => {
            if (res && res.Code === "200") {
                const point = parseInt(res.data.Point);
                setRewardPoint(point.toLocaleString("ko-KR"));
            }
        });
    }

    const getTtrData = async () => {
        const coin = await getUsableCoinData(user.decPhoneNum, user.wallet_name);
        setCoinData(coin)
    }

    return (
        <SafeAreaView style={styles.drawerContent}>
            <ScrollView>
                <View style={styles.section}>
                    {/** login */}
                    <View style={styles.item}>
                        {!isAuthenticated ? (
                            <TouchableOpacity
                                onPress={() => navigation.navigate("SignIn", {screenName: 'Home'})}
                                style={globalStyle.flexRow}
                            >
                                <Image
                                    source={require("../../assets/images/side_ic_login.png")}
                                    style={styles.icon}
                                />
                                <Text style={globalStyle.bigText}>{"로그인"}</Text>
                            </TouchableOpacity>
                        ) : (
                            <View style={globalStyle.flexRow}>
                                <Image
                                    source={require("../../assets/images/side_ic_11.png")}
                                    style={styles.icon}
                                />
                                <Text style={globalStyle.bigText}>{`${
                                    user && user.nickname
                                }님`}</Text>
                                {user && user?.subscribe[0]?.type === "MANIA" ?
                                    <Image
                                        source={require("../../assets/images/side_mania_logo.png")}
                                        style={styles.maniaicon}
                                    /> : <View/>
                                }
                            </View>
                        )}
                        <View style={globalStyle.flexRow}>
                            <TouchableOpacity onPress={() => navigation.navigate("Home")}>
                                <Image
                                    source={require("../../assets/images/side_ic_home.png")}
                                    style={styles.icon}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.navigate("Alarm")}>
                                <Image
                                    source={require("../../assets/images/side_ic_alarm.png")}
                                    style={styles.icon}
                                />
                                {AlarmReducer.alarmsUnreadList.length ? (
                                    <Badge
                                        style={styles.badge}
                                        number={AlarmReducer.alarmsUnreadList.length}
                                    />
                                ) : null}
                            </TouchableOpacity>
                        </View>
                    </View>
                    {/** tongtong meni */}
                    {isAuthenticated && (
                        <>
                            <View style={styles.spliter}/>
                            <TouchableOpacity
                                onPress={() => getTtrData()}
                                style={styles.item}
                            >
                                <View style={globalStyle.flexRow}>
                                    <Image
                                        source={require("../../assets/images/side_ic_coin_1.png")}
                                        style={styles.icon}
                                    />
                                    <Text style={globalStyle.regularText}>{"통통코인"}</Text>
                                </View>
                                <Text style={globalStyle.regularText}>
                                    {coinData.coin_balance
                                        ? `${numberWithCommas(
                                            Number(coinData.coin_balance).toFixed(2)
                                        )}`
                                        : "-"}
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => getRewardData()}
                                style={styles.item}
                            >
                                <View style={globalStyle.flexRow}>
                                    <Image
                                        source={require("../../assets/images/ttco_symbol.png")}
                                        style={styles.icon}
                                    />
                                    <Text style={globalStyle.regularText}>{"통통쿠폰"}</Text>
                                </View>
                                <Text style={globalStyle.regularText}>
                                    {rewardPoint === 0
                                        ? "-"
                                        : rewardPoint}
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => getTtrData()}
                                style={styles.item}
                            >
                                <View style={globalStyle.flexRow}>
                                    <Image
                                        source={require("../../assets/images/ttr_symbol.png")}
                                        style={styles.icon}
                                    />
                                    <Text style={globalStyle.regularText}>{"통통리워드"}</Text>
                                </View>
                                <Text style={globalStyle.regularText}>
                                    {coinData.ttr_balance
                                        ? `${numberWithCommas(
                                            Number(coinData.ttr_balance)
                                        )}`
                                        : "-"}
                                </Text>
                            </TouchableOpacity>
                        </>
                    )}
                    <View style={styles.spliter}/>
                    {/** tongtong sign and checkin */}
                    {/* <View style={styles.item}>
            <TouchableOpacity style={[globalStyle.flexRow, { flex: 1 }]}>
              <Image
                source={require("../../assets/images/side_ic_sign.png")}
                style={styles.icon}
              />
              <Text style={globalStyle.regularText}>{"통통사인"}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[globalStyle.flexRow, { flex: 1 }]}>
              <Image
                source={require("../../assets/images/side_ic_qrcheckin.png")}
                style={styles.icon}
              />
              <Text style={globalStyle.regularText}>{"체크인"}</Text>
            </TouchableOpacity>
          </View> */}
                    {/* <View style={styles.spliter} /> */}

                    <TouchableOpacity
                        onPress={() => setIsPerformExtend(!isPerformExtend)}
                        style={[globalStyle.flexRow, {paddingVertical: perfectSize(10)}]}
                    >
                        <Text
                            style={[
                                globalStyle.regularText,
                                {marginRight: perfectSize(15)},
                            ]}
                        >
                            {"공연"}
                        </Text>
                        {isPerformExtend ? (
                            <Entypo
                                name="chevron-thin-down"
                                size={perfectSize(14)}
                                color={c_grey}
                            />
                        ) : (
                            <Entypo
                                name="chevron-thin-up"
                                size={perfectSize(14)}
                                color={c_grey}
                            />
                        )}
                    </TouchableOpacity>
                    <Collapsible
                        collapsed={isPerformExtend}
                        style={{paddingLeft: perfectSize(24)}}
                    >
                        {/** tongtong preSail */}
                        <TouchableOpacity
                            onPress={() => navigation.navigate("Category")}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"티켓예매"}</Text>
                        </TouchableOpacity>
                        {/** tongtong ok/cancel */}
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("ConfirmTicket");
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"예매확인/취소"}</Text>
                        </TouchableOpacity>
                        {/** tongtong tiket */}
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("MobileTicket");
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"모바일티켓"}</Text>
                        </TouchableOpacity>
                        {/** tongtong like */}
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("Favorite");
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"찜한 공연"}</Text>
                        </TouchableOpacity>
                    </Collapsible>

                    {
                        <TouchableOpacity
                            onPress={() => setIsManiaExtend(!isManiaExtend)}
                            style={[globalStyle.flexRow, {paddingVertical: perfectSize(10)}]}
                        >
                            <Text
                                style={[
                                    globalStyle.regularText,
                                    {marginRight: perfectSize(15)},
                                ]}
                            >
                                {"매니아 회원"}
                            </Text>
                            {isManiaExtend ? (
                                <Entypo
                                    name="chevron-thin-down"
                                    size={perfectSize(14)}
                                    color={c_grey}
                                />
                            ) : (
                                <Entypo
                                    name="chevron-thin-up"
                                    size={perfectSize(14)}
                                    color={c_grey}
                                />
                            )}
                        </TouchableOpacity>
                    }

                    <Collapsible
                        collapsed={isManiaExtend}
                        style={{paddingLeft: perfectSize(24)}}
                    >
                        {/** 매니아 혜택 */}
                        {/*<TouchableOpacity*/}
                        {/*    onPress={() => navigation.navigate("Mania_gift", {user: user})}*/}
                        {/*    style={styles.item}*/}
                        {/*>*/}
                        {/*    <Text style={globalStyle.regularText}>{"기프트 테스트"}</Text>*/}
                        {/*</TouchableOpacity>*/}
                        {/** 매니아 구독 */}
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate("Mania_benefits");
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"매니아 구독"}</Text>
                        </TouchableOpacity>
                        {/** 매니아 추천 */}
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate("Mania_recommend");
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"매니아 추천"}</Text>
                        </TouchableOpacity>
                        {/** 구독자 혜택 */}
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("BenefitsPage", {user: user});
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"구독자 혜택"}</Text>
                        </TouchableOpacity>
                    </Collapsible>

                    {/*<TouchableOpacity*/}
                    {/*  onPress={() => {*/}
                    {/*    navigation.navigate("VideoDetail", { type: "video" });*/}
                    {/*  }}*/}
                    {/*  style={styles.item}*/}
                    {/*>*/}
                    {/*  <Text style={globalStyle.regularText}>{"비디오"}</Text>*/}
                    {/*</TouchableOpacity>*/}

                    {/*<TouchableOpacity*/}
                    {/*  onPress={() => {*/}
                    {/*    navigation.navigate("VideoDetail", { type: "vr360" });*/}
                    {/*  }}*/}
                    {/*  style={styles.item}*/}
                    {/*>*/}
                    {/*  <Text style={globalStyle.regularText}>{"VR360"}</Text>*/}
                    {/*</TouchableOpacity>*/}

                    {/*<TouchableOpacity*/}
                    {/*  onPress={() => {*/}
                    {/*    navigation.navigate("AudioDetail", { type: "flac" });*/}
                    {/*  }}*/}
                    {/*  style={styles.item}*/}
                    {/*>*/}
                    {/*  <Text style={globalStyle.regularText}>{"FLAC"}</Text>*/}
                    {/*</TouchableOpacity>*/}

                    {/*<TouchableOpacity*/}
                    {/*  onPress={() => {*/}
                    {/*    navigation.navigate("AudioDetail", { type: "dsd" });*/}
                    {/*  }}*/}
                    {/*  style={styles.item}*/}
                    {/*>*/}
                    {/*  <Text style={globalStyle.regularText}>{"DSD"}</Text>*/}
                    {/*</TouchableOpacity>*/}

                    {Platform.OS === 'android' ?
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("NFTDetail");
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"NFT"}</Text>
                        </TouchableOpacity> : <View/>}

                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate("VideoPlayer", {isLive: true});
                        }}
                        style={styles.item}
                    >
                        <View style={globalStyle.flexRow}>
                            <View
                                style={{
                                    width: perfectSize(31),
                                    height: perfectSize(26),
                                    borderRadius: perfectSize(10),
                                    borderWidth: 2,
                                    borderColor: c_primary_red,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: perfectSize(8),
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: perfectSize(14),
                                        fontWeight: "bold",
                                        color: c_primary_red,
                                    }}
                                >
                                    TV
                                </Text>
                            </View>
                            <Text style={globalStyle.regularText}>{"토마토클래식"}</Text>
                        </View>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => setIsCommunityExtend(!isCommunityExtend)}
                        style={[globalStyle.flexRow, {paddingVertical: perfectSize(10)}]}
                    >
                        <Text
                            style={[
                                globalStyle.regularText,
                                {marginRight: perfectSize(15)},
                            ]}
                        >
                            {"커뮤니티"}
                        </Text>
                        {isCommunityExtend ? (
                            <Entypo
                                name="chevron-thin-down"
                                size={perfectSize(14)}
                                color={c_grey}
                            />
                        ) : (
                            <Entypo
                                name="chevron-thin-up"
                                size={perfectSize(14)}
                                color={c_grey}
                            />
                        )}
                    </TouchableOpacity>
                    <Collapsible
                        collapsed={isCommunityExtend}
                        style={{paddingLeft: perfectSize(24)}}
                    >
                        {/** tongtong event */}
                        <TouchableOpacity
                            onPress={() => navigation.navigate("Event")}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"이벤트"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => navigation.navigate("Notice")}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"공지사항"}</Text>
                        </TouchableOpacity>
                        {/** tongtong community */}
                        {/*<TouchableOpacity*/}
                        {/*  onPress={() => navigation.navigate("Community")}*/}
                        {/*  style={styles.item}*/}
                        {/*>*/}
                        {/*  <Text style={globalStyle.regularText}>{"커뮤니티"}</Text>*/}
                        {/*</TouchableOpacity>*/}
                        {/** tongtong ticket book */}
                    </Collapsible>


                    <TouchableOpacity
                        onPress={() => setIsMyPageExtend(!isMyPageExtend)}
                        style={[globalStyle.flexRow, {paddingVertical: perfectSize(10)}]}
                    >
                        <Text
                            style={[
                                globalStyle.regularText,
                                {marginRight: perfectSize(15)},
                            ]}
                        >
                            {"마이페이지"}
                        </Text>
                        {isMyPageExtend ? (
                            <Entypo
                                name="chevron-thin-down"
                                size={perfectSize(14)}
                                color={c_grey}
                            />
                        ) : (
                            <Entypo
                                name="chevron-thin-up"
                                size={perfectSize(14)}
                                color={c_grey}
                            />
                        )}
                    </TouchableOpacity>
                    <Collapsible
                        collapsed={isMyPageExtend}
                        style={{paddingLeft: perfectSize(24)}}
                    >
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("Mania_buy_goods", {user: user});
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"구매한 콘텐츠"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("Coupon");
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"초대권"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("ReviewBook");
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"리뷰북"}</Text>
                        </TouchableOpacity>

                        {/** tongtong ticket book */}
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("TicketBook");
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"티켓북"}</Text>
                        </TouchableOpacity>
                        {/** tongtong community */}
                        <TouchableOpacity
                            onPress={() => navigation.navigate("CustomerService")}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"고객센터"}</Text>
                        </TouchableOpacity>
                        {/** tongtong review book */}
                        <TouchableOpacity
                            onPress={() => {
                                if (isAuthenticated) {
                                    navigation.navigate("Profile");
                                } else {
                                    navigation.navigate("SignIn", {screenName: 'Home'});
                                }
                            }}
                            style={styles.item}
                        >
                            <Text style={globalStyle.regularText}>{"회원정보수정"}</Text>
                        </TouchableOpacity>
                        {isAuthenticated ? (
                            <TouchableOpacity
                                onPress={() => {
                                    navigation.navigate("Home");
                                    logout();
                                    oneAlert("로그아웃 되었습니다.");
                                }}
                                style={styles.item}
                            >
                                <Text style={globalStyle.regularText}>{"로그아웃"}</Text>
                            </TouchableOpacity>
                        ) : null}
                    </Collapsible>

                    {/** setting */}
                    <TouchableOpacity
                        onPress={() => navigation.navigate("Setting")}
                        style={styles.item}
                    >
                        <Text style={globalStyle.regularText}>{"설정"}</Text>
                    </TouchableOpacity>

                    {/*{Platform.OS === "web" && (*/}
                    {/*  <TouchableOpacity*/}
                    {/*    onPress={() => navigation.navigate("Setting")}*/}
                    {/*    style={styles.item}*/}
                    {/*  >*/}
                    {/*    <Text style={globalStyle.regularText}>{"APP 다운로드"}</Text>*/}
                    {/*  </TouchableOpacity>*/}
                    {/*)}*/}

                    <TouchableOpacity
                        onPress={() => Linking.openURL("http://partner.tickettong.net/partner/login")}
                        style={styles.item}
                    >
                        <Text style={globalStyle.regularText}>{"파트너센터"}</Text>
                    </TouchableOpacity>
                    {/** 사업자정보 */}
                    <TouchableOpacity
                        onPress={() => navigation.navigate("PolicyBook")}
                        style={styles.item}
                    >
                        <Text style={globalStyle.regularText}>{"사업자정보"}</Text>
                    </TouchableOpacity>
                    {/** banner */}
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate("Detail", {id: banner?.performance?._id});
                        }}
                        style={{
                            backgroundColor: c_light_grey,
                            height: perfectSize(130),
                            marginHorizontal: perfectSize(-26),
                            alignItems: "center",
                            marginVertical: perfectSize(12),
                        }}
                    >
                        <Image
                            source={{uri: banner ? UPLOAD_URL + banner.image : null}}
                            style={{width: "100%", height: perfectSize(130)}}
                        />
                    </TouchableOpacity>
                </View>
            </ScrollView>
            {activityLoading && (
                <View style={styles.activityLoading}>
                    <ActivityIndicator size={"large"} color={c_primary_red}/>
                </View>
            )}
        </SafeAreaView>
    );
}

const mapStateTopProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    banners: state.app.banners,
    nNotification: state.app.nNotification,
});

export default connect(mapStateTopProps, {logout, getWalletBalance})(
    DrawerContent
);

const styles = {
    drawerContent: {
        flex: 1,
    },
    section: {
        paddingTop: perfectSize(25),
        paddingHorizontal: perfectSize(25),
        paddingBottom: perfectSize(20),
    },
    icon: {
        width: perfectSize(18),
        height: perfectSize(18),
        resizeMode: "contain",
        marginRight: perfectSize(15),
    },
    maniaicon: {
        width: perfectSize(43),
        height: perfectSize(18),
        resizeMode: "contain",
        marginLeft: perfectSize(10),
    },
    item: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: perfectSize(12),
    },
    badge: {
        position: "absolute",
        left: perfectSize(8),
        bottom: perfectSize(6),
    },
    spliter: {
        height: perfectSize(5),
        backgroundColor: c_third_grey,
        marginHorizontal: perfectSize(-26),
        marginVertical: perfectSize(12),
    },
    activityLoading: {
        flex: 1,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00000022",
    },
};
