import React, {useEffect, useState} from "react";
import {Image, TouchableOpacity, View, ScrollView, Platform} from "react-native";
import {globalStyle, perfectSize} from "../../variables/styles";
import {
    c_black,
    c_fourth_grey, c_fourth_white,
    c_light_grey,
    c_primary_black,
    c_primary_red,
    c_thrid_white,
    c_white
} from "../../variables/colors";
import {Text} from "react-native-web";
import Button from "../../components/Button";
import PayForm from "../../components/PayForm";
import {TOMATOPAY_LICENSE, TOMATOPAY_MID, TOMATOPAY_RETURN_URL} from "../../variables/constants";

export default function Mania_payInfo({
                                          route,
                                          navigation
                                      }) {
    const sUsrAg = navigator.userAgent;
    const {user, point} = route.params;
    const [rewardPoint, setRewardPoint] = useState(0);

    const prodprice = 50000;

    // pay 파라미터
    const data = {
        mid: TOMATOPAY_MID,
        license: TOMATOPAY_LICENSE,
        user: user?._id, // db id
        userid: user?.decPhoneNum, // one id
        prodname: '[티켓통]매니아 회원 구독',
        phone: user?.decPhoneNum,
        username: user?.name,
        prodprice: prodprice - rewardPoint,
        returnurl: TOMATOPAY_RETURN_URL,
        seq: '',
        subDate: new Date().toISOString(),
        point: rewardPoint // 사용 포인트
    };

    useEffect(() => {
        navigation.setOptions({
            title: '결제 정보',
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    useEffect(() => {
        if (point > 0) {
            const percentage = 70;
            const result = (prodprice * percentage) / 100; // 70퍼센트 금액
            if (point - result > 0) { //70퍼센트 금액 이상포인트 있는경우
                setRewardPoint(35000);
            } else { // 아닌경우
                setRewardPoint(prodprice - point)
            }
        }

    }, [point]);

    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.scrollContainer}>
                    { point > 0 ?
                        <View style={{
                            marginTop: perfectSize(15),
                            marginLeft: perfectSize(15),
                            marginRight: perfectSize(15),
                            backgroundColor: c_fourth_white
                        }}>
                            <Text style={styles.headText}>상품</Text>
                            <Text style={[styles.contentText, {marginTop: perfectSize(10)}]}>[티켓통]매니아 회원 정기구독</Text>
                            <Text style={[styles.headText, {marginTop: perfectSize(50)}]}>구독료</Text>
                            <View style={{flexDirection: 'row', marginTop: perfectSize(10)}}>
                                <Text style={[styles.contentText,{textDecorationLine: 'line-through'}]}>월 5만원</Text><Text style={[styles.contentText, {color: c_primary_red}]}> → 월 1만 5천원</Text>
                            </View>
                            <Text style={styles.contentInfoText}>· 보유한 TTCO가 할인액만큼 차감됩니다.</Text>
                            <Text style={styles.contentInfoText}>· 보유한 TTCO가 모두 소진된 경우 정가로 결제됩니다.</Text>
                            <Text style={styles.contentInfoText}>· 취소시에도 사용된 TTCO는 반환되지 않습니다.</Text>
                        </View> :
                        <View style={{
                            marginTop: perfectSize(15),
                            marginLeft: perfectSize(15),
                            marginRight: perfectSize(15),
                            backgroundColor: c_fourth_white
                        }}>
                            <Text style={styles.headText}>상품</Text>
                            <Text style={[styles.contentText, {marginTop: perfectSize(10)}]}>[티켓통]매니아 회원 정기구독</Text>
                            <Text style={[styles.headText, {marginTop: perfectSize(50)}]}>구독료</Text>
                            <Text style={[styles.contentText, {marginTop: perfectSize(10)}]}>월 5만원</Text>
                        </View>
                    }
                </View>
            </ScrollView>
            {Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                    <View
                        style={[
                            globalStyle.flexRow,
                            {
                                height: perfectSize(65),
                                marginBottom: perfectSize(50),
                            },
                        ]}
                    >
                        <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                                <PayForm
                                    data={data}
                                    label={"결제하기"}
                                    type={"mania"}
                                />
                        </View>
                    </View> :
                    <View
                        style={[
                            globalStyle.flexRow,
                            {
                                height: perfectSize(65),
                            },
                        ]}
                    >
                        <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                                <PayForm
                                    data={data}
                                    label={"결제하기"}
                                    type={"mania"}
                                />
                            {/*<Button*/}
                            {/*    onPress={onItem}*/}
                            {/*    isBorder={true}*/}
                            {/*    textColor={c_white}*/}
                            {/*    label={"결제하기"}*/}
                            {/*    color={c_primary_red}*/}
                            {/*    borderRadius={8}*/}
                            {/*/>*/}
                        </View>
                    </View> :
                <View />
            }
        </View>
        )
}

const styles = {
    scrollContainer: {
        padding: perfectSize(15)
    },
    headText: {
        fontSize: perfectSize(18),
        letterSpacing: -0.18,
        color: c_thrid_white,
        fontWeight: 'bold',
    },
    contentText: {
        fontSize: perfectSize(18),
        letterSpacing: -0.18,
        color: c_black,
        fontWeight: 'bold',
    },
    contentInfoText: {
        fontSize: perfectSize(15),
        letterSpacing: -0.1,
        color: c_fourth_grey,
    }

}