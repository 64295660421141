import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { connect } from 'react-redux';
import {
    View,
    Dimensions,
    TouchableOpacity,
    Text,
    Image,
    ScrollView
} from 'react-native';
import { globalStyle, perfectSize } from '../../../../variables/styles';
import {c_dark_red, c_grey, c_primary_green, c_primary_red, c_white} from '../../../../variables/colors';
import { updateSeat, resetSeat, setTotalPrice, setOrderDetail } from '../../../../store/actions';
import { numberWithCommas, oneAlert } from '../../../../utils';
import Button from '../../../../components/Button';
import SeatMapModal from "../../../../modals/SeatMapModal";

const { width } = Dimensions.get('window');

const Level = ({ performance, roundIndex, selectedSeat, updateSeat, selectedNum, resetSeat, setTotalPrice, setOrderDetail }) => {

    const [isMoreNotice, setMoreNotice] = useState({});
    const navigation = useNavigation();
    const [visibleSeatModal, setVisibleSeatModal] = useState(false);

    useEffect(() => {
        resetSeat();
    }, []);

    const colors =
        {
            VIP: '#9778f1',
            R: '#f56464',
            S: '#ffd56a',
            A: '#72bff6',
            B: '#573ff2',
            C: '#57ff57',
            D: '#2ac4eb',
            E: '#AAC46C',
            '일반': '#FFCCDD'
        }

    const renderSelectedSeat = () => {
        if (!_.isEmpty(selectedSeat)) {
            return selectedSeat.map((item, index) => {
                const seatData = item.split(":");
                return (
                    <View key={index} style={{
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderColor: c_white,
                        borderWidth: 1,
                        paddingHorizontal: perfectSize(12),
                        paddingVertical: perfectSize(8),
                        margin: perfectSize(2)
                    }}>
                        <Text style={{
                            color: c_white,
                            fontSize: perfectSize(11)
                        }}>
                            {`${seatData[4]}석`} {seatData[0] !== '0' && `${seatData[0]}층`} {seatData[1] !== '0' && `${seatData[1]}구역`} {seatData[2] !== '0' && `${seatData[2]}열`} {`${seatData[3]}번`}
                        </Text>
                        <TouchableOpacity onPress={() => {
                            updateSeat(item, false, selectedNum[index]);
                        }}
                                          style={{ width: perfectSize(25) }}>
                            <Image
                                source={require('../../../../../assets/images/ic_close.png')}
                                style={[globalStyle.navIcon, { tintColor: c_white, width: perfectSize(10), marginRight: 0 }]}
                            />
                        </TouchableOpacity>
                    </View>
                )
            })
        }
    };

    const renderSelectedSeatCount = () => {
        if (!_.isEmpty(selectedSeat)) {
            let count = {};
            selectedSeat.map((item, index) => {
                const seatData = item.split(":");
                count[seatData[4]] = count[seatData[4]] ? count[seatData[4]] + 1 : 1;
            });
            const keys = Object.keys(count);
            const vals = Object.values(count);
            return vals.map((item, index) => {
                return <Text style={{ fontSize: perfectSize(12), color: c_white, marginRight: perfectSize(10) }} key={index}>
                    {`${keys[index]}석 ${item}매`}
                </Text>
            });
        } else {
            return <Text style={{ fontSize: perfectSize(14), fontWeight: 'bold', color: c_white, marginRight: perfectSize(10) }}>
                {'선택좌석 없음'}
            </Text>
        }
    }

    const renderSeatLevels = () => {
        return isMoreNotice &&
            <View style={{
                maxHeight: perfectSize(100)
            }}>
                <ScrollView showsHorizontalScrollIndicator={false}>
                    <View style={{
                        // flexDirection: 'row',
                        // flexWrap: 'wrap',
                        // justifyContent: 'space-between',
                        paddingHorizontal: perfectSize(15),
                        paddingBottom: perfectSize(15),
                    }}>
                        {renderSelectedSeat()}
                    </View>
                    <View>
                        <Text style={styles.seatType}>
                            {'좌석등급'}
                        </Text>
                        <View style={styles.seatDetails}>
                            {
                                performance.prices.prices.map(price =>
                                    <View style={styles.seatDetail} key={price._id}>
                                        <View style={[styles.colorView, { backgroundColor: colors[price.grade] }]} />
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, paddingRight: perfectSize(20) }}>
                                            <Text style={styles.priceView}>{`${price.grade}석`}</Text>
                                            <Text style={styles.priceView}>{`${numberWithCommas(price.price)}원`}</Text>
                                        </View>
                                    </View>
                                )
                            }
                        </View>
                    </View>
                </ScrollView>
            </View>

    }

    const onNext = () => {
        if (selectedSeat.length) {
            navigation.navigate('Order', { performance, roundIndex });
        } else {
            oneAlert('좌석을 선택해주세요')
        }
    }

    return (
        <View style={[globalStyle.flex, {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#00000099',
            paddingBottom: perfectSize(30)
        }]}>
            <View style={{
                height: perfectSize(20),
                backgroundColor: c_white,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <TouchableOpacity
                    onPress={() => setVisibleSeatModal(true)}
                    style={{
                        width: perfectSize(160),
                        height: perfectSize(35),
                        backgroundColor: c_white,
                }}>
                    <Image
                        source={require('../../../../../assets/images/btn_large.svg')}
                        style={{
                            width: perfectSize(160),
                            height: perfectSize(28),
                        }}
                    />
                </TouchableOpacity>
            </View>
            <View style={{
                height: perfectSize(20),
                backgroundColor: c_white,
            }} />
            <View style={[globalStyle.flex, {
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: perfectSize(15),
                paddingVertical: perfectSize(12),
            }]}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {renderSelectedSeatCount()}
                </View>
                <TouchableOpacity
                    onPress={() => setMoreNotice(!isMoreNotice)}
                    style={{
                        width: perfectSize(20),
                        height: perfectSize(20),
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <Image
                        source={require('../../../../../assets/images/ic_more_detail.png')}
                        style={[styles.loadMoreBtnIcon, { transform: [{ rotate: isMoreNotice ? '0deg' : '180deg' }], tintColor: c_white }]}
                    />
                </TouchableOpacity>
            </View>
            {renderSeatLevels()}
            <View style={{
                flex: 1,
                paddingHorizontal: perfectSize(15),
                paddingBottom: perfectSize(15),
                paddingTop: perfectSize(3)
            }}>
                <Button
                    onPress={onNext}
                    textColor={c_white}
                    label={`다음 (총 ${selectedSeat.length}매)`}
                    color={c_primary_red}
                    isBorder={false}
                    disable={false}
                    borderRadius={6}
                />
            </View>
            {visibleSeatModal &&
                <SeatMapModal
                    isVisible={visibleSeatModal}
                    hideModal={() => {
                        setVisibleSeatModal(false);
                    }}
                    performance={performance}
                    roundIndex={roundIndex}
                />
            }
        </View>
    );
};

const mapStateTopProps = state => ({
    selectedSeat: state.seat.selectedSeat,
    selectedNum: state.seat.selectedNum
});

export default connect(mapStateTopProps, { updateSeat, resetSeat, setTotalPrice, setOrderDetail })(Level);

const styles = {
    seatDetails: {
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    seatType: {
        color: c_white,
        fontSize: perfectSize(14),
        marginBottom: perfectSize(10),
        paddingLeft: perfectSize(15),
        fontWeight: 'bold'
    },
    loadMoreBtnIcon: {
        width: perfectSize(12),
        height: perfectSize(7),
        resizeMode: 'contain',
    },
    seatDetail: {
        flexDirection: 'row',
        width: '50%',
        alignItems: 'center',
        paddingLeft: perfectSize(15),
        paddingBottom: perfectSize(12)
    },
    colorView: {
        backgroundColor: 'red',
        width: perfectSize(12),
        height: perfectSize(12),
        marginRight: perfectSize(6),
        borderRadius: perfectSize(2)
    },
    priceView: {
        color: c_white,
        fontSize: perfectSize(12)
    },
}