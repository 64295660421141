import _ from 'lodash';
import { instance, oneAlert } from '../../utils';
import {
  COIN_LOAD_START,
  COIN_LOAD_FAIL,
  TTMI_COIN_LOAD_SUCCESS,
  TTMI_COIN_LIST_RESET,
  TTM_COIN_LOAD_SUCCESS,
  TTM_COIN_LIST_RESET,
} from '../../variables/constants';

export const TTMICoinLoadSuccess = (data) => ({
  type: TTMI_COIN_LOAD_SUCCESS,
  payload: data
});

export const TTMCoinLoadSuccess = (data) => ({
  type: TTM_COIN_LOAD_SUCCESS,
  payload: data
});

export const coinLoadStart = () => ({
  type: COIN_LOAD_START
});

export const coinLoadFail = () => ({
  type: COIN_LOAD_FAIL
});

export const TTMICoinListReset = () => ({
  type: TTMI_COIN_LIST_RESET
});

export const TTMCoinListReset = () => ({
  type: TTM_COIN_LIST_RESET
});

export const coinList = (coinType, type) => {
  return (dispatch, getState) => {

    dispatch(coinLoadStart());

    const { user } = getState().auth;
    if (!user) {
      dispatch(coinLoadFail());
      return;
    }

    instance.get(`api/tongtongcoin/getCoinCheckoutListByUser/${user._id}?type=${type}`)
      .then(res => {
        if (coinType === 'TTM') {
          dispatch(TTMCoinLoadSuccess(res.data.data));
        } else {
        dispatch(TTMICoinLoadSuccess(res.data.data));
        }
      })
      .catch(err => {
        if (err.response) {
          oneAlert(err.response.data.message);
        }
        dispatch(coinLoadFail());
      });
  }
}