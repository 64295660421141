import React, {useEffect, useState} from "react";
import {Image, Platform, ScrollView, Text, TextInput, TouchableOpacity, View} from "react-native";
import {globalStyle, perfectSize} from "../../variables/styles";
import {c_fourth_white, c_light_grey, c_primary_black, c_primary_red, c_white} from "../../variables/colors";
import ModalDropdown from "react-native-modal-dropdown";
import Button from "../../components/Button";
import {setGift} from "../../store/actions";
import PostCodeModal from "../../modals/PostCodeModal";
import {oneAlert} from "../../utils";

export default function Mania_gift({
                                       route,
                                       navigation
                                   }) {
    const sUsrAg = navigator.userAgent;
    const {user} = route.params;

    const giftList1 = ['선택', '통통생활세트', '비타올인원(4개 x 1L)', '쌀(16kg)', '쌀(8kg)', '특별감사선물(랜덤배정)'];
    const giftList2 = ['선택', '통통생활세트', '비타올인원(4개 x 1L)', '쌀(16kg)', '쌀(8kg)', '특별감사선물(랜덤배정)'];
    const [sortType1, setSortType1] = useState(0);
    const [sortType2, setSortType2] = useState(0);

    const [visibleModal, setVisibleModal] = useState(false);
    const [postCode, setPostCode] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');


    const onItem = () => {
        if (postCode === '') {
            oneAlert('주소지를 입력해주세요.')
            return;
        }
        if (sortType1 === sortType2) {
            oneAlert('다른종류의 선물을 선택해주세요.')
            return;
        }
        setGiftData();
    };
    const setGiftData = async () => {
        await setGift(user?._id, postCode, address1, address2, giftList1[sortType1], giftList2[sortType2]).then(
            res => {
                if (res.status === 1) {
                    navigation.navigate('ManiaPayReturn');
                    oneAlert('사은품 등록이 완료되었습니다.');
                } else {
                    oneAlert('등록에 실패하였습니다.')
                }
            }
        ).catch(err => {
            console.log('setGift', err);
            oneAlert('등록에 실패하였습니다.');
        })
    }
    useEffect(() => {
        navigation.setOptions({
            title: '사은품 선택 및 주소입력',
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    const onSearchAddress = () => {
        setVisibleModal(true);
    }

    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.scrollContainer}>
                    <View style={{
                        marginTop: perfectSize(15),
                        marginLeft: perfectSize(15),
                        marginRight: perfectSize(15),
                        backgroundColor: c_fourth_white
                    }}>
                        <View style={{marginTop: perfectSize(15), flexDirection: 'row'}}>
                            <Text style={styles.headSubText}>사은품 선택</Text>
                            <Image
                                source={require('../../../assets/images/gift_point.png')}
                                style={{width: perfectSize(6), height: perfectSize(6), marginLeft: perfectSize(2)}}
                            />
                        </View>


                        <View style={{marginTop: perfectSize(15),}}>
                            <Text style={styles.headText}>두 종류의 사은품을 선택해주세요.</Text>
                        </View>
                    </View>
                    <View style={{
                        marginTop: perfectSize(15),
                        marginLeft: perfectSize(15),
                        marginRight: perfectSize(15),
                        backgroundColor: c_fourth_white
                    }
                    }>

                    </View>

                    <View style={{
                        marginTop: perfectSize(15),
                        marginLeft: perfectSize(15),
                        marginRight: perfectSize(15),
                        backgroundColor: c_fourth_white
                    }
                    }>
                        <View style={{
                            borderWidth: 1, // 테두리 두께
                            borderColor: 'black', // 테두리 색상
                            borderRadius: 0, // 테두리 둥글기
                            padding: 15, // View 안쪽 여백
                        }}>
                            <ModalDropdown
                                onSelect={index => setSortType1(index)}
                                options={giftList1}
                                defaultIndex={0}
                                defaultValue={giftList1[0]}
                                renderSeparator={() => <View/>}
                                dropdownStyle={{
                                    width: perfectSize(300),
                                    height: perfectSize(40 * giftList1.length),
                                    marginTop: perfectSize(8),
                                }}
                                dropdownTextStyle={globalStyle.dropdownTextStyle}
                                dropdownTextHighlightStyle={{color: c_primary_red}}
                                textStyle={globalStyle.regularText}
                                renderRightComponent={() =>
                                    <Image
                                        source={require('../../../assets/images/ic_list_order.png')}
                                        style={globalStyle.dropdownRightImage}/>
                                }
                                style={{
                                    width: '100%',
                                }}
                            />
                        </View>

                        <View style={{
                            borderWidth: 1, // 테두리 두께
                            borderColor: 'black', // 테두리 색상
                            borderRadius: 0, // 테두리 둥글기
                            padding: 15, // View 안쪽 여백
                            marginTop: perfectSize(6)
                        }}>
                            <ModalDropdown
                                onSelect={index => setSortType2(index)}
                                options={giftList2}
                                defaultIndex={0}
                                defaultValue={giftList2[0]}
                                renderSeparator={() => <View/>}
                                dropdownStyle={{
                                    width: perfectSize(300),
                                    height: perfectSize(40 * giftList2.length),
                                    marginTop: perfectSize(8),
                                }}
                                dropdownTextStyle={globalStyle.dropdownTextStyle}
                                dropdownTextHighlightStyle={{color: c_primary_red}}
                                textStyle={globalStyle.regularText}
                                renderRightComponent={() =>
                                    <Image
                                        source={require('../../../assets/images/ic_list_order.png')}
                                        style={globalStyle.dropdownRightImage}/>
                                }
                                style={{
                                    width: '100%',
                                }}
                            />
                        </View>
                        <View>
                            <View style={{marginTop: perfectSize(15)}}>
                                <Text style={[styles.headSubText, {marginBottom: perfectSize(5)}]}>
                                    {'수령 주소입력'}
                                </Text>
                                <View style={globalStyle.flexRow}>
                                    <TextInput
                                        value={postCode}
                                        onChangeText={text => setPostCode(text)}
                                        placeholder={'우편번호'}
                                        style={styles.textInput}
                                        editable={false}
                                    />
                                    <TouchableOpacity
                                        onPress={onSearchAddress}
                                        style={styles.button}>
                                        <Text style={styles.buttonLabel}>
                                            {'주소찾기'}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                <TextInput
                                    value={address1}
                                    onChangeText={text => setAddress1(text)}
                                    placeholder={'주소'}
                                    style={[styles.textInput, {marginVertical: perfectSize(10)}]}
                                    editable={false}
                                />
                                <TextInput
                                    value={address2}
                                    onChangeText={text => setAddress2(text)}
                                    placeholder={'상세주소'}
                                    style={[styles.textInput, {marginVertical: perfectSize(10)}]}
                                    // editable={false}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView>
            {Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                    <View
                        style={[
                            globalStyle.flexRow,
                            {
                                height: perfectSize(65),
                                marginBottom: perfectSize(50),
                            },
                        ]}
                    >
                        <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                            <Button
                                onPress={onItem}
                                isBorder={true}
                                textColor={c_white}
                                label={"다음"}
                                color={c_primary_red}
                                borderRadius={8}
                            />
                        </View>
                    </View> :
                    <View
                        style={[
                            globalStyle.flexRow,
                            {
                                height: perfectSize(65),
                            },
                        ]}
                    >
                        <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                            <Button
                                onPress={onItem}
                                isBorder={true}
                                textColor={c_white}
                                label={"다음"}
                                color={c_primary_red}
                                borderRadius={8}
                            />
                        </View>
                    </View> :
                <View/>
            }
            <PostCodeModal
                isVisible={visibleModal}
                hideModal={() => setVisibleModal(false)}
                onSelect={address => {
                    setPostCode(address.zonecode);
                    setAddress1(address.roadAddress);
                }}
            />
        </View>
    )
}

const styles = {
    scrollContainer: {
        padding: perfectSize(15)
    },
    headText: {
        fontSize: perfectSize(18),
        letterSpacing: -0.18,
        color: "#222222",
    },
    headSubText: {
        fontSize: perfectSize(15),
        letterSpacing: -0.1,
        color: "#222222",
        fontWeight: 'bold'
    },
    contentText: {
        fontSize: perfectSize(12),
        letterSpacing: -0.12,
        color: "#222222"
    },
    contentInfoText: {
        fontSize: perfectSize(12),
        letterSpacing: -0.12,
        color: "#999999"
    },
    textInput: {
        fontSize: perfectSize(14),
        letterSpacing: -0.15,
        color: c_primary_black,
        flex: 1,
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(6),
        paddingHorizontal: perfectSize(15),
        padding: perfectSize(16),
        marginRight: perfectSize(5),
    },
    button: {
        width: perfectSize(70),
        height: perfectSize(46),
        borderRadius: perfectSize(6),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#555555'
    },
    buttonLabel: {
        fontWeight: 'bold',
        fontSize: perfectSize(13),
        color: c_white
    },

}