import React from 'react';
import {
    View,
    Text,
    Image,
} from 'react-native';
import { globalStyle, perfectSize } from '../../../variables/styles';
import {
    c_grey,
    c_primary_red,
    c_second_white,
    c_white
} from '../../../variables/colors';

export default function AdditionalInfo({ onLayout }) {
    return (
        <View onLayout={onLayout} style={styles.mainContainer}>

            {/** 기획사정보 */}
            {/* <Text style={globalStyle.regularBoldText}>
                {'기획사정보'}
            </Text>
            <View style={{ marginTop: perfectSize(15) }}>
                <Text style={{ ...globalStyle.regularText, marginBottom: perfectSize(5) }}>
                    {'주최: 토마토엔터테인먼트'}
                </Text>
                <Text style={{ ...globalStyle.regularText, marginBottom: perfectSize(5) }}>
                    {'제작: 아르떼티비'}
                </Text>
                <Text style={{ ...globalStyle.regularText, marginBottom: perfectSize(5) }}>
                    {'투자: SK브로드밴드'}
                </Text>
                <Text style={{ ...globalStyle.regularText, marginBottom: perfectSize(5) }}>
                    {'관련문의: 02-2265-7651'}
                </Text>
            </View>

            <View style={{
                marginTop: perfectSize(20),
                marginBottom: perfectSize(30)
            }}>
                <View style={{ flexDirection: 'row', marginTop: perfectSize(-1) }}>
                    <View style={styles.tableLeft}>
                        <Text style={globalStyle.smText}>{'기획/제작'}</Text>
                    </View>
                    <View style={styles.tableRight}>
                        <Text style={globalStyle.smText}>{'토마토엔터테인먼트'}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', marginTop: perfectSize(-1) }}>
                    <View style={styles.tableLeft}>
                        <Text style={globalStyle.smText}>{'공연시간'}</Text>
                    </View>
                    <View style={styles.tableRight}>
                        <Text style={globalStyle.smText}>{'150분(인터미션 20분)'}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', marginTop: perfectSize(-1) }}>
                    <View style={styles.tableLeft}>
                        <Text style={globalStyle.smText}>{'공연장소'}</Text>
                    </View>
                    <View style={styles.tableRight}>
                        <Text style={globalStyle.smText}>{'예술의전당 IBK챔버홀'}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', marginTop: perfectSize(-1) }}>
                    <View style={styles.tableLeft}>
                        <Text style={globalStyle.smText}>{'관람등급'}</Text>
                    </View>
                    <View style={styles.tableRight}>
                        <Text style={globalStyle.smText}>{'모바일/현장수령무료\n배송 2800원'}</Text>
                    </View>
                </View>
            </View> */}

            {/** 티켓수령안내*/}
            {/* <Text style={globalStyle.regularBoldText}>
                {'기획사정보'}
            </Text>
            <View style={{ marginTop: perfectSize(15) }}>
                <Text style={{ ...globalStyle.regularText, marginBottom: perfectSize(5) }}>
                    {'[모바일티켓]'}
                </Text>
                <Text style={{ ...globalStyle.regularText, marginBottom: perfectSize(5) }}>
                    {'QR코드가 표시되어 있는 모바일티켓을 매표소에 제출하시면 편리하게 입장 하실수 있습니다'}
                </Text>
                <Text style={{ ...globalStyle.regularText, marginBottom: perfectSize(25) }}>
                    {'[모바일티켓]'}
                </Text>
                <Text style={{ ...globalStyle.regularText, marginBottom: perfectSize(5) }}>
                    {'QR코드가 표시되어 있는 모바일티켓을 매표소에 제출하시면 편리하게 입장 하실수 있습니다'}
                </Text>
            </View> */}
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(30),
        paddingHorizontal: perfectSize(15)
    },
    tableRight: {
        height: perfectSize(35),
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderColor: c_second_white,
        borderWidth: 1,
        flex: 1,
        marginLeft: -1,
        paddingLeft: perfectSize(9)
    },
    tableLeft: {
        width: perfectSize(101),
        height: perfectSize(35),
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: c_second_white,
        borderWidth: 1,
    },
}