import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  StyleSheet,
  Text,
} from "react-native";
import { c_grey, c_primary_red } from "../../../variables/colors";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { useNavigation } from "@react-navigation/native";
import ModalDropdown from "react-native-modal-dropdown";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { getNFTs } from "../../../store/actions/performance";
import { UPLOAD_URL } from "../../../variables/constants";
import { numberWithCommas } from "../../../utils";
import { useSelector } from "react-redux";

const dropdownList = ["최신순", "가격 낮은순", "가격 높은순"];

const NFTList = props => {
  const { searchText, type, typeLabel } = props;
  const { coinData } = useSelector(state => state).app;
  const navigation = useNavigation();
  const [sortType, setSortType] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setPage(1);
    getData(1);
  }, [searchText, sortType]);

  const getData = page => {
    getNFTs({
      page,
      limit: 6,
      searchWord: searchText,
      type,
      sortType,
      isForSale: true,
    })
      .then(res => {
        if (res.data.nfts) {
          setTotal(res.data.total);
          if (page === 1) {
            setData(res.data.nfts);
          } else {
            setData([...data, ...res.data.nfts]);
          }
        }
      })
      .catch(err => console.error(err));
  };

  if (_.isEmpty(data)) return null;

  const onLoadMore = () => {
    getData(page + 1);
    setPage(page + 1);
  };

  const onPress = item => {
    navigation.navigate("NFTMolDetail", { id: item._id });
  };

  return (
    <View>
      <View style={styles.container}>
        <Text style={{ fontSize: perfectSize(15) }}>
          {`${typeLabel} 총 ${total}개`}
        </Text>
        <ModalDropdown
          onSelect={index => setSortType(index)}
          options={dropdownList}
          defaultIndex={0}
          defaultValue={dropdownList[0]}
          renderSeparator={() => <View />}
          dropdownStyle={{
            width: perfectSize(80),
            height: perfectSize(40 * dropdownList.length),
          }}
          dropdownTextStyle={globalStyle.dropdownTextStyle}
          dropdownTextHighlightStyle={{ color: c_primary_red }}
          textStyle={globalStyle.regularText}
          renderRightComponent={() => (
            <Image
              source={require("../../../../assets/images/ic_list_order.png")}
              style={globalStyle.dropdownRightImage}
            />
          )}
          style={globalStyle.dropdownStyle}
        />
      </View>
      <View style={styles.list}>
        {data.map((item, index) => {
          return (
            <TouchableOpacity
              onPress={() => onPress(item)}
              key={"nft_list_" + index}
              style={styles.itemWrapper}
            >
              <Image
                source={{ uri: UPLOAD_URL + item.content.thumbnail }}
                style={{
                  width: "100%",
                  height: perfectSize(100),
                  borderTopLeftRadius: perfectSize(15),
                  borderTopRightRadius: perfectSize(15),
                }}
              />
              <View style={styles.detail}>
                <Text style={styles.title} numberOfLines={2}>
                  {item.content?.title}
                </Text>
                <View style={styles.bottomWrapper}>
                  <View>
                    <Text style={styles.count}>
                      {`판매수량 ${item.count - item.list.length}`}
                    </Text>
                    <Text style={styles.count}>{`발행수량 ${item.count}`}</Text>
                  </View>
                  <View>
                    <View style={globalStyle.flexRow}>
                      <Image
                        source={{
                          uri: "https://twallet.tongtongchain.io/images/api/ttcoin.png",
                        }}
                        style={styles.icon}
                      />
                      <Text style={styles.price}>
                        {numberWithCommas(
                          String(
                            Number(
                              (item.price / coinData?.meni?.coin_price) *
                                coinData?.coin.coin_price || 0
                            ).toFixed(2)
                          )
                        )}
                      </Text>
                    </View>
                    {item.isEnabledTTM && (
                      <View style={globalStyle.flexRow}>
                        <Image
                          source={{
                            uri: "https://twallet.tongtongchain.io/images/api/gtc.png",
                          }}
                          style={styles.icon}
                        />
                        <Text style={styles.price}>
                          {Number(numberWithCommas(item.price)).toFixed(2)}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
      {total !== data.length && <LoadMoreButton onPress={onLoadMore} />}
    </View>
  );
};

export default NFTList;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  list: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  itemWrapper: {
    width: "49%",
    borderRadius: perfectSize(15),
    height: perfectSize(200),
    shadowColor: c_grey,
    shadowOffset: { x: 2, y: 6 },
    shadowRadius: 4,
    shadowOpacity: 0.3,
    elevation: 2,
    marginBottom: perfectSize(10),
  },
  title: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(19.6),
    letterSpacing: -0.2,
    color: "#111111",
  },
  detail: {
    padding: perfectSize(10),
    justifyContent: "space-between",
    height: perfectSize(100),
    borderBottomRightRadius: perfectSize(15),
    borderBottomLeftRadius: perfectSize(15),
    backgroundColor: "white",
  },
  count: {
    color: c_grey,
    fontSize: perfectSize(10),
  },
  price: {
    fontSize: perfectSize(10),
  },
  bottomWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    width: perfectSize(10),
    height: perfectSize(10),
    resizeMode: "contain",
    marginRight: perfectSize(3),
  },
});
