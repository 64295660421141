import React from 'react';
import { View, Text } from 'react-native';
import {
    c_primary_black,
    c_white,
    c_primary_red,
    c_grey
} from '../variables/colors';
import { perfectSize } from '../variables/styles';

export default function Badge(props) {
    return (
        <View style={[styles.container, props.style]}>
            <Text style={[styles.label, props.labelStyle]}>{props.number}</Text>
        </View>
    )
}

const styles = {
    container: {
        backgroundColor: c_primary_red,
        height: perfectSize(17),
        borderRadius: perfectSize(9),
        minWidth: perfectSize(17),
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: perfectSize(4)
    },
    label: {
        color: c_white,
        fontSize: perfectSize(14)
    }
}