import _ from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { UPLOAD_URL } from "../../../variables/constants";
import { c_third_grey } from "../../../variables/colors";
import { useNavigation } from "@react-navigation/native";

export const AudioItem = props => {
  const {} = useSelector(state => state);
  const { type, audio, disable } = props;

  const dispatch = useDispatch();
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      disabled={disable}
      onPress={() => {
        navigation.navigate("AudioPlayer", { audio: audio });
      }}
      style={globalStyle.flexSpaceBetween}
    >
      <View style={[globalStyle.flexRow, { maxWidth: "65%" }]}>
        <Image
          source={{ uri: UPLOAD_URL + audio.content?.thumbnail }}
          style={styles.thumbnail}
        />
        <View>
          <Text style={styles.title} numberOfLines={1}>
            {audio.content?.title}
          </Text>
          <Text style={styles.artist}>{audio.content?.category}</Text>
        </View>
      </View>
      <Image
        source={require("../../../../assets/images/icon_mov_red.png")}
        style={styles.audioIcon}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  thumbnail: {
    width: perfectSize(60),
    height: perfectSize(60),
    borderRadius: perfectSize(4),
    backgroundColor: c_third_grey,
    marginRight: perfectSize(10),
    resizeMode: "contain",
  },
  title: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(19.6),
    letterSpacing: -0.2,
    color: "#111111",
  },
  artist: {
    fontSize: perfectSize(12),
    lineHeight: perfectSize(16.8),
    letterSpacing: -0.2,
    color: "#777777",
  },
  audioIcon: {
    width: perfectSize(28),
    height: perfectSize(28),
    resizeMode: "contain",
  },
});
