import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    Dimensions,
    ScrollView
} from 'react-native';
import Modal from 'react-native-modal';
import moment from 'moment';

import { globalStyle, perfectSize } from '../variables/styles';
import {
    c_light_grey,
    c_primary_red,
} from '../variables/colors';
import { numberWithCommas, oneAlert } from '../utils';

const { height } = Dimensions.get('window');

export default function CouponModal({
    isVisible,
    hideModal,
    data,
    selectedCoupon,
    performanceId,
    totalPrice,
}) {

    return (
        <Modal
            animationIn="slideInUp"
            animationOut="slideOutDown"
            style={{ margin: 0 }}
            isVisible={isVisible}>
            <View style={styles.swipeModal}>
                <TouchableOpacity
                    onPress={hideModal}
                    style={globalStyle.flex}>
                </TouchableOpacity>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    <View style={[globalStyle.flexSpaceBetween, styles.headerContainer]}>
                        <Text style={[globalStyle.bigBoldText, globalStyle.flex]}>
                            {'쿠폰'}
                        </Text>
                        {/** close */}
                        <TouchableOpacity
                            onPress={hideModal}>
                            <Image
                                source={require('../../assets/images/ic_close.png')}
                                style={styles.closeIcon}
                            />
                        </TouchableOpacity>
                    </View>

                    <ScrollView>
                        <TouchableOpacity
                            onPress={() => {
                                hideModal(false);
                                selectedCoupon(null, null);
                            }}
                            style={[styles.cardSection,
                            {
                                paddingVertical: perfectSize(15),
                                backgroundColor: '#f9f9f9'
                            }]}>
                            <Text style={globalStyle.regularText}>
                                {'사용안함'}
                            </Text>
                        </TouchableOpacity>

                        {
                            data.map((item, index) => {
                                const {
                                    name,
                                    discount_type,
                                    discount_amount,
                                    limit_amount,
                                    from,
                                    to,
                                } = item;
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (!item.isToAll && !item.product_list.includes(performanceId)) {
                                                oneAlert('선택한 쿠폰은 이 공연에서 사용할수 없습니다.');
                                            } else if (item.limit_amount > totalPrice) {
                                                oneAlert(`쿠폰적용가능한 최소금액은 ${numberWithCommas(item.limit_amount)}원입니다.`);
                                            } else {
                                                hideModal(false);
                                                selectedCoupon(item, index);
                                            }
                                        }}
                                        style={styles.cardSection}
                                        key={index}>
                                        <View style={globalStyle.flexRow}>
                                            <Text style={styles.price}>
                                                {discount_type === '할인율' ? discount_amount + '%할인' : numberWithCommas(discount_amount) + '원'}
                                                {/* <Text style={{ fontSize: perfectSize(12) }}>
                                                    {'원'}
                                                </Text> */}
                                            </Text>
                                            <Text style={globalStyle.smText}>
                                                {name}
                                            </Text>
                                        </View>
                                        <Text style={[globalStyle.smGreyText, { marginTop: perfectSize(8) }]}>
                                            {`유효기간 : ${moment(from).format('YYYY.MM.DD')} ~ ${moment(to).format('YYYY.MM.DD')}`}
                                        </Text>
                                    </TouchableOpacity>
                                )
                            })
                        }

                    </ScrollView>
                </View>
            </View>
        </Modal>
    )
}

const styles = {
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        alignItems: 'stretch',
        paddingHorizontal: perfectSize(20),
        paddingTop: 0,
        paddingBottom: perfectSize(20),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: perfectSize(16),
        borderTopLeftRadius: perfectSize(16),
        maxHeight: height / 4 * 3
    },
    headerContainer: {
        marginVertical: perfectSize(15),
        marginTop: perfectSize(25)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
    cardSection: {
        borderWidth: 1,
        borderRadius: perfectSize(4),
        borderColor: c_light_grey,
        padding: perfectSize(15),
        paddingVertical: perfectSize(12),
        marginBottom: perfectSize(5)
    },
    price: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        color: c_primary_red,
        letterSpacing: -0.16,
        marginRight: perfectSize(10)
    }
}