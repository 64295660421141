import {
    ORDER_LOAD_START,
    ORDER_LOAD_SUCCESS,
    ORDER_LOAD_FAIL,
    ORDER_LIST_RESET,
    ORDER_PAGE_CHANGE,
} from '../../variables/constants';

const initialState = {
    availableOrders: [],
    cancelledOrders: [],
    ordersLoading: false,
    ordersPage: 1,
    totalAvailalbeOrders: 0,
    totalCancelledOrders: 0,
}

export default function OrderReducer(state = initialState, action) {
    switch (action.type) {

        case ORDER_LOAD_START:
            return state = { ...state, ordersLoading: true };

        case ORDER_LOAD_SUCCESS:
            return state = {
                ...state,
                availableOrders: [...state.availableOrders, ...action.payload.available_tickets],
                cancelledOrders: [...state.cancelledOrders, ...action.payload.cancelled_tickets],
                ordersLoading: false,
                totalAvailalbeOrders: action.payload.available_cnt,
                totalCancelledOrders: action.payload.cancelled_cnt
            };

        case ORDER_LIST_RESET:
            return state = { ...state, availableOrders: [], cancelledOrders: [], ordersPage: 1 };

        case ORDER_LOAD_FAIL:
            return state = { ...state, ordersLoading: false };

        case ORDER_PAGE_CHANGE:
            return state = { ...state, ordersPage: action.payload };

        default:
            return state;
    }
}