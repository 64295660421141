import React, { useEffect, useMemo, useState } from "react";
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  Platform,
  NativeModules,
  Linking,
  ActivityIndicator,
  SafeAreaView,
} from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import {
  c_grey,
  c_light_grey,
  c_primary_red,
  c_second_grey,
  c_third_grey,
  c_white,
} from "../../../variables/colors";
import WebView from "react-native-webview";
import Loader from "../../../components/Loader";
import { UPLOAD_URL } from "../../../variables/constants";
import { instance, numberWithCommas, oneAlert, twoAlert } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import AntDesign from "@expo/vector-icons/AntDesign";
// import YoutubePlayer from "react-native-youtube-iframe";
import { isEmpty } from "lodash";
import BuyNFTModal from "../../../modals/BuyNFTModal";
import NoWalletModal from "../../../modals/NoWalletModal";
import { getWalletBalance, updateWallet } from "../../../store/actions";
// import { Viro360Video } from "@viro-community/react-viro";

const dropdownList = [
  "https://twallet.tongtongchain.io/images/api/ttcoin.png",
  "https://twallet.tongtongchain.io/images/api/gtc.png",
];

function RadioButton(props) {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[globalStyle.flexRow, props.style]}
    >
      <View
        style={[
          {
            height: 18,
            width: 18,
            borderRadius: 12,
            borderWidth: 2,
            borderColor: c_primary_red,
            alignItems: "center",
            justifyContent: "center",
          },
        ]}
      >
        {props.selected ? (
          <View
            style={{
              height: 10,
              width: 10,
              borderRadius: 6,
              backgroundColor: c_primary_red,
            }}
          />
        ) : null}
      </View>
      <Text style={{ fontSize: perfectSize(12), marginLeft: perfectSize(6) }}>
        {props.label}
      </Text>
    </TouchableOpacity>
  );
}

export default function SellNFT({ route, navigation }) {
  const { data } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [coinType, setCoinType] = useState(0);
  const [isVisibleCoinSelect, setIsVisiblecoinSelect] = useState(false);
  const [price, setPrice] = useState("");
  const [isEnabledTTM, setIsEnabledTTM] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const { coinData } = useSelector(state => state).app;
  const { user } = useSelector(state => state).auth;
  const dispatch = useDispatch();

  useEffect(() => {
    navigation.setOptions({
      title: "NFT몰",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../../assets/images/ic_before_stroke.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
    });
  }, []);

  const runFirst = `
      document.body.style.backgroundColor = 'white';
      true; // note: this is required, or you'll sometimes get silent failures
    `;

  const type = data.asset?.asset_type;

  const uri = useMemo(() => {
    if (type === "video") {
      return data.asset?.type === "upload" ? UPLOAD_URL + data.url : data.url;
    }
    if (type === "vr360") {
      return `https://admin.tickettong.net/vr360-player?id=${data._id}`;
    }
    if (type === "flac") {
      return `https://admin.tickettong.net/audio-player?id=${data.asset._id}`;
    }
    if (type === "dsd") {
      return `https://admin.tickettong.net/audio-player?id=${data.asset._id}`;
    }
  }, [data]);

  const coinPrice = useMemo(() => {
    return coinType
      ? (
          (parseFloat(price || 0) * parseFloat(coinData?.coin.coin_price)) /
          parseFloat(coinData?.mile.coin_price)
        ).toFixed(2)
      : price;
  }, [data, coinData, coinType, price]);

  const coinWonPrice = useMemo(() => {
    return numberWithCommas(
      String(parseFloat(coinPrice || 0) * parseFloat(coinData?.coin.coin_price))
    );
  }, [data, coinPrice]);

  if (isEmpty(data)) return null;

  const onSellNFT = () => {
    twoAlert("판매 등록하시겠습니까?", () => {
      if (!price || !isTerms) {
        oneAlert("판매가를 기입하거나\n판매 약관에 동의해주세요.");
      } else {
        setIsLoadingRegister(true);
        const req_body = {
          isEnabledTTM,
          price: coinPrice,
          count: 1,
          userId: user._id,
          nftId: data._id,
        };
        instance
          .post(`api/nft/register-sale`, req_body)
          .then(res => {
            setIsLoadingRegister(false);
            oneAlert(
              "판매 등록이 완료되었습니다.",
              "",
              navigation.navigate("NFTDetail")
            );
          })
          .catch(err => {
            setIsLoadingRegister(false);
            oneAlert("등록이 실패하였습니다.");
          });
      }
    });
  };

  return (
    <SafeAreaView
      style={styles.container}
      activeOpacity={1}
      onPress={() => setIsVisiblecoinSelect(false)}
    >
      <ScrollView
        style={{ width: "100%" }}
        showsVerticalScrollIndicator={false}
      >
        {type !== "vr360" && type !== "video" && (
          <>
            <Text style={styles.title}>{data.content?.title}</Text>
            <Image
              source={{ uri: UPLOAD_URL + data.content?.thumbnail }}
              style={styles.thumbnail}
            />
          </>
        )}
        <View
          style={{
            height:
              type === "vr360" || type === "video"
                ? perfectSize(220)
                : perfectSize(100),
          }}
        >
            <WebView
              source={{
                uri: uri,
              }}
              allowsFullscreenVideo
              onMessage={msg => console.log("====")}
              onLoadEnd={() => setIsLoading(false)}
              onError={err => console.log(err)}
              onHttpError={err => console.log(err)}
              scrollEnabled={false}
              domStorageEnabled
              javaScriptEnabled
              originWhitelist={["*"]}
              injectedJavaScript={runFirst}
            />

          {/* <Viro360Video source={{ uri: UPLOAD_URL + video.url }} /> */}
        </View>
        <View style={{ padding: perfectSize(15) }}>
          <Text
            style={{
              fontSize: perfectSize(14),
              fontWeight: "bold",
              marginBottom: perfectSize(10),
            }}
          >
            {data.content?.category}
          </Text>
          <Text
            style={{ fontSize: perfectSize(14), marginBottom: perfectSize(20) }}
          >
            {data.content?.title}
          </Text>
          <Text
            style={{
              fontSize: perfectSize(14),
              fontWeight: "bold",
              marginBottom: perfectSize(10),
            }}
          >
            {"지갑주소"}
          </Text>
          <Text
            style={{ fontSize: perfectSize(14), marginBottom: perfectSize(20) }}
          >
            {data.walletName}
          </Text>
          <Text
            style={{
              fontSize: perfectSize(14),
              fontWeight: "bold",
              marginBottom: perfectSize(10),
            }}
          >
            {"판매가"}
          </Text>
          <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
            <View>
              <TouchableOpacity
                onPress={() => setIsVisiblecoinSelect(true)}
                style={[
                  globalStyle.flexRow,
                  { borderWidth: 1, borderColor: c_grey, padding: 3 },
                ]}
              >
                <Image
                  source={{
                    uri: dropdownList[coinType],
                  }}
                  style={styles.icon}
                />
                <AntDesign name={"caretdown"} size={perfectSize(10)} />
              </TouchableOpacity>
            </View>
            <TextInput
              value={price}
              onChangeText={text => setPrice(text)}
              style={{
                width: perfectSize(100),
                borderBottomWidth: 1,
                borderBottomColor: "grey",
                marginLeft: perfectSize(4),
                fontSize: perfectSize(16),
                textAlign: "center",
              }}
              keyboardType="number-pad"
            />

            <View style={{ marginLeft: perfectSize(4) }}>
              <Text style={styles.price}>({coinWonPrice}원)</Text>
            </View>
          </View>

          <RadioButton
            selected={isEnabledTTM}
            onPress={() => setIsEnabledTTM(!isEnabledTTM)}
            label={"TTC, TTM 모두 받기"}
            style={{ marginTop: perfectSize(10) }}
          />
          <Text
            style={{
              fontSize: perfectSize(8),
              color: "grey",
              marginLeft: perfectSize(22),
            }}
          >
            {
              "체크 시 선택한 코인에 가입된 수량이 자동으로 선택하지 않은 코인 수량으로도 한산되어 NFT몰에 노출합니다."
            }
          </Text>
          <RadioButton
            selected={isTerms}
            onPress={() => setIsTerms(!isTerms)}
            label={"NFT 판매 약관에 동의합니다."}
            style={{ marginTop: perfectSize(10) }}
          />
        </View>

        <View style={{ padding: perfectSize(15) }}>
          {isVisibleCoinSelect && (
            <View
              style={{
                borderWidth: 1,
                borderColor: c_grey,
                alignItems: "center",
                position: "absolute",
                top: perfectSize(-90),
                left: perfectSize(15),
                zIndex: 99,
                backgroundColor: "white",
                padding: 3,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setCoinType(0);
                  setIsVisiblecoinSelect(false);
                }}
                style={{ paddingVertical: perfectSize(2) }}
              >
                <Image
                  source={{
                    uri: "https://twallet.tongtongchain.io/images/api/ttcoin.png",
                  }}
                  style={styles.icon}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setCoinType(1);
                  setIsVisiblecoinSelect(false);
                }}
                style={{ paddingVertical: perfectSize(2) }}
              >
                <Image
                  source={{
                    uri: "https://twallet.tongtongchain.io/images/api/gtc.png",
                  }}
                  style={styles.icon}
                />
              </TouchableOpacity>
            </View>
          )}
        </View>
        {!isLoadingRegister ? (
          <TouchableOpacity
            onPress={onSellNFT}
            style={{
              backgroundColor: c_primary_red,
              height: perfectSize(52),
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: perfectSize(16), color: "white" }}>
              {"판매 등록하기"}
            </Text>
          </TouchableOpacity>
        ) : (
          <View
            style={{
              height: perfectSize(52),
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator color={c_primary_red} />
          </View>
        )}
      </ScrollView>
      {isLoading && (
        <View style={styles.loader}>
          <Loader />
        </View>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafafa",
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "#fafafa",
  },
  title: {
    fontSize: perfectSize(18),
    lineHeight: perfectSize(24),
    fontWeight: "bold",
    padding: perfectSize(15),
  },
  subTitle: {
    fontSize: perfectSize(12),
    lineHeight: perfectSize(13.6),
    color: "#777777",
    paddingLeft: perfectSize(15),
  },
  thumbnail: {
    width: perfectSize(160),
    height: perfectSize(160),
    resizeMode: "contain",
    backgroundColor: c_third_grey,
    borderRadius: perfectSize(4),
    alignSelf: "center",
    marginTop: perfectSize(15),
  },
  button: {
    flex: 1,
    marginLeft: perfectSize(20),
    backgroundColor: c_primary_red,
    alignItems: "center",
    paddingVertical: perfectSize(10),
  },
  noButton: {
    backgroundColor: c_primary_red,
    alignItems: "center",
    borderRadius: perfectSize(6),
    paddingVertical: perfectSize(10),
    opacity: 0.4,
  },
  buttonTitle: {
    color: "#fafafa",
    fontSize: perfectSize(13),
    lineHeight: perfectSize(14.75),
    fontWeight: "bold",
  },
  content: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(22),
    paddingVertical: perfectSize(15),
  },
  reviewTitle: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(15.89),
    fontWeight: "bold",
    marginTop: perfectSize(20),
    marginBottom: perfectSize(5),
  },
  reviewName: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(22),
    fontWeight: "bold",
    marginRight: perfectSize(10),
  },
  reviewContent: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(22),
  },
  count: {
    color: c_grey,
    fontSize: perfectSize(10),
  },
  price: {
    fontSize: perfectSize(12),
  },
  icon: {
    width: perfectSize(20),
    height: perfectSize(20),
    resizeMode: "contain",
    marginRight: perfectSize(3),
  },
  countInput: {
    fontSize: perfectSize(16),
    borderWidth: 2,
    paddingVertical: perfectSize(4),
    marginHorizontal: perfectSize(10),
    minWidth: perfectSize(40),
    maxWidth: perfectSize(40),
    textAlign: "center",
    borderColor: c_grey,
  },
  tab: {
    height: perfectSize(40),
    justifyContent: "center",
    alignItems: "center",
    borderBottomColor: c_primary_red,
    borderBottomWidth: 2,
    flex: 1,
  },
  tabUnSelect: {
    height: perfectSize(40),
    justifyContent: "center",
    alignItems: "center",
    borderBottomColor: c_light_grey,
    borderBottomWidth: 2,
    flex: 1,
  },
  tabLabel: {
    color: "black",
    fontSize: perfectSize(14),
  },
  tabLabelUnSelect: {
    color: c_light_grey,
    fontSize: perfectSize(14),
  },
  boldTitle: {
    fontSize: perfectSize(14),
    fontWeight: "bold",
  },
  normalTitle: {
    fontSize: perfectSize(14),
    marginVertical: perfectSize(20),
    marginTop: perfectSize(10),
  },
  activityLoading: {
    flex: 1,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000022",
  },
});
