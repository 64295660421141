import _ from 'lodash';
import React, {useEffect, useState} from "react";
import {
    View,
    Text,
    StyleSheet,
    Dimensions,
    ActivityIndicator,
    Platform, TouchableOpacity, ScrollView
} from "react-native";
import ReactNativeZoomableView from '@openspacelabs/react-native-zoomable-view/src/ReactNativeZoomableView';
import {getSeatMap, updateSeatOffset} from '../../../../store/actions';
import {globalStyle, perfectSize} from '../../../../variables/styles';
import {c_primary_red, c_white} from '../../../../variables/colors';
import Seat from './seat';
import MiniMap from './mini_map';
import {connect} from 'react-redux';

const {width} = Dimensions.get('window');

const SeatMap = ({performance, roundIndex, updateSeatOffset}) => {

    const [sitWidth, setSitWidth] = useState();
    const [sitMap, setSitMap] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLimited, setLimited] = useState(performance.limit ? performance.limit.isLimited : false);

    const colors =
        {
            VIP: '#9778f1',
            R: '#f56464',
            S: '#ffd56a',
            A: '#72bff6',
            B: '#573ff2',
            C: '#57ff57',
            D: '#2ac4eb',
            E: '#AAC46C',
            '일반': '#FFCCDD'
        }

    useEffect(() => {
        getSeatMaps();
        renderIndicator();
    }, []);

    const getSeatMaps = async () => {
        const res = await getSeatMap(performance.seat_map_data[roundIndex]);
        let maxLength = 0;
        for (let n = 0; n < res.length; n++) {
            if (maxLength < res[n].length) {
                maxLength = res[n].length;
            }
        }
        setSitWidth(Math.floor(width / (maxLength - 1)));
        setSitMap(res);
    };

    const renderSeatRow = (j) => {
        let tempArray = [];
        for (let i = 0; i < sitMap[j].length; i++) {
            let type = [];

            if (sitMap[j][i] && (typeof (sitMap[j][i]) !== 'number')) {
                type = sitMap[j][i].split(":");
                if (type[0] === sitMap[j][i]) {
                    tempArray.push(
                        <View
                            key={'' + i + j}
                            style={{
                                width: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                                height: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                                backgroundColor: 'transparent',
                                borderWidth: 0.4,
                                borderColor: 'transparent',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <Text style={{
                                color: 'black',
                                fontSize: sitWidth,
                                position: 'absolute',
                                width: perfectSize(30),
                                textAlign: 'center'
                            }}>{sitMap[j][i]}</Text>
                        </View>
                    )
                } else {
                    // type[5] === "1" && console.log(sitMap[j][i])
                    const clr = (type[4] === '0' || type[5] === "1") ? '#a3a3a3' : colors[type[4]];
                    (type[4] === '0' || type[5] === "1") ?
                        tempArray.push(
                            <View
                                key={'' + i + j}
                                style={{
                                    width: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                                    height: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                                    backgroundColor: clr,
                                    borderWidth: 0.4,
                                    borderColor: 'white'
                                }}>
                            </View>
                        ) :
                        tempArray.push(
                            <Seat
                                isLimited={isLimited}
                                limitValue={performance.limit.limit_value}
                                onPress={selected => {
                                    if (performance.limit.isLimited) {
                                        if (selected) {
                                            maxSeatCount += 1;
                                        } else {
                                            maxSeatCount -= 1;
                                        }
                                        if (maxSeatCount === performance.limit.limit_value) {
                                            setLimited(false);
                                        } else {
                                            setLimited(true);
                                        }
                                    }
                                    // if (selected) {
                                    //     let temp = selectedSeat ? selectedSeat : {};
                                    //     temp[sitMap[j][i]] = true;
                                    //     setSelectSeat({ ...temp });
                                    // } else {
                                    //     let temp = selectedSeat ? selectedSeat : {};
                                    //     temp[sitMap[j][i]] = false;
                                    //     setSelectSeat({ ...temp });
                                    // }
                                }}
                                key={'' + i + j}
                                seatNum={'i' + i + 'j' + j}
                                clr={clr}
                                val={sitMap[j][i]}
                                sitWidth={Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3}
                            />
                        )
                }
            } else if (sitMap[j][i]) {
                tempArray.push(
                    <View
                        key={'' + i + j}
                        style={{
                            width: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                            height: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                            backgroundColor: 'transparent',
                            borderWidth: 0.4,
                            borderColor: 'transparent',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <Text style={{color: 'red', textAlign: 'left', fontSize: 7}}>{sitMap[j][i]}</Text>
                    </View>
                )
            } else {
                tempArray.push(
                    <View
                        key={'' + i + j}
                        style={{
                            width: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                            height: Platform.OS !== 'web' ? sitWidth : sitWidth * 1.3,
                            backgroundColor: 'transparent',
                            borderWidth: 0.4,
                            borderColor: 'transparent',
                        }}>
                    </View>
                )
            }
        }
        ;
        return tempArray;
    }

    const renderSeat = () => {
        let seatArray = [];
        for (let j = 0; j < sitMap.length; j++) {
            seatArray.push(
                <View style={{flexDirection: 'row'}} key={j}>
                    {renderSeatRow(j)}
                </View>
            )
        }
        return seatArray;
    }

    const renderMiniMap = () => {
        return (
            <View style={{
                width: width / 2,
                height: width / 2.2,
                backgroundColor: '#00000033',
                position: 'absolute',
                top: 0,
                right: 0,
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: perfectSize(10),
                overflow: 'hidden',
            }}>
                <ReactNativeZoomableView
                    minZoom={0.5}
                    maxZoom={0.5}
                    initialZoom={0.5}>
                    {renderSeat()}
                </ReactNativeZoomableView>
                <MiniMap/>
            </View>
        )
    }

    const renderIndicator = () => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const handleZoomState = (event, gestureState, zoomableViewEventObject) => {
        const {offsetX, offsetY, originalWidth, originalHeight, zoomLevel} = zoomableViewEventObject;
        updateSeatOffset({
            top: (width) * -Math.abs(offsetY / originalHeight),
            bottom: (width) * -Math.abs(offsetY / originalHeight),
            left: (width) * -Math.abs(offsetX / originalWidth),
            right: (width) * -Math.abs(offsetX / originalWidth),
        });
    }

    return (
        <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}>
            <View style={styles.con}>
                {
                    <View>
                        <ReactNativeZoomableView
                            onPanResponderEnd={handleZoomState}
                            maxZoom={3}
                            minZoom={1}
                            initialZoom={0.25}
                            style={{flex: 1, marginTop: -700}}>
                            <View
                                style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: perfectSize(100)
                                }}>
                                <View>
                                    {renderSeat()}
                                </View>
                            </View>
                        </ReactNativeZoomableView>
                    </View>
                }
                {Platform.OS !== 'web' && renderMiniMap()}
                {
                    loading &&
                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: c_white
                    }}>
                        <ActivityIndicator color={c_primary_red}/>
                    </View>
                }
            </View>
        </ScrollView>

    );
}

const mapStateTopProps = state => ({})


export default connect(mapStateTopProps, {updateSeatOffset})(SeatMap);

const styles = StyleSheet.create({
    con: {
        flex: 1,
        backgroundColor: c_white
    },
    zoomView: {
        justifyContent: 'center',
        backgroundColor: c_white
    },
    container: {
        flexDirection: 'row',
    },
    col: {
        flexDirection: 'row'
    },
});