import React, { useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity
} from 'react-native';
import Entypo from '@expo/vector-icons/Entypo';
import Collapsible from 'react-native-collapsible';
import moment from 'moment';
import { useNavigation } from '@react-navigation/native';

import {
    c_white,
    c_grey,
    c_primary_red,
    c_third_grey,
    c_dark_white
} from '../variables/colors';
import { globalStyle, perfectSize } from '../variables/styles';
import Separator from './Separator';

export default function InvitationCard({
    data
}) {
    const navigation = useNavigation();
    const [expanable, setExpand] = useState(true);
    const {
        product_id,
        seat,
        seat_grade,
        from,
        to,
        memo,
        round,
        round_num
    } = data;

    return (
        <TouchableOpacity
            onPress={() => {
                if (round === 'all') {
                    navigation.navigate('SeatMap', { performance: product_id, roundIndex: 0 });
                } else {
                    navigation.navigate('SeatMap', { performance: product_id, roundIndex: round_num });
                }
            }}
            style={styles.cardTopSection}>
            <View style={styles.headerRedLine}>
                <Separator
                    height={perfectSize(6)}
                    color={c_primary_red}
                />
            </View>
            <View style={styles.mainContent}>
                <View style={styles.seatGrade}>
                    <Text style={styles.price}>
                        {seat !== 'all' ? seat_grade + '석' : '전체좌석'}
                    </Text>
                    <Text style={globalStyle.regularText}>
                        {round !== 'all' && moment(product_id.schedule.detailed_time[Number(round_num)].date).format('YYYY.MM.DD')} {round === 'all' ? '모든회차' : `(${Number(round_num + 1)}회차)`}
                    </Text>
                </View>
                <Text style={[globalStyle.normalBoldText, { marginBottom: perfectSize(20) }]}>
                    {product_id.title.name}
                </Text>
                <Text style={globalStyle.greySmText}>
                    {`유효기간 ${moment(from).format('YYYY.MM.DD')} ~ ${moment(to).format('YYYY.MM.DD')}`}
                </Text>
                <Separator
                    height={1}
                    color={c_third_grey}
                    marginTop={perfectSize(12)}
                />
            </View>
            <TouchableOpacity
                onPress={() => setExpand(!expanable)}
                style={styles.button}>
                <View style={globalStyle.flexRow}>
                    <Text style={globalStyle.smText}>
                        {'초대권 안내'}
                    </Text>
                    <View style={{ marginLeft: perfectSize(5) }}>
                        <Entypo name={expanable ? "chevron-thin-down" : "chevron-thin-up"} size={perfectSize(12)} />
                    </View>
                </View>
            </TouchableOpacity>
            <Collapsible
                collapsed={expanable}
                style={styles.collapsedContainer}>
                <View style={styles.collapsedContent}>
                    <Text style={globalStyle.smGreyText}>
                        {'1. 초대권을 누르시면 좌석지정이 가능합니다.\n2. 지정된 좌석등급과 공연일에 한해 사용하실수 있습니다.\n3. 반드시 유효기간 내 사용해주세요, 유효기간이 지난 초대권은 복구되지 않습니다.'}
                    </Text>
                </View>
            </Collapsible>
        </TouchableOpacity>
    )
}

const styles = {
    cardTopSection: {
        elevation: 2,
        shadowColor: c_grey,
        shadowOffset: { x: 2, y: 6 },
        shadowRadius: 4,
        shadowOpacity: 0.3,
        marginBottom: perfectSize(10),
        marginHorizontal: perfectSize(15),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
    },
    headerRedLine: {
        borderTopLeftRadius: perfectSize(6),
        borderTopRightRadius: perfectSize(6),
        overflow: 'hidden'
    },
    price: {
        fontSize: perfectSize(20),
        letterSpacing: -0.2,
        color: c_primary_red,
        fontWeight: 'bold',
        marginRight: perfectSize(15)
    },
    mainContent: {
        paddingTop: perfectSize(15),
        paddingHorizontal: perfectSize(15)
    },
    button: {
        alignItems: 'center',
        paddingTop: perfectSize(10),
        paddingBottom: perfectSize(14),
    },
    collapsedContent: {
        borderRadius: perfectSize(6),
        backgroundColor: c_dark_white,
        padding: perfectSize(12)
    },
    collapsedContainer: {
        paddingHorizontal: perfectSize(15),
        paddingBottom: perfectSize(15)
    },
    seatGrade: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: perfectSize(5)
    },
}