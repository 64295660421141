import React, { useEffect, useMemo, useState } from "react";
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  SafeAreaView,
} from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import {
  c_grey,
  c_light_grey,
  c_primary_red,
  c_second_grey,
  c_third_grey,
  c_white,
} from "../../../variables/colors";
import { UPLOAD_URL } from "../../../variables/constants";
import { numberWithCommas, oneAlert } from "../../../utils";
import { useSelector } from "react-redux";

export default function BuyNFT({ route, navigation }) {
  const { data, price, priceWon, coinType, count } = route.params;
  const { auth } = useSelector(state => state);

  useEffect(() => {
    navigation.setOptions({
      title: "NFT 구매",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../../assets/images/ic_before_stroke.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
    });
  }, []);

  const onBuyNft = () => {
    if (
      auth.user?.wallet &&
      Number(auth.user?.wallet[coinType === "TTC" ? "TTCOIN" : coinType]) >
        price
    ) {
      navigation.navigate("NFTPin", { data, count, price, coinType });
    } else {
      oneAlert("잔고가 부족합니다. 코인을 충전해주세요.");
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <View style={styles.contentWrapper}>
          <Text style={styles.label}>{"지갑 주소"}</Text>
          <Text style={{ fontSize: perfectSize(12) }}>{data.walletName}</Text>
        </View>
        <View style={styles.contentWrapper}>
          <Text style={styles.label}>{"NFT 구매 정보"}</Text>
          <View style={{ flexDirection: "row" }}>
            <Image
              source={{ uri: UPLOAD_URL + data.content?.thumbnail }}
              style={styles.thumbnail}
            />
            <Text style={{ flex: 1 }}>{data.content?.title}</Text>
          </View>
        </View>
        <View style={styles.contentWrapper}>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={{ fontSize: perfectSize(12) }}>{"판매가"}</Text>
            <Text style={{ fontSize: perfectSize(12) }}>
              {numberWithCommas(Number(price.toFixed(2)) * count)}
              {coinType}
            </Text>
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={{ fontSize: perfectSize(12) }}>{"수수료"}</Text>
            <Text style={{ fontSize: perfectSize(12) }}>0{coinType}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: perfectSize(10),
            }}
          >
            <Text style={{ fontSize: perfectSize(14) }}>{"총금액"}</Text>
            <View>
              <Text style={{ fontSize: perfectSize(14), textAlign: "right" }}>
                {numberWithCommas(Number(price.toFixed(2)) * count)}
                {coinType}
              </Text>
              <Text style={{ fontSize: perfectSize(12), textAlign: "right" }}>
                {numberWithCommas(String(priceWon * count))}원
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.contentWrapper}>
          <Text style={{ fontSize: perfectSize(12), color: c_primary_red }}>
            보유잔고:{" "}
            {auth.user?.wallet &&
              numberWithCommas(
                Number(
                  auth.user?.wallet[coinType === "TTC" ? "TTCOIN" : coinType]
                ).toFixed(2)
              )}
            {coinType}
          </Text>
        </View>
        <TouchableOpacity
          onPress={onBuyNft}
          style={{
            backgroundColor: c_primary_red,
            height: perfectSize(48),
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ color: "white", fontSize: perfectSize(16) }}>
            {"구매하기"}
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: perfectSize(15),
  },
  contentWrapper: {
    marginBottom: perfectSize(20),
  },
  label: {
    fontSize: perfectSize(12),
    color: "grey",
    marginBottom: perfectSize(5),
  },
  thumbnail: {
    width: perfectSize(100),
    height: perfectSize(60),
    backgroundColor: c_light_grey,
    marginRight: perfectSize(6),
  },
});
