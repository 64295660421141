import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
} from 'react-native';
import moment from 'moment';
import {
    c_dark_white,
    c_primary_black,
    c_primary_red,
    c_second_white,
    c_white
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Separator from '../../components/Separator';
import Button from '../../components/Button';
import { makeTwoString } from '../../utils';
import { invitationListReset, invitationList } from '../../store/actions/invitation';
import { couponListReset, couponList } from '../../store/actions/coupon';

export default function OrderComplete({ navigation, route }) {

    const { data } = route.params;
    const { InvitationReducer, CouponReducer } = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(couponListReset());
        dispatch(couponList());
        dispatch(invitationListReset());
        dispatch(invitationList());
    }, []);

    useEffect(() => {
        navigation.setOptions({
            title: "예매성공",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    return (
        <View style={styles.mainContainer}>
            <Image
                source={require('../../../assets/images/img.png')}
                style={styles.logo}
            />
            <Text style={styles.headerTitle}>
                {'예매가 완료되었습니다.'}
            </Text>

            <View style={styles.mainContents}>
                <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                    {'공연명'}
                </Text>
                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(15) }]}>
                    {data.name}
                </Text>

                <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                    {'공연일시'}
                </Text>
                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(15) }]}>
                    {`${moment(data.date).format('YYYY.MM.DD')} ${makeTwoString(data.hour)}:${makeTwoString(data.min)}`}
                </Text>

                <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                    {'공연장소'}
                </Text>
                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(15) }]}>
                    {data.address}
                </Text>

                <Text style={[globalStyle.greySmText, { marginBottom: perfectSize(5) }]}>
                    {'수령방법'}
                </Text>
                <Text style={[globalStyle.regularText, { marginBottom: perfectSize(15) }]}>
                    {data.delivery_method}
                </Text>
            </View>

            <View style={styles.bottomButtons}>
                <Separator
                    height={perfectSize(1)}
                    color={c_second_white}
                />
                <View style={[
                    globalStyle.flexRow,
                    styles.bottomButtonWrapper
                ]}>
                    <View style={globalStyle.flex}>
                        <Button
                            onPress={() => navigation.navigate('ConfirmTicket')}
                            textColor={c_white}
                            label={'예매 확인/취소'}
                            color={c_primary_red}
                            isBorder={false}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(20),
        backgroundColor: c_white,
        paddingHorizontal: perfectSize(15),
        alignItems: 'center'
    },
    logo: {
        width: perfectSize(90),
        height: perfectSize(90),
        resizeMode: 'contain',
        marginBottom: perfectSize(20)
    },
    headerTitle: {
        fontSize: perfectSize(25),
        color: c_primary_black,
        marginBottom: perfectSize(20),
    },
    bottomButtons: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: c_white
    },
    mainContents: {
        borderRadius: perfectSize(6),
        backgroundColor: c_dark_white,
        paddingHorizontal: perfectSize(20),
        paddingVertical: perfectSize(15),
        alignSelf: 'stretch'
    },
    bottomButtonWrapper: {
        paddingHorizontal: perfectSize(15),
        paddingBottom: perfectSize(15),
        paddingTop: perfectSize(10)
    }
}