import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TouchableOpacity } from 'react-native';
import { c_primary_red, c_white } from '../../../../variables/colors';
import { updateSeat } from '../../../../store/actions';
import { oneAlert } from '../../../../utils';

function Seat({ clr, sitWidth, val, updateSeat, selectedSeat, seatNum, limitValue }) {

    return (
        <TouchableOpacity
            onPress={() => {
                console.log(selectedSeat.includes(val));
                if (limitValue === selectedSeat.length && !selectedSeat.includes(val)) {
                    oneAlert(`선택가능한 최대좌석수는 ${limitValue}입니다.`);
                } else {
                    console.log('**************');
                    console.log(val, !selectedSeat.includes(val), seatNum)
                    updateSeat(val, !selectedSeat.includes(val), seatNum);
                }
            }}
            style={{
                width: sitWidth,
                height: sitWidth,
                backgroundColor: clr,
                borderWidth: 0.4,
                borderColor: selectedSeat.includes(val) ? c_primary_red : c_white
            }} >
        </TouchableOpacity>
    )
}

const mapStateTopProps = state => ({
    selectedSeat: state.seat.selectedSeat
});

export default connect(mapStateTopProps, { updateSeat })(Seat);