import React, { useEffect } from 'react';
import {
    View,
    Text,
} from 'react-native';
import CryptoJS from 'crypto-js';
import { groupLogin } from "../../store/actions";


export default function LoginScreen({ navigation }) {
    useEffect(() => {
        ReadUrl();
        }, []);
    function ReadUrl() {
        const RUrl = window.location.href;
        const ori_params = RUrl.substring(RUrl.search('tomatoEnc=') + 10);
        const params = fnDecrypt(ori_params);
        const tomatoid = params.split('&')[0].split('tomatoid=')[1];
        const groupNo = params.split('&')[1].split('tomatoGroupNo=')[1];
        const refNo = params.split('&')[2].split('tomatoRefNo=')[1];
        if (tomatoid === '') {
            navigation.navigate("Home");
        } else {
            groupLogin(tomatoid);
            localStorage.setItem('tomatoGroupNo', groupNo);
            localStorage.setItem('tomatoRefNo', refNo);
        }
    }

    function fnDecrypt(pText) {
        var key = CryptoJS.enc.Utf8.parse('tomatogroup pass');
        var iv = CryptoJS.enc.Utf8.parse('');

        var decode = decodeURIComponent(pText);
        var cipherData = CryptoJS.AES.decrypt(decode, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            //format: CryptoJS.format.OpenSSL
        }).toString(CryptoJS.enc.Utf8);
        return cipherData;
    }

    return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <Text>자동로그인 중입니다</Text>
        </View>
    );
};