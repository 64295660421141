import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    ActivityIndicator,
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView,
} from 'react-native';
import {
    c_second_white,
    c_white,
    c_primary_red,
    c_thrid_white,
} from '../../variables/colors';
import { useIsFocused } from '@react-navigation/native';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import { getEvent } from '../../store/actions';
import Event from './event';

function ReviewBook({
    navigation,
    user
}) {

    const isFocusedHistory = useIsFocused();

    const [loading, setLoading] = useState(false);
    const [event, setEvent] = useState([]);
    const [tab, setTab] = useState(true);

    useEffect(() => {
        navigation.setOptions({
            title: "이벤트",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        const getEvnets = async () => {
            setLoading(true);
            const res = await getEvent();
            setEvent(res);
            setLoading(false);
        };
        getEvnets();
    }, [isFocusedHistory]);

    const renderEvents = () => {
        if (loading) return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: perfectSize(20) }}>
                <ActivityIndicator color={c_primary_red} />
            </View>
        );
        if (_.isEmpty(event)) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: perfectSize(20) }}>
                    <Text>{'진행중인 이벤트가 없습니다.'}</Text>
                </View>
            )
        }
        return (
            <ScrollView style={{ paddingHorizontal: perfectSize(15) }}>
                {
                    event.map((item, index) => {
                        return (
                            <Event key={index} data={item} />
                        )
                    })
                }
            </ScrollView>
        )
    }

    return (
        <View style={styles.mainContainer}>
            <View style={[globalStyle.flexRow, { paddingHorizontal: perfectSize(17), marginBottom: perfectSize(20) }]}>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={() => setTab(true)}
                        isBorder={false}
                        textColor={tab ? c_white : c_thrid_white}
                        label={`진행중인 이벤트`}
                        color={tab ? c_primary_red : c_second_white}
                    />
                </View>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={() => setTab(false)}
                        isBorder={false}
                        textColor={!tab ? c_white : c_thrid_white}
                        label={`참여내역`}
                        color={!tab ? c_primary_red : c_second_white}
                    />
                </View>
            </View>
            {renderEvents()}
        </View >
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
})

export default connect(mapStateTopProps)(ReviewBook);

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
        paddingTop: perfectSize(15),
    },
}