import React, { useEffect, useRef } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions,
    Platform,
    Linking,
    SafeAreaView
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';
import Modal from 'react-native-modal';
import WebModal from 'modal-react-native-web';
import {
    c_primary_black,
    c_primary_red,
    c_third_grey,
    c_white,
} from '../variables/colors';
// import MapView, { Marker } from 'react-native-maps';
import Separator from '../components/Separator';
import Footer from '../components/Footer';
import WebView from 'react-native-webview';
import Clipboard from 'expo-clipboard';
import { HOST } from '../variables/constants';
import {oneAlert, twoAlert} from '../utils';

const height = Dimensions.get('window').height;

export default function CardModal({
                                      isVisible,
                                      hideModal,
                                      data,
                                      moid,
                                      onSuccess
                                  }) {

    const ws = useRef(new WebSocket('wss://www.tickettong.net/api')).current;
    // const ws = useRef(new WebSocket('wss://www.tickettong.net:3000/review')).current;
    // const ws = useRef(new WebSocket('wss://211.192.45.228:3000')).current;



    useEffect(() => {
        const req = {
            type: 'connect',
            moid,
        };
        ws.onopen = (e) => {
            console.log('WebSocket is connected.');
            ws.send(JSON.stringify(req));
        }
        ws.onmessage = (e) => {
            const data = JSON.parse(e.data);
            if (data.OID === moid) {
                if (data.ResultCode === '3001') {
                    ws.close();
                    onSuccess(data.TID);
                } else {
                    oneAlert('카드결제시 오류가 발생하였습니다. ');
                    ws.close();
                    hideModal();
                }
            }
        }
        ws.onerror = (e) => {
            console.log('WebSocket error',e.message);
        };
        ws.onclose = (e) => {
            console.log('WebSocket is Disconnected.');
        };
    }, []);

    function AndroidBackButton() {
        twoAlert(
            '결제창을 완전히 닫습니다.\n계속하시겠습니까?',
            () => {
                ws.close();
                hideModal();
            },
        );
    }

    const modalContent = () => {
        return (
            <View style={styles.swipeModal}>
                <SafeAreaView style={[styles.swipeModalContainer]}>
                    <View style={{ paddingHorizontal: perfectSize(15), alignSelf: 'stretch' }}>
                        {/** close */}
                        <TouchableOpacity
                            onPress={() => {
                                ws.close();
                                hideModal();
                            }}
                            style={styles.closeBtn}>
                            <Image
                                source={require('../../assets/images/ic_close.png')}
                                style={styles.closeIcon}
                            />
                        </TouchableOpacity>
                    </View>
                    {
                        Platform.OS !== 'web' ?
                            <WebView
                                javaScriptEnabled={true}
                                domStorageEnabled={true}
                                originWhitelist={['*']}
                                source={{ uri: data }}
                                style={styles.mapContainer}
                                onShouldStartLoadWithRequest={(event) => {
                                    if (
                                        event.url.startsWith('http://') ||
                                        event.url.startsWith('https://') ||
                                        event.url.startsWith('about:blank')
                                    ) {
                                        return true
                                    }
                                    if (Platform.OS === 'android') {
                                        const SendIntentAndroid = require('react-native-send-intent');
                                        SendIntentAndroid.openChromeIntent(event.url)
                                            .then(isOpened => {
                                                if (!isOpened) {
                                                    let pname = event.url.substring(event.url.indexOf('package=') + 8).replace(event.url.substring(event.url.indexOf('package=') + 8).substring(event.url.substring(event.url.indexOf('package=') + 8).indexOf(';')), '')
                                                    Linking.openURL(
                                                        `https://play.google.com/store/apps/details?id=${pname}`
                                                    )
                                                }
                                            })
                                            .catch(err => {
                                                console.log(err);
                                            });
                                        return false;
                                    } else {
                                        Linking.openURL(event.url)
                                            .catch(err => {
                                                console.log(err);
                                                // oneAlert('앱이 설치되어있지 않습니다. 설치하기 버튼을 눌러주세요.');
                                            });
                                    }
                                    return false;
                                }}
                            />
                            : <iframe
                                src={data}
                                height={height}
                            />
                    }
                </SafeAreaView>
            </View>
        )
    }

    return (
        Platform.OS !== 'web' ?
            <Modal
                onRequestClose={() => AndroidBackButton()}
                animationIn="slideInUp"
                animationOut="slideOutDown"
                style={{ margin: 0 }}
                isVisible={isVisible}>
                {modalContent()}
            </Modal>
            :
            <WebModal
                animationIn="slideInDown"
                animationOut="slideOutUp"
                transparent={true}
                visible={isVisible}>
                <View style={{
                    flex: 1,
                    backgroundColor: '#00000099'
                }}>
                    {modalContent()}
                </View>
            </WebModal>
    )
}

const styles = {
    swipeModal: {
        flex: 1,
    },
    swipeModalContainer: {
        paddingHorizontal: 0,
        height: '100%',
        backgroundColor: c_white
    },
    closeBtn: {
        alignSelf: 'flex-end',
        marginVertical: perfectSize(15)
    },
    closeIcon: {
        width: perfectSize(20),
        height: perfectSize(20),
        resizeMode: 'contain',
    },
    title: {
        fontSize: perfectSize(18),
        letterSpacing: -0.18,
        color: c_primary_black,
        fontWeight: 'bold',
        marginBottom: perfectSize(10)
    },
    redDot: {
        width: perfectSize(4),
        height: perfectSize(4),
        marginRight: perfectSize(5)
    },
    hyperText: {
        textDecorationLine: 'underline',
        color: '#2d7aee',
        fontSize: perfectSize(14),
    },
    mapContainer: {
        flex: 1,
    },
    markerImage: {
        width: perfectSize(24),
        height: perfectSize(33),
        resizeMode: 'contain',
    },
}