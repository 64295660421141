import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    Platform
} from 'react-native';
import { useSelector } from 'react-redux';
import { globalStyle, perfectSize } from '../variables/styles';
import Modal from 'react-native-modal';
import WebModal from 'modal-react-native-web';

export default function SearchModal({
    isVisible,
    hideModal,
    navigation,
    filters,
    selectedIndex,
    setSelectionIndex
}) {

    const { PerformanceReducer } = useSelector(state => state);

    const modalContent = () => {
        return (
            <View style={globalStyle.swipeModal}>
                <View style={globalStyle.swipeModalContainer}>

                    <View style={styles.filterSelections}>
                        {
                            filters.map(item => {
                                const count = PerformanceReducer.filterList[item.label];
                                return (
                                    <TouchableOpacity
                                        key={item.id}
                                        style={[globalStyle.flexRow, styles.selection]}
                                        onPress={() => setSelectionIndex(item.id)}>
                                        <Image
                                            source={
                                                selectedIndex == item.id ?
                                                    require('../../assets/images/radio_on.png') :
                                                    require('../../assets/images/radio_off.png')
                                            }
                                            style={styles.radioBtn}
                                        />
                                        <Text style={globalStyle.normalText}>{item.label}</Text>
                                        <Text style={globalStyle.smText}>{`(${count > 99 ? '99+' : count ? count : 0})`}</Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </View>

                    {/** knob */}
                    <TouchableOpacity onPress={hideModal}>
                        <Image
                            source={require('../../assets/images/ic_pop_close_stroke.png')}
                            style={globalStyle.knob}
                        />
                    </TouchableOpacity>
                </View>
                <TouchableOpacity
                    onPress={hideModal}
                    style={globalStyle.flex}>
                </TouchableOpacity>
            </View>
        )
    }


    return (
        Platform.OS !== 'web' ?
            <Modal
                animationIn="slideInDown"
                animationOut="slideOutUp"
                style={{ margin: 0 }}
                isVisible={isVisible}
                swipeDirection={'up'}
                onSwipeComplete={hideModal}>
                {modalContent()}
            </Modal>
            :
            <WebModal
                animationIn="slideInDown"
                animationOut="slideOutUp"
                transparent={true}
                visible={isVisible}>
                <View style={{
                    flex: 1,
                    backgroundColor: '#00000099'
                }}>
                    {modalContent()}
                </View>
            </WebModal>
    )
}

const styles = {
    filterSelections: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start'
    },
    selection: {
        width: '50%',
        height: perfectSize(44)
    },
    radioBtn: {
        width: perfectSize(20),
        height: perfectSize(20),
        resizeMode: 'contain',
        marginRight: perfectSize(8)
    },
}