import React, { useEffect, useState } from 'react';
import {
    View,
    TouchableOpacity,
    Image,
    Text
} from 'react-native';
import {
    c_white,
    c_light_grey,
    c_light_blue,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import CustomTextInput from '../../components/CustomTextInput';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { resetPassword } from '../../store/actions';

export default function PasswordReset({ navigation, route }) {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState('');
    const [equalErr, setEqualErr] = useState(false);
    const [strongErr, setStrongErr] = useState(false);
    const { phonenum, code } = route.params;

    useEffect(() => {
        navigation.setOptions({
            title: "비밀번호재설정",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    const onReset = async () => {
        if (password !== confirmPassword) {
            setEqualErr(true);
            return;
        }
        const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/;
        if (!pattern.test(password)) {
            setStrongErr(true);
            return;
        }
        setLoading(true);
        await resetPassword({ newpasswd: password, phonenum, code });
        navigation.navigate('SignIn', {screenName: 'Home'});
        setLoading(false);
    }

    return (
        <View style={globalStyle.mainContainer}>
            <KeyboardAwareScrollView style={{ flex: 1 }}>
                <View style={styles.mainContainer}>

                    <CustomTextInput
                        editable={false}
                        warningText={'비밀번호를 잘못입력하셨습니다'}
                        isWarn={false}
                        placeholder={phonenum}
                        style={{ marginBottom: perfectSize(6), backgroundColor: c_light_grey }}
                    />
                    {/* <CustomTextInput
                        warningText={'비밀번호를 잘못입력하셨습니다'}
                        isWarn={false}
                        placeholder={'현재 비밀번호입력'}
                        value={oldPassword}
                        onChangeText={text => setOldPassword(text)}
                        style={{ marginBottom: perfectSize(6) }}
                        isSecure={true}
                        isShowEye={true}
                    /> */}
                    <CustomTextInput
                        warningText={'영문+숫자+특수기호 조합 8~15자'}
                        isWarn={strongErr}
                        placeholder={'변경할 비밀번호입력'}
                        value={password}
                        onChangeText={text => {
                            setEqualErr(false);
                            setStrongErr(false);
                            setPassword(text);
                        }}
                        style={{ marginBottom: perfectSize(6) }}
                        isSecure={true}
                        isShowEye={true}
                    />
                    {
                        !strongErr &&
                        <Text style={[globalStyle.smText, styles.bottomText]}>
                            {'영문+숫자+특수기호 조합 8~15자'}
                        </Text>
                    }
                    <CustomTextInput
                        warningText={'비밀번호가 일치하지 않습니다'}
                        isWarn={equalErr}
                        placeholder={'비밀번호확인'}
                        value={confirmPassword}
                        onChangeText={text => {
                            setEqualErr(false);
                            setStrongErr(false);
                            setConfirmPassword(text);
                        }}
                        style={{ marginVertical: perfectSize(6) }}
                        isSecure={true}
                        isShowEye={true}
                    />
                    {
                        !equalErr &&
                        <Text style={[globalStyle.smText, styles.bottomText]}>
                            {'비밀번호를 한 번 더 입력하세요'}
                        </Text>
                    }
                </View>
            </KeyboardAwareScrollView>
            <View style={styles.bottomView}>
                <Button
                    onPress={onReset}
                    textColor={c_white}
                    label={'확인'}
                    color={c_primary_red}
                    disable={(!password || !confirmPassword) ? true : false}
                    loading={loading}
                />
            </View>
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        marginTop: perfectSize(30),
        paddingHorizontal: perfectSize(21)
    },
    bottomView: {
        position: 'absolute',
        bottom: perfectSize(30),
        left: perfectSize(21),
        right: perfectSize(21)
    },
    bottomText: {
        alignSelf: 'stretch',
        textAlign: 'left',
        color: c_light_blue,
    },
}