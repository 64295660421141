import React from 'react';
import { View } from 'react-native';

export default function Separator({
    height,
    color,
    marginTop,
    marginBottom,
}) {
    return (
        <View style={[styles.separator,
        {
            height,
            backgroundColor: color,
            marginTop,
            marginBottom
        }]} />
    )
}

const styles = {
    separator: {
        height: 1,
        width: '100%'
    },
}