import React, {useEffect, useState} from 'react';
import {c_fourth_white, c_primary_red, c_thrid_white, c_white} from "../../variables/colors";
import {Image, Platform, ScrollView, Text, TouchableOpacity, View} from "react-native";
import Button from "../../components/Button";
import axios from "axios";
import {HOST, UPDATE_USER_SUBSCRIBE} from "../../variables/constants";
import {globalStyle, perfectSize} from "../../variables/styles";
import {storeData} from "../../utils";
import {useDispatch} from "react-redux";
import Mania_gift from "./mania_gift";
export default function ManiaPayReturn({
	route,
	navigation,
}) {
	const sUsrAg = navigator.userAgent;
	const dispatch = useDispatch();
	const [isUserData, setUserData] = useState();

	useEffect(() => {
		navigation.setOptions({
			title: '매니아 구독',
			headerLeft: () => (
				<View style={globalStyle.flexRow}>
					<TouchableOpacity onPress={() => navigation.goBack()}>
						<Image
							source={require('../../../assets/images/ic_before_stroke.png')}
							style={globalStyle.headerLeftBack}
						/>
					</TouchableOpacity>
				</View>
			)
		});
	}, []);

	useEffect(() => {
		const params = route.params;
		if(!params) {
			return;
		}
		if(params.status === '0') {
			alert(params.message);
			navigation.navigate('Main');
		}
		if(params.status === '1') {
			getUser(params.userid);
		}
	}, []);

	function getUser(userId) {
		axios.get(`${HOST}/api/user/detail/${userId}`)
			.then(res => res.data)
			.then(data => {
				const userData = data.data;
				setUserData(userData);

				if(data.status === 1) {
					dispatch({ type: UPDATE_USER_SUBSCRIBE, payload: userData.subscribe });
					storeData("@user", userData);
				}
			});
	}

	const setAddress = () => {
		navigation.navigate('Mania_gift', {user: isUserData});
	};

	const setCheck = () => {
		navigation.navigate("BenefitsPage", {user: isUserData});
	};

	return (
		<View style={globalStyle.mainContainer}>
			<ScrollView showsVerticalScrollIndicator={false}>
				<View style={styles.scrollContainer}>
					<View style={{
						marginLeft: perfectSize(15),
						marginRight: perfectSize(15),
						backgroundColor: c_fourth_white
					}
					}>
						<View>
							<Image
								style={styles.contentImage}
								source={require('../../../assets/images/mania_complete.png')}
							/>
						</View>
						{/*<Text>* 구독상태가 아니라면 재</Text>*/}
					</View>

				</View>
			</ScrollView>
			{Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
				<View

					style={[
						globalStyle.flexRow,
						{
							height: perfectSize(65),
							marginBottom: perfectSize(50),
						},
					]}
				>
					{
						isUserData?.subscribe[0]?.isDiscounted ?
							<View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
								<Button
									onPress={setCheck}
									isBorder={true}
									textColor={c_white}
									label={"혜택 확인 페이지로 이동"}
									color={c_primary_red}
									borderRadius={8}
								/>
							</View> :
							<View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
								<Button
									onPress={setAddress}
									isBorder={true}
									textColor={c_white}
									label={"사은품 선택 / 배송지 등록"}
									color={c_primary_red}
									borderRadius={8}
								/>
							</View>

					}
				</View> :
				<View

					style={[
						globalStyle.flexRow,
						{
							height: perfectSize(65),
						},
					]}
				>
					<View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
						{
							isUserData?.subscribe[0]?.isDiscounted ?
								<View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
									<Button
										onPress={setCheck}
										isBorder={true}
										textColor={c_white}
										label={"혜택 확인 페이지로 이동"}
										color={c_primary_red}
										borderRadius={8}
									/>
								</View> :
								<View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
									<Button
										onPress={setAddress}
										isBorder={true}
										textColor={c_white}
										label={"사은품 선택 / 배송지 등록"}
										color={c_primary_red}
										borderRadius={8}
									/>
								</View>

						}
					</View>
				</View> : <View />

			}

		</View>
	)
}

const styles = {
	scrollContainer: {
		padding: perfectSize(15)
	},
	contentImage: {
		width: perfectSize(330),
		height: perfectSize(440),
	}
}
