import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';
import Modal from 'react-native-modal';
import {
    c_third_grey,
    c_dark_white,
    c_primary_red
} from '../variables/colors';
import Button from '../components/Button';

export default function TicketCancelRuleModal({
    isVisible,
    hideModal,
}) {
    return (
        <Modal
            animationIn="slideInUp"
            animationOut="slideOutDown"
            style={{ margin: 0 }}
            isVisible={isVisible}>
            <View style={styles.swipeModal}>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    <View style={styles.headerContainer}>
                        {/** close */}
                        <TouchableOpacity
                            onPress={hideModal}>
                            <Image
                                source={require('../../assets/images/ic_close.png')}
                                style={styles.closeIcon}
                            />
                        </TouchableOpacity>
                    </View>

                    <Text style={styles.headerTitle}>
                        {'취소규정'}
                    </Text>

                    <View style={styles.cancelRule}>
                        <ScrollView>
                            <Text style={globalStyle.regularGreyText}>
                                {'1. 반드시 배송된 티켓을 취소마감 시간 이전까지 티켓통 고객센터에 보내주셔야 취소가 가능합니다.\n\n2. 티켓을 반송하신 후에는 전화나 메일을 통해 티켓 도착여부 혹은 취소 여부를 확인하시기 바랍니다.\n\n3. 반드시 우송된 티켓을 소지하시고 취소마감 시간 이전까지 티켓통 고객센터로 방문하셔야 취소가 가능합니다.\n\n4. 반드시 배송된 티켓을 취소마감 시간 이전까지 티켓통 고객센터에 보내주셔야 취소가 가능합니다.\n\n5. 티켓을 반송하신 후에는 전화나 메일을 통해 티켓 도착여부 혹은 취소 여부를 확인하시기 바랍니다.\n\n6. 반드시 우송된 티켓을 소지하시고 취소마감 시간 이전까지 통통 티켓 고객센터로 방문하셔야 취소가 가능합니다.'}
                            </Text>
                        </ScrollView>
                    </View>

                    <View style={styles.deliveryAddress}>
                        <Text style={globalStyle.regularText}>
                            {'티켓통 반송주소'}
                        </Text>
                        <Text style={[globalStyle.smGreyText, { marginTop: perfectSize(8) }]}>
                            {'(04084) 서울 마포구 양화진 4길 32 2층 토마토 클래식티켓통 고객센터 환불 담당자'}
                        </Text>
                    </View>

                </View>
                <View style={globalStyle.oneBottomButton}>
                    <Button
                        label={'확인'}
                        buttonStyle={{
                            backgroundColor: c_primary_red,
                        }}
                        onPress={hideModal}
                    />
                </View>
            </View>
        </Modal>
    )
}

const styles = {
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        alignItems: 'stretch',
        paddingHorizontal: perfectSize(20),
        paddingTop: 0,
        paddingBottom: perfectSize(20),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: perfectSize(16),
        borderTopLeftRadius: perfectSize(16),
    },
    headerContainer: {
        alignItems: 'flex-end',
        marginVertical: perfectSize(15),
        marginTop: perfectSize(25)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
    headerTitle: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        textAlign: 'center'
    },
    cancelRule: {
        height: perfectSize(334),
        paddingVertical: perfectSize(20),
        paddingHorizontal: perfectSize(15),
        borderWidth: 1,
        borderColor: c_third_grey,
        marginVertical: perfectSize(15)
    },
    deliveryAddress: {
        padding: perfectSize(15),
        borderRadius: perfectSize(6),
        backgroundColor: c_dark_white,
    },
}