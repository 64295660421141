import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    ActivityIndicator,
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import {
    c_third_grey,
    c_white,
    c_primary_red,
    c_primary_black,
    c_grey
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import BorderButton from '../../components/BorderButton';
import { getInquiry } from '../../store/actions';

function Inquiry({
    navigation,
    user
}) {

    const [loading, setLoading] = useState(false);
    const [inQueries, setInQueries] = useState([]);
    const isFocusedHistory = useIsFocused();

    useEffect(() => {
        navigation.setOptions({
            title: "문의내역",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        getInquires();
    }, [isFocusedHistory]);

    const getInquires = async () => {
        setLoading(true);
        const data = await getInquiry(user._id);
        data.reverse();
        setInQueries(data ? data : []);
        setLoading(false);
    }

    const renderInquries = () => {
        if (loading) return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: perfectSize(40) }}>
                <ActivityIndicator color={c_primary_red} />
            </View>
        );
        if (_.isEmpty(inQueries)) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: perfectSize(40) }}>
                    <Text>{'문의내역이 없습니다.'}</Text>
                </View>
            )
        }

        return inQueries.map((item, index) => {
            return (
                <View style={styles.item} key={index}>
                    <TouchableOpacity onPress={() => navigation.navigate('InquiryDetail', { data: item })}>
                        <Text
                            numberOfLines={1}
                            style={{ ...globalStyle.regularText, width: perfectSize(250) }}>
                            {item.title}
                        </Text>
                        <Text style={{ ...globalStyle.smGreyText, marginTop: perfectSize(5) }}>
                            {moment(item.updatedAt).format('YYYY.MM.DD')}
                        </Text>
                    </TouchableOpacity>
                    <View style={styles.status}>
                        <Text style={[globalStyle.smText, { color: item.answer ? c_primary_black : c_grey }]}>
                            {item.answer ? '답변완료' : '답변대기중'}
                        </Text>
                    </View>
                </View>
            )
        })
    }

    return (
        <ScrollView style={{ ...globalStyle.flex, backgroundColor: c_white }}>
            <View style={styles.mainContainer}>

                <BorderButton
                    onPress={() => navigation.navigate('InquiryRequest', { userId: user._id })}
                    color={c_primary_red}
                    label={'문의하기'}
                    buttonStyle={{
                        marginTop: perfectSize(20),
                        marginBottom: perfectSize(15),
                        height: perfectSize(45),
                        marginHorizontal: perfectSize(15)
                    }}
                />

                <Text style={{ ...globalStyle.regularBoldText, padding: perfectSize(15) }}>
                    {`총 ${inQueries.length}건`}
                </Text>

                {/** List */}
                <View style={styles.list}>
                    {renderInquries()}
                </View>

            </View>
        </ScrollView>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateTopProps)(Inquiry);

const styles = {
    mainContainer: {
        flex: 1,
        paddingBottom: perfectSize(40),
    },
    list: {
        borderTopWidth: perfectSize(1),
        borderColor: c_third_grey,
    },
    item: {
        ...globalStyle.flexSpaceBetween,
        borderBottomWidth: 1,
        borderBottomColor: c_third_grey,
        paddingVertical: perfectSize(22),
        paddingHorizontal: perfectSize(20)
    },
    status: {
        paddingVertical: perfectSize(6),
        paddingHorizontal: perfectSize(13),
        borderWidth: 1,
        borderRadius: perfectSize(3),
        borderColor: c_third_grey
    }
}