import _ from "lodash";
import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, Image, Dimensions } from "react-native";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";

import { UPLOAD_URL } from "../../../variables/constants";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { c_light_grey, c_third_grey } from "../../../variables/colors";
import UrlPreview from "../../../components/UrlPreview";
import Separator from "../../../components/Separator";
import {numberWithCommas } from "../../../utils";
import MediaModal from "../../../modals/MediaModal";
import { getContents } from "../../../store/actions/performance";

const { width } = Dimensions.get("window");

export default function Details({ performance }) {
  const [isMoreNotice, showMoreNotice] = useState(false);
  const [isMoreDescription, showMoreDescription] = useState(false);
  const [isMoreContent, showMoreContent] = useState(false);
  const [imageWidth, setWidth] = useState();
  const [imageHeight, setHeight] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [mediaLink, setMediaLink] = useState("");
  const navigation = useNavigation();
    const [contents, setContents] = useState();

  useEffect(() => {
    Image.getSize(UPLOAD_URL + performance.promotions[0], (w, h) => {
        if (navigator.userAgent.match(/Windows/i)){
            setWidth(w);
            setHeight(h);
        } else {
            setWidth(width - perfectSize(30));
            setHeight((h / w) * (width - perfectSize(30)));
        }
    });
  }, [performance]);

  useEffect(() => {
    getContents(performance._id)
      .then(res => {
        console.log("=======res", res.data.data);
        setContents(res.data.data.contents[0]);
      })
      .catch(err => {});
  }, []);

  return (
    <View style={styles.mainContainer}>
      {/** 공지사항 */}
      <Text style={globalStyle.regularBoldText}>{"공지사항"}</Text>
      <View
        style={{
          marginVertical: perfectSize(15),
          maxHeight: isMoreNotice ? null : perfectSize(88),
          overflow: "hidden",
        }}
      >
        <Text style={globalStyle.regularText}>{performance.notice}</Text>
      </View>

      {/** 더보기 버튼 */}
      <TouchableOpacity
        onPress={() => showMoreNotice(!isMoreNotice)}
        style={styles.loadMoreBtn}
      >
        <Text style={globalStyle.regularGreyText}>{"더보기"}</Text>
        <Image
          source={require("../../../../assets/images/ic_more_detail.png")}
          style={[
            styles.loadMoreBtnIcon,
            { transform: [{ rotate: !isMoreNotice ? "0deg" : "180deg" }] },
          ]}
        />
      </TouchableOpacity>

      <Separator
        height={1}
        color={c_third_grey}
        marginBottom={perfectSize(30)}
      />

      {/** 공연시간 */}
      <Text style={globalStyle.regularBoldText}>{"공연시간"}</Text>
      <View style={styles.subDescription}>
        {performance.schedule.detailed_time.map((item, index) => {
          return (
            <Text key={item._id} style={globalStyle.regularText}>
              {/* {'전일17시(월~토 관람 시)/전일11시(일요일 관람 시) 2021년 1월20일(수) PM:8:00'} */}
              {`${index + 1}회차: ${moment(item.date).format(
                "YYYY년 MM월DD일"
              )} ${item.hour}시 ${item.minute}분`}
            </Text>
          );
        })}
      </View>

      <Separator height={1} color={c_third_grey} />

      {/** 가격정보 */}
      <View style={styles.subTitle}>
        <Text style={globalStyle.regularBoldText}>{"가격정보"}</Text>
      </View>
      <View style={styles.priceInfo}>
        {performance.prices.prices.map(price => {
          return (
            <View key={price._id} style={styles.priceItem}>
              <Text style={globalStyle.regularText}>
                {numberWithCommas(price.grade) +
                  "석 " +
                  numberWithCommas(price.price) +
                  "원"}
              </Text>
            </View>
          );
        })}
      </View>

      <Separator height={1} color={c_third_grey} />

      {/** 공연정보 */}
      <View style={styles.subTitle}>
        <Text style={globalStyle.regularBoldText}>{"공연정보"}</Text>
      </View>
      <View
        style={{
          maxHeight: !isMoreDescription ? perfectSize(120) : null,
          overflow: "hidden",
        }}
      >
        <View
          style={{
            width: imageWidth,
            height: imageHeight,
            overflow: "hidden",
          }}
        >
          <Image
            source={{
              uri: _.isEmpty(performance)
                ? null
                : UPLOAD_URL + performance.promotions[0],
            }}
            style={{
              width: imageWidth,
              height: imageHeight,
            }}
          />
        </View>
        <View style={{ marginTop: perfectSize(15) }}>
          <Text style={globalStyle.regularText}>{performance.description}</Text>
        </View>
      </View>

      {/** 더보기 버튼 */}
      <TouchableOpacity
        onPress={() => showMoreDescription(!isMoreDescription)}
        style={styles.loadMoreBtn}
      >
        <Text style={globalStyle.regularGreyText}>{"더보기"}</Text>
        <Image
          source={require("../../../../assets/images/ic_more_detail.png")}
          style={[
            styles.loadMoreBtnIcon,
            { transform: [{ rotate: !isMoreDescription ? "0deg" : "180deg" }] },
          ]}
        />
      </TouchableOpacity>

      {/** 연주곡 정보 */}
      {/*  {(contents === undefined) ? <View /> :*/}
      {/*      <View>*/}
      {/*          <View style={styles.subTitle}>*/}
      {/*              <Text style={globalStyle.regularBoldText}>{"연주곡 정보"}</Text>*/}
      {/*          </View>*/}
      {/*          <View>*/}
      {/*              <Text*/}
      {/*                  style={{*/}
      {/*                      fontSize: perfectSize(12),*/}
      {/*                      lineHeight: perfectSize(18),*/}
      {/*                      color: "#777777",*/}
      {/*                  }}*/}
      {/*              >*/}
      {/*                  {contents.title}*/}
      {/*              </Text>*/}
      {/*              <Text*/}
      {/*                  style={{*/}
      {/*                      fontSize: perfectSize(14),*/}
      {/*                      lineHeight: perfectSize(22),*/}
      {/*                      color: "#222222",*/}
      {/*                      marginTop: perfectSize(17),*/}
      {/*                      maxHeight: isMoreContent ? null : perfectSize(88),*/}
      {/*                      overflow: "hidden",*/}
      {/*                  }}*/}
      {/*              >*/}
      {/*                  {contents.description}*/}
      {/*              </Text>*/}
      {/*          </View>*/}
      {/*          /!** 더보기 버튼 *!/*/}
      {/*          <TouchableOpacity*/}
      {/*              onPress={() => showMoreContent(!isMoreContent)}*/}
      {/*              style={styles.loadMoreBtn}*/}
      {/*          >*/}
      {/*              <Text style={globalStyle.regularGreyText}>{"더보기"}</Text>*/}
      {/*              <Image*/}
      {/*                  source={require("../../../../assets/images/ic_more_detail.png")}*/}
      {/*                  style={[*/}
      {/*                      styles.loadMoreBtnIcon,*/}
      {/*                      { transform: [{ rotate: !isMoreContent ? "0deg" : "180deg" }] },*/}
      {/*                  ]}*/}
      {/*              />*/}
      {/*          </TouchableOpacity>*/}
      {/*      </View>}*/}

      <MediaModal
        isVisible={visibleModal}
        hideModal={() => setVisibleModal(false)}
        link={mediaLink}
      />
    </View>
  );
}

const styles = {
  mainContainer: {
    flex: 1,
    paddingTop: perfectSize(30),
    paddingHorizontal: perfectSize(15),
  },
  priceInfo: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    marginBottom: perfectSize(30),
  },
  priceItem: {
    width: "50%",
    marginBottom: perfectSize(5),
  },
  subTitle: {
    marginTop: perfectSize(30),
    marginBottom: perfectSize(15),
  },
  subDescription: {
    marginTop: perfectSize(15),
    marginBottom: perfectSize(30),
  },
  showInfo: {
    width: width - perfectSize(30),
    height: "100%",
    resizeMode: "contain",
    backgroundColor: "#eeeeee",
  },
  videoContainer: {
    flexDirection: "row",
    marginBottom: perfectSize(15),
  },
  videoTitle: {
    paddingHorizontal: perfectSize(10),
    flex: 1,
  },
  youtubeIcon: {
    width: perfectSize(55),
    height: perfectSize(12),
    marginBottom: perfectSize(8),
    resizeMode: "contain",
  },
  loadMoreBtn: {
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    height: perfectSize(40),
    backgroundColor: "white",
  },
  loadMoreBtnIcon: {
    width: perfectSize(12),
    height: perfectSize(7),
    resizeMode: "contain",
    marginLeft: perfectSize(8),
  },
  musicSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: perfectSize(15),
  },
};
