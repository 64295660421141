import React, {useEffect, useState} from "react";
import {Image, TouchableOpacity, View, Text, Dimensions, ScrollView} from "react-native";
import {globalStyle, perfectSize} from "../../variables/styles";
import {connect} from "react-redux";
import {c_fourth_grey, c_primary_black, c_primary_red, c_third_grey, c_white} from "../../variables/colors";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {getAvailableTicket, logoutMember, termsOfService, DeleteMember} from "../../store/actions";
import _ from "lodash";
import HTML from "react-native-render-html";
import CheckBox from "../../components/CheckBox";
import Button from "../../components/Button";
import OneAlertModal from "../../modals/OneAlertModal";
import {oneAlert} from "../../utils";
import TwoAlertModal from "../../modals/TwoAlertModal";
import CustomTextInput from "../../components/CustomTextInput";

const { width } = Dimensions.get('window');
function LeaveMember({ navigation, user, logoutMember, DeleteMember}) {

    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [contents, setContents] = useState();
    const [isStatus, setStatus] = useState(0);
    const [termAll, setTermAll] = useState(false);
    const [visibleAlertModal, setVisibleAlertModal] = useState(false);
    const [isAlertText, setAlertText] = useState('');
    const [visibleTwoAlertModal, setvisibleTwoAlertModal] = useState(false);
    const [isTwoAlertText, setTwoAlertText] = useState('');

    useEffect(() => {
        navigation.setOptions({
            title: "회원 탈퇴",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
        getTermsOfService();
        getTicketStatus();
    }, []);

    const getTermsOfService = async () => {
        const res = await termsOfService();
        if (!_.isEmpty(res)) {
            setContents(res);
        }
    }
    const getTicketStatus = async () => {
        const res = await getAvailableTicket(user?._id);
        if (!_.isEmpty(res)) {
            setStatus(res.status);
        }
    }
    const onLeave = () => {
        if (((user?.subscribe && user?.subscribe[0]?.status === "ACTIVE"))) {
            showAlertWithText(<Text>
                현재 매니아 회원 구독 중입니다. 구독 종료 후 탈퇴하실 수 있습니다.
                {"\n\n"}
                {"   "}
                <Text style={{ color: c_fourth_grey, fontSize: perfectSize(14), letterSpacing: -0.14}}>
                    문의: 02-2128-3883</Text>
            </Text>);
            return
        } else if (isStatus === 1) {
            showAlertWithText(<Text>
                <Text style={{
                    color: c_primary_black,
                    fontSize: perfectSize(14),
                    letterSpacing: -0.14,}}>
                    현재 예매하신 티켓이 있습니다.
                    공연 관람 후, 또는 취소 후
                    탈퇴하실 수 있습니다.</Text>
            </Text>);
            return
        }
        setvisibleTwoAlertModal(true);
        setTwoAlertText('정말 탈퇴하시겠습니까?')
    }

    const onLeaveMember = async () => {
        const res = await DeleteMember(user._id, password)
        if (res?.status === 0) {
            if (res?.code === 1015) {
                showAlertWithText("비밀번호를 확인해주세요.")
            } else {
                showAlertWithText(<Text>
                    장애가 발생하였습니다.
                    {"\n\n"}
                    {"   "}
                    <Text style={{ color: c_fourth_grey, fontSize: perfectSize(14), letterSpacing: -0.14}}>
                        문의: 02-2128-3887</Text>
                </Text>);
            }
            return
        } else if (res.status === 1) {
            navigation.navigate('Home');
            // Toast.show('회원탈퇴가 완료되었습니다.', Toast.SHORT);
        }
    }
    const showAlertWithText = (text) => {
        setVisibleAlertModal(true);
        setAlertText(text);
    };
    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView style={{ flex: 1 }}>
                <View style={styles.mainContainer}>
                    <Text style={{
                        fontSize: perfectSize(11),
                        letterSpacing: -0.01,
                        color: c_fourth_grey,
                        fontWeight: 'normal',
                        marginBottom: perfectSize(5)
                    }}>비밀번호 확인</Text>
                    <CustomTextInput
                        value={password}
                        warningText={'틀린 비밀번호 입니다.'}
                        isWarn={error}
                        placeholder={'비밀번호입력'}
                        value={password}
                        onChangeText={text => {
                            setPassword(text);
                            setError(false);
                        }}
                        isSecure={true}
                        isShowEye={true}
                    />
                    <View style={{
                        marginTop: perfectSize(30),
                        marginBottom: perfectSize(20),
                        backgroundColor: c_third_grey,
                        width: '100%',
                        height: perfectSize(3)}}/>
                    <View>
                        <Text style={{
                            fontSize: perfectSize(15),
                            letterSpacing: -0.15,
                            color: c_primary_black,
                            fontWeight: 'bold',
                            marginBottom: perfectSize(20)
                        }}>탈퇴 유의사항</Text>
                        {
                            contents &&
                            <HTML
                                source={{ html: contents[8].content }}
                                baseFontStyle={styles.baseFontStyle}
                                tagsStyles={styles.tagsStyles}
                                containerStyle={styles.containerStyle}
                                contentWidth={width}
                            />
                        }

                    </View>
                    <View style={{
                        marginTop: perfectSize(30),
                        marginBottom: perfectSize(21),
                        backgroundColor: c_third_grey,
                        width: '100%',
                        height: perfectSize(3)}}/>
                    <View>
                        <CheckBox
                            isSelected={termAll}
                            onSelect={() => {
                                setTermAll(!termAll);
                            }}
                            label={
                                <Text style={{
                                    fontSize: perfectSize(14),
                                    letterSpacing: -0.14,
                                    color: c_primary_black,
                                }}>
                                    유의사항을 모두 확인하였으며 이에 동의합니다.
                                </Text>
                            }
                        />
                    </View>
                    <View style={{ marginBottom: perfectSize(100)}}/>
                    <View style={styles.bottomView}>
                        <Button
                            onPress={onLeave}
                            textColor={c_primary_red}
                            label={'회원탈퇴'}
                            color={c_white}
                            isBorder={true}
                            disable={(termAll && password.length > 1) ? false : true}
                        />
                    </View>
                </View>
            </ScrollView>
            {visibleAlertModal &&
                <OneAlertModal
                    isVisible={visibleAlertModal}
                    hideModal={() => {
                        setVisibleAlertModal(false);
                    }}
                    contents={isAlertText}
                    txt_type={"nomal"}
                />
            }
            {visibleTwoAlertModal &&
                <TwoAlertModal
                    isVisible={visibleTwoAlertModal}
                    hideModal={() => {
                        setvisibleTwoAlertModal(false);
                    }}
                    contents={isTwoAlertText}
                    method = {onLeaveMember}
                />
            }
        </View>
    )
}



const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps, {logoutMember, DeleteMember})(LeaveMember);

const styles = {
    mainContainer: {
        flex: 1,
        marginTop: perfectSize(20),
        paddingHorizontal: perfectSize(21)
    },
    mainContent: {
        flex: 1,
        padding: perfectSize(15)
    },
    baseFontStyle: {
        fontSize: 18,
        textAlign: 'center',
    },
    tagsStyles: {
        p: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    containerStyle: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomView: {
        position: 'absolute',
        bottom: perfectSize(30),
        left: perfectSize(21),
        right: perfectSize(21)
    },
}