import React, { useState, useEffect, useRef } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    Modal,
    TextInput,
    ScrollView,
    Platform,
} from 'react-native';
import {
    c_primary_black,
    c_white,
    c_primary_red,
    c_ligth_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Tag from '../../components/Tag';
import { SafeAreaView } from 'react-native-safe-area-context';
import { storeData, getData } from '../../utils';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

export default function Search({
    navigation,
    route
}) {

    const [searchText, setSearchText] = useState('');
    const [searchHistory, setSearchHistory] = useState([]);
    const SearchInputRef = useRef();
    const { sword } = route.params;

    useEffect(() => {
        SearchInputRef.current.focus();
        getSearchHistory();
    }, []);

    const getSearchHistory = async () => {
        const data = await getData('search');
        data && setSearchHistory(data);
    };

    const onSearch = async (text) => {
        let newArray = searchHistory ? searchHistory : [];
        let _searchText = text ? text : searchText;
        if (!_searchText) {
            alert('검색어를 입력해주세요.');
        } else if (!searchHistory || !searchHistory.includes(_searchText)) {
            newArray.splice(0, 0, _searchText);
            setSearchText('');
            setSearchHistory([...newArray]);
            await storeData('search', newArray);
            navigation.navigate('SearchResult', { searchWord: _searchText.trim() });
        } else {
            navigation.navigate('SearchResult', { searchWord: _searchText.trim() });
        }
    };

    const onResetHistory = async () => {
        setSearchHistory([]);
        await storeData('search', []);
    };

    const onRemoveHistory = async (index) => {
        let newArray = searchHistory;
        newArray.splice(index, 1);
        setSearchHistory([...newArray]);
        await storeData('search', newArray);
    }

    return (
        <SafeAreaView style={styles.container}>
            <KeyboardAwareScrollView>
                <View style={{
                    padding: perfectSize(15)
                }}>
                    <TouchableOpacity
                        onPress={() => navigation.goBack()}
                        style={{ alignItems: 'flex-end', marginTop: perfectSize(14) }}>
                        <Text style={globalStyle.normalText}>{'닫기'}</Text>
                    </TouchableOpacity>

                    <View style={styles.searchInput}>
                        <TextInput
                            ref={SearchInputRef}
                            value={searchText}
                            onChangeText={text => setSearchText(text)}
                            onSubmitEditing={() => onSearch('')}
                            returnKeyType="search"
                            style={[globalStyle.regularText, { flex: 1 }]}
                            placeholder={'검색어를 입력해주세요'} />
                        {/* <TouchableOpacity onPress={() => { hideModal(); navigation.navigate('Search') }}> */}
                        <TouchableOpacity onPress={() => onSearch('')}>
                            <Image
                                source={require('../../../assets/images/ic_search_b.png')}
                                style={globalStyle.searchIcon}
                            />
                        </TouchableOpacity>
                    </View>
                    <Text style={globalStyle.bigBoldText}>{'추천검색어'}</Text>
                    <View style={styles.tagsContainer}>
                        {
                            sword &&
                            sword.map((item, index) => {
                                return (
                                    <View key={index} style={{ marginBottom: perfectSize(10) }}>
                                        <Tag label={item.name} color={c_primary_black} onPress={() => onSearch(item.name)} />
                                    </View>
                                )
                            })
                        }
                    </View>
                    <View style={styles.checklist}>
                        <Text style={globalStyle.bigBoldText}>{'검색기록'}</Text>
                        <TouchableOpacity onPress={onResetHistory}>
                            <Text style={globalStyle.greySmText}>{'전체삭제'}</Text>
                        </TouchableOpacity>
                    </View>

                    {/** Search History List */}
                    {
                        searchHistory &&
                        searchHistory.map((item, index) => {
                            return (
                                <View key={index} style={styles.historyList}>
                                    <View style={globalStyle.flexSpaceBetween}>
                                        <TouchableOpacity
                                            onPress={() => navigation.navigate('SearchResult', { searchWord: item.trim() })}
                                            style={globalStyle.flex}>
                                            <Text style={globalStyle.regularText}>{item}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => onRemoveHistory(index)}>
                                            <Image
                                                source={require('../../../assets/images/ic_delete.png')}
                                                style={styles.deleteIcon}
                                            />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )
                        })
                    }
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    )
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: c_white,
    },
    searchInput: {
        height: perfectSize(40),
        borderColor: c_primary_red,
        borderWidth: 1,
        borderRadius: perfectSize(6),
        marginVertical: perfectSize(20),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: perfectSize(15)
    },
    tagsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: perfectSize(15),
        marginBottom: perfectSize(20)
    },
    checklist: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: perfectSize(8)
    },
    deleteIcon: {
        width: perfectSize(10),
        height: perfectSize(10),
        resizeMode: 'contain',
    },
    historyList: {
        paddingVertical: perfectSize(12),
        borderBottomColor: c_ligth_red,
        borderBottomWidth: 1
    },
}
