import React, { useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';

export default function RadioButton({
    label,
    isSelected,
    style,
    onSelect
}) {

    return (
        <TouchableOpacity
            onPress={() => {
                onSelect(!isSelected);
            }}
            style={[
                globalStyle.flexRow,
                style
            ]}>
            <Image
                source={
                    isSelected ? require('../../assets/images/radio_on.png')
                        : require('../../assets/images/radio_off.png')
                }
                style={{
                    width: perfectSize(18),
                    height: perfectSize(18),
                    resizeMode: 'contain',
                    marginRight: perfectSize(8)
                }}
            />
            <Text style={globalStyle.regularText}>
                {label}
            </Text>
        </TouchableOpacity>
    )
}