import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {
    Dimensions,
    View,
    Image,
    SafeAreaView,
    TouchableOpacity,
    ScrollView, Text, TextInput, Alert, Platform
} from 'react-native';
import HTML from 'react-native-render-html';
import {checkResetPasscode, termsOfService} from '../../store/actions';
import {globalStyle, perfectSize} from '../../variables/styles';
import Button from "../../components/Button";
import {
    c_fourth_white,
    c_light_grey,
    c_primary_black, c_primary_blue,
    c_primary_red,
    c_third_grey,
    c_white
} from "../../variables/colors";
import {oneAlert} from "../../utils";
import {sendSMS} from "../../store/actions/performance";
import {connect} from "react-redux";

const {width} = Dimensions.get('window');

function Mania_recommend({
                             isAuthenticated,
                             user,
                             navigation
                         }) {

    const sUsrAg = navigator.userAgent;

    const [fromNameText, setFromNameText] = useState('');
    const [fromPhoneNumber, setFromPhoneNumber] = useState('');
    const [toPhoneNumber, setToPhoneNumber] = useState('');
    const [isRecommendText, setRecommendText] = useState('');
    const [isNameText, setNameText] = useState('');

    useEffect(() => {
        navigation.setOptions({
            title: '티켓통 매니아 추천',
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.navigate('Main')}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    const onJoin = async () => {
        if (!isAuthenticated) {
            oneAlert('로그인이 필요한 서비스입니다.')
            navigation.navigate("SignIn", {screenName: 'Mania_recommend'})
            return;
        }
        if (isNameText === '') {
            oneAlert('이름을 입력해주세요')
            return;
        }
        if (toPhoneNumber === '') {
            oneAlert('전화번호를 입력해주세요')
            return;
        }
        await sendSMS(user._id, toPhoneNumber, `${user.name} 님이 ${isNameText}님에게 티켓통 매니아 정기구독을 추천합니다.\n` + isRecommendText).then(
            res => {
                if (res.status === 2 || res.status === 3) {
                    oneAlert('매니아 회원만 추천 가능합니다.');
                } else if (res.status === 1) {
                    oneAlert('추천해주셔서 감사합니다.');
                } else {
                    oneAlert('발송실패 하였습니다.');
                }
            }
        ).catch(err => oneAlert('발송실패 하였습니다.'));
    };

    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}>
                <View style={styles.scrollContainer}>
                    <View style={{
                        width: perfectSize(330),
                        height: perfectSize(550),
                        marginTop: perfectSize(15),
                        marginLeft: perfectSize(15),
                        marginRight: perfectSize(15),
                        backgroundColor: c_fourth_white
                    }
                    }>
                        <Text style={styles.contentText}>친구나 지인에게 티켓통 매니아 가입을 추천해주세요.</Text>
                        <Text style={styles.contentText}>추천 받은 분이 가입하시면 추천한 분께 </Text>
                        <Text style={styles.contentText}>약정액의 5%를TTC로 보너스 지급합니다</Text>
                        <Text style={[styles.contentText, {color: c_primary_blue, marginTop: perfectSize(10), marginBottom: perfectSize(10)}]}>추천은 매니아 구독 중인 회원만 가능합니다.</Text>
                        <Text style={[styles.phoneText, {
                            color: "#777777",
                        }
                        ]}>추천하는 분</Text>
                        <TextInput
                            value={isAuthenticated ? user.name : fromNameText}
                            placeholder={'이름을 입력해주세요.'}
                            onChangeText={text => {
                                if (isAuthenticated) {
                                    setFromNameText(text)
                                } else {
                                    oneAlert('로그인이 필요한 서비스입니다.')
                                    navigation.navigate("SignIn", {screenName: 'Mania_recommend'})
                                }
                            }}
                            style={styles.textInput}
                        />
                        <TextInput
                            value={isAuthenticated ? user.decPhoneNum : fromPhoneNumber}
                            keyboardType={'number-pad'}
                            placeholder={'휴대폰번호를 입력해주세요.'}
                            onChangeText={text => {
                                if (isAuthenticated) {
                                    setFromPhoneNumber(text)
                                } else {
                                    oneAlert('로그인이 필요한 서비스입니다.')
                                    navigation.navigate("SignIn", {screenName: 'Mania_recommend'})
                                }
                            }}
                            style={styles.textInput}
                        />

                        <Text style={[styles.phoneText, {
                            color: "#dd3c3c",
                        }
                        ]}>추천받는 분</Text>
                        <TextInput
                            value={isNameText}
                            placeholder={'이름을 입력해주세요.'}
                            onChangeText={text => {
                                if (isAuthenticated) {
                                    setNameText(text)
                                } else {
                                    oneAlert('로그인이 필요한 서비스입니다.')
                                    navigation.navigate("SignIn", {screenName: 'Mania_recommend'})
                                }
                            }}
                            style={styles.textInput}
                        />
                        <TextInput
                            value={toPhoneNumber}
                            keyboardType={'number-pad'}
                            placeholder={'휴대폰번호를 입력해주세요.'}
                            onChangeText={text => {
                                if (isAuthenticated) {
                                    setToPhoneNumber(text)
                                } else {
                                    oneAlert('로그인이 필요한 서비스입니다.')
                                    navigation.navigate("SignIn", {screenName: 'Mania_recommend'})
                                }
                            }}
                            style={styles.textInput}
                        />
                        <Text style={[styles.phoneText, {
                            color: "#777777",
                        }
                        ]}>추천 메세지 직접 입력</Text>
                        <TextInput
                            value={isRecommendText}
                            placeholder={'특별히 하실 말씀 적어주시면 같이 문자로 발송해 드립니다'}
                            onChangeText={text => setRecommendText(text)}
                            style={[styles.textRecommend, {padding: perfectSize(0), textAlign: 'left'}]}
                            multiline={true}
                        />
                    </View>
                </View>
            </ScrollView>
            {Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                    <View
                        style={[
                            globalStyle.flexRow,
                            {
                                height: perfectSize(65),
                                marginBottom: perfectSize(50),
                            },
                        ]}
                    >
                        <View style={{flex: 1, marginRight: perfectSize(5), marginLeft: perfectSize(15)}}>
                            <Button
                                onPress={() => navigation.navigate('Main')}
                                isBorder={true}
                                textColor={c_primary_red}
                                label={"취소"}
                                color={c_white}
                                borderColor={c_primary_red}
                            />
                        </View>
                        <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                            <Button
                                onPress={onJoin}
                                isBorder={true}
                                textColor={c_white}
                                label={"추천"}
                                color={c_primary_red}
                            />
                        </View>
                    </View> :
                    <View
                        style={[
                            globalStyle.flexRow,
                            {
                                height: perfectSize(65),

                            },
                        ]}
                    >
                        <View style={{flex: 1, marginRight: perfectSize(5), marginLeft: perfectSize(15)}}>
                            <Button
                                onPress={() => navigation.navigate('Main')}
                                isBorder={true}
                                textColor={c_primary_red}
                                label={"취소"}
                                color={c_white}
                                borderColor={c_primary_red}
                            />
                        </View>
                        <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                            <Button
                                onPress={onJoin}
                                isBorder={true}
                                textColor={c_white}
                                label={"추천"}
                                color={c_primary_red}
                            />
                        </View>
                    </View> :
                <View/>
            }
        </View>
    )
}

const mapStateTopProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});
export default connect(mapStateTopProps)(Mania_recommend);

const styles = {
    scrollContainer: {
        padding: perfectSize(15)
    },
    headText: {
        fontSize: perfectSize(18),
        letterSpacing: -0.18,
        color: "#222222",
        marginTop: perfectSize(20),
        marginLeft: perfectSize(20),
        marginBottom: perfectSize(20)
    },
    contentText: {
        fontSize: perfectSize(15),
        letterSpacing: -0.15,
        color: "#222222",
        marginLeft: perfectSize(18),
    },
    phoneText: {
        fontSize: perfectSize(11),
        letterSpacing: -0.11,
        marginLeft: perfectSize(18),
        marginTop: perfectSize(30),
    },
    textInput: {
        fontSize: perfectSize(14),
        color: c_primary_black,
        borderWidth: 1,
        borderColor: c_third_grey,
        borderRadius: perfectSize(6),
        height: perfectSize(40),
        marginLeft: perfectSize(18),
        marginRight: perfectSize(20),
        marginTop: perfectSize(17),
        paddingLeft: perfectSize(15)
    },
    textRecommend: {
        fontSize: perfectSize(14),
        color: c_primary_black,
        borderWidth: 1,
        borderColor: c_third_grey,
        borderRadius: perfectSize(6),
        height: perfectSize(98),
        marginLeft: perfectSize(18),
        marginRight: perfectSize(20),
        marginTop: perfectSize(17),
        paddingLeft: perfectSize(15)
    },
}