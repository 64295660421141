import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    ActivityIndicator,
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
} from 'react-native';
import moment from 'moment';
import { useIsFocused } from '@react-navigation/native';

import {
    c_white,
    c_grey,
    c_light_grey,
    c_second_grey,
    c_primary_red,
    c_black,
    c_third_grey
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import { getMobileTickets, getOrders } from '../../store/actions';
import { UPLOAD_URL } from '../../variables/constants';
import { makeTwoString } from '../../utils';
import { SafeAreaView } from 'react-native-web';
import BarcodeModal from '../../modals/BarcodeModal';

function MobileTicket({ navigation, user }) {

    const [loading, setLoading] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [barcode, setBarCode] = useState('');
    const [tickets, setTickets] = useState([]);
    const isFocusedHistory = useIsFocused();
    const days = ['일', '월', '화', '수', '목', '금', '토'];

    useEffect(() => {
        navigation.setOptions({
            title: "모바일티켓",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.navIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        getOrdersDetail();
    }, [isFocusedHistory]);

    const getOrdersDetail = async () => {
        setLoading(true);
        const res = await getMobileTickets(user._id);
        if (res) {
            setTickets(res);
        } else {
            setTickets([]);
        }
        setLoading(false);
    };

    const renderTickets = () => {
        if (loading) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator color={c_primary_red} />
                </View>
            )
        }
        if (tickets.length < 1) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Text>{'보유중인 모바일티켓이 없습니다.'}</Text>
                </View>
            )
        }

        return (
            <ScrollView>
                {
                    tickets.map(ticket => {
                        let ticketDate =  '';
                        let hour =  '';
                        let minute =  '';
                        let museum = '';
                        let link = '';
                        let titleName = '';
                        if (ticket.product_id == null) {
                            console.log('ticket data is null!')
                        } else {
                            ticketDate = ticket.product_id.schedule ? ticket.product_id.schedule.detailed_time[ticket.round].date : '';
                            hour = ticket.product_id.schedule ? ticket.product_id.schedule.detailed_time[ticket.round].hour : '';
                            minute = ticket.product_id.schedule ? ticket.product_id.schedule.detailed_time[ticket.round].minute : '';
                            museum = ticket.product_id.location.name;
                            link = ticket.product_id.poster_link;
                            titleName = ticket.product_id.title.name;
                        }
                        return (
                            <View style={{ marginBottom: perfectSize(20) }} key={ticket._id}>
                                <View
                                    activeOpacity={1}>
                                    <TouchableOpacity style={styles.cardTopSection}
                                        onPress={() => navigation.navigate('MobileTicketDetail', { ticket: ticket })}>
                                        <View style={globalStyle.flexRow}>
                                            <Image
                                                source={{ uri: (UPLOAD_URL + link) }}
                                                style={styles.image}
                                            />
                                            <View style={globalStyle.flex}>
                                                <Text style={globalStyle.regularText}>
                                                    {titleName}
                                                </Text>
                                                <Text style={styles.date}>
                                                    {moment(ticketDate).format('YYYY.MM.DD')}
                                                </Text>
                                                <Text style={styles.time}>
                                                    {`${days[new Date(ticketDate).getDay()]} ${makeTwoString(hour)}:${makeTwoString(minute)}`}
                                                </Text>
                                                <Text style={{
                                                    fontSize: perfectSize(13),
                                                    color: c_primary_red,
                                                    marginTop: perfectSize(12)
                                                }}>
                                                    {`${museum} >`}
                                                </Text>
                                                <Text style={styles.position}>
                                                    {ticket.seat_name}
                                                </Text>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                    <View style={styles.dotLine} />
                                    <View style={styles.cardBottomSection}>
                                        <View style={globalStyle.flexSpaceBetween}>
                                            <View>
                                                <Text style={[globalStyle.smGreyText, { marginBottom: perfectSize(2) }]}>
                                                    {'티켓번호'}
                                                </Text>
                                                <Text style={[globalStyle.bigBoldText, { width: perfectSize(180) }]} numberOfLines={1}>
                                                    {ticket._id.toUpperCase()}
                                                </Text>
                                            </View>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    setBarCode(ticket.barcode);
                                                    setVisibleModal(true);
                                                }}
                                                style={globalStyle.flexRow}>
                                                <Text style={globalStyle.regularText}>
                                                    {'||||||||| 티켓 바코드'}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                                {/* <View style={[globalStyle.flexRow, styles.addtionalBar]}> */}
                                {/* <View style={[globalStyle.flex, { alignItems: 'center' }]}>
                                            <Text style={styles.leftTimeLabel}>
                                                {'티켓인증까지\n남은 시간'}
                                            </Text>
                                        </View>
                                        <Text style={styles.leftTime}>
                                            {'21:54:17'}
                                        </Text>
                                        <View style={styles.split} /> */}
                                {/* <TouchableOpacity
                                            onPress={() => navigation.navigate('TicketGift', { ticket: ticket, data: data })}
                                            style={globalStyle.flex}>
                                            <Text style={styles.gift}>
                                                {'선물하기'}
                                            </Text>
                                        </TouchableOpacity>
                                        <View style={styles.split} />
                                        <TouchableOpacity style={globalStyle.flex} disabled>
                                            <Text style={styles.ticketConfirm}>
                                                {'티켓인증'}
                                            </Text>
                                        </TouchableOpacity>
                                    </View> */}
                            </View>
                        )
                    })
                }
            </ScrollView>
        )
    }

    return (
        <View style={styles.mainContainer}>
            <View style={[globalStyle.flexSpaceBetween, styles.headerTitle]}>
                <Text style={globalStyle.regularText}>
                    {'보유중인 모바일티켓'}
                </Text>
                <Text style={globalStyle.regularText}>
                    {`총 ${tickets.length}매`}
                </Text>
            </View>
            {renderTickets()}
            <BarcodeModal
                hideModal={() => setVisibleModal(false)}
                isVisible={visibleModal}
                data={barcode}
            />
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateTopProps)(MobileTicket);

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white
    },
    headerTitle: {
        padding: perfectSize(15),
        marginTop: perfectSize(5)
    },
    cardTopSection: {
        elevation: 2,
        shadowColor: c_grey,
        shadowOffset: { x: 2, y: 6 },
        shadowRadius: 4,
        shadowOpacity: 0.3,
        marginTop: perfectSize(10),
        marginHorizontal: perfectSize(15),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
    },
    cardBottomSection: {
        elevation: 2,
        shadowColor: c_grey,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        marginHorizontal: perfectSize(15),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
        paddingHorizontal: perfectSize(15),
        paddingVertical: perfectSize(7),
    },
    image: {
        width: perfectSize(110),
        height: perfectSize(160),
        marginRight: perfectSize(15),
        borderRadius: 6,
        resizeMode: 'stretch',
        backgroundColor: c_third_grey,
    },
    dotLine: {
        borderWidth: 1,
        marginHorizontal: perfectSize(25),
        borderColor: c_third_grey,
        borderStyle: 'dashed',
        backgroundColor: c_white
    },
    date: {
        fontSize: perfectSize(26),
        marginTop: perfectSize(8)
    },
    time: {
        fontSize: perfectSize(18),
        color: c_grey,
        marginTop: perfectSize(2)
    },
    position: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        marginTop: perfectSize(6)
    },
    borderIcon: {
        width: perfectSize(32),
        height: perfectSize(32),
        padding: perfectSize(6),
        paddingLeft: perfectSize(4),
        borderWidth: 1,
        borderRadius: perfectSize(4),
        borderColor: c_light_grey,
        marginLeft: perfectSize(8)
    },
    addtionalBar: {
        borderWidth: 1,
        borderRadius: 6,
        borderColor: c_second_grey,
        height: perfectSize(50),
        marginHorizontal: perfectSize(15),
        marginTop: perfectSize(10),
    },
    leftTimeLabel: {
        fontSize: perfectSize(11),
        letterSpacing: -0.15,
        color: c_grey,
        textAlign: 'right'
    },
    leftTime: {
        flex: 1,
        fontSize: perfectSize(18),
        color: c_primary_red,
        fontWeight: 'bold',
        textAlign: 'left'
    },
    split: {
        width: 1,
        height: 15,
        backgroundColor: c_light_grey
    },
    gift: {
        fontSize: perfectSize(13),
        color: c_black,
        textAlign: 'center'
    },
    ticketConfirm: {
        fontSize: perfectSize(13),
        color: c_light_grey,
        textAlign: 'center'
    },
}