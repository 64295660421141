import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput
} from 'react-native';
import {
    c_black,
    c_primary_black,
    c_second_white,
    c_third_grey,
    c_white,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { UPLOAD_URL } from '../../variables/constants';
import { makeTwoString } from '../../utils';
import { addMemoTicket } from '../../store/actions';

export default function TicketBookDetail({ navigation, route }) {

    const { data } = route.params;
    const days = ['일', '월', '화', '수', '목', '금', '토'];
    const [memo, setMemo] = useState(data.memo);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        navigation.setOptions({
            title: "티켓북",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    const onTicketRevisit = async () => {
        setLoading(true);
        const res = await addMemoTicket(data._id, memo);
        if (res) navigation.navigate('TicketRevisit', { data });
        setLoading(false);
    }

    return (
        <View style={globalStyle.flex}>
            <KeyboardAwareScrollView>
                <View style={styles.scrollView}>
                    <View style={styles.orderConfirmCard}>
                        <View style={{ padding: perfectSize(15), paddingBottom: 0 }}>
                            <Image
                                source={{ uri: (UPLOAD_URL + data.product_id.poster_link) }}
                                style={{
                                    width: '100%',
                                    height: perfectSize(420),
                                    resizeMode: 'stretch',
                                    backgroundColor: c_second_white,
                                    marginBottom: perfectSize(15),
                                    borderRadius: perfectSize(6),
                                }}
                            />
                            <Text style={globalStyle.bigBoldText}>
                                {data.product_id.title.name}
                            </Text>

                            <Text style={{ ...globalStyle.greySmText, marginTop: perfectSize(12) }}>
                                {'일시'}
                            </Text>
                            <Text style={{ ...globalStyle.normalText, marginTop: perfectSize(5) }}>
                                {`${moment(data.product_id.schedule.detailed_time[data.round].date).format('YYYY.MM.DD')}(${days[new Date(data.product_id.schedule.detailed_time[data.round].date).getDay()]}) ${data.product_id.schedule.detailed_time[data.round].hour}:${makeTwoString(data.product_id.schedule.detailed_time[data.round].minute)}`}
                            </Text>

                            <Text style={{ ...globalStyle.greySmText, marginTop: perfectSize(12) }}>
                                {'장소'}
                            </Text>
                            <Text style={{ ...globalStyle.normalText, marginTop: perfectSize(5) }}>
                                {data.product_id.location.name}
                            </Text>

                            <Text style={{ ...globalStyle.greySmText, marginTop: perfectSize(12) }}>
                                {'좌석'}
                            </Text>
                            <Text style={{ ...globalStyle.normalText, marginTop: perfectSize(5) }}>
                                {`${data.seat_grade}석 1매`}
                            </Text>

                            <Text style={{ ...globalStyle.greySmText, marginTop: perfectSize(12) }}>
                                {'메모'}
                            </Text>
                            <View style={styles.textInput}>
                                <TextInput
                                    editable={!data.isReviewed}
                                    value={memo}
                                    onChangeText={text => text.length < 100 && setMemo(text)}
                                    multiline={true}
                                    placeholder={'메모를 적어주세요(최대 100자)'}
                                    style={{
                                        ...globalStyle.regularGreyText,
                                        color: c_primary_black,
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
            <View style={globalStyle.oneBottomButton}>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={onTicketRevisit}
                        textColor={c_white}
                        label={'재관람 할인 확인'}
                        color={c_primary_red}
                        isBorder={false}
                        loading={loading}
                        disable={data.isReviewed}
                    />
                </View>
            </View>
        </View>
    )
}

const styles = {
    orderConfirmCard: {
        marginBottom: perfectSize(20),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
        shadowColor: c_black,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        elevation: 2,
    },
    scrollView: {
        paddingHorizontal: perfectSize(17),
        paddingVertical: perfectSize(20)
    },
    textInput: {
        borderWidth: 1,
        borderColor: c_third_grey,
        borderRadius: perfectSize(6),
        height: perfectSize(94),
        marginTop: perfectSize(7),
        marginBottom: perfectSize(20),
        padding: perfectSize(12)
    },
}