import React, { useState } from "react";
import {
  View,
  Image,
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
} from "react-native";
import { globalStyle, perfectSize } from "../variables/styles";
import Modal from "react-native-modal";
import WebModal from "modal-react-native-web";
import { c_primary_black, c_primary_red, c_white } from "../variables/colors";
import { oneAlert } from "../utils";

const height = Dimensions.get("window").height;

function RadioButton(props) {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[
        {
          height: 18,
          width: 18,
          borderRadius: 12,
          borderWidth: 2,
          borderColor: c_primary_red,
          alignItems: "center",
          justifyContent: "center",
        },
        props.style,
      ]}
    >
      {props.selected ? (
        <View
          style={{
            height: 10,
            width: 10,
            borderRadius: 6,
            backgroundColor: c_primary_red,
          }}
        />
      ) : null}
    </TouchableOpacity>
  );
}

export default function BuyNFTModal({
  isVisible,
  hideModal,
  data,
  coinIcon,
  coinPrice,
  coinWonPrice,
  buyNFT,
}) {
  const [isChecked, setIsChecked] = useState(false);

  const modalContent = () => {
    return (
      <View style={styles.swipeModal}>
        <Text
          style={{
            fontSize: perfectSize(14),
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: perfectSize(10),
          }}
        >
          {"구매하기"}
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: perfectSize(14),
              flex: 1,
              marginRight: perfectSize(10),
            }}
          >
            {data.content?.title}
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Image
              source={{ uri: coinIcon }}
              style={{ width: perfectSize(20), height: perfectSize(20) }}
            />
            <View>
              <Text>{coinPrice}</Text>
              <Text>{coinWonPrice}원</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            marginVertical: perfectSize(10),
            justifyContent: "center",
            alignItems: 'center'
          }}
        >
          <RadioButton
            selected={isChecked}
            onPress={() => setIsChecked(!isChecked)}
          />
          <Text
            style={{
              marginLeft: perfectSize(4),
              fontSize: perfectSize(12),
            }}
          >
            {"NFT 구매 약관에 동의합니다. (NFT정보 하단 참조)"}
          </Text>
        </View>

        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <TouchableOpacity
            onPress={hideModal}
            style={{
              width: "30%",
              height: perfectSize(30),
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "grey",
              marginHorizontal: perfectSize(5),
            }}
          >
            <Text style={{ fontSize: perfectSize(12), color: "white" }}>
              {"취소"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (!isChecked) {
                oneAlert('NFT구매 약관 확인 후 동의해주세요.')
              } else {
                buyNFT();
              }
            }}
            style={{
              width: "30%",
              height: perfectSize(30),
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: c_primary_red,
              marginHorizontal: perfectSize(5),
            }}
          >
            <Text style={{ fontSize: perfectSize(12), color: "white" }}>
              {"구매"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return Platform.OS !== "web" ? (
    <Modal
      transparent={true}
      isVisible={isVisible}
      onBackdropPress={hideModal}
      onSwipeComplete={hideModal}
    >
      {modalContent()}
    </Modal>
  ) : (
    <WebModal transparent={true} visible={isVisible}>
      <View
        style={{
          flex: 1,
          backgroundColor: "#00000099",
        }}
      >
        {modalContent()}
      </View>
    </WebModal>
  );
}

const styles = {
  swipeModal: {
    backgroundColor: "white",
    padding: perfectSize(20),
    paddingBottom: perfectSize(30),
  },
  swipeModalContainer: {
    paddingHorizontal: 0,
    backgroundColor: c_white,
  },
  closeBtn: {
    alignSelf: "flex-end",
    marginVertical: perfectSize(15),
  },
  closeIcon: {
    width: perfectSize(14),
    height: perfectSize(14),
    resizeMode: "contain",
  },
  title: {
    fontSize: perfectSize(18),
    letterSpacing: -0.18,
    color: c_primary_black,
    fontWeight: "bold",
    marginBottom: perfectSize(10),
  },
  redDot: {
    width: perfectSize(4),
    height: perfectSize(4),
    marginRight: perfectSize(5),
  },
  hyperText: {
    textDecorationLine: "underline",
    color: "#2d7aee",
    fontSize: perfectSize(14),
  },
  mapContainer: {
    flex: 1,
  },
  markerImage: {
    width: perfectSize(24),
    height: perfectSize(33),
    resizeMode: "contain",
  },
};
