import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
} from 'react-native';
import {
    c_primary_red,
    c_second_grey,
    c_third_grey,
    c_white,
    c_black,
    c_primary_black
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import BorderButton from '../../components/BorderButton';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { getShippingAddress } from '../../store/actions';
import Button from "../../components/Button";

function ShippingAddress({ navigation, route }) {
    const { type, user } = route.params;
    const [deliveryAddress, setDeliveryAddress] = useState(null);

    useEffect(() => {
        navigation.setOptions({
            title: "배송지 관리",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        if(type){
            getDeliveryAddress();
            navigation.setParams({ type: false });
        }
    }, [type]);

    const getDeliveryAddress = async () => {
        const res = await getShippingAddress(user._id);
        setDeliveryAddress(res ? res : null);
    }

    const onJoin = () => {
        navigation.navigate("BenefitsPage", {user: user});
    };

    return (
        <View style={styles.mainContainer}>
            <KeyboardAwareScrollView>
                <View style={styles.mainContent}>
                {deliveryAddress !== null ?
                    <View>
                        <View style={styles.card}>
                            <View style={globalStyle.flexSpaceBetween}>
                                <TouchableOpacity
                                    onPress={() => navigation.navigate('AddShippingAddress', { type: false, data: deliveryAddress, user: user })}
                                    style={globalStyle.flexRow}>
                                    <Text style={styles.name}>
                                        {deliveryAddress.receiver}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => navigation.navigate('AddShippingAddress', { type: false, data: deliveryAddress, user: user })}
                                >
                                    <Text style={styles.editButton}>
                                        {'수정'}
                                    </Text>
                                </TouchableOpacity>
                            </View>

                            <View style={[globalStyle.flexRow, { marginTop: perfectSize(22) }]}>
                                <Text style={globalStyle.regularBoldText}>
                                    {deliveryAddress.receiver}
                                </Text>
                                <View style={styles.separator} />
                                <Text style={globalStyle.regularBoldText}>
                                    {deliveryAddress.phoneNum}
                                </Text>
                            </View>

                            <Text style={styles.idNumber}>
                                {`[${deliveryAddress.post_code}]`}
                            </Text>
                            <Text style={globalStyle.smText}>
                                {`${deliveryAddress.address1}\n${deliveryAddress.address2}`}
                            </Text>
                        </View>
                    </View>
                    :
                    <BorderButton
                        onPress={() => navigation.navigate('AddShippingAddress', { type: true, user: user })}
                        color={c_primary_red}
                        label={'+ 배송지 추가'}
                        buttonStyle={{ marginTop: perfectSize(0), marginBottom: perfectSize(30) }}
                    />
                }
                </View>
            </KeyboardAwareScrollView>
            {user && user?.subscribe[0]?.type === "MANIA" ?
                <View
                    style={[
                        globalStyle.flexRow,
                        {
                            height: perfectSize(65),
                        },
                    ]}
                >
                    <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                        <Button
                            onPress={onJoin}
                            isBorder={true}
                            textColor={c_white}
                            label={"혜택 보기"}
                            color={c_primary_red}
                        />
                    </View>
                </View> : <View />
            }
        </View >
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps)(ShippingAddress);

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
    },
    mainContent: {
        flex: 1,
        padding: perfectSize(15)
    },
    card: {
        borderWidth: 1,
        borderColor: c_third_grey,
        borderRadius: perfectSize(6),
        padding: perfectSize(20),
        marginBottom: perfectSize(10)
    },
    name: {
        letterSpacing: -0.15,
        fontWeight: 'bold',
        color: c_black,
        fontSize: perfectSize(16)
    },
    editButton: {
        letterSpacing: -0.15,
        color: c_primary_black,
        fontSize: perfectSize(13)
    },
    separator: {
        width: 1,
        height: perfectSize(12),
        backgroundColor: c_second_grey,
        marginHorizontal: perfectSize(8)
    },
    idNumber: {
        fontSize: perfectSize(12),
        letterSpacing: -0.15,
        color: c_black,
        marginTop: perfectSize(10),
        marginBottom: perfectSize(5)
    },
}