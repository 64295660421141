import _ from "lodash";
import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { AudioItem } from "./AudioItem";
import LoadMoreButton from "../LoadMore";
import { useNavigation } from "@react-navigation/native";
import { getAsset } from "../../../store/actions/performance";

export const FLACList = props => {
  const { hiddenPlus, showLoadMore, smallTitle, performanceId, searchWord } = props;
  const navigation = useNavigation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getData(page);
  }, []);

  const getData = (page) => {
    getAsset("flac", page, smallTitle ? 5 : 3, "전체", performanceId, searchWord)
      .then(res => {
        if (res.data.assets) {
          setTotal(res.data.total);
          setData([...data, ...res.data.assets]);
        }
      })
      .catch(err => console.error(err));
  }

  if (_.isEmpty(data)) return null;

  const onLoadMore = () => {
    getData(page + 1);
    setPage(page + 1);
  }

  return (
    <View style={styles.videoListWrap}>
      <View
        style={[
          globalStyle.flexSpaceBetween,
          { marginVertical: perfectSize(10) },
        ]}
      >
        <Text
          style={
            smallTitle
              ? {
                  fontSize: perfectSize(14),
                  lineHeight: perfectSize(16),
                  color: "#222222",
                  fontWeight: "bold",
                }
              : globalStyle.bigBoldText
          }
        >
          {"FLAC"}
        </Text>
        <TouchableOpacity
          onPress={() => navigation.navigate("AudioDetail", { type: "flac" })}
        >
          {!hiddenPlus && (
            <Image
              source={require("../../../../assets/images/ic_more.png")}
              style={styles.moreIcon}
            />
          )}
        </TouchableOpacity>
      </View>
      {data.map(audio => {
        return (
          <View key={audio._id} style={{ marginBottom: perfectSize(10) }}>
            <AudioItem type={"flac"} audio={audio} />
          </View>
        );
      })}
      {showLoadMore && total !== data.length && <LoadMoreButton onPress={onLoadMore} />}
    </View>
  );
};

const styles = StyleSheet.create({
  videoListWrap: {
    marginBottom: perfectSize(30),
  },
  moreIcon: {
    width: perfectSize(14),
    height: perfectSize(14),
    resizeMode: "contain",
  },
});
