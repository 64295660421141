import {StyleSheet, Image, TouchableOpacity, Platform, View} from 'react-native';
import {perfectSize} from "../variables/styles";
import {SIZE} from "../constants";
import * as Linking from "expo-linking";
import React from "react";

export default function AppInduction({ }) {
    const sUsrAg = navigator.userAgent;

    return (
        Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                <div style={styles.div}>
                    <Image
                        style={styles.logo_Img}
                        source={require("../../assets/images/logo_tickettong.png")}
                    />
                    <p style={styles.p} >티켓통
                        <br/>
                        <span style={styles.span} >스마트한 공연 관람</span>
                    </p>
                    <TouchableOpacity
                        onPress={() => {Linking.openURL("https://play.google.com/store/apps/details?id=com.etomato.ttticket")}}>
                        <Image
                            style={styles.android_Img}
                            source={require("../../assets/images/logo_android.png")} />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {Linking.openURL('https://itunes.apple.com/kr/app/id1577341224?mt=8')}}>
                        <Image
                            style={styles.ios_Img}
                            source={require("../../assets/images/logo_ios.png")} />
                    </TouchableOpacity>
                </div> :
                <View /> :
            <View />
    );
}

const styles = StyleSheet.create({
    div: {
        width: '100%',
        height: perfectSize(40),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: perfectSize(9),
        backgroundColor: 'white',
    },
    logo_Img: {
        width: perfectSize(80),
        height: perfectSize(30),
        display: 'block',
        marginBottom: perfectSize(0),
    },
    p: {
        lineHeight: SIZE(1),
        margin: perfectSize(0),
        display: 'block',
        fontSize: SIZE(13),
        fontWeight: 'bold',
        marginLeft: perfectSize(30)
    },
    span: {
        lineHeight: SIZE(1),
        margin: perfectSize(0),
        display: 'block',
        fontSize: SIZE(12),
        color: '#D13E3B',
        fontWeight: '500',
    },
    android_Img: {
        width: perfectSize(30),
        height: perfectSize(40),
        marginTop: perfectSize(5),
        marginLeft: SIZE(50),
    },
    ios_Img: {
        width: perfectSize(30),
        height: perfectSize(45),
        marginRight: SIZE(25),
        marginTop: perfectSize(5),

    },
});