import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    View,
    TouchableOpacity,
    Image,
} from 'react-native';
import {
    c_white,
    c_second_white,
    c_primary_red,
    c_thrid_white,
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import Coupon from './coupon';
import Invitation from './invitation';

function CouponAndInvitation({ navigation, user }) {

    const [tab, setTab] = useState(true);

    useEffect(() => {
        navigation.setOptions({
            title: "쿠폰/초대권",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.navIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    return (
        <View style={styles.mainContainer}>
            <View style={[globalStyle.flexRow, { paddingHorizontal: perfectSize(17) }]}>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={() => setTab(true)}
                        isBorder={false}
                        textColor={tab ? c_white : c_thrid_white}
                        label={`쿠폰`}
                        color={tab ? c_primary_red : c_second_white}
                    />
                </View>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={() => setTab(false)}
                        isBorder={false}
                        textColor={!tab ? c_white : c_thrid_white}
                        label={`초대권`}
                        color={!tab ? c_primary_red : c_second_white}
                    />
                </View>
            </View>
            {
                tab ? <Coupon /> : <Invitation />
            }
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps)(CouponAndInvitation);

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(15),
        backgroundColor: c_white
    },
    headerTitle: {
        padding: perfectSize(15),
        marginTop: perfectSize(5)
    },
}