import _ from 'lodash';
import {
    LOGIN,
    REGISTER,
    LOGOUT,
    UPDATE_PROFILE,
    UPDATE_SHIPPING_ADDRESS,
    ADD_SHIPPING_ADDRESS,
    DELETE_SHIPPING_ADDRESS,
    SET_DEFAULT_ADDRESS,
    UPDATE_USER_WALLET,
    UPDATE_USER_SUBSCRIBE
} from '../../variables/constants';

const INITIAL_STATE = {
    user: null,
    isAuthenticated: false,
}

export default function UserReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case REGISTER:
        case LOGIN: {
            return {
                ...state,
                user: { ...action.payload },
                isAuthenticated: true
            }
        }
        case LOGOUT: {
            return {
                ...state,
                isAuthenticated: false
            }
        }
        case UPDATE_PROFILE: {
            let _user = state.user;
            _user[action.payload.type] = action.payload.value;
            return {
                ...state,
                user: { ..._user }
            }
        }
        case UPDATE_SHIPPING_ADDRESS: {
            let _user = state.user;
            const index = _.findIndex(_user.delivery_address, (o) => { return o._id === action.payload.delivery_address._id });
            _user.delivery_address[index] = action.payload.delivery_address;
            return {
                ...state,
                user: { ..._user }
            }
        }

        case SET_DEFAULT_ADDRESS: {
            let _user = state.user;
            _user.delivery_address = action.payload;
            return {
                ...state,
                user: { ..._user }
            }
        }

        case ADD_SHIPPING_ADDRESS: {
            const _user = state.user;
            _user.delivery_address.push(action.payload);
            return {
                ...state,
                user: { ..._user }
            }
        }

        case DELETE_SHIPPING_ADDRESS: {
            let _user = state.user;
            const index = _.findIndex(_user.delivery_address, (o) => { return o._id === action.payload });
            _user.delivery_address.splice(index, 1);
            return {
                ...state,
                user: { ..._user }
            }
        }

        case UPDATE_USER_WALLET: {
            let _user = state.user;
            _user.wallet = action.payload;
            return {
                ...state,
                user: { ..._user }
            }
        }

        case UPDATE_USER_SUBSCRIBE: {
            let _user = state.user;
            _user.subscribe = action.payload;
            return {
                ...state,
                user: { ..._user },
            }
        }

        default:
            return state;
    }
}