import React, {useEffect} from "react";
import {Image, SafeAreaView, Text, TouchableOpacity, View} from "react-native";
import {globalStyle, perfectSize} from "../../variables/styles";
import Octicons from "@expo/vector-icons/Octicons";
import {c_primary_blue, c_primary_red, c_second_grey, c_white} from "../../variables/colors";


export default function classtvfinish({ navigation }) {
    useEffect(() => {
        navigation.setOptions({
            title: "로그인 완료",
        });
    }, []);

    return (
        <View
            style={{
                flex: 1,
                paddingTop: perfectSize(40),
                paddingHorizontal: perfectSize(15),
                paddingBottom: perfectSize(40),
                backgroundColor: c_second_grey,
            }}
        >
            <View
                style={{
                    flex: 1,
                    paddingTop: perfectSize(40),
                    paddingHorizontal: perfectSize(15),
                    paddingBottom: perfectSize(40),
                    backgroundColor: c_white,
                }}>
                <Octicons
                    name="checklist"
                    color={c_primary_blue}
                    size={perfectSize(80)}
                    style={{ alignSelf: "center", marginLeft: perfectSize(30) }}
                />
                <Text
                    style={{
                        fontSize: perfectSize(20),
                        textAlign: "center",
                        marginVertical: perfectSize(20),
                        color: c_primary_blue,
                        fontWeight: "bold",
                    }}
                >
                    {"로그인 완료"}
                </Text>

                <Text
                    style={{
                        fontSize: perfectSize(18),
                        textAlign: "center",
                        marginTop: perfectSize(30),
                        fontWeight: "bold",
                    }}
                >
                    {"로그인이 완료되었습니다.\n\n\n"}
                </Text>
                <Text
                    style={{
                        fontSize: perfectSize(16),
                        textAlign: "center",
                    }}
                >
                    {"해당 브라우저를 종료하시고\n 클래식 TV를 즐겨보세요"}
                </Text>
            </View>

        </View>
    );

}