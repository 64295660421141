import React, { useState } from 'react';
import {
    View,
    Text,
    TextInput,
    Image,
    TouchableOpacity
} from 'react-native';
import {
    c_primary_black,
    c_white,
    c_primary_red,
    c_grey
} from '../variables/colors';
import { globalStyle, perfectSize } from '../variables/styles';

export default function CustomTextInput({
    style,
    warningText,
    isWarn,
    value,
    onChangeText,
    placeholder,
    editable,
    isSecure,
    isShowEye,
    inputStyle,
    numeric
}) {
    const [secure, setSecure] = useState(isSecure);
    return (
        <View style={[styles.container, style]}>
            <TextInput
                placeholder={placeholder}
                secureTextEntry={secure}
                style={[globalStyle.textInput, inputStyle, isWarn && { borderColor: c_primary_red }]}
                value={value}
                onChangeText={onChangeText}
                editable={editable}
                keyboardType={numeric ? 'numeric' : 'default'}
            />
            {
                isShowEye &&
                <TouchableOpacity
                    onPress={() => setSecure(!secure)}
                    style={styles.eyeBtn}>
                    <Image
                        source={
                            secure ? require('../../assets/images/btn_hide_on.png') :
                                require('../../assets/images/btn_hide_off.png')
                        }
                        style={styles.eyeIcon}
                    />
                </TouchableOpacity>
            }
            {
                isWarn &&
                <View style={[globalStyle.flexRow, { marginTop: perfectSize(6) }]}>
                    <Image
                        source={require('../../assets/images/ic_wrong.png')}
                        style={styles.warningIcon}
                    />
                    <Text style={globalStyle.smRedText}>{warningText}</Text>
                </View>
            }
        </View>
    )
}

const styles = {
    container: {
        alignSelf: 'stretch'
    },
    warningIcon: {
        width: perfectSize(15),
        height: perfectSize(15),
        marginRight: perfectSize(6)
    },
    eyeBtn: {
        position: 'absolute',
        right: perfectSize(15),
        top: perfectSize(15)
    },
    eyeIcon: {
        width: 18,
        height: 18,
        resizeMode: 'contain'
    }
}