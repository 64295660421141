import React from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Dimensions,
    Platform,
    Linking,
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';
import Modal from 'react-native-modal';
import WebModal from 'modal-react-native-web';
import {
    c_primary_black,
    c_primary_red,
    c_third_grey,
    c_white,
} from '../variables/colors';
// import MapView, { Marker } from 'react-native-maps';
import Separator from '../components/Separator';
import Footer from '../components/Footer';
import WebView from 'react-native-webview';
// import Clipboard from 'expo-clipboard';
import { HOST } from '../variables/constants';

const height = Dimensions.get('window').height;

export default function MapModal({
    data,
    isVisible,
    hideModal,
}) {


    const modalContent = () => {
        return (
            <View style={styles.swipeModal}>
                <View style={[globalStyle.swipeModalContainer, styles.swipeModalContainer]}>
                    <View style={{ paddingHorizontal: perfectSize(15), alignSelf: 'stretch' }}>
                        {/** close */}
                        <TouchableOpacity
                            onPress={hideModal}
                            style={styles.closeBtn}>
                            <Image
                                source={require('../../assets/images/ic_close.png')}
                                style={styles.closeIcon}
                            />
                        </TouchableOpacity>

                        {/** title */}
                        <Text style={styles.title}>
                            {data.location.name}
                        </Text>

                        {/** seperator */}
                        <Separator height={1} color={c_third_grey} />
                    </View>

                    <ScrollView style={{ paddingHorizontal: perfectSize(15), alignSelf: 'stretch' }}>
                        <View style={{ marginTop: perfectSize(20) }}>
                            <View style={[globalStyle.flexRow, { marginBottom: perfectSize(15) }]}>
                                <View style={styles.redDot} />
                                <Text style={[globalStyle.regularBoldText, { width: perfectSize(70) }]}>
                                    {'주소'}
                                </Text>
                                <Text style={globalStyle.regularText}>
                                    {data.location.address}
                                </Text>
                                {/* <TouchableOpacity onPress={() => { Clipboard.setString(data.location.address); }}>
                                    <Text style={[styles.hyperText, { fontSize: perfectSize(12) }]}>
                                        {' 복사'}
                                    </Text>
                                </TouchableOpacity> */}
                            </View>

                            <View style={[globalStyle.flexRow, { marginBottom: perfectSize(15) }]}>
                                <View style={styles.redDot} />
                                <Text style={[globalStyle.regularBoldText, { width: perfectSize(70) }]}>
                                    {'연락처'}
                                </Text>
                                <TouchableOpacity onPress={() => { Linking.openURL(`tel:${data.location.phone.replace(/-/gi, "")}`) }}>
                                    <Text style={styles.hyperText}>
                                        {data.location.phone}
                                    </Text>
                                </TouchableOpacity>
                            </View>

                            <View style={[globalStyle.flexRow, { marginBottom: perfectSize(15) }]}>
                                <View style={styles.redDot} />
                                <Text style={[globalStyle.regularBoldText, { width: perfectSize(70) }]}>
                                    {'홈페이지'}
                                </Text>
                                <TouchableOpacity onPress={() => { Linking.openURL(data.location.homepage) }}>
                                    <Text style={styles.hyperText}>
                                        {data.location.homepage}
                                    </Text>
                                </TouchableOpacity>
                            </View>

                            <View style={[globalStyle.flexRow, { marginBottom: perfectSize(15) }]}>
                                <View style={styles.redDot} />
                                <Text style={[globalStyle.regularBoldText, { width: perfectSize(70) }]}>
                                    {'지도'}
                                </Text>
                            </View>
                            {
                                data.location.address &&
                                    Platform.OS !== 'web' ?
                                    <WebView
                                        javaScriptEnabled={true}
                                        domStorageEnabled={true}
                                        source={{ uri: `https://admin.tickettong.net/map?search=${data.location.address}` }}
                                        style={styles.mapContainer}
                                    />
                                    : <iframe
                                        src={`https://admin.tickettong.net/map?search=${data.location.address}`}
                                        height={perfectSize(318)}
                                    />
                            }
                        </View>

                        <Separator
                            height={1}
                            color={c_third_grey}
                            marginTop={perfectSize(50)}
                            marginBottom={perfectSize(20)}
                        />

                        <Footer hideModal={hideModal} />
                    </ScrollView>
                </View>
            </View>
        )
    }

    return (
        Platform.OS !== 'web' ?
            <Modal
                animationIn="slideInUp"
                animationOut="slideOutDown"
                style={{ margin: 0 }}
                isVisible={isVisible}
                onSwipeComplete={hideModal}>
                {modalContent()}
            </Modal>
            :
            <WebModal
                animationIn="slideInDown"
                animationOut="slideOutUp"
                transparent={true}
                visible={isVisible}>
                <View style={{
                    flex: 1,
                    backgroundColor: '#00000099'
                }}>
                    {modalContent()}
                </View>
            </WebModal>
    )
}

const styles = {
    swipeModal: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    swipeModalContainer: {
        paddingHorizontal: 0,
        alignItems: 'flex-start',
        paddingTop: 0,
        paddingBottom: perfectSize(20),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: perfectSize(16),
        borderTopLeftRadius: perfectSize(16),
        height: '90%',
        backgroundColor: c_white
    },
    closeBtn: {
        alignSelf: 'flex-end',
        marginVertical: perfectSize(15)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
    title: {
        fontSize: perfectSize(18),
        letterSpacing: -0.18,
        color: c_primary_black,
        fontWeight: 'bold',
        marginBottom: perfectSize(10)
    },
    redDot: {
        width: perfectSize(4),
        height: perfectSize(4),
        backgroundColor: c_primary_red,
        marginRight: perfectSize(5)
    },
    hyperText: {
        textDecorationLine: 'underline',
        color: '#2d7aee',
        fontSize: perfectSize(14),
    },
    mapContainer: {
        alignSelf: 'stretch',
        height: perfectSize(318)
    },
    markerImage: {
        width: perfectSize(24),
        height: perfectSize(33),
        resizeMode: 'contain',
    },
}