import { instance, oneAlert } from '../../utils';
import {
    INVITATION_LOAD_START,
    INVITATION_LOAD_SUCCESS,
    INVITATION_LOAD_FAIL,
    INVITATION_LIST_RESET,
} from '../../variables/constants';

export const invitationLoadSuccess = (data) => ({
    type: INVITATION_LOAD_SUCCESS,
    payload: data
});

export const invitationLoadStart = () => ({
    type: INVITATION_LOAD_START
});

export const invitationLoadFail = () => ({
    type: INVITATION_LOAD_FAIL
});

export const invitationListReset = () => ({
    type: INVITATION_LIST_RESET
});

export const invitationList = () => {
    return (dispatch, getState) => {

        dispatch(invitationLoadStart());

        const { user } = getState().auth;

        instance.get(`api/invitation/listByUser?decPhoneNum=${user.decPhoneNum}`)
            .then(res => {
                dispatch(invitationLoadSuccess(res.data.data));
            })
            .catch(err => {
                oneAlert(err.response.data.message);
                dispatch(invitationLoadFail());
            });
    }
}