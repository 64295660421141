import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    ActivityIndicator
} from 'react-native';
import {
    c_primary_red,
    c_second_white,
    c_thrid_white,
    c_white,
    c_grey,
    c_second_grey,
    c_second_black
} from '../../variables/colors';
import { UPLOAD_URL } from '../../variables/constants';
import { globalStyle, perfectSize } from '../../variables/styles';
import Separator from '../../components/Separator';
import ModalDropdown from 'react-native-modal-dropdown';
import Footer from '../../components/Footer';
import { FAB } from 'react-native-paper';
import AntDesign from '@expo/vector-icons/AntDesign';
import Button from '../../components/Button';
import { getMyCommunity, deleteMyCommunity, getMyTempCommunity } from '../../store/actions';
import { formatDate, twoAlert } from '../../utils';
import { useIsFocused } from '@react-navigation/native';
import { numberWithCommas } from '../../utils';

function MyCommunity({
    navigation,
    user
}) {

    const [recommands, setRecommand] = useState(0);
    const [open, onStateChange] = useState(false);
    const [reviewType, setReviewType] = useState(false);
    const [community, setCommunity] = useState([]);
    const [communities, setCommunities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tempCommunity, setTempCommunity] = useState([]);
    const [tempCommunities, setTempCommunities] = useState([]);
    const [numberOfLikes, setNumberOfLikes] = useState(0);

    const dropdownList = ['최신순', '추천순', '조회순'];
    const isFocusedHistory = useIsFocused();


    useEffect(() => {
        navigation.setOptions({
            title: "MY커뮤니티",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);
    useEffect(() => {
        getMyCommunities();
        getTempCommunities();
    }, [isFocusedHistory]);

    const getMyCommunities = async () => {
        setLoading(true);
        const res = await getMyCommunity(user._id);
        let _numberOfLikes = 0;
        res.map(item => _numberOfLikes = _numberOfLikes + item.likes.length);
        setNumberOfLikes(_numberOfLikes);
        setLoading(false);
        setCommunities(res);
    }

    const getTempCommunities = async () => {
        setLoading(true);
        const res = await getMyTempCommunity(user._id);
        setTempCommunities(res);
        setLoading(false);
    }

    useEffect(() => {
        let _communities = [];
        switch (recommands) {
            case 0:
                _communities = _.sortBy(communities, (o) => { return o.createdAt });
                _communities.reverse();
                break;
            case 1:
                _communities = _.sortBy(communities, (o) => { return o.likes.length });
                _communities.reverse();
                break;
            case 2:
                _communities = _.sortBy(communities, (o) => { return o.views });
                _communities.reverse();
                break;
            default:
                _communities = communities;
        }
        setCommunity(_communities);
    }, [communities, recommands]);

    useEffect(() => {
        let _communities = [];
        switch (recommands) {
            case 0:
                _communities = _.sortBy(tempCommunities, (o) => { return o.updatedAt });
                _communities.reverse();
                break;
            case 1:
                _communities = _.sortBy(tempCommunities, (o) => { return o.updatedAt });
                _communities.reverse();
                break;
            case 2:
                _communities = _.sortBy(tempCommunities, (o) => { return o.updatedAt });
                _communities.reverse();
                break;
            default:
                _communities = tempCommunities;
        }
        setTempCommunity(_communities);
    }, [tempCommunities, recommands]);

    const onDeleteCommunity = (id, index) => {
        twoAlert('게시글을 삭제하시겠습니까?', async () => {
            deleteMyCommunity(id);
            community.splice(index, 1);
            setCommunity([...community]);
        });
    }

    const onDeleteTempCommunity = async (id, index) => {
        twoAlert('임시글을 삭제하시겠습니까?', async () => {
            deleteMyCommunity(id);
            tempCommunity.splice(index, 1);
            setTempCommunity([...tempCommunity]);
        });
    }

    const CommunityList = () => {
        if (loading) {
            return (
                <View style={[globalStyle.mainContainer, { alignItems: 'center', justifyContent: 'center', marginVertical: perfectSize(120) }]}>
                    <ActivityIndicator color={c_primary_red} />
                </View>
            )
        }

        if (_.isEmpty(community)) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginVertical: perfectSize(120) }}>
                    <Text>{'게시글이 없습니다.'}</Text>
                </View>
            )
        }

        return community.map((item, index) =>
            <TouchableOpacity
                onPress={() => {
                    !reviewType ?
                        navigation.navigate('CommunityDetail', { id: item._id }) :
                        navigation.navigate('CommunityWrite')
                }}
                key={index}>
                <View style={styles.communityList}>
                    {
                        item.imageUrl &&
                        <Image
                            source={{ uri: UPLOAD_URL + item.imageUrl }}
                            style={styles.communityImage} />
                    }
                    <View style={{ flex: 1 }}>
                        <Text style={globalStyle.normalText}>{item.title}</Text>
                        <View style={styles.communityInfo}>
                            <Text style={styles.communityInfoText}>{formatDate(item.createdAt)}</Text>
                            <Text style={styles.communityInfoText}>{'조회 ' + item.views}</Text>
                            <Text style={styles.communityInfoText}>{'추천 ' + item.likes.length}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                            {
                                item.hashtags &&
                                item.hashtags.map((tag, index) => {
                                    return <Text key={index} style={styles.communityInfoTag}>{`#${tag}`}</Text>
                                })
                            }
                        </View>
                    </View>

                    <TouchableOpacity
                        onPress={() => onDeleteCommunity(item._id, index)}
                        style={styles.deleteBtn}>
                        <Text style={{ ...globalStyle.smText, color: c_white }}>
                            {'삭제'}
                        </Text>
                    </TouchableOpacity>
                </View>
                <Separator
                    height={perfectSize(1)}
                    color={c_second_white}
                />
            </TouchableOpacity>
        )
    }

    const TempCommunity = () => {
        if (loading) {
            return (
                <View style={[globalStyle.mainContainer, { alignItems: 'center', justifyContent: 'center', marginVertical: perfectSize(120) }]}>
                    <ActivityIndicator color={c_primary_red} />
                </View>
            )
        }

        if (_.isEmpty(tempCommunity)) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginVertical: perfectSize(120) }}>
                    <Text>{'임시글이 없습니다.'}</Text>
                </View>
            )
        }

        return tempCommunity.map((item, index) => {
            return (
                <TouchableOpacity
                    onPress={() => {
                        !reviewType ?
                            navigation.navigate('CommunityDetail', { id: item._id }) :
                            navigation.navigate('CommunityWrite', { data: item })
                    }}
                    key={index}>
                    <View style={styles.communityList}>
                        {
                            item.imageUrl &&
                            <Image
                                source={item.imageUrl}
                                style={styles.communityImage} />
                        }
                        <View style={{ flex: 1 }}>
                            <Text style={globalStyle.normalText}>{item.title}</Text>
                            <View style={styles.communityInfo}>
                                <Text style={styles.communityInfoText}>{formatDate(item.updatedAt)}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                {
                                    item.hashtags &&
                                    item.hashtags.map((tag, index) => {
                                        return (
                                            <Text
                                                key={index}
                                                style={styles.communityInfoTag}>
                                                {`#${tag}`}
                                            </Text>
                                        )
                                    })
                                }
                            </View>
                        </View>

                        <TouchableOpacity
                            onPress={() => onDeleteTempCommunity(item._id, index)}
                            style={styles.deleteBtn}>
                            <Text style={{ ...globalStyle.smText, color: c_white }}>
                                {'삭제'}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <Separator
                        height={perfectSize(1)}
                        color={c_second_white}
                    />
                </TouchableOpacity>
            )
        })
    }

    return (
        <View style={globalStyle.flex}>
            <ScrollView style={globalStyle.flex}>

                <View style={styles.topSection}>
                    <View style={globalStyle.flexRow}>
                        <Text style={globalStyle.bigBoldText}>
                            {user.nickname}
                        </Text>
                        <Image
                            source={require('../../../assets/images/ic_review_like_on.png')}
                            style={styles.likeIcon}
                        />
                        <Text style={globalStyle.regularRedText}>
                            {numberWithCommas(numberOfLikes)}
                        </Text>
                    </View>
                    {/* <View style={{ ...globalStyle.flexSpaceBetween, marginTop: perfectSize(15) }}>
                        <Text style={globalStyle.regularGreyText}>
                            {'통통머니'}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {'9,999,999.00'}
                        </Text>
                    </View>
                    <View style={{ ...globalStyle.flexSpaceBetween, marginTop: perfectSize(15) }}>
                        <Text style={globalStyle.regularGreyText}>
                            {'통통마일'}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {'9,999,999.00'}
                        </Text>
                    </View> */}
                </View>

                {/** Main Content */}
                <View style={styles.mainContent}>

                    {/** Select Type */}
                    <View style={[globalStyle.flexRow, { marginTop: perfectSize(15) }]}>
                        <View style={globalStyle.flex}>
                            <Button
                                onPress={() => setReviewType(false)}
                                isBorder={false}
                                textColor={!reviewType ? c_white : c_thrid_white}
                                label={`게시글 ${community.length || 0}`}
                                color={!reviewType ? c_second_black : c_second_white}
                            />
                        </View>
                        <View style={globalStyle.flex}>
                            <Button
                                onPress={() => setReviewType(true)}
                                isBorder={false}
                                textColor={reviewType ? c_white : c_thrid_white}
                                label={`임시글 ${tempCommunity.length || 0}`}
                                color={reviewType ? c_second_black : c_second_white}
                            />
                        </View>
                    </View>

                    <ModalDropdown
                        onSelect={index => setRecommand(index)}
                        options={dropdownList}
                        defaultIndex={0}
                        defaultValue={dropdownList[0]}
                        renderSeparator={() => <View />}
                        dropdownStyle={{
                            width: perfectSize(80),
                            height: perfectSize(40 * dropdownList.length),
                            marginTop: perfectSize(8)
                        }}
                        dropdownTextStyle={globalStyle.dropdownTextStyle}
                        dropdownTextHighlightStyle={{ color: c_primary_red }}
                        textStyle={globalStyle.regularText}
                        renderRightComponent={() =>
                            <Image
                                source={require('../../../assets/images/ic_list_order.png')}
                                style={globalStyle.dropdownRightImage} />
                        }
                        style={globalStyle.dropdownStyle}
                    />

                    <View style={{ marginTop: perfectSize(-10), minHeight: perfectSize(240) }}>
                        {!reviewType ? CommunityList() : TempCommunity()}
                    </View>

                    <View style={styles.footer}>
                        <Footer />
                    </View>
                </View>
            </ScrollView>

            <FAB.Group
                open={open}
                icon={open ? 'close' : 'plus'}
                actions={[
                    {
                        icon: (props) => {
                            return (
                                <View style={styles.fabIcon}>
                                    <AntDesign name={'form'} color={c_primary_red} size={perfectSize(23)} />
                                </View>
                            )
                        },
                        style: styles.action,
                        onPress: () => navigation.navigate('CommunityWrite'),
                        small: false,
                    },
                    {
                        icon: (props) => {
                            return (
                                <View style={styles.fabIcon}>
                                    <AntDesign name={'user'} color={c_primary_red} size={perfectSize(23)} />
                                </View>
                            )
                        },
                        style: styles.action,
                        onPress: () => console.log('Pressed notifications'),
                        small: false,
                    },
                    {
                        icon: (props) => {
                            return (
                                <View style={styles.fabIcon}>
                                    <AntDesign name={'home'} color={c_primary_red} size={perfectSize(23)} />
                                </View>
                            )
                        },
                        style: styles.action,
                        onPress: () => navigation.navigate('Community'),
                        small: false,
                    },
                ]}
                color={c_white}
                onStateChange={() => onStateChange(!open)}
                onPress={() => {
                    if (open) {
                        // do something if the speed dial is open
                    }
                }}
                fabStyle={{
                    backgroundColor: c_primary_red,
                }}
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0
                }}
            />
        </View>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(MyCommunity);

const styles = {
    mainContent: {
        backgroundColor: c_white,
        paddingHorizontal: perfectSize(15)
    },
    topSection: {
        marginVertical: perfectSize(8),
        paddingHorizontal: perfectSize(15),
        paddingTop: perfectSize(10),
        paddingBottom: perfectSize(20),
        backgroundColor: c_white
    },
    footer: {
        marginTop: perfectSize(20)
    },
    communityList: {
        flexDirection: 'row',
        marginVertical: perfectSize(15)
    },
    communityImage: {
        width: perfectSize(50),
        height: perfectSize(50),
        borderRadius: perfectSize(8),
        backgroundColor: c_grey,
        marginRight: perfectSize(15),
        backgroundColor: c_second_white
    },
    communityInfo: {
        flexDirection: 'row',
        marginVertical: perfectSize(8)
    },
    communityInfoText: {
        color: c_second_grey,
        fontSize: perfectSize(12),
        marginRight: perfectSize(13)
    },
    communityInfoTag: {
        color: c_grey,
        fontSize: perfectSize(13),
        marginRight: perfectSize(10)
    },
    fabIcon: {
        marginLeft: perfectSize(-12),
        marginRight: perfectSize(-5),
        alignItems: 'center',
        height: perfectSize(60),
        marginTop: perfectSize(-6)
    },
    action: {
        width: perfectSize(40),
        height: perfectSize(40),
        marginRight: perfectSize(5)
    },
    likeIcon: {
        width: perfectSize(13),
        height: perfectSize(13),
        resizeMode: 'contain',
        marginLeft: perfectSize(15),
        marginRight: perfectSize(5)
    },
    deleteBtn: {
        borderRadius: perfectSize(4),
        backgroundColor: '#a7a7a7',
        width: perfectSize(35),
        height: perfectSize(50),
        marginLeft: perfectSize(15),
        alignItems: 'center',
        justifyContent: 'center',
    }
}