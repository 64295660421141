import React, { useState } from "react";
import {
  View,
  Image,
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
} from "react-native";
import { globalStyle, perfectSize } from "../variables/styles";
import Modal from "react-native-modal";
import WebModal from "modal-react-native-web";
import { c_primary_black, c_primary_red, c_white } from "../variables/colors";
import { oneAlert } from "../utils";

const {width, height} = Dimensions.get("window");

const WIDTH = width > 1000 ? height / 1.5 : width;

export default function NoWalletModal({ isVisible, hideModal, onPress }) {
  const modalContent = () => {
    return (
      <View style={styles.swipeModal}>
        <Text
          style={{
            fontSize: perfectSize(14),
            textAlign: "center",
            marginBottom: perfectSize(15),
          }}
        >
          {"NFT 구매를 위해 통통지갑을 연동하거나 생성해주세요."}
        </Text>
        <TouchableOpacity
          onPress={onPress}
          style={{
            height: perfectSize(36),
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: c_primary_red,
            marginHorizontal: perfectSize(5),
          }}
        >
          <Text style={{ fontSize: perfectSize(12), color: "white" }}>
            {"통통지갑 연동하기"}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return Platform.OS !== "web" ? (
    <Modal
      transparent={true}
      isVisible={isVisible}
      onBackdropPress={hideModal}
      onSwipeComplete={hideModal}
    >
      {modalContent()}
    </Modal>
  ) : (
    <WebModal transparent={true} visible={isVisible}>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          backgroundColor: '#00000050',
        }}
      >
        {modalContent()}
      </View>
    </WebModal>
  );
}

const styles = {
  swipeModal: {
    width: Platform.OS !== 'web' ? '100%' : WIDTH - perfectSize(15),
    backgroundColor: "white",
    padding: perfectSize(15),
  },
};
