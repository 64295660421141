import React from 'react';
import {
    View,
    TouchableOpacity,
    Image,
    Dimensions,
    Platform,
    SafeAreaView
} from 'react-native';
import { globalStyle, perfectSize } from '../variables/styles';
import Modal from 'react-native-modal';
import WebModal from 'modal-react-native-web';
import {
    c_primary_black,
    c_primary_red,
    c_third_grey,
    c_white,
} from '../variables/colors';

const height = Dimensions.get('window').height;

export default function BarcodeModal({
    isVisible,
    hideModal,
    data
}) {

    const modalContent = () => {
        return (
            <View style={styles.swipeModal}>
                <Image source={{ uri: data }} style={{ width: '100%', height: perfectSize(100) }} />
            </View>
        )
    }

    return (
        Platform.OS !== 'web' ?
            <Modal
                transparent={true}
                isVisible={isVisible}
                onBackdropPress={hideModal}
                onSwipeComplete={hideModal}>
                {modalContent()}
            </Modal>
            :
            <WebModal
                transparent={true}
                visible={isVisible}>
                <View style={{
                    flex: 1,
                    backgroundColor: '#00000099'
                }}>
                    {modalContent()}
                </View>
            </WebModal>
    )
}

const styles = {
    swipeModal: {
        backgroundColor: 'white',
        padding: perfectSize(20),
        paddingBottom: perfectSize(30)
    },
    swipeModalContainer: {
        paddingHorizontal: 0,
        backgroundColor: c_white
    },
    closeBtn: {
        alignSelf: 'flex-end',
        marginVertical: perfectSize(15)
    },
    closeIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: 'contain',
    },
    title: {
        fontSize: perfectSize(18),
        letterSpacing: -0.18,
        color: c_primary_black,
        fontWeight: 'bold',
        marginBottom: perfectSize(10)
    },
    redDot: {
        width: perfectSize(4),
        height: perfectSize(4),
        marginRight: perfectSize(5)
    },
    hyperText: {
        textDecorationLine: 'underline',
        color: '#2d7aee',
        fontSize: perfectSize(14),
    },
    mapContainer: {
        flex: 1,
    },
    markerImage: {
        width: perfectSize(24),
        height: perfectSize(33),
        resizeMode: 'contain',
    },
}