import React, {useEffect, useState} from "react";
import {globalStyle, perfectSize} from "../../variables/styles";
import {View} from "react-native-web";
import {Image, Linking, Platform, ScrollView, Text, TouchableOpacity} from "react-native";
import {
    c_black,
    c_grey, c_light_grey,
    c_ligth_red,
    c_primary_blue,
    c_primary_red, c_second_white,
    c_thrid_white,
    c_white
} from "../../variables/colors";
import Button from "../../components/Button";
import paperProvider from "react-native-paper/src/core/PaperProvider";
import {getManiaData, getRewardPoint} from "../../store/actions";

export default function BenefitsPage({
                                         route,
                                         navigation,
                                     }) {

    const sUsrAg = navigator.userAgent;
    const userAgent = window.navigator.userAgent;

    const {user} = route.params;
    const [rewardPoint, setRewardPoint] = useState(0);
    const [isPrice, setPrice] = useState(0);
    const [isDate, setDate] = useState('');
    const [type, setType] = useState('');

    useEffect(() => {
        navigation.setOptions({
            title: "구독자 혜택",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.navigate("Main")}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.navigate("Main")}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        getRewardData();
        getManiaInfo();
    }, [user]);

    const getRewardData = async () => { // user.decPhoneNum 01050556371
        await getRewardPoint(user._id).then(res => {
            if (res && res.Code === "200") {
                const point = parseInt(res.data.Point);
                setRewardPoint(point.toLocaleString("ko-KR")+" 원");
            }
        });
    }

    const getManiaInfo = async () => {
        const res = await getManiaData(user._id);
        setPrice(res?.cardPay?.Amt);
        if (res?.cardPay?.createdAt) {
            const originalDate = new Date(res?.cardPay?.createdAt);
            const year = originalDate.getFullYear();
            const month = String(originalDate.getMonth() + 1).padStart(2, "0");
            const day = String(originalDate.getDate()).padStart(2, "0");

            const formattedDate = `${day} 일`;
            setDate(formattedDate);
        }
    }

    const onPassClick = () => {
        const appUrl = userAgent.includes("iPhone") ?
            `tomatopass://fromapp?from=tongtongticket&phone=${user?.decPhoneNum || ""}` :
            `tomatopass://m.etomato.com?open=fromapp&from=${"com.etomato.ttticket"}&phone=${
                user?.decPhoneNum || ""
            }`;
        const storeUrlAndroid = 'https://play.google.com/store/apps/details?id=com.etomato.tomatopass'; // Android 스토어 URL
        const storeUrlIOS = 'https://itunes.apple.com/kr/app/id1120510279?mt=8'; // iOS 스토어 URL

        if (userAgent.includes("iPhone")) {
            var visiteTm = ( new Date() ).getTime();
            setTimeout( function () {
                if ( ( new Date() ).getTime() - visiteTm < 3000 ) {
                    location.href = storeUrlIOS;
                }
            } ,2500 );
            setTimeout( function () {  // 앱실행
                location.href = appUrl;
            } ,0 );
        } else {
            location.href = 'intent://m.etomato.com#Intent;scheme=passtong;package=com.etomato.tomatopass;end';
        }
    };

    const onTujaClick = () => {
        const appUrl = userAgent.includes("iPhone") ?
            `eTomatoApp://fromapp?from=tongtongticket&phone=${user?.decPhoneNum || ""}` :
            `investclub://m.etomato.com?open=fromapp&from=${"com.etomato.ttticket"}&phone=${
                user?.decPhoneNum || ""
            }`
        const storeUrlAndroid = 'https://play.google.com/store/apps/details?id=com.etomato.etomatoapp';
        const storeUrlIOS = 'https://itunes.apple.com/kr/app/id1059953565?mt=8';

        if (userAgent.includes("iPhone")) {
            var visiteTm = ( new Date() ).getTime();
            setTimeout( function () {
                if ( ( new Date() ).getTime() - visiteTm < 3000 ) {
                    location.href = storeUrlIOS;
                }
            } ,2500 );
            setTimeout( function () {  // 앱실행
                location.href = appUrl;
            } ,0 );
        } else {
            location.href = 'intent://m.etomato.com#Intent;scheme=investclub;package=com.etomato.etomatoapp;end';
        }
    };

    const onMallClick = () => {
        const appUrl = userAgent.includes("iPhone") ?
            `tongtongmall://fromapp?from=tongtongticket&phone=${user?.decPhoneNum || ""}` :
            `tongtongmall://m.etomato.com?open=fromapp&from=${"com.etomato.ttticket"}&phone=${
                user?.decPhoneNum || ""
            }`
        const storeUrlAndroid = 'https://play.google.com/store/apps/details?id=com.tongtong.tongtongmall1';
        const storeUrlIOS = 'https://itunes.apple.com/kr/app/id1471587538?mt=8';

        if (userAgent.includes("iPhone")) {
            var visiteTm = ( new Date() ).getTime();
            setTimeout( function () {
                if ( ( new Date() ).getTime() - visiteTm < 3000 ) {
                    location.href = storeUrlIOS;
                }
            } ,2500 );
            setTimeout( function () {  // 앱실행
                location.href = appUrl;
            } ,0 );
        } else {
            location.href = 'intent://m.etomato.com#Intent;scheme=tongtongmall;package=com.tongtong.tongtongmall1;end';
        }




        // if (userAgent.includes("SamsungBrowser") || userAgent.includes("Chrome")  || userAgent.includes("Android")) {
        //     const uri = `tongtongmall://m.etomato.com?open=fromapp&from=${"com.etomato.ttticket"}&phone=${
        //         user?.decPhoneNum || ""
        //     }`;
        //
        //     Linking.openURL(uri).catch(() =>
        //         Linking.openURL(
        //             `http://play.google.com/store/apps/details?id=com.tongtong.tongtongmall1`
        //         )
        //     );
        //     return;
        // } else if (userAgent.includes("Safari") && userAgent.includes("iPhone")) {
        //     const uri = `tongtongmall://fromapp?from=tongtongticket&phone=${user?.decPhoneNum || ""}`;
        //     Linking.openURL(uri).catch(err => {
        //         Linking.openURL('https://itunes.apple.com/kr/app/id1471587538?mt=8');
        //     });
        // } else {
        //     Linking.openURL('https://itunes.apple.com/kr/app/id1471587538?mt=8');
        // }
    };

    const onNewsClick = () => {
        const appUrl = userAgent.includes("iPhone") ?
            `tomatoPrime://fromapp?from=tongtongticket&phone=${user?.decPhoneNum || ""}` :
            `tomatoPrime://m.etomato.com?open=fromapp&from=${"com.etomato.ttticket"}&phone=${
                user?.decPhoneNum || ""
            }`
        const storeUrlAndroid = 'https://play.google.com/store/apps/details?id=com.tomato.solution.newstong';
        const storeUrlIOS = 'https://itunes.apple.com/kr/app/id1023633406?mt=8';

        if (userAgent.includes("iPhone")) {
            var visiteTm = ( new Date() ).getTime();
            setTimeout( function () {
                if ( ( new Date() ).getTime() - visiteTm < 3000 ) {
                    location.href = storeUrlIOS;
                }
            } ,2500 );
            setTimeout( function () {  // 앱실행
                location.href = appUrl;
            } ,0 );
        } else {
            location.href = 'intent://m.etomato.com#Intent;scheme=tomatoPrime;package=com.tomato.solution.newstong;end';
        }



        // if (userAgent.includes("SamsungBrowser") || userAgent.includes("Chrome")  || userAgent.includes("Android")) {
        //     const uri = `tomatoPrime://m.etomato.com?open=fromapp&from=${"com.etomato.ttticket"}&phone=${
        //         user?.decPhoneNum || ""
        //     }`;
        //     // const uri = `http://play.google.com/store/apps/details?id=com.tomato.solution.newstong`;
        //     Linking.openURL(uri).catch(() =>
        //         Linking.openURL(
        //             `http://play.google.com/store/apps/details?id=com.tomato.solution.newstong`
        //         )
        //     );
        //     return;
        // } else if (userAgent.includes("Safari") && userAgent.includes("iPhone")) {
        //     // const uri = `tomatoPrime://fromapp?from=tongtongticket&phone=${user?.decPhoneNum || ""}`;
        //     const uri = 'https://itunes.apple.com/kr/app/id1023633406?mt=8';
        //     Linking.openURL(uri).catch(err => {
        //         Linking.openURL('https://itunes.apple.com/kr/app/id1023633406?mt=8');
        //     });
        // } else {
        //     Linking.openURL('https://itunes.apple.com/kr/app/id1023633406?mt=8');
        // }
    };

    const getDay = (date) => {
        const dateTime = new Date(date);
        return dateTime.getDate();
    }

    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}>
                <View style={styles.scrollContainer}>
                    <View style={styles.paddingContainer}>
                        <Text style={[globalStyle.bigBoldText, {marginBottom: perfectSize(30)}]}>{`${
                            user && user.name
                        } 님`}</Text>
                        {/*결제정보*/}
                        <View style={{marginBottom: perfectSize(20)}}>
                            <View style={[globalStyle.flexRow, styles.spaceBetween]}>
                                <View style={[styles.center]}>
                                    <Text style={[globalStyle.regularBoldText, styles.marginBottomSm]}>
                                        약정금액
                                    </Text>
                                    <Text style={{
                                        fontSize: perfectSize(12),
                                        color: c_grey,
                                        letterSpacing: -0.15,
                                    }}>{`${isPrice > 0 ? '50000 원' : ''}`}</Text>
                                </View>
                                <View style={[styles.center]}>
                                    <Text style={[globalStyle.regularBoldText, styles.marginBottomSm]}>
                                        결제방식
                                    </Text>
                                    <Text style={{
                                        fontSize: perfectSize(12),
                                        color: c_grey,
                                        letterSpacing: -0.15,
                                    }}>{type ? type : '-'}</Text>
                                </View>
                                <View style={[styles.center]}>
                                    <Text style={[globalStyle.regularBoldText, styles.marginBottomSm]}>
                                        결제일
                                    </Text>
                                    <Text style={{
                                        fontSize: perfectSize(12),
                                        color: c_grey,
                                        letterSpacing: -0.15,
                                    }}>{`${isDate === '' ? `` : `매월 ${isDate}`}`}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                        {/*통통쿠폰*/}
                        <View style={[globalStyle.flexRow, styles.spaceBetween, styles.paddingContainer]}>
                            <View style={[globalStyle.flexRow]}>
                                <Image
                                    source={require('../../../assets/images/ttco_symbol.png')}
                                    style={styles.imgCoinSymbol}
                                />
                                <Text style={[styles.textCoinInfo, {marginLeft: perfectSize(5)}]}>
                                    {'통통쿠폰(TTCO)'}
                                </Text>
                            </View>
                            <Text style={[styles.textCoinInfo]}>
                                {rewardPoint === 0
                                    ? "-"
                                    : rewardPoint}
                            </Text>
                        </View>
                    <View>
                        <View style={styles.paddingContainer}>
                            <Text style={{
                                fontSize: perfectSize(14),
                                letterSpacing: -0.15,
                                fontWeight: 'bold',
                                color: c_black,
                                marginBottom: perfectSize(20)

                            }}>
                                {'메니아 구독혜택'}
                            </Text>
                            <Text style={[globalStyle.regularGreyText, {marginBottom: perfectSize(20)}]}>
                                {'전체 약정금액에 상당하는 통통쿠폰(TTCO)과 소정의 TTC를 드립니다.\n' +
                                    'TTC 수령과 사용을 위해 통통지갑 App을 설치해주시기 바랍니다.\n' +
                                    'TTCO로 최대 70% 할인, TTC 사용시 추가 5% 할인 등 여러 혜택을 드리며\n' +
                                    '각 서비스와 상품별로 할인율 차이가 있으니 유의하시기 바랍니다.'}
                            </Text>
                            <Text style={[globalStyle.regularGreyText, {marginBottom: perfectSize(20)}]}>
                                {'* TTCO 할인혜택서비스'}
                            </Text>
                            {/*합격통*/}
                            <View style={styles.spaceBetween}>
                                <View style={{width: '25%'}}>
                                    <Image
                                        source={require('../../../assets/images/logo_pass.png')}
                                        style={{
                                            width: perfectSize(40),
                                            height: perfectSize(45),
                                        }}
                                    />
                                </View>
                                <View style={{
                                    justifyContent: "center",
                                    width: '45%'
                                }}>
                                    <Text style={{
                                        fontSize: perfectSize(12),
                                        letterSpacing: -0.15,
                                        fontWeight: 'bold',
                                        color: c_black,
                                        marginTop: perfectSize(-10)
                                    }}>
                                        {'합격통 70% 할인'}
                                    </Text>
                                    <Text style={{
                                        fontSize: perfectSize(9),
                                        color: c_grey,
                                        letterSpacing: -0.15,
                                    }}>
                                        {'금융자격증 교육기관'}
                                    </Text>
                                </View>
                                <View>
                                    {Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                                        <TouchableOpacity
                                            onPress={() => onPassClick()}
                                            style={{
                                                borderRadius: 4,
                                                borderColor: c_primary_red,
                                                borderWidth: 1,
                                                paddingLeft: perfectSize(24),
                                                paddingRight: perfectSize(24),
                                                paddingTop: perfectSize(4),
                                                paddingBottom: perfectSize(4),
                                                backgroundColor: c_primary_red,
                                                marginTop: perfectSize(-10)
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: perfectSize(13),
                                                    color: c_ligth_red,
                                                }}
                                            >바로가기</Text>
                                        </TouchableOpacity> :
                                        <TouchableOpacity
                                            onPress={() => Linking.openURL("https://www.tomatopass.com/main.do")}
                                            style={{
                                                borderRadius: 4,
                                                borderColor: c_primary_red,
                                                borderWidth: 1,
                                                paddingLeft: perfectSize(24),
                                                paddingRight: perfectSize(24),
                                                paddingTop: perfectSize(4),
                                                paddingBottom: perfectSize(4),
                                                backgroundColor: c_primary_red,
                                                marginTop: perfectSize(-10)
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: perfectSize(13),
                                                    color: c_ligth_red,
                                                }}
                                            >바로가기</Text>
                                        </TouchableOpacity> :
                                        <View />
                                    }
                                </View>
                            </View>
                            {/*투자클럽*/}
                            <View style={styles.spaceBetween}>
                                <View style={{width: '25%'}}>
                                    <Image
                                        source={require('../../../assets/images/logo_tujaclub.png')}
                                        style={{
                                            width: perfectSize(30),
                                            height: perfectSize(30),
                                        }}
                                    />
                                </View>
                                <View style={{
                                    justifyContent: "center",
                                    width: '45%'
                                }}>
                                    <Text style={{
                                        fontSize: perfectSize(12),
                                        letterSpacing: -0.15,
                                        fontWeight: 'bold',
                                        color: c_black,
                                        marginTop: perfectSize(0)
                                    }}>
                                        {'투자클럽 70% 할인'}
                                    </Text>
                                    <Text style={{
                                        fontSize: perfectSize(9),
                                        color: c_grey,
                                        letterSpacing: -0.15,
                                    }}>
                                        {'국내 1 위 증권정보'}
                                    </Text>
                                </View>
                                <View>
                                    {Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                                        <TouchableOpacity
                                            onPress={() => onTujaClick()}
                                            style={{
                                                borderRadius: 4,
                                                borderColor: c_primary_red,
                                                borderWidth: 1,
                                                paddingLeft: perfectSize(24),
                                                paddingRight: perfectSize(24),
                                                paddingTop: perfectSize(4),
                                                paddingBottom: perfectSize(4),
                                                backgroundColor: c_primary_red,
                                                marginTop: perfectSize(-5)
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: perfectSize(13),
                                                    color: c_ligth_red,
                                                }}
                                            >바로가기</Text>
                                        </TouchableOpacity> :
                                        <TouchableOpacity
                                            onPress={() => Linking.openURL("https://www.etomato.com/home/clubbroadcast/AdviserProfile.aspx")}
                                            style={{
                                                borderRadius: 4,
                                                borderColor: c_primary_red,
                                                borderWidth: 1,
                                                paddingLeft: perfectSize(24),
                                                paddingRight: perfectSize(24),
                                                paddingTop: perfectSize(4),
                                                paddingBottom: perfectSize(4),
                                                backgroundColor: c_primary_red,
                                                marginTop: perfectSize(-5)
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: perfectSize(13),
                                                    color: c_ligth_red,
                                                }}
                                            >바로가기</Text>
                                        </TouchableOpacity> :
                                        <View />
                                    }
                                </View>
                            </View>
                            {/*통통몰*/}
                            <View style={styles.spaceBetween}>
                                <View style={{width: '25%', marginTop: perfectSize(18)}}>
                                    <Image
                                        source={require('../../../assets/images/logo_tongtongmall.png')}
                                        style={{
                                            width: perfectSize(30),
                                            height: perfectSize(30),
                                        }}
                                    />
                                </View>
                                <View style={{
                                    justifyContent: "center",
                                    width: '45%'
                                }}>
                                    <Text style={{
                                        fontSize: perfectSize(12),
                                        letterSpacing: -0.15,
                                        fontWeight: 'bold',
                                        color: c_black,
                                        marginTop: perfectSize(15)
                                    }}>
                                        {'통통몰 30% 할인'}
                                    </Text>
                                    <Text style={{
                                        fontSize: perfectSize(9),
                                        color: c_grey,
                                        letterSpacing: -0.15,
                                    }}>
                                        {'실용적인 쇼핑몰'}
                                    </Text>
                                </View>
                                <View>
                                    {Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                                        <TouchableOpacity
                                            onPress={() => onMallClick()}
                                            style={{
                                                borderRadius: 4,
                                                borderColor: c_primary_red,
                                                borderWidth: 1,
                                                paddingLeft: perfectSize(24),
                                                paddingRight: perfectSize(24),
                                                paddingTop: perfectSize(4),
                                                paddingBottom: perfectSize(4),
                                                backgroundColor: c_primary_red,
                                                marginTop: perfectSize(10)
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: perfectSize(13),
                                                    color: c_ligth_red,
                                                }}
                                            >바로가기</Text>
                                        </TouchableOpacity> :
                                        <TouchableOpacity
                                            onPress={() => Linking.openURL("https://www.tongtongmall.net/main")}
                                            style={{
                                                borderRadius: 4,
                                                borderColor: c_primary_red,
                                                borderWidth: 1,
                                                paddingLeft: perfectSize(24),
                                                paddingRight: perfectSize(24),
                                                paddingTop: perfectSize(4),
                                                paddingBottom: perfectSize(4),
                                                backgroundColor: c_primary_red,
                                                marginTop: perfectSize(10)
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: perfectSize(13),
                                                    color: c_ligth_red,
                                                }}
                                            >바로가기</Text>
                                        </TouchableOpacity> :
                                        <View />
                                    }

                                </View>
                            </View>
                            {/*뉴스토마토*/}
                            <View style={styles.spaceBetween}>
                                <View style={{width: '25%', marginTop: perfectSize(18)}}>
                                    <Image
                                        source={require('../../../assets/images/logo_newstomato.png')}
                                        style={{
                                            width: perfectSize(35),
                                            height: perfectSize(35),
                                        }}
                                    />
                                </View>
                                <View style={{
                                    justifyContent: "center",
                                    width: '45%'
                                }}>
                                    <Text style={{
                                        fontSize: perfectSize(12),
                                        letterSpacing: -0.15,
                                        fontWeight: 'bold',
                                        color: c_black,
                                        marginTop: perfectSize(15)
                                    }}>
                                        {'뉴스토마토 프리미엄\n구독 70% 할인'}
                                    </Text>
                                    <Text style={{
                                        fontSize: perfectSize(9),
                                        color: c_grey,
                                        letterSpacing: -0.15,
                                    }}>
                                        {'싱싱한 뉴스, 멀티미디어 경제매체'}
                                    </Text>
                                </View>
                                <View>
                                    {Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                                        <TouchableOpacity
                                            onPress={() => onNewsClick()}
                                            style={{
                                                borderRadius: 4,
                                                borderColor: c_primary_red,
                                                borderWidth: 1,
                                                paddingLeft: perfectSize(24),
                                                paddingRight: perfectSize(24),
                                                paddingTop: perfectSize(4),
                                                paddingBottom: perfectSize(4),
                                                backgroundColor: c_primary_red,
                                                marginTop: perfectSize(10)
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: perfectSize(13),
                                                    color: c_ligth_red,
                                                }}
                                            >바로가기</Text>
                                        </TouchableOpacity> :
                                        <TouchableOpacity
                                            onPress={() => Linking.openURL("https://www.newstomato.com/")}
                                            style={{
                                                borderRadius: 4,
                                                borderColor: c_primary_red,
                                                borderWidth: 1,
                                                paddingLeft: perfectSize(24),
                                                paddingRight: perfectSize(24),
                                                paddingTop: perfectSize(4),
                                                paddingBottom: perfectSize(4),
                                                backgroundColor: c_primary_red,
                                                marginTop: perfectSize(10)
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: perfectSize(13),
                                                    color: c_ligth_red,
                                                }}
                                            >바로가기</Text>
                                        </TouchableOpacity> :
                                        <View />
                                    }
                                </View>
                            </View>
                            {/*우라아이재단*/}
                            {/*<View style={styles.spaceBetween}>*/}
                            {/*    <View style={{width: '25%', marginTop: perfectSize(18)}}>*/}
                            {/*        <Image*/}
                            {/*            source={require('../../../assets/images/logo_save.png')}*/}
                            {/*            style={{*/}
                            {/*                width: perfectSize(30),*/}
                            {/*                height: perfectSize(30),*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </View>*/}
                            {/*    <View style={{*/}
                            {/*        justifyContent: "center",*/}
                            {/*        width: '45%'*/}
                            {/*    }}>*/}
                            {/*        <Text style={{*/}
                            {/*            fontSize: perfectSize(12),*/}
                            {/*            letterSpacing: -0.15,*/}
                            {/*            fontWeight: 'bold',*/}
                            {/*            color: c_black,*/}
                            {/*            marginTop: perfectSize(15)*/}
                            {/*        }}>*/}
                            {/*            {'우리아이재단 후원'}*/}
                            {/*        </Text>*/}
                            {/*        <Text style={{*/}
                            {/*            fontSize: perfectSize(9),*/}
                            {/*            color: c_grey,*/}
                            {/*            letterSpacing: -0.15,*/}
                            {/*        }}>*/}
                            {/*            {'세상을 위한 좋은 변화'}*/}
                            {/*        </Text>*/}
                            {/*    </View>*/}
                            {/*    <View>*/}
                            {/*        <TouchableOpacity*/}
                            {/*            onPress={() => Linking.openURL("https://ourchildren.or.kr/")}*/}
                            {/*            style={{*/}
                            {/*                borderRadius: 4,*/}
                            {/*                borderColor: c_primary_red,*/}
                            {/*                borderWidth: 1,*/}
                            {/*                paddingLeft: perfectSize(24),*/}
                            {/*                paddingRight: perfectSize(24),*/}
                            {/*                paddingTop: perfectSize(4),*/}
                            {/*                paddingBottom: perfectSize(4),*/}
                            {/*                backgroundColor: c_primary_red,*/}
                            {/*                marginTop: perfectSize(13)*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <Text*/}
                            {/*                style={{*/}
                            {/*                    fontSize: perfectSize(13),*/}
                            {/*                    color: c_ligth_red,*/}
                            {/*                }}*/}
                            {/*            >바로가기</Text>*/}
                            {/*        </TouchableOpacity>*/}
                            {/*    </View>*/}
                            {/*</View>*/}
                        </View>

                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
    },
    scrollContainer: {
        padding: perfectSize(15)
    },
    spaceBetween: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    imgCoinSymbol: {
        width: perfectSize(20),
        height: perfectSize(20),
        // paddingHorizontal: perfectSize(20),
    },
    textCoinInfo: {
        fontSize: perfectSize(14),
    },
    viewTTco: {
        marginTop: perfectSize(25),
        marginLeft: perfectSize(15),
    },
    center: {
        alignItems: 'center'
    },
    marginBottomSm: {
        marginBottom: perfectSize(6)
    },
    paddingContainer: {
        padding: perfectSize(20)
    }
}