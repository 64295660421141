import _ from 'lodash';
import {
    ADD_SEAT,
    DELET_SEAT,
    RESET_SEAT,
    SET_TOTAL_PRICE,
    SET_ORDER_DETAIL,
    UPDATE_SEAT_OFFSET
} from '../../variables/constants';

const INITIAL_STATE = {
    selectedSeat: [],
    selectedNum: [],
    offset: { top: -30, bottom: -30, left: -30, right: -30 }
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ADD_SEAT: {
            const _seat = state.selectedSeat;
            _seat.push(action.payload.val);
            const _num = state.selectedNum;
            _num.push(action.payload.seatNum);
            return {
                ...state,
                selectedSeat: [..._seat],
                selectedNum: [..._num],
            }
        }
        case DELET_SEAT: {
            const seat = state.selectedSeat;
            const _seat = _.filter(seat, (o) => { return action.payload.val !== o });
            const num = state.selectedNum;
            const _num = _.filter(num, (o) => { return action.payload.seatNum !== o });
            return {
                ...state,
                selectedSeat: [..._seat],
                selectedNum: [..._num]
            }
        }
        case RESET_SEAT: {
            return {
                ...state,
                selectedSeat: [],
                selectedNum: []
            }
        }
        case SET_TOTAL_PRICE: {
            return {
                ...state,
                totalPrice: action.payload
            }
        }
        case SET_ORDER_DETAIL: {
            return {
                ...state,
                orderDetail: action.payload
            }
        }
        case UPDATE_SEAT_OFFSET: {
            return {
                ...state,
                offset: action.payload
            }
        }
        default:
            return state;
    }
}