import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Keyboard,
    TouchableWithoutFeedback,
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView,
    TextInput
} from 'react-native';
import {
    c_third_grey,
    c_white,
    c_primary_red
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import Separator from '../../components/Separator';
import { deleteInQueiry, updateInQueiry } from '../../store/actions';

export default function InquiryDetails({ navigation, route }) {

    const { data } = route.params;
    const [editable, setEditable] = useState(false);
    const [title, setTitle] = useState(data.title);
    const [content, setContent] = useState(data.content);

    useEffect(() => {
        navigation.setOptions({
            title: "문의내역",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    const answerItem = () => {
        return (
            <View style={styles.answerItem}>
                <View style={globalStyle.flexRow}>
                    <View style={styles.answerTag}>
                        <Text style={globalStyle.smText}>
                            {'답변'}
                        </Text>
                    </View>
                    <Text style={globalStyle.regularBoldText}>
                        {'관리자'}
                    </Text>
                </View>

                <Text style={{ ...globalStyle.smGreyText, marginVertical: perfectSize(10) }}>
                    {moment(data.updatedAt).format('YYYY.MM.DD')}
                </Text>

                <Text style={globalStyle.regularText}>
                    {data.answer}
                </Text>
            </View>
        )
    }

    const onDelete = async () => {
        await deleteInQueiry(data._id);
        navigation.goBack();
    }

    const onUpdate = async () => {
        if (!title) {
            alert('제목을 입력해주세요.');
            return;
        }
        if (!content) {
            alert('내용을 입력해주세요.');
        }
        const req = {
            title,
            content,
        }
        await updateInQueiry(data._id, req);
        navigation.goBack();
    }

    return (
        <View style={{ ...globalStyle.flex, backgroundColor: c_white }}>
            {editable ?
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                    <View style={globalStyle.flex}>
                        <View style={styles.mainContainer}>
                            <TextInput
                                value={title}
                                onChangeText={text => setTitle(text)}
                                placeholder={'제목'}
                                style={styles.title}
                            />
                            <TextInput
                                value={content}
                                onChangeText={text => setContent(text)}
                                placeholder={'문의내용을 입력해주세요'}
                                multiline={true}
                                style={{
                                    ...globalStyle.regularText,
                                    marginTop: perfectSize(20),
                                    maxHeight: perfectSize(300),
                                }}
                            />
                        </View>
                        <View style={globalStyle.oneBottomButton}>
                            <View style={globalStyle.flex}>
                                <Button
                                    onPress={onUpdate}
                                    textColor={c_white}
                                    label={'문의 수정하기'}
                                    color={c_primary_red}
                                />
                            </View>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
                :
                <>
                    <ScrollView>
                        <View style={styles.mainContainer}>
                            <Text style={styles.statusLabel}>
                                {data.answer ? '답변완료' : '답변대기중'}
                            </Text>
                            <Text style={{ ...globalStyle.bigText, fontSize: perfectSize(20) }}>
                                {data.title}
                            </Text>
                            <Separator
                                height={1}
                                color={c_third_grey}
                                marginTop={perfectSize(15)}
                                marginBottom={perfectSize(20)}
                            />
                            <Text style={globalStyle.regularText}>
                                {data.content}
                            </Text>
                            <View style={{ marginHorizontal: perfectSize(-15) }}>
                                <Separator
                                    height={8}
                                    color={c_third_grey}
                                    marginTop={perfectSize(30)}
                                />
                            </View>
                            <View>
                                {data.answer && answerItem()}
                            </View>
                        </View>
                    </ScrollView>
                    <View style={globalStyle.oneBottomButton}>
                        {
                            data.answer ?
                                <View style={globalStyle.flex}>
                                    <Button
                                        onPress={onDelete}
                                        isBorder
                                        textColor={c_primary_red}
                                        label={'삭제'}
                                        color={c_white}
                                    />
                                </View>
                                : <View style={globalStyle.flexRow}>
                                    <View style={[globalStyle.flex, { marginRight: perfectSize(5) }]}>
                                        <Button
                                            onPress={onDelete}
                                            isBorder
                                            textColor={c_primary_red}
                                            label={'삭제'}
                                            color={c_white}
                                        />
                                    </View>
                                    <View style={[globalStyle.flex, { marginLeft: perfectSize(5) }]}>
                                        <Button
                                            onPress={() => setEditable(true)}
                                            textColor={c_white}
                                            label={'수정'}
                                            color={c_primary_red}
                                        />
                                    </View>
                                </View>
                        }
                    </View>
                </>
            }
        </View >
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        paddingHorizontal: perfectSize(15),
        paddingTop: perfectSize(25),
    },
    statusLabel: {
        ...globalStyle.regularRedText,
        fontSize: perfectSize(13),
        marginBottom: perfectSize(10)
    },
    answerItem: {
        borderBottomWidth: 1,
        borderBottomColor: c_third_grey,
        paddingTop: perfectSize(20),
        paddingBottom: perfectSize(30)
    },
    answerTag: {
        paddingHorizontal: perfectSize(10),
        paddingVertical: perfectSize(4),
        borderWidth: 1,
        borderColor: c_third_grey,
        borderRadius: perfectSize(3),
        marginRight: perfectSize(8)
    },
    title: {
        fontSize: perfectSize(16),
        fontWeight: 'bold',
        letterSpacing: -0.16,
        paddingBottom: perfectSize(10),
        borderBottomColor: c_third_grey,
        borderBottomWidth: 1,
    },
}