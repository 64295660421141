import React, { useEffect } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
} from 'react-native';
import {
    c_dark_white,
    c_primary_black,
    c_primary_red,
    c_second_white,
    c_white
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Separator from '../../components/Separator';
import Button from '../../components/Button';

export default function TicketCancelComplete({ navigation }) {

    useEffect(() => {
        navigation.setOptions({
            title: "취소완료",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    return (
        <View style={styles.mainContainer}>
            <Image
                source={require('../../../assets/images/img.png')}
                style={styles.logo}
            />
            <Text style={styles.headerTitle}>
                {'예매취소가 완료되었습니다.'}
            </Text>

            <View style={styles.mainContents}>
                <Text style={globalStyle.regularGreyText}>
                    {'취소완료 후 결제 수단에 따라 입금까지 약 1일 ~ 2주 소요될 수 있습니다.\n\n취소내역 확인을 클릭하시면 예매취소/확인 페이지로 이동합니다. 부분취소의 경우 취소내역은 예매확인 페이지에서 확인 바랍니다. '}
                </Text>
            </View>

            <View style={styles.bottomButtons}>
                <Separator
                    height={perfectSize(1)}
                    color={c_second_white}
                />
                <View style={globalStyle.flex}>
                    <View style={[styles.bottomButtonWrapper]}>
                        <Button
                            onPress={() => navigation.navigate('ConfirmTicket')}
                            isBorder={false}
                            textColor={c_white}
                            label={'취소내역 확인'}
                            color={c_primary_red}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        paddingTop: perfectSize(20),
        backgroundColor: c_white,
        paddingHorizontal: perfectSize(15),
        alignItems: 'center'
    },
    logo: {
        width: perfectSize(90),
        height: perfectSize(90),
        resizeMode: 'contain',
        marginBottom: perfectSize(20)
    },
    headerTitle: {
        fontSize: perfectSize(25),
        color: c_primary_black,
        marginBottom: perfectSize(20),
    },
    bottomButtons: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: c_white
    },
    mainContents: {
        borderRadius: perfectSize(6),
        backgroundColor: c_dark_white,
        paddingHorizontal: perfectSize(20),
        paddingVertical: perfectSize(15),
        alignSelf: 'stretch'
    },
    bottomButtonWrapper: {
        paddingHorizontal: perfectSize(15),
        paddingBottom: perfectSize(15),
        paddingTop: perfectSize(10)
    }
}