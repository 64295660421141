export const c_white = '#ffffff';
export const c_dark_white = '#f8f8f8f8';
export const c_second_white = '#f5f5f5';
export const c_thrid_white = '#b0b0b0';
export const c_fourth_white = '#0c000000';
export const c_black = '#111111';
export const c_primary_black = '#222222';
export const c_second_black = '#4e4e4e';
export const c_third_black = '#111111';
export const c_fourth_black = '#353941';
export const c_fifth_black = '#000000';
export const c_grey = '#888888';
export const c_second_grey = '#c8c8c8';
export const c_third_grey = '#eeeeee';
export const c_fourth_grey = '#777777';
export const c_light_grey = '#dadada';
export const c_fifth_grey = '#b8b8b8';
export const c_primary_red = '#dd3c3c';
export const c_ligth_red = '#FEF1F6';
export const c_dark_red = '#660066'
export const c_primary_green = '#8CCE46';
export const c_dark_green = '#0da7a3';
export const c_light_green = '#B4FCB9';
export const c_light_blue = '#1DA1F2';
export const c_primary_blue = '#2e83e0'
export const c_third_blue = '#22a8f3';
export const c_purple = '#551e83';
export const c_indigo = '#35589c';