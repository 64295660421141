import React from "react";
import { StyleSheet, Text, TouchableOpacity, Image } from "react-native";
import { c_third_grey } from "../variables/colors";
import { globalStyle, perfectSize } from "../variables/styles";

export default function LoadMoreButton({
  onPress,
  icon,
  label,
  color,
  borderColor,
  buttonStyle,
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.loadMoreBtn, borderColor && { borderColor }, buttonStyle]}
    >
      <Text style={[globalStyle.regularGreyText, color && { color }]}>
        {'더보기'}
      </Text>
      {icon && (
        <Image
          source={icon}
          style={[styles.loadMoreIcon, { tintColor: color }]}
        />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  loadMoreBtn: {
    flex: 1,
    height: perfectSize(40),
    borderRadius: perfectSize(4),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderColor: c_third_grey,
  },
  loadMoreIcon: {
    width: perfectSize(10),
    height: perfectSize(10),
    marginRight: perfectSize(10),
    resizeMode: "contain",
    marginLeft: perfectSize(8),
  },
});
