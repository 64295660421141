import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
  TextInput,
} from "react-native";
import { perfectSize } from "../variables/styles";
import Modal from "react-native-modal";
import WebModal from "modal-react-native-web";
import {
  c_primary_red,
  c_second_grey,
  c_second_white,
} from "../variables/colors";

const { width } = Dimensions.get("window");

export default function ReviewModal({ isVisible, hideModal, onRegister }) {
  const [text, setText] = useState("");

  const modalContent = () => {
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.modalContainer}
      >
        <View style={styles.modalContent}>
          <TextInput
            value={text}
            onChangeText={val => setText(val)}
            multiline={true}
            placeholder={"리뷰를 작성해주세요."}
            placeholderTextColor={c_second_grey}
            style={styles.textInput}
          />
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={() => {
                if (text) {
                  onRegister(text);
                  setText("");
                }
              }}
              style={styles.registerButton}
            >
              <Text style={{ color: "white", fontSize: perfectSize(14) }}>
                등록
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setText("");
                hideModal();
              }}
              style={styles.cancelButton}
            >
              <Text style={{ color: c_primary_red, fontSize: perfectSize(14) }}>
                취소
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAvoidingView>
    );
  };

  return Platform.OS !== "web" ? (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      style={{ margin: 0 }}
      isVisible={isVisible}
      swipeDirection={"up"}
      onSwipeComplete={hideModal}
    >
      {modalContent()}
    </Modal>
  ) : (
    <WebModal visible={isVisible} transparent={true}>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          backgroundColor: "#00000050",
        }}
      >
        {modalContent()}
      </View>
    </WebModal>
  );
}

const styles = {
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: perfectSize(10),
  },
  modalContent: {
    backgroundColor: "white",
    width: Platform.OS !== "web" ? "100%" : width,
    borderRadius: perfectSize(6),
    padding: Platform.OS !== "web" ? perfectSize(15) : perfectSize(0),
  },
  textInput: {
    width: "100%",
    height: perfectSize(200),
    backgroundColor: c_second_white,
    borderRadius: perfectSize(6),
    marginVertical: perfectSize(14),
    padding: perfectSize(10),
    fontSize: perfectSize(14),
  },
  registerButton: {
    backgroundColor: c_primary_red,
    flex: 1,
    paddingVertical: perfectSize(10),
    borderRadius: perfectSize(6),
    alignItems: "center",
    marginRight: perfectSize(5),
  },
  cancelButton: {
    backgroundColor: "white",
    flex: 1,
    paddingVertical: perfectSize(10),
    borderRadius: perfectSize(6),
    alignItems: "center",
    marginLeft: perfectSize(5),
    borderWidth: 1,
    borderColor: c_primary_red,
  },
};
