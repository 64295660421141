import {
  COIN_LOAD_START,
  COIN_LOAD_FAIL,
  TTMI_COIN_LOAD_SUCCESS,
  TTMI_COIN_LIST_RESET,
  TTM_COIN_LOAD_SUCCESS,
  TTM_COIN_LIST_RESET,
} from '../../variables/constants';

const initialState = {
  ttmCoins: [],
  ttmiCoins: [],
  coinsLoading: false,
}

export default function CoinReducer(state = initialState, action) {
  switch (action.type) {

    case COIN_LOAD_START:
      return state = { ...state, coinsLoading: true };

    case TTMI_COIN_LOAD_SUCCESS:
      return state = {
        ...state,
        ttmiCoins: [...action.payload],
        coinsLoading: false
      };

    case TTM_COIN_LOAD_SUCCESS:
      return state = {
        ...state,
        ttmCoins: [...action.payload],
        coinsLoading: false
      };

    case TTMI_COIN_LIST_RESET:
      return state = { ...state, ttmiCoins: [] };

    case TTM_COIN_LIST_RESET:
      return state = { ...state, ttmCoins: [] };

    case COIN_LOAD_FAIL:
      return state = { ...state, coinsLoading: false };

    default:
      return state;
  }
}