import _ from "lodash";
import React from "react";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { UPLOAD_URL } from "../../../variables/constants";
import { c_third_grey } from "../../../variables/colors";
import { useNavigation } from "@react-navigation/native";

export const ReviewItem = props => {
  const { review } = props;
  const navigation = useNavigation();

  const renderRate = () => {
    const rate = [];
    for (let i = 0; i < review.star; i++) {
      rate.push(
        <Image
          key={`${review._id} + ${i}`}
          source={require("../../../../assets/images/icon_star_big.png")}
          style={styles.star}
        />
      );
    }
    return rate;
  };

  const onPerformance = () => {
    if (review.product_id._id)
      navigation.navigate("Detail", { id: review.product_id._id, isReview: true });
  };

  return (
    <TouchableOpacity onPress={onPerformance} style={globalStyle.flexRow}>
      <Image
        source={{ uri: UPLOAD_URL + review.product_id?.poster_link }}
        style={styles.thumbnail}
      />
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Text style={styles.productTitle} numberOfLines={1}>
          {review.product_id?.title?.name}
        </Text>
        <View
          style={[globalStyle.flexRow, { marginVertical: perfectSize(15) }]}
        >
          {renderRate()}
        </View>
        <Text style={styles.title} numberOfLines={2}>
          {review.content}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  thumbnail: {
    width: perfectSize(71),
    height: perfectSize(98),
    borderRadius: perfectSize(4),
    backgroundColor: c_third_grey,
    marginRight: perfectSize(10),
  },
  star: {
    width: perfectSize(12),
    height: perfectSize(12),
    resizeMode: "contain",
  },
  productTitle: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(19.6),
    letterSpacing: -0.2,
    color: "#111111",
  },
  title: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(16.8),
    letterSpacing: -0.2,
    color: "#777777",
  },
});
