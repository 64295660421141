import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Alert,
    ActivityIndicator,
    View,
    Text,
    Image,
    useWindowDimensions,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import {
    c_second_grey,
    c_grey,
    c_second_white,
    c_white,
    c_primary_black,
    c_light_grey,
    c_primary_red,
    c_thrid_white
} from '../../variables/colors';
import { useIsFocused } from '@react-navigation/native';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';
import Separator from '../../components/Separator';
import Badge from '../../components/Badge';
import { getReviewBook, deleteReview, deleteExpectation } from '../../store/actions';

function ReviewBook({
    navigation,
    user,
    deleteReview,
    deleteExpectation
}) {

    const layout = useWindowDimensions();
    const isFocusedHistory = useIsFocused();

    const [recommandType, setRecommandTyp] = useState(true);
    const [loading, setLoading] = useState(false);
    const [review, setReview] = useState([]);
    const [expect, setExpect] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [expects, setExpects] = useState([]);
    const [isMoreNotice, showMoreNotice] = useState(false);
    const [tab, setTab] = useState(true);

    useEffect(() => {
        navigation.setOptions({
            title: "리뷰북",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        const getPerformances = async () => {
            setLoading(true);
            const res = await getReviewBook(user._id);
            const _reviews = _.filter(res, (o) => { return o.type === 'review' });
            setReviews(_reviews);
            const _expects = _.filter(res, (o) => { return o.type !== 'review' });
            setExpects(_expects);
            const _reviews1 = _.sortBy(_reviews, (o) => { return o.likes });
            const _expects1 = _.sortBy(_expects, (o) => { return o.likes });
            _reviews1.reverse();
            _expects1.reverse();
            setReview([..._reviews1]);
            setExpect([..._expects1]);
            setLoading(false);
        };
        getPerformances();
    }, [isFocusedHistory]);

    useEffect(() => {
        if (recommandType) {
            const _reviews = _.sortBy(reviews, (o) => { return o.likes });
            const _expects = _.sortBy(expects, (o) => { return o.likes });
            _reviews.reverse();
            _expects.reverse();
            setReview([..._reviews]);
            setExpect([..._expects]);
        } else {
            const _reviews = _.sortBy(reviews, (o) => { return o.updatedAt });
            const _expects = _.sortBy(expects, (o) => { return o.updatedAt });
            _reviews.reverse();
            _expects.reverse();
            setReview([..._reviews]);
            setExpect([..._expects]);
        }
    }, [recommandType]);

    const renderStar = (star) => {
        let starArray = [];
        for (let i = 0; i < star; i++) {
            starArray.push(
                <Image
                    source={require('../../../assets/images/ic_star.png')}
                    style={styles.star}
                />
            )
        }
        return starArray;
    }

    const onDeleteReview = async (id, index) => {
        let data = tab ? review : expect;
        data.splice(index, 1);
        Alert.alert(
            `${tab ? '리뷰를' : '기대평을'} 삭제하시겠습니까?`,
            '',
            [
                {
                    text: '취소',
                    onPress: () => { },
                    style: 'cancel'
                },
                {
                    text: '확인',
                    onPress: async () => {
                        if (tab) {
                            await deleteReview(id);
                            setReview([...data]);
                            setReviews([...data]);
                        } else {
                            await deleteExpectation(id);
                            setExpect([...data]);
                            setExpects([...data]);
                        }
                    },
                    style: 'destructive'
                },
            ],
        );
    }

    const renderReviews = () => {
        if (loading) return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: perfectSize(20) }}>
                <ActivityIndicator color={c_primary_red} />
            </View>
        );
        const data = tab ? review : expect;
        if (_.isEmpty(data)) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: perfectSize(20) }}>
                    <Text>{`${tab ? '리뷰가' : '기대평이'} 없습니다.`}</Text>
                </View>
            )
        }

        return (

            <ScrollView
                style={{
                    paddingHorizontal: perfectSize(15),
                    paddingTop: perfectSize(22)
                }}>
                {
                    data.map((item, index) => {
                        return (
                            <View style={{ alignSelf: 'stretch' }} key={`${tab}${index}`}>

                                {/** ID */}
                                <View style={globalStyle.flexSpaceBetween}>
                                    <View style={globalStyle.flexRow}>
                                        <Text style={globalStyle.regularText}>{user.nickname}</Text>
                                        <View style={styles.badge}>
                                            <Text style={[globalStyle.greySmText, { color: c_primary_black }]}>
                                                {'예매자'}
                                            </Text>
                                        </View>
                                    </View>
                                    <TouchableOpacity onPress={() => onDeleteReview(item._id, index)}>
                                        <Text style={globalStyle.regularText}>
                                            {'삭제'}
                                        </Text>
                                    </TouchableOpacity>
                                </View>

                                {/** Date and Rank */}
                                <View style={[globalStyle.flexRow, { marginTop: perfectSize(8), marginBottom: perfectSize(15) }]}>
                                    <Text style={[globalStyle.smGreyText, { marginRight: perfectSize(5) }]}>
                                        {moment(item.createdAt).format('YYYY.MM.DD')}
                                    </Text>
                                    {item.star && renderStar(item.star)}
                                </View>

                                {/** Comment */}
                                <Text style={globalStyle.regularText} numberOfLines={isMoreNotice ? null : 1}>
                                    {item.content}
                                </Text>

                                {/** Load More Btn */}
                                <TouchableOpacity
                                    onPress={() => showMoreNotice(!isMoreNotice)}
                                    style={styles.loadMoreBtn}>
                                    <Text style={globalStyle.regularGreyText}>
                                        {'더보기'}
                                    </Text>
                                    <Image
                                        source={require('../../../assets/images/ic_more_detail.png')}
                                        style={[styles.loadMoreBtnIcon, { transform: [{ rotate: isMoreNotice ? '0deg' : '180deg' }] }]}
                                    />
                                </TouchableOpacity>

                                {/** Report */}
                                <View style={globalStyle.flexSpaceBetween}>
                                    <View style={globalStyle.flexRow}>
                                    </View>
                                    <View style={globalStyle.flexRow}>
                                        <Image
                                            source={require('../../../assets/images/ic_review_like_on.png')}
                                            style={styles.reportIcon}
                                        />
                                        <Text style={globalStyle.regularText}>{item.likes.length}</Text>
                                    </View>
                                </View>
                                <Separator
                                    height={perfectSize(1)}
                                    color={c_second_white}
                                    marginTop={perfectSize(10)}
                                    marginBottom={perfectSize(22)}
                                />
                            </View>
                        )
                    })
                }
            </ScrollView>
        )
    }

    return (
        <View style={styles.mainContainer}>
            <View style={[globalStyle.flexRow, { paddingHorizontal: perfectSize(15) }]}>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={() => setTab(true)}
                        isBorder={false}
                        textColor={tab ? c_white : c_thrid_white}
                        label={`공연리뷰 ${review.length}`}
                        color={tab ? c_primary_red : c_second_white}
                    />
                </View>
                <View style={globalStyle.flex}>
                    <Button
                        onPress={() => setTab(false)}
                        isBorder={false}
                        textColor={!tab ? c_white : c_thrid_white}
                        label={`기대평 ${expect.length}`}
                        color={!tab ? c_primary_red : c_second_white}
                    />
                </View>
            </View>

            {/** Review Header */}
            <View style={{
                alignItems: 'flex-start',
                flexDirection: 'row',
                width: layout.width,
                paddingHorizontal: perfectSize(15),
                marginTop: perfectSize(30)
            }}>
                <TouchableOpacity
                    onPress={() => setRecommandTyp(true)}
                    style={styles.recommend}
                >
                    <Image
                        source={
                            recommandType ? require('../../../assets/images/ic_check_on.png')
                                : require('../../../assets/images/ic_check_off.png')
                        }
                        style={styles.checkIcon}
                    />
                    <Text style={
                        recommandType ? globalStyle.regularText : globalStyle.regularGreyText
                    }>
                        {'추천순'}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => setRecommandTyp(false)}
                    style={styles.recommend}
                >
                    <Image
                        source={
                            !recommandType ? require('../../../assets/images/ic_check_on.png')
                                : require('../../../assets/images/ic_check_off.png')
                        }
                        style={styles.checkIcon}
                    />
                    <Text style={
                        !recommandType ? globalStyle.regularText : globalStyle.regularGreyText
                    }>
                        {'최신순'}
                    </Text>
                </TouchableOpacity>
            </View>
            <Separator
                height={perfectSize(1)}
                color={c_second_white}
                marginTop={perfectSize(10)}
            />
            {/** Review List */}
            {renderReviews()}
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
})

export default connect(mapStateTopProps, { deleteReview, deleteExpectation })(ReviewBook);

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
        paddingTop: perfectSize(15),
    },
    noReview: {
        fontSize: perfectSize(15),
        letterSpacing: -0.15,
        fontWeight: 'bold',
        color: c_grey,
        color: c_second_grey,
        textAlign: 'center',
        marginTop: perfectSize(40)
    },
    checkIcon: {
        width: perfectSize(11),
        height: perfectSize(8),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
    recommend: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: perfectSize(15)
    },
    star: {
        width: perfectSize(12),
        height: perfectSize(12),
        resizeMode: 'contain'
    },
    badge: {
        backgroundColor: c_white,
        borderWidth: 1,
        borderColor: c_light_grey,
        borderRadius: perfectSize(3),
        width: perfectSize(44),
        height: perfectSize(21),
        marginLeft: perfectSize(8),
        alignItems: 'center',
        justifyContent: 'center'
    },
    loadMoreBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        height: perfectSize(40)
    },
    loadMoreBtnIcon: {
        width: perfectSize(12),
        height: perfectSize(7),
        resizeMode: 'contain',
        marginLeft: perfectSize(8),
    },
    reportIcon: {
        width: perfectSize(13),
        height: perfectSize(13),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
}