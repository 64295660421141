import _ from 'lodash';
import {
    GET_BANNER,
    GET_HASH_TAG,
    GET_SEARCH_WORD,
    GET_CATEGORY,
    GET_COMMUNITY,
    GET_PERFORMANCE_LIST,
    GET_REVIEW,
    ADD_REVIEW,
    DELETE_REVIEW,
    GET_COUPONS,
    GET_NOTICE,
    GET_EXPECTATION,
    ADD_EXPECTATION,
    DELETE_EXPECTATION,
    SET_NOTIFICATION_COUNT,
    UPDATE_COIN_PRICES,
} from '../../variables/constants';

const INITIAL_STATE = {
    expectations: [],
    nNotification: 0,
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_BANNER: {
            return {
                ...state,
                banners: action.payload
            }
        }
        case GET_HASH_TAG: {
            return {
                ...state,
                hastags: action.payload
            }
        }
        case GET_SEARCH_WORD: {
            return {
                ...state,
                sword: action.payload
            }
        }
        case GET_CATEGORY: {
            return {
                ...state,
                categories: action.payload
            }
        }
        case GET_COMMUNITY: {
            return {
                ...state,
                communities: action.payload
            }
        }
        case GET_PERFORMANCE_LIST: {
            return {
                ...state,
                performances: action.payload
            }
        }
        case GET_REVIEW: {
            return {
                ...state,
                reviews: action.payload
            }
        }
        case ADD_REVIEW: {
            return {
                ...state,
                reviews: [...state.reviews, action.payload]
            }
        }
        case DELETE_REVIEW: {
            let _review = state.reviews;
            _review = _.filter(_review, (o) => { return o._id !== action.payload });
            return {
                ...state,
                reviews: [..._review]
            }
        }
        case GET_EXPECTATION: {
            return {
                ...state,
                expectations: action.payload
            }
        }
        case ADD_EXPECTATION: {
            return {
                ...state,
                expectations: [...state.expectations, action.payload]
            }
        }
        case DELETE_EXPECTATION: {
            let _expectations = state.expectations;
            _expectations = _.filter(_expectations, (o) => { return o._id !== action.payload });
            return {
                ...state,
                expectations: [..._expectations]
            }
        }
        case GET_COUPONS: {
            return {
                ...state,
                coupons: action.payload
            }
        }
        case GET_NOTICE: {
            return {
                ...state,
                notice: action.payload
            }
        }
        case SET_NOTIFICATION_COUNT: {
            return {
                ...state,
                nNotification: action.payload
            }
        }

        case UPDATE_COIN_PRICES: {
            return {
                ...state,
                coinData: action.payload
            }
        }

        default:
            return state;
    }
}