import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    TextInput
} from 'react-native';
import {
    c_black,
    c_grey,
    c_light_grey,
    c_primary_red,
    c_second_grey,
    c_second_white,
    c_third_grey,
    c_thrid_white,
    c_white
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Separator from '../../components/Separator';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import { searchUser, sendGift } from '../../store/actions';
import { makeTwoString, numberWithCommas, oneAlert } from '../../utils';
import moment from 'moment';
import { connect } from 'react-redux';

function TicketGift({ navigation, route, user }) {

    const [searchText, setSearchText] = useState('');
    const [searchedUser, setSearchedUser] = useState([]);
    const [selectedUser, setSelectUser] = useState();
    const [loading, setLoading] = useState(false);
    const { ticket, data } = route.params;

    useEffect(() => {
        navigation.setOptions({
            title: "선물하기",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    let ticketDate = data.product_id.schedule.detailed_time[ticket.round].date;
    const hour = data.product_id.schedule.detailed_time[ticket.round].hour;
    const minute = data.product_id.schedule.detailed_time[ticket.round].minute;
    const museum = data.product_id.location.name;
    const seat = ticket.seat_name.split(' ');
    ticketDate = new Date(ticketDate).setHours(hour);
    ticketDate = new Date(ticketDate).setMinutes(minute);

    const renderTicketCard = () => {
        return (
            <View style={styles.orderConfirmCard}>
                <Image
                    source={require('../../../assets/images/sample5.png')}
                    style={{
                        width: '100%',
                        height: perfectSize(180),
                        resizeMode: 'stretch',
                        backgroundColor: c_second_white
                    }}
                />
                <View style={{ padding: perfectSize(15), paddingBottom: perfectSize(30) }}>
                    <Text style={globalStyle.bigBoldText}>
                        {data.product_id.title.name}
                    </Text>
                    <Text style={[globalStyle.normalText, { marginVertical: perfectSize(14) }]}>
                        {`${data.product_id.location.name} I ${moment(data.product_id.schedule.detailed_time[data.round].date).format('YYYY.MM.DD')} ${data.product_id.schedule.detailed_time[data.round].hour}:${makeTwoString(data.product_id.schedule.detailed_time[data.round].minute)}`}
                    </Text>
                    <View style={styles.dotLine} />
                    <View style={[globalStyle.flexRow, styles.orderDetail]}>
                        <Text style={globalStyle.regularText}>
                            {'티켓번호: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {ticket._id.toUpperCase()}
                        </Text>
                    </View>
                    <View style={[globalStyle.flexRow, styles.orderDetail]}>
                        <Text style={globalStyle.regularText}>
                            {'좌석번호: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {ticket.seat_name}
                        </Text>
                    </View>
                    <View style={[globalStyle.flexRow, styles.orderDetail]}>
                        <Text style={globalStyle.regularText}>
                            {'좌석등급: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {`${ticket.seat_grade}석`}
                        </Text>
                    </View>
                    <View style={[globalStyle.flexRow, styles.orderDetail]}>
                        <Text style={globalStyle.regularText}>
                            {'가격선택: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {ticket.discount.name}
                        </Text>
                    </View>
                    <View style={[globalStyle.flexRow, styles.orderDetail]}>
                        <Text style={globalStyle.regularText}>
                            {'가격: '}
                        </Text>
                        <Text style={globalStyle.regularText}>
                            {`${numberWithCommas(ticket.price)}원`}
                        </Text>
                    </View>
                </View>
            </View>
        )
    }

    const onSearch = async () => {
        setSelectUser();
        if (searchText) {
            const res = await searchUser(searchText, user._id);
            if (!_.isEmpty(res)) {
                setSearchedUser(res);
            } else {
                oneAlert('존재하지 않는 계정정보입니다.');
            }
        } else {
            oneAlert('검색어를 입력해주세요.');
        }
    }

    const onGift = async () => {
        const req = {
            certified_user: user._id,
            gifted_user: selectedUser,
            isGift: true
        }
        setLoading(true);
        const res = await sendGift(ticket._id, req);
        if (res) {
            oneAlert('선물하기가 실패하였습니다.');
        } else {
            oneAlert('선물이 성공적으로 전달되었습니다! \n선물하신 내용은 예매확인/취소 페이지에서 확인하실수 있습니다.');
            navigation.goBack();
        }
        setLoading(false);
    }

    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView>
                <View style={styles.scrollView}>
                    <View style={styles.header}>
                        {/** 계정검색 */}
                        <Text style={globalStyle.regularBoldText}>
                            {'계정검색'}
                        </Text>
                        <View style={styles.searchInput}>
                            <TextInput
                                value={searchText}
                                onChangeText={text => {
                                    setSearchText(text);
                                    setSearchedUser([]);
                                }}
                                style={[globalStyle.regularText, { width: perfectSize(270) }]}
                                placeholder={'상대방의 전화번호를 입력하세요'} />
                            <TouchableOpacity
                                onPress={onSearch}>
                                <Image
                                    source={require('../../../assets/images/ic_search_b.png')}
                                    style={globalStyle.searchIcon}
                                />
                            </TouchableOpacity>
                        </View>

                        {/** 계정정보 */}
                        {
                            searchedUser.length > 0 &&
                            <View style={{ paddingVertical: perfectSize(10) }}>
                                <Text style={globalStyle.regularBoldText}>
                                    {'계정정보'}
                                </Text>
                                {
                                    searchedUser.map((item, index) => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    setSelectUser(item._id);
                                                }}
                                                style={styles.borderContainer} key={index}>
                                                <Image
                                                    source={
                                                        selectedUser === item._id ?
                                                            require('../../../assets/images/radio_on.png')
                                                            : require('../../../assets/images/radio_off.png')
                                                    }
                                                    style={{
                                                        width: perfectSize(18),
                                                        height: perfectSize(18),
                                                        resizeMode: 'contain',
                                                        marginRight: perfectSize(15),
                                                        marginTop: perfectSize(-2)
                                                    }}
                                                />
                                                <View>
                                                    <View style={[globalStyle.flexRow, { marginBottom: perfectSize(10) }]}>
                                                        <Text style={[globalStyle.regularGreyText, {
                                                            width: perfectSize(80)
                                                        }]}>
                                                            {'이름'}
                                                        </Text>
                                                        <Text style={globalStyle.regularText}>
                                                            {item.name}
                                                        </Text>
                                                    </View>
                                                    <View style={globalStyle.flexRow}>
                                                        <Text style={[globalStyle.regularGreyText, {
                                                            width: perfectSize(80)
                                                        }]}>
                                                            {'전화번호'}
                                                        </Text>
                                                        <Text style={globalStyle.regularText}>
                                                            {item.decPhoneNum}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </View>
                        }
                    </View>
                    <Separator
                        height={perfectSize(8)}
                        color={c_second_white}
                    />

                    {/** 선물내용 */}
                    <View style={styles.giftConatiner}>
                        <Text style={[
                            globalStyle.regularBoldText, {
                                paddingBottom: perfectSize(15)
                            }]}>
                            {'선물내용'}
                        </Text>
                        {renderTicketCard()}
                        <Footer marginTop={perfectSize(50)} />
                    </View>
                </View>
            </ScrollView>
            <View style={globalStyle.twoBottomButton}>
                <View style={[globalStyle.flex, { marginRight: perfectSize(5) }]}>
                    <Button
                        onPress={() => navigation.goBack()}
                        isBorder={true}
                        textColor={c_primary_red}
                        label={'취소하기'}
                        color={c_white}
                    />
                </View>
                <View style={[globalStyle.flex, { marginLeft: perfectSize(5) }]}>
                    <Button
                        onPress={onGift}
                        isBorder={false}
                        textColor={c_white}
                        label={'선물하기'}
                        color={c_primary_red}
                        disable={searchedUser.length <= 0}
                        loading={loading}
                    />
                </View>
            </View>
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateTopProps)(TicketGift);

const styles = {
    header: {
        backgroundColor: c_white,
        paddingHorizontal: perfectSize(15),
        paddingTop: perfectSize(20),
        paddingBottom: perfectSize(10),
        marginBottom: perfectSize(8)
    },
    borderContainer: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: c_third_grey,
        marginTop: perfectSize(12),
        marginBottom: perfectSize(10),
        padding: perfectSize(15),
        borderRadius: perfectSize(6)
    },
    dotLine: {
        borderStyle: 'dashed',
        borderColor: c_grey,
        borderWidth: 1,
        marginBottom: perfectSize(15),
        marginHorizontal: perfectSize(1)
    },
    orderDetail: {
        marginBottom: perfectSize(4)
    },
    orderConfirmCard: {
        marginBottom: perfectSize(20),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
        shadowColor: c_black,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        elevation: 2,
    },
    noticeIcon: {
        width: perfectSize(17),
        height: perfectSize(17),
        resizeMode: 'contain',
        marginRight: perfectSize(5)
    },
    countDown: {
        color: c_primary_red,
        fontSize: perfectSize(22),
        fontWeight: 'bold',
        marginBottom: perfectSize(10),
        marginTop: perfectSize(5)
    },
    scrollView: {
        paddingHorizontal: perfectSize(0),
    },
    warningMsg: {
        borderRadius: perfectSize(2),
        borderWidth: 1,
        borderColor: c_second_grey,
        paddingHorizontal: perfectSize(6),
        paddingVertical: perfectSize(2),
        marginLeft: perfectSize(10)
    },
    searchInput: {
        height: perfectSize(40),
        borderColor: c_primary_red,
        borderWidth: 1,
        borderRadius: perfectSize(6),
        marginVertical: perfectSize(20),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: perfectSize(15)
    },
    giftConatiner: {
        backgroundColor: c_white,
        padding: perfectSize(15)
    },
}