import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';

import {
    ActivityIndicator,
    View,
    Text,
    TouchableOpacity,
    Image,
    FlatList
} from 'react-native';
import {
    c_white,
    c_primary_red,
    c_second_white,
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import { getFavoritePerformance } from '../../store/actions';
import FavoriteItem from './favorite';

function Favorite({ navigation, user }) {

    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(false);

    const isFocusedHistory = useIsFocused();

    useEffect(() => {
        navigation.setOptions({
            title: "찜한 공연",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    useEffect(() => {
        getFavorites();
    }, [isFocusedHistory]);

    const getFavorites = async () => {
        setLoading(true);
        const res = await getFavoritePerformance(user._id);
        if (res) {
            setFavorites(res);
        } else {
            setFavorites([]);
        }
        setLoading(false);
    };

    if (loading) {
        return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: c_white }}>
                <ActivityIndicator color={c_primary_red} />
            </View>
        )
    }
    if (_.isEmpty(favorites)) {
        return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: c_white }}>
                <Text>{'찜한공연이 없습니다.'}</Text>
            </View>
        )
    }

    const renderFavorites = ({ item, index }) => {
        return (
            <FavoriteItem
                item={item}
                key={index}
                user={user}
                navigation={navigation}
            />
        )
    }

    return (
        <View style={styles.container}>
            <FlatList
                data={favorites}
                renderItem={renderFavorites}
                keyExtractor={item => item._id}
                contentContainerStyle={{ paddingTop: perfectSize(15) }}
            />
        </View>
    )
}

const mapStateTopProps = state => ({
    user: state.auth.user
});

export default connect(mapStateTopProps)(Favorite);

const styles = {
    container: {
        flex: 1,
        backgroundColor: c_white,
    },
    categoryImage: {
        width: perfectSize(100),
        height: perfectSize(140),
        marginRight: perfectSize(10),
        borderRadius: perfectSize(4),
        resizeMode: 'stretch',
        backgroundColor: c_second_white
    },
    badge: {
        borderColor: c_primary_red,
        borderWidth: 1,
        borderRadius: perfectSize(3),
        padding: perfectSize(3),
        alignSelf: 'flex-start'
    },
    badgeText: {
        fontSize: perfectSize(11),
        color: c_primary_red
    },
    likeIcon: {
        width: perfectSize(23),
        height: perfectSize(19),
        resizeMode: 'contain',
        marginLeft: perfectSize(15)
    },
}