import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView
} from 'react-native';
import {
    c_third_grey,
    c_white,
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';

function CustomerService({ navigation, isAuthenticated }) {

    useEffect(() => {
        navigation.setOptions({
            title: "고객센터",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                        <Image
                            source={require('../../../assets/images/ic_menu_sub.png')}
                            style={globalStyle.headerNormalIcon}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                        source={require('../../../assets/images/ic_close.png')}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            )
        });
    }, []);

    const onInquiry = () => {
        if (isAuthenticated) {
            navigation.navigate('Inquiry');
        } else {
            alert('로그인 해주세요.');
            navigation.navigate('SignIn', {screenName: 'Home'});
        }
    }

    return (
        <ScrollView style={{ ...globalStyle.flex, backgroundColor: c_white }}>
            <View style={styles.mainContainer}>

                {/** List */}
                <View style={styles.list}>
                    <TouchableOpacity
                        onPress={onInquiry}
                        style={styles.item}>
                        <Text style={globalStyle.regularBoldText}>
                            {'문의내역'}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => navigation.navigate('Notice')}
                        style={styles.item}>
                        <Text style={globalStyle.regularBoldText}>
                            {'공지사항'}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => navigation.navigate('FAQ')}
                        style={styles.item}>
                        <Text style={globalStyle.regularBoldText}>
                            {'자주 묻는 질문'}
                        </Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity style={styles.item}>
                        <Text style={globalStyle.regularBoldText}>
                            {'자주 묻는 질문'}
                        </Text>
                    </TouchableOpacity> */}
                </View>

            </View>
        </ScrollView>
    )
}

const mapStateTopProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateTopProps)(CustomerService);

const styles = {
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: perfectSize(40)
    },
    headerTitle: {
        alignItems: 'flex-end',
        alignSelf: 'stretch',
        paddingRight: perfectSize(15)
    },
    list: {
        width: '100%',
        borderTopWidth: perfectSize(1),
        borderColor: c_third_grey,
    },
    item: {
        borderBottomWidth: 1,
        borderBottomColor: c_third_grey,
        paddingVertical: perfectSize(22),
        paddingHorizontal: perfectSize(20)
    },
}