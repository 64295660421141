import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {
    Dimensions,
    View,
    Image,
    SafeAreaView,
    TouchableOpacity,
    ScrollView, Text, Platform
} from 'react-native';
import HTML from 'react-native-render-html';
import {getRewardPoint, termsOfService} from '../../store/actions';
import {globalStyle, perfectSize} from '../../variables/styles';
import Button from "../../components/Button";
import {c_primary_black, c_primary_red, c_white} from "../../variables/colors";
import {oneAlert} from "../../utils";
import ManiaModal from "../../modals/ManiaModal";
import axios from "axios";
import CardModal from "../../modals/CardModal";
import {TextInput} from "react-native-web";
import {HOST, TOMATOPAY_MID, TOMATOPAY_LICENSE, TOMATOPAY_RETURN_URL} from "../../variables/constants";

import PayForm from "../../components/PayForm";
import {setContentStatus} from "../../store/actions/performance";

const {width} = Dimensions.get('window');

export default function Terms({
                                  route,
                                  navigation
                              }) {

    const sUsrAg = navigator.userAgent;
    const [contents, setContents] = useState();
    const {type, user, paytype, item, point} = route.params;
    const contentType = [
        '이용약관',
        '전자금융거래약관',
        '개인정보취급방침',
        '청소년보호정책',
        '토마토 ONE-ID 이용약관',
        '정기구독 유료서비스 이용약관'
    ];
    const [visibleManiaModal, setVisibleManiaModal] = useState(false);

    //개별구매
    const currentTimeRef = useRef(null);
    const [visibleCardModal, setVisibleCardModal] = useState(false);
    const [email, setEmail] = useState('');
    const [rewardPoint, setRewardPoint] = useState(0);
    const [isPrice, setPrice] = useState(50000);
    const [disCountPoint, setDisCountPoint] = useState(0);

    useEffect(() => {
        navigation.setOptions({
            title: contentType[type],
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
        getTermsOfService();
        getRewardData();
    }, []);

    useEffect(() => {
        if (rewardPoint > 0) {
            const percentage = 70;
            const result = (isPrice * percentage) / 100; // 70퍼센트 금액
            if (rewardPoint - result > 0) { //70퍼센트 금액 이상포인트 있는경우
                setPrice(35000);
            } else { // 아닌경우
                setPrice(isPrice - rewardPoint)
            }
            setDisCountPoint(50000 - isPrice)
        }
    }, [rewardPoint]);

    const getRewardData = async () => { // user.decPhoneNum 01050556371
        if (user) {
            await getRewardPoint(user._id).then(res => {
                if (res && res.Code === "200") {
                    const point = parseInt(res.data.Point);
                    setRewardPoint(point);
                }
            });
        }
    }

    const getTermsOfService = async () => {
        const res = await termsOfService();
        if (!_.isEmpty(res)) {
            setContents(res);
        }
    }

    function onJoin() {
        if (paytype === 1) {
            // 개별구매
            if (!email) {
                oneAlert('이메일을 입력하세요.');
                return;
            }
            setVisibleCardModal(true);
            const newDate = new Date().getTime();
            currentTimeRef.current = newDate;
            const moid = "ttticket0m" + newDate;
            onCreateCard(moid);
        } else {
            navigation.navigate('Mania_payInfo', {user: user, point: point});
        }
    }

    const onCreateCard = (moid) => {
        axios.post(`${HOST}/api/tongtongcoin/createCardPay`, {moid})
            .then(res => {
                // onCheckCard(moid);
            })
            .catch(err => {
                oneAlert('카드결제 서버에 접속할수 없습니다. 다시 시도해주세요.');
            })
    }

    const onSuccess = async (TID) => {
        setVisibleManiaModal(false);
        setVisibleCardModal(false);

        if (paytype === 1) {
            await setContentStatus(user?._id, item?._id, isPrice, `ttticket0m${currentTimeRef.current}`).then(
                res => {
                    navigation.navigate("ManiaItemReturn", {user: user});
                }
            ).catch(err => console.log('setContentStatus err ='))
        }
    };

    const onHideModal = async () => {
        setVisibleCardModal(false);
        if (paytype === 1) {
            await setContentStatus(user?._id, item?._id, isPrice, `ttticket0m${currentTimeRef.current}`).then(
                res => {
                    navigation.navigate("ManiaItemReturn", {user: user});
                }
            ).catch(err => console.log('setContentStatus err ='))
        }
    }

    return (
        <View style={globalStyle.mainContainer}>
            <ScrollView>
                <View style={styles.scrollContainer}>
                    {
                        contents && typeof (type) === 'number' &&
                        <HTML
                            source={{html: contents[type].content}}
                            baseFontStyle={styles.baseFontStyle}
                            tagsStyles={styles.tagsStyles}
                            containerStyle={styles.containerStyle}
                            contentWidth={width}
                        />
                    }
                </View>
            </ScrollView>
            {type === 5 &&
            Platform.OS === 'web' ? !sUsrAg.match(/Windows/i) ?
                <View style={{marginBottom: perfectSize(50)}}>
                    {paytype === 1 ?
                        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: perfectSize(10)}}>
                            <Text style={[styles.contenttext, {marginRight: perfectSize(8)}]}>결제 이메일 : </Text>
                            <TextInput
                                value={email}
                                keyboardType={'email-address'}
                                placeholder={'이메일을 입력하세요.'}
                                onChangeText={text => setEmail(text)}
                                style={[styles.textInput, {padding: perfectSize(10)}]}/>
                        </View> : <View/>
                    }
                    <View
                        style={[
                            globalStyle.flexRow,
                            {},
                        ]}
                    >

                        <View style={{flex: 1, marginRight: perfectSize(5), marginLeft: perfectSize(15)}}>
                            <Button
                                onPress={() => navigation.goBack()}
                                isBorder={true}
                                textColor={c_primary_red}
                                label={"취소"}
                                color={c_white}
                                borderColor={c_primary_red}
                                borderRadius={8}
                            />
                        </View>
                        <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                            {/*{paytype === 1 ?*/}
                                <Button
                                    onPress={onJoin}
                                    isBorder={true}
                                    textColor={c_white}
                                    label={"약관동의"}
                                    color={c_primary_red}
                                    borderRadius={8}
                                />
                            {/*:*/}
                            {/*    <PayForm*/}
                            {/*        data={data}*/}
                            {/*        label={"약관동의"}*/}
                            {/*        type={"mania"}*/}
                            {/*    />*/}
                            {/*}*/}
                        </View>
                    </View>
                </View> :
                <View style={{marginBottom: perfectSize(10)}}>
                    {paytype === 1 ?
                        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: perfectSize(10)}}>
                            <Text style={[styles.contenttext, {marginRight: perfectSize(8)}]}>결제 이메일 : </Text>
                            <TextInput
                                value={email}
                                keyboardType={'email-address'}
                                placeholder={'이메일을 입력하세요.'}
                                onChangeText={text => setEmail(text)}
                                style={[styles.textInput, {padding: perfectSize(10)}]}/>
                        </View> : <View/>
                    }
                    <View
                        style={[
                            globalStyle.flexRow,
                            {},
                        ]}
                    >

                        <View style={{flex: 1, marginRight: perfectSize(5), marginLeft: perfectSize(15)}}>
                            <Button
                                onPress={() => navigation.goBack()}
                                isBorder={true}
                                textColor={c_primary_red}
                                label={"취소"}
                                color={c_white}
                                borderColor={c_primary_red}
                                borderRadius={8}
                            />
                        </View>
                        <View style={{flex: 1, marginLeft: perfectSize(5), marginRight: perfectSize(15)}}>
                            {/*{paytype === 1 ?*/}
                                <Button
                                    onPress={onJoin}
                                    isBorder={true}
                                    textColor={c_white}
                                    label={"약관동의"}
                                    color={c_primary_red}
                                    borderRadius={8}
                                />
                            {/*:*/}
                            {/*    <PayForm*/}
                            {/*        data={data}*/}
                            {/*        label={"약관동의"}*/}
                            {/*        type={"mania"}*/}
                            {/*    />*/
                            }
                        </View>
                    </View>
                </View> :
                <View />
            }
            {visibleManiaModal &&
                <ManiaModal
                    isVisible={visibleManiaModal}
                    hideModal={() => {
                        setVisibleManiaModal(false);
                    }}
                    onSuccess={onSuccess}
                    user={user}
                />
            }
            {visibleCardModal &&
                <CardModal
                    isVisible={visibleCardModal}
                    hideModal={onHideModal}
                    onSuccess={onSuccess}
                    moid={`ttticket0m${currentTimeRef.current}`}
                    data={`https://admin.tickettong.net/card-pay?product_name=${encodeURIComponent(item.title)}&price=${isPrice}&buyer_name=${encodeURIComponent(user?.nickname || user?.decPhoneNum)}&buyer_phone=${user?.decPhoneNum}&buyer_email=${email}&moid=ttticket0m${currentTimeRef.current}`}
                />
            }
        </View>
    )
}

const styles = {
    scrollContainer: {
        padding: perfectSize(15)
    },
    baseFontStyle: {
        fontSize: 18,
        textAlign: 'center',
    },
    tagsStyles: {
        p: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    containerStyle: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contenttext: {
        fontSize: perfectSize(14),
        letterSpacing: -0.01,
        color: c_primary_black,
        paddingLeft: perfectSize(15)
    },
    textInput: {
        fontSize: perfectSize(14),
        letterSpacing: -0.15,
        color: c_primary_black,
        flex: 1,
        borderWidth: 1,
        borderColor: c_primary_red,
        borderRadius: perfectSize(6),
        paddingHorizontal: perfectSize(15),
        height: perfectSize(46),
        marginRight: perfectSize(15)
    },
}