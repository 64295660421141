import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    TextInput,
    SafeAreaView
} from 'react-native';
import {
    c_grey,
    c_second_grey,
    c_light_grey
} from '../../variables/colors';
import { globalStyle, perfectSize } from '../../variables/styles';
import Button from '../../components/Button';

export default function AddPhone({ navigation }) {

    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        navigation.setOptions({
            title: "전화번호입력",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require('../../../assets/images/ic_before_stroke.png')}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            )
        });
    }, []);

    return (
        <View style={styles.mainContainer}>
            <Text style={globalStyle.largeText}>
                {'토마토 One-ID'}
            </Text>
            <Text style={[globalStyle.normalText, styles.marginV]}>
                {'토마토 One-ID로 티켓통을 이용하세요!'}
            </Text>

            <View style={styles.phoneInputContainer}>
                <View style={styles.countryCode}>
                    <Text style={globalStyle.normalText}>{'+82'}</Text>
                </View>
                <View style={styles.phoneInput}>
                    <TextInput
                        placeholder={'01012345678'}
                        keyboardType={'phone-pad'}
                        maxLength={11}
                        style={globalStyle.textInput}
                        value={phoneNumber}
                        onChangeText={text => setPhoneNumber(text)}
                        warningText={'비밀번호를 잘못입력하셨습니다'}
                        isWarn={true}
                    />
                </View>
            </View>

            <View style={styles.bottomView}>
                <Text
                    style={[globalStyle.greyNormalText, {
                        marginBottom: perfectSize(30),
                        textAlign: 'center'
                    }]}>
                    {'인증한 휴대폰 번호는 아이디가 됩니다'}
                </Text>
                <Button
                    onPress={() => navigation.navigate('Passcode')}
                    label={'인증번호 발송'}
                    buttonStyle={(!phoneNumber) && {
                        backgroundColor: c_light_grey
                    }}
                    disable={(!phoneNumber) ? true : false}
                />
            </View>
        </View>
    )
}

const styles = {
    mainContainer: {
        flex: 1,
        alignItems: 'center',
        marginTop: perfectSize(60),
        paddingHorizontal: perfectSize(21)
    },
    marginV: {
        marginTop: perfectSize(18),
        marginBottom: perfectSize(30)
    },
    bottomView: {
        position: 'absolute',
        bottom: perfectSize(30),
        left: perfectSize(21),
        right: perfectSize(21)
    },
    phoneInputContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch'
    },
    countryCode: {
        width: perfectSize(72),
        height: perfectSize(48),
        borderWidth: 1,
        borderColor: c_second_grey,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: perfectSize(6)
    },
    phoneInput: {
        flex: 1,
    },
}