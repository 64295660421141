import React, { useEffect, useState } from "react";
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Platform,
} from "react-native";
import { globalStyle, perfectSize } from "../../variables/styles";
import {
  c_primary_red,
  c_second_grey,
  c_third_grey,
} from "../../variables/colors";
import WebView from "react-native-webview";
import Loader from "../../components/Loader";
import LoadMoreButton from "../../components/LoadMoreButton";
import ReviewModal from "../../modals/ReviewModal";
import { Feather } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { instance } from "../../utils";
import ReactPlayer from "react-player";

export default function VideoPlayer({ route, navigation }) {
  const { video, isLive } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [isExpend, setIsExpend] = useState(false);
  const [isVisibleReviewModal, setIsVisibleReviewModal] = useState(false);
  const [reveiewData, setReviewData] = useState([]);
  const [liveLink, setLiveLink] = useState("");
  const { auth } = useSelector(state => state);
  let title = "";
  useEffect(() => {
    if (isLive === undefined) {
      title = video.asset_type === "vr360" ? "VR360" : "비디오";
    } else {
      title = "TV";
    }
    navigation.setOptions({
      title: title,
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../assets/images/ic_before_stroke.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
    });
  }, []);

  useEffect(() => {
    instance.get("api/tvlive").then(res => setLiveLink(res.data.data.url));
  }, []);


  if (isLive) {
    return Platform.OS === "web" ? (
        <ReactPlayer
            url={liveLink}
            height={'100%'}
            width={'100%'}
            muted={true}
            playing={true}
            controls={true}
        />
    ) : (
      <WebView
        style={{ flex: 1 }}
        source={{ uri: liveLink }}
        scrollEnabled={false}
        domStorageEnabled
        javaScriptEnabled
        originWhitelist={["*"]}
      />
    );
  }

  const runFirst = `
      document.body.style.backgroundColor = 'white';
      true; // note: this is required, or you'll sometimes get silent failures
    `;

  useEffect(() => {
    getReview();
  }, []);

  const getReview = async () => {
    const req_body = {
      type: "content",
      asset_id: video._id,
      product_id: video.performance._id,
    };
    instance.get("api/review", { params: req_body }).then(res => {
      setReviewData(res.data.data);
    });
  };

  const registerReview = text => {
    if (auth.user?._id) {
      const req_body = {
        type: "content",
        content: text,
        user_id: auth.user?._id,
        product_id: video.performance._id,
        asset_id: video._id,
      };
      instance.post("api/review", req_body).then(res => {
        if (res.data.status) {
          setIsVisibleReviewModal(false);
          getReview();
        }
      });
    }
  };

  console.log('====video.asset_type', video.type)
  console.log('====video.video._id', video._id)

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={{ height: perfectSize(220) }}>
          {video.asset_type === "vr360" ? (
             <iframe src={`https://admin.tickettong.net/vr360-player?id=${video._id}`} style={styles.iframe_container}/>
          ) :
            <ReactPlayer
                url={video.url}
                height={'100%'}
                width={'100%'}
                muted={true}
                playing={true}
                controls={true}
            />
          }
        </View>
        <Text style={styles.title}>{video.content?.title}</Text>
        <View style={{ padding: perfectSize(15) }}>
          {video.nft ? (
            <TouchableOpacity onPress={() => navigation.navigate('NFTMolDetail', {id: video.nft})} style={styles.button}>
              <Text style={styles.buttonTitle}>{"해당 비디오의 NFT 정보"}</Text>
            </TouchableOpacity>
          ) : (
            <View style={styles.noButton}>
              <Text style={styles.buttonTitle}>{"해당 비디오의 NFT 정보"}</Text>
            </View>
          )}
          <Text
            style={[
              styles.content,
              !isExpend && { maxHeight: perfectSize(200), overflow: "hidden" },
            ]}
          >
            {video.content?.description}
          </Text>
          {!isExpend && (
            <LoadMoreButton
              onPress={() => setIsExpend(true)}
              icon={require("../../../assets/images/ic_more_detail.png")}
              label={"더보기"}
              color={c_second_grey}
              buttonStyle={{ height: perfectSize(40) }}
            />
          )}
          <View style={{ marginTop: perfectSize(20) }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: perfectSize(5),
              }}
            >
              <Text
                style={{
                  fontSize: perfectSize(14),
                  fontWeight: "bold",
                }}
              >
                {"리뷰"}
              </Text>
              <TouchableOpacity onPress={() => setIsVisibleReviewModal(true)}>
                <Feather
                  name="edit"
                  size={perfectSize(18)}
                  color={c_primary_red}
                />
              </TouchableOpacity>
            </View>
            {reveiewData.map(item => {
              return (
                <View
                  key={item._id}
                  style={{
                    flexDirection: "row",
                    marginBottom: perfectSize(10),
                  }}
                >
                  <Text
                    style={{
                      fontSize: perfectSize(14),
                      lineHeight: perfectSize(22),
                      fontWeight: "bold",
                      marginRight: perfectSize(10),
                    }}
                  >
                    {item.user_id?.nickname}
                  </Text>
                  <Text
                    style={{
                      fontSize: perfectSize(14),
                      lineHeight: perfectSize(22),
                    }}
                  >
                    {item.content}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      </ScrollView>
      <ReviewModal
        isVisible={isVisibleReviewModal}
        hideModal={() => setIsVisibleReviewModal(false)}
        onRegister={registerReview}
      />
      {isLoading && (
        <View style={styles.loader}>
          <Loader />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fafafa",
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "#fafafa",
  },
  title: {
    fontSize: perfectSize(18),
    lineHeight: perfectSize(24),
    fontWeight: "bold",
    padding: perfectSize(15),
  },
  subTitle: {
    fontSize: perfectSize(12),
    lineHeight: perfectSize(13.6),
    color: "#777777",
    paddingLeft: perfectSize(15),
  },
  thumbnail: {
    width: perfectSize(160),
    height: perfectSize(160),
    resizeMode: "contain",
    backgroundColor: c_third_grey,
    borderRadius: perfectSize(4),
    alignSelf: "center",
    marginTop: perfectSize(15),
  },
  button: {
    backgroundColor: c_primary_red,
    alignItems: "center",
    borderRadius: perfectSize(6),
    paddingVertical: perfectSize(10),
  },
  noButton: {
    backgroundColor: c_primary_red,
    alignItems: "center",
    borderRadius: perfectSize(6),
    paddingVertical: perfectSize(10),
    opacity: 0.4,
  },
  buttonTitle: {
    color: "#fafafa",
    fontSize: perfectSize(13),
    lineHeight: perfectSize(14.75),
    fontWeight: "bold",
  },
  content: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(22),
    paddingVertical: perfectSize(15),
  },
  reviewTitle: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(15.89),
    fontWeight: "bold",
    marginTop: perfectSize(20),
    marginBottom: perfectSize(5),
  },
  reviewName: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(22),
    fontWeight: "bold",
    marginRight: perfectSize(10),
  },
  reviewContent: {
    fontSize: perfectSize(14),
    lineHeight: perfectSize(22),
  },
  iframe_container: {
    border: '0',
    overflow: 'hidden',
    width: "100%",
    height: "100%",
  },
});
