import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {
    ActivityIndicator,
    View,
    Text,
    TouchableOpacity,
    Image,
    ScrollView,
    Share,
    Platform,
} from "react-native";
import {
    c_black,
    c_light_grey,
    c_primary_red, c_second_grey,
    c_second_white,
    c_thrid_white,
    c_white,
} from "../../variables/colors";
import {UPLOAD_URL} from "../../variables/constants";
import {globalStyle, perfectSize} from "../../variables/styles";
import Details from "./tabs/details";
import Reviews from "./tabs/reviews";
import AdditionalInfo from "./tabs/additionalInfo";
import Contents from "./tabs/contents";
import Separator from "../../components/Separator";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Modal from "react-native-modal";
import WebModal from "modal-react-native-web";
import moment from "moment";
import MapModal from "../../modals/MapModal";
import {performanceLike} from "../../store/actions";
import {numberWithCommas, oneAlert, twoAlert} from "../../utils";
import {getReview, getExpectation, getPerformance} from "../../store/actions";
import * as Linking from "expo-linking";
import {VideoList, VR360List} from "../main/video";
import {DsdList, FLACList} from "../main/audio";
import {NFTList} from "../main/nft";
import {getContents} from "../../store/actions/performance";

function Detail({
                    navigation,
                    route,
                    categories,
                    isAuthenticated,
                    user,
                    reviews,
                    getReview,
                    getExpectation,
                    expectations,
                }) {
    const sUsrAg = navigator.userAgent;
    const [indexTab, selectTab] = useState(0);
    const [isLike, setLike] = useState(false);
    const [visibleReservationModal, setVisibleReservationModal] = useState(false);
    const [visibleMapModal, setVisibleMapModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [performance, setPerformance] = useState();
    const mainScrollRef = useRef(null);
    const {id, isReview} = route.params;
    const [contents, setContents] = useState();

    useEffect(() => {
        getPerformanceDetail();
    }, []);

    useEffect(() => {
        getContents(id)
            .then(res => {
                setContents(res.data.data.contents);
            })
            .catch(err => {
            });
    }, []);

    const getPerformanceDetail = async () => {
        setLoading(true);
        const res = await getPerformance(id);
        setPerformance(res);
        getReviewList(res);
        setLoading(false);
        if (isReview) mainScrollRef.current.scrollToEnd({animated: true});
    };

    useEffect(() => {
        navigation.setOptions({
            title: performance ? performance.title.name.length > 20 ? performance.title.name.substring(0, 18) + "..." : performance.title.name : "",
            headerLeft: () => (
                <View style={globalStyle.flexRow}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                        <Image
                            source={require("../../../assets/images/ic_before.png")}
                            style={globalStyle.headerLeftBack}
                        />
                    </TouchableOpacity>
                </View>
            ),
            headerRight: () => (
                <TouchableOpacity onPress={() => navigation.navigate("Home")}>
                    <Image
                        source={require("../../../assets/images/ic_close.png")}
                        style={globalStyle.navIcon}
                    />
                </TouchableOpacity>
            ),
        });
        isAuthenticated &&
        performance &&
        setLike(_.includes(performance.likes, user._id));
    }, [performance]);

    const handlePageChange = (pageNumber) => {
        selectTab(pageNumber);
    };

    const getReviewList = async (res) => {
        let req = {
            type: "review",
            product_id: res._id,
        };
        await getReview(req);
        req = {
            type: "expectation",
            product_id: res._id,
        };
        await getExpectation(req);
    };

    const onDeleteReview = () => {
    };

    const renderTabView = () => {
        return (
            <View style={globalStyle.flex}>
                {indexTab === 0 && <Details key="Details" performance={performance}/>}
                {indexTab === 1 && (
                    <Reviews
                        key="Reviews"
                        performance={performance}
                        onDeleteReview={onDeleteReview}
                    />
                )}
                {indexTab === 2 && (
                    <Contents key="Details3" performance={performance}/>
                )}
                {indexTab === 3 && (
                    <AdditionalInfo key="AdditionalInfo" performance={performance}/>
                )}
            </View>
        );
    };

    const onLike = () => {
        performanceLike(user._id, performance._id);
        setLike(!isLike);
    };

    const onShare = async () => {
        const url = Linking.createURL("/Detail", {
            queryParams: {id: performance._id},
        });

        try {
            if(Platform.OS === 'web'){
                await navigator.clipboard.writeText(url);
                alert('주소가 클립보드에 복사되었습니다.');    
            }else{
                const result = await Share.share({
                    title: performance.category_names[0],
                    message: performance.title.name,
                    url: url,
                });
                if (result.action === Share.sharedAction) {
                    if (result.activityType) {
                        // shared with activity type of result.activityType
                    } else {
                        // shared
                    }
                } else if (result.action === Share.dismissedAction) {
                    // dismissed
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const renderModalcontent = () => {
        return (
            <View style={styles.modalContainer}>
                <TouchableOpacity
                    onPress={() => setVisibleReservationModal(false)}
                    style={styles.modalCancelBtn}
                >
                    <Image
                        source={require("../../../assets/images/ic_close.png")}
                        style={styles.modalCancelIcon}
                    />
                </TouchableOpacity>
                {performance.others &&
                    performance.others.map((item, index) => {
                        return (
                            <TouchableOpacity
                                key={index}
                                style={[
                                    styles.modalButtonSelection,
                                    {opacity: _.isEmpty(item.link) ? 0.5 : 1},
                                ]}
                                disabled={_.isEmpty(item.link)}
                                onPress={() => Linking.openURL(item.link)}
                            >
                                <Text style={globalStyle.regularBoldText}>{item.type}</Text>
                            </TouchableOpacity>
                        );
                    })}
            </View>
        );
    };

    const onOrder = () => {
        if (isAuthenticated) {
            if (!sUsrAg.includes('Mobile')) {
                navigation.navigate("SelectDate", { performance })
            } else {
                twoAlert('예매는 App에서 가능합니다', moveApp());
            }
        } else {
            oneAlert('로그인이 필요한 서비스입니다.')
        }
    };

    const moveApp = () => {
        if (sUsrAg.match(/iPhone|iPod/i)) {
            var visiteTm = (new Date()).getTime();
            setTimeout(function () {
                if ((new Date()).getTime() - visiteTm < 3000) {
                    location.href = "https://itunes.apple.com/kr/app/id1577341224?mt=8";
                }
            }, 2500);
            setTimeout(function () {  // 앱실행
                location.href = "tongtongticket://";
            }, 0);
        } else {
            location.href = 'intent://m.etomato.com#Intent;scheme=ttticket;package=com.etomato.ttticket;end';
        }
    };

    if (loading)
        return (
            <View
                style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: perfectSize(20),
                }}
            >
                <ActivityIndicator color={c_primary_red}/>
            </View>
        );
    if (_.isEmpty(performance)) {
        return (
            <View
                style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: perfectSize(20),
                }}
            >
                <Text>{"공연이 없습니다."}</Text>
            </View>
        );
    }

    const detailedTimes = performance.schedule.detailed_time;
    let limitDate = new Date(detailedTimes[detailedTimes.length - 1].date);
    limitDate.setDate(limitDate.getDate() - 1);
    let current = new Date();
    current.setHours(0);
    current.setMinutes(0);
    current.setSeconds(0);
    current.setMilliseconds(0);
    // limitDate.setHours(17);

    const renderContents = () => {
        if (_.isEmpty(contents)) return <Text style={styles.noReview}>{"컨텐츠가 없습니다."}</Text>;
        return contents.map((item, index) => {
            return (
                <View key={index} style={{
                    justifyContent: 'space-between',
                    marginTop: perfectSize(10),
                    marginBottom: perfectSize(10),
                    marginLeft: perfectSize(15),
                    marginRight: perfectSize(15)
                }}>
                    <TouchableOpacity
                        onPress={() => navigation.navigate("ContentDetail", {
                            performance: performance,
                            index: index,
                            item: item,
                            user: user
                        })}
                        style={{flexDirection: 'row', alignItems: 'center'}}
                    >
                        <Text style={{
                            fontWeight: 'normal', marginRight: perfectSize(9),
                            fontSize: perfectSize(14),
                            lineHeight: perfectSize(22),
                            color: "#222222",
                            overflow: "hidden",


                        }}>
                            {index + 1}
                        </Text>
                        <Text style={{
                            fontWeight: 'normal', marginRight: perfectSize(9),
                            fontSize: perfectSize(14),
                            lineHeight: perfectSize(22),
                            color: "#222222",
                            overflow: "hidden", width: '85%'
                        }} numberOfLines={1}>
                            {item.title}
                        </Text>
                        <Image
                            source={require("../../../assets/images/btn_details_contents.png")}
                            style={{width: 10, height: 16, marginLeft: 'auto', marginRight: perfectSize(15)}}
                        />
                    </TouchableOpacity>
                </View>
            )
        })
    }

    return (
        <View style={styles.mainContainer}>
            <ScrollView ref={mainScrollRef} style={globalStyle.flex}>
                {/** Banner */}
                <View style={styles.banner}>
                    <View style={styles.blurContainer}/>
                    <Image
                        source={{
                            uri: performance.poster_link
                                ? UPLOAD_URL + performance.poster_link
                                : null,
                        }}
                        blurRadius={4}
                        style={globalStyle.flex}
                    />
                </View>
                <View style={styles.absoluteContainer}>
                    <Image
                        source={{
                            uri: performance.poster_link
                                ? UPLOAD_URL + performance.poster_link
                                : null,
                        }}
                        style={styles.categoryImage}
                    />
                    <View style={[globalStyle.flexRowBetween, globalStyle.flex]}>
                        <Text style={styles.bannerHeaderLabel}>
                            {performance.category_names[0]}
                        </Text>
                        <View style={styles.bannerBottom}>
                            <Text style={styles.bannerTag}>
                                {performance.isOnsale
                                    ? new Date(performance.schedule.to) > new Date()
                                        ? "판매중"
                                        : "판매종료"
                                    : "판매중단"}
                            </Text>
                            <View style={globalStyle.flexRow}>
                                {isAuthenticated && (
                                    <TouchableOpacity onPress={onLike}>
                                        <Image
                                            source={
                                                isLike
                                                    ? require("../../../assets/images/book_ic_like_on.png")
                                                    : require("../../../assets/images/book_ic_like.png")
                                            }
                                            style={styles.bannerButton}
                                        />
                                    </TouchableOpacity>
                                )}
                                <TouchableOpacity onPress={onShare}>
                                    <Image
                                        source={require("../../../assets/images/book_ic_share.png")}
                                        style={styles.bannerButton}
                                    />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>

                {/** Main Content */}
                <View style={styles.mainContent}>
                    {/** Seil */}
                    {/* <Text style={styles.seilBadge}>
                        {'-30%'}
                    </Text> */}

                    <View style={[styles.context, {marginTop: perfectSize(58)}]}>
                        {/** Title */}
                        <Text style={globalStyle.dotumText}>{performance.title.name}</Text>

                        {/** Tags Section */}
                        <View style={styles.tagSection}>
                            <Text style={styles.tag}>{performance.genre}</Text>
                            <Text style={styles.tag}>
                                {performance.isAllAllowed
                                    ? "전체 관람가능"
                                    : `${performance.level}세이상 관람가능`}
                            </Text>
                            <Text style={styles.tag}>
                                {`${performance.event_time.duration}분`}
                                {performance.event_time.intermission
                                    ? `(인터미션 ${performance.event_time.intermission}분)`
                                    : ""}
                            </Text>
                        </View>

                        {/** Book Marks */}
                        <View
                            style={[globalStyle.flexRow, {marginBottom: perfectSize(10)}]}
                        >
                            <Image
                                source={require("../../../assets/images/book_ic_date.png")}
                                style={styles.bookMark}
                            />
                            <Text style={globalStyle.regularText}>
                                {`${moment(performance.schedule.from).format(
                                    "YYYY.MM.DD"
                                )} ~ ${moment(performance.schedule.to).format("YYYY.MM.DD")}`}
                            </Text>
                        </View>
                        <TouchableOpacity
                            onPress={() => setVisibleMapModal(true)}
                            style={[globalStyle.flexRow, {marginBottom: perfectSize(10)}]}
                        >
                            <Image
                                source={require("../../../assets/images/book_ic_location.png")}
                                style={styles.bookMark}
                            />
                            <Text style={globalStyle.regularText}>
                                {`${performance.location.name} >`}
                            </Text>
                        </TouchableOpacity>
                        <View
                            style={[globalStyle.flexRow, {marginBottom: perfectSize(30)}]}
                        >
                            <Image
                                source={require("../../../assets/images/book_ic_price.png")}
                                style={styles.bookMark}
                            />
                            <Text style={globalStyle.regularText}>
                                {numberWithCommas(
                                        performance.prices.prices[
                                        performance.prices.prices.length - 1
                                            ].price
                                    ) +
                                    " ~ " +
                                    numberWithCommas(performance.prices.prices[0].price)}
                            </Text>
                        </View>
                    </View>

                    <View
                        style={[
                            globalStyle.flexRow,
                            {
                                paddingHorizontal: perfectSize(15),
                                marginBottom: perfectSize(40),
                            },
                        ]}
                    >
                        {performance.isOnsale
                            ? new Date(limitDate) < new Date(current)
                                ? <View /> 
                                : <View style={{flex: 1, marginLeft: perfectSize(5)}}>
                                    <Button
                                        onPress={onOrder}
                                        isBorder={true}
                                        textColor={c_white}
                                        label={"예매하기"}
                                        color={c_primary_red}
                                        disable={new Date(limitDate) < new Date(current)}
                                    />
                                </View>
                            : <View style={{flex: 1, marginLeft: perfectSize(5)}}>
                                <Button
                                    label={"판매중단"}
                                    color={c_light_grey}
                                />
                            </View>
                        }
                    </View>

                    <Separator height={perfectSize(8)} color={c_second_white}/>

                    <Details key="Details" performance={performance}/>

                    <View>
                        <View style={{
                            marginTop: perfectSize(30),
                            marginBottom: perfectSize(15),
                            marginLeft: perfectSize(15)
                        }}>
                            <Text style={globalStyle.regularBoldText}>{"연주곡 정보"}</Text>
                        </View>
                        {renderContents()}
                    </View>

                    <View style={{paddingHorizontal: perfectSize(15)}}>
                        {/*<VideoList*/}
                        {/*  hiddenPlus*/}
                        {/*  showLoadMore*/}
                        {/*  smallTitle*/}
                        {/*  performanceId={performance._id}*/}
                        {/*/>*/}
                        {/*<VR360List*/}
                        {/*  hiddenPlus*/}
                        {/*  showLoadMore*/}
                        {/*  smallTitle*/}
                        {/*  performanceId={performance._id}*/}
                        {/*/>*/}
                        {/*<FLACList*/}
                        {/*  hiddenPlus*/}
                        {/*  showLoadMore*/}
                        {/*  smallTitle*/}
                        {/*  performanceId={performance._id}*/}
                        {/*/>*/}
                        {/*<DsdList*/}
                        {/*  hiddenPlus*/}
                        {/*  showLoadMore*/}
                        {/*  smallTitle*/}
                        {/*  performanceId={performance._id}*/}
                        {/*/>*/}
                        {/*<NFTList*/}
                        {/*  hiddenPlus*/}
                        {/*  showLoadMore*/}
                        {/*  smallTitle*/}
                        {/*  performanceId={performance._id}*/}
                        {/*/>*/}
                        <Reviews
                            key="Reviews"
                            performance={performance}
                            onDeleteReview={onDeleteReview}
                        />
                    </View>
                    <View style={styles.footer}>
                        <Footer/>
                    </View>
                </View>
            </ScrollView>

            {Platform.OS !== "web" ? (
                <Modal
                    isVisible={visibleReservationModal}
                    style={{alignItems: "center"}}
                >
                    {renderModalcontent()}
                </Modal>
            ) : (
                <WebModal visible={visibleReservationModal} transparent={true}>
                    <View
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            flex: 1,
                            backgroundColor: "#00000050",
                        }}
                    >
                        {renderModalcontent()}
                    </View>
                </WebModal>
            )}
            <MapModal
                data={performance}
                isVisible={visibleMapModal}
                hideModal={() => setVisibleMapModal(false)}
            />
        </View>
    );
}

const mapStateTopProps = (state) => ({
    categories: state.app.categories,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    reviews: state.app.reviews,
    expectations: state.app.expectations,
});

export default connect(mapStateTopProps, {getReview, getExpectation})(Detail);

const styles = {
    mainContainer: {
        flex: 1,
        backgroundColor: c_white,
    },
    banner: {
        height: perfectSize(110),
        overflow: "hidden",
    },
    blurContainer: {
        backgroundColor: "#00000077",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
    },
    absoluteContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        height: perfectSize(110),
        flexDirection: "row",
        padding: perfectSize(15),
    },
    categoryImage: {
        width: perfectSize(100),
        height: perfectSize(140),
        backgroundColor: c_light_grey,
        borderRadius: perfectSize(5),
    },
    bannerHeaderLabel: {
        marginLeft: perfectSize(15),
        color: c_white,
        fontSize: perfectSize(18),
    },
    bannerBottom: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    bannerTag: {
        color: c_white,
        fontSize: perfectSize(11),
        marginLeft: perfectSize(15),
        borderWidth: 1,
        borderColor: c_white,
        paddingHorizontal: perfectSize(6),
        paddingVertical: perfectSize(4),
    },
    bannerButton: {
        width: perfectSize(17),
        height: perfectSize(17),
        resizeMode: "contain",
        marginLeft: perfectSize(20),
    },
    mainContent: {
        alignItems: "stretch",
        backgroundColor: c_white,
    },
    seilBadge: {
        alignSelf: "flex-start",
        marginLeft: perfectSize(130),
        marginTop: perfectSize(10),
        marginBottom: perfectSize(20),
        color: c_white,
        backgroundColor: c_primary_red,
        paddingVertical: perfectSize(4),
        paddingHorizontal: perfectSize(7),
        fontSize: perfectSize(14),
        fontWeight: "bold",
    },
    context: {
        paddingHorizontal: perfectSize(15),
    },
    tagSection: {
        ...globalStyle.flexRow,
        marginTop: perfectSize(8),
        marginBottom: perfectSize(20),
    },
    tag: {
        ...globalStyle.smGreyText,
        marginRight: perfectSize(13),
    },
    bookMark: {
        width: perfectSize(15),
        height: perfectSize(15),
        resizeMode: "contain",
        marginRight: perfectSize(8),
    },
    tabBarContainer: {
        flexDirection: "row",
        height: perfectSize(40),
        width: "100%",
        backgroundColor: c_second_white,
        borderRadius: perfectSize(6),
        padding: perfectSize(1),
    },
    tabBarItem: {
        flex: 1,
        borderRadius: perfectSize(6),
        backgroundColor: c_primary_red,
        alignItems: "center",
        justifyContent: "center",
    },
    footer: {
        width: "100%",
        paddingHorizontal: perfectSize(15),
    },
    modalContainer: {
        backgroundColor: c_white,
        width: perfectSize(260),
        borderRadius: perfectSize(6),
        padding: perfectSize(15),
    },
    modalCancelBtn: {
        alignSelf: "flex-end",
        marginBottom: perfectSize(20),
    },
    modalCancelIcon: {
        width: perfectSize(14),
        height: perfectSize(14),
        resizeMode: "contain",
    },
    modalButtonSelection: {
        borderColor: c_light_grey,
        borderWidth: 1,
        borderRadius: perfectSize(4),
        alignItems: "center",
        paddingVertical: perfectSize(15),
        marginBottom: perfectSize(10),
    },
    bottomButtons: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: c_white,
    },
    noReview: {
        ...globalStyle.greyNormalText,
        color: c_second_grey,
        textAlign: "center",
        marginTop: perfectSize(40),
    },
};
