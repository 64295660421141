import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  ActivityIndicator,
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  TextInput,
  Dimensions,
  FlatList,
} from "react-native";
import ModalDropdown from "react-native-modal-dropdown";
import Entypo from "@expo/vector-icons/Entypo";
import moment from "moment";
import { useIsFocused } from "@react-navigation/native";

import {
  c_grey,
  c_white,
  c_primary_red,
  c_second_white,
} from "../../variables/colors";
import { UPLOAD_URL } from "../../variables/constants";
import { globalStyle, perfectSize } from "../../variables/styles";
import FilterModal from "../../modals/FilterModal";
import {
  performanceListReset,
  performanceList,
} from "../../store/actions/performance";
import Loader from "../../components/Loader";
import EmptyText from "../../components/EmptyText";
import { VideoList, VR360List } from "../main/video";
import { DsdList, FLACList } from "../main/audio";
import { NFTList } from "../main/nft";

const { width, height } = Dimensions.get("window");

const FILTER_DATA = [
  {
    id: 0,
    label: "전체",
    value: "",
  },
  {
    id: 1,
    label: "클래식",
    value: "classic",
  },
  {
    id: 2,
    label: "연극/뮤지컬",
    value: "musical",
  },
  {
    id: 3,
    label: "콘서트",
    value: "concert",
  },
  {
    id: 4,
    label: "무용",
    value: "dance",
  },
  {
    id: 5,
    label: "전시/행사",
    value: "event",
  },
  {
    id: 6,
    label: "스포츠",
    value: "sport",
  },
];

const dropdownList = ["추천순", "임박순", "오래된순"];

function SearchResult({ navigation, route, reviews }) {
  const { PerformanceReducer } = useSelector(state => state);
  const dispatch = useDispatch();

  const { searchWord } = route.params;
  const isFocusedHistory = useIsFocused();

  const [searchText, setSearchText] = useState(searchWord);
  const [sortType, setSortType] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedIndex, setSelectionIndex] = useState(0);
  const [review, setReview] = useState({});

  useEffect(() => {
    const _review = _.sortBy(reviews, o => {
      return o.likes.length;
    });
    _review.reverse();
    setReview(_review[0]);
  }, [reviews]);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <View style={globalStyle.flexRow}>
          <Text style={{ fontSize: 16, marginRight: perfectSize(4) }}>
            {"검색결과"}
          </Text>
        </View>
      ),
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../assets/images/ic_before_stroke.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
      headerRight: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.navigate("Home")}>
            <Image
              source={require("../../../assets/images/ic_close.png")}
              style={globalStyle.headerNormalIcon}
            />
          </TouchableOpacity>
        </View>
      ),
    });
  }, [selectedIndex]);

  useEffect(() => {
    const req = {
      sortType,
      selectedIndex,
      searchWord: searchText,
      page: 1,
    };
    dispatch(performanceListReset());
    dispatch(performanceList(req));
  }, [isFocusedHistory, selectedIndex, sortType]);

  const getPerformances = useCallback(req => {
    dispatch(performanceList(req));
  }, []);

  const iterateFlatItems = ({item}) => {
    const detailedTimes = item.schedule.detailed_time;
    let limitDate = new Date(detailedTimes[detailedTimes.length - 1].date);
    limitDate.setDate(limitDate.getDate() - 1);
    let current = new Date();
    current.setHours(0);
    current.setMinutes(0);
    current.setSeconds(0);
    current.setMilliseconds(0);

    return (
      <TouchableOpacity
        onPress={() => navigation.navigate("Detail", { id: item._id })}
        style={[globalStyle.shadow, styles.searchResult]}
        key={item._id}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Image
            source={{
              uri: item.poster_link ? UPLOAD_URL + item.poster_link : null,
            }}
            style={styles.categoryImage}
          />
          <View
            style={{
              flex: 1,
              paddingVertical: perfectSize(12),
              paddingRight: perfectSize(15),
            }}
          >
            <Text
              style={[
                globalStyle.normalBoldText,
                { marginBottom: perfectSize(15) },
              ]}
            >
              {item.title.name}
            </Text>
            <Text
              style={[
                globalStyle.regularText,
                { marginBottom: perfectSize(8), color: c_primary_red },
              ]}
            >
              {item.location.name}
            </Text>
            <Text
              style={[
                globalStyle.greySmText,
                { marginBottom: perfectSize(20) },
              ]}
            >
              {`${moment(item.schedule.from).format("YYYY.MM.DD")} ~ ${moment(
                item.schedule.to
              ).format("YYYY.MM.DD")}`}
            </Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View
                style={[
                  styles.badge,
                  item.isOnsale
                    ? new Date(limitDate) > new Date(current)
                      ? { borderColor: c_primary_red }
                      : { borderColor: c_grey }
                    : { borderColor: c_grey },
                ]}
              >
                <Text
                  style={[
                    styles.badgeText,
                    item.isOnsale
                      ? new Date(limitDate) > new Date(current)
                        ? { color: c_primary_red }
                        : { color: c_grey }
                      : { color: c_grey },
                  ]}
                >
                  {item.isOnsale
                    ? new Date(limitDate) > new Date(current)
                      ? "판매중"
                      : "판매종료"
                    : "판매중단"}
                </Text>
              </View>
              {item.event_list && item.event_list.length > 0 && (
                <View style={[styles.badge, { marginLeft: perfectSize(5) }]}>
                  <Text style={styles.badgeText}>{"이벤트"}</Text>
                </View>
              )}
            </View>
          </View>
        </View>
        <View style={{ paddingTop: perfectSize(15) }}>
          {review?.content && (
            <>
              <Text
                style={{
                  fontSize: perfectSize(12),
                  color: "#111111",
                  fontWeight: "bold",
                  marginTop: perfectSize(18),
                }}
              >
                {"리뷰"}
              </Text>
              <Text
                numberOfLines={2}
                style={{
                  fontSize: perfectSize(14),
                  lineHeight: perfectSize(22),
                  marginTop: perfectSize(6),
                }}
              >
                {review?.content}
              </Text>
            </>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  const renderCategories = () => {
    if (PerformanceReducer.performancesLoading) return <Loader />;
    if (_.isEmpty(PerformanceReducer.performances))
      return <EmptyText label="공연이 없습니다." />;

    return <FlatList
      data={PerformanceReducer.performances}
      renderItem={iterateFlatItems}
      keyExtractor={item => item._id}
    />;
  };

  return (
    <View style={styles.container}>
      <View style={styles.searchInput}>
        <TextInput
          value={searchText}
          onChangeText={text => setSearchText(text)}
          style={[globalStyle.regularText, globalStyle.flex]}
          placeholder={"검색어를 입력해주세요"}
        />
        <TouchableOpacity
          onPress={() => {
            const req = {
              sortType,
              selectedIndex,
              searchWord: searchText,
              page: 1,
            };
            dispatch(performanceListReset());
            dispatch(performanceList(req));
          }}
        >
          <Image
            source={require("../../../assets/images/ic_search_b.png")}
            style={globalStyle.searchIcon}
          />
        </TouchableOpacity>
      </View>
      <View style={globalStyle.flex}>
        <View style={styles.content}>
          <View style={globalStyle.flexRow}>
            <Text style={globalStyle.normalText}>{"총 "}</Text>
            <Text style={globalStyle.normalBoldText}>
              {PerformanceReducer.total}
            </Text>
            <Text style={globalStyle.normalText}>{" 개"}</Text>
          </View>
          <ModalDropdown
            onSelect={index => setSortType(index)}
            options={dropdownList}
            defaultIndex={0}
            defaultValue={dropdownList[0]}
            renderSeparator={() => <View />}
            dropdownStyle={{
              width: perfectSize(80),
              height: perfectSize(40 * dropdownList.length),
              marginTop: perfectSize(8),
            }}
            dropdownTextStyle={globalStyle.dropdownTextStyle}
            dropdownTextHighlightStyle={{ color: c_primary_red }}
            textStyle={globalStyle.regularText}
            renderRightComponent={() => (
              <Image
                source={require("../../../assets/images/ic_list_order.png")}
                style={globalStyle.dropdownRightImage}
              />
            )}
            style={globalStyle.dropdownStyle}
          />
        </View>
        {/** search result list */}
        {renderCategories()}
      </View>
      {/** Filter Modal */}
      <FilterModal
        isVisible={modalVisible}
        hideModal={() => setModalVisible(false)}
        filters={FILTER_DATA}
        selectedIndex={selectedIndex}
        setSelectionIndex={setSelectionIndex}
      />
    </View>
  );
}

const mapStateTopProps = state => ({
  reviews: state.app.reviews,
});

export default connect(mapStateTopProps)(SearchResult);

const styles = {
  container: {
    flex: 1,
    backgroundColor: c_white,
  },
  content: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: perfectSize(15),
  },
  categoryImage: {
    width: perfectSize(100),
    height: perfectSize(140),
    marginRight: perfectSize(15),
    borderRadius: perfectSize(4),
    backgroundColor: c_second_white,
  },
  searchResult: {
    marginBottom: perfectSize(20),
    borderRadius: perfectSize(6),
    backgroundColor: c_white,
    marginHorizontal: perfectSize(15),
    padding: perfectSize(15),
  },
  badge: {
    borderColor: c_primary_red,
    borderWidth: 1,
    borderRadius: perfectSize(3),
    padding: perfectSize(3),
    alignSelf: "flex-start",
  },
  badgeText: {
    fontSize: perfectSize(11),
    color: c_primary_red,
  },
  carouselPagerWrapper: {
    height: perfectSize(200),
    backgroundColor: c_second_white,
  },
  carouselPager: {
    backgroundColor: c_second_white,
  },
  carouselImage: {
    resizeMode: "stretch",
    width: width,
    height: perfectSize(200),
  },
  searchInput: {
    height: perfectSize(40),
    borderColor: c_primary_red,
    borderWidth: 1,
    borderRadius: perfectSize(6),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: perfectSize(15),
    marginHorizontal: perfectSize(15),
    marginTop: perfectSize(15),
  },
};
