import React, { useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity
} from 'react-native';
import {
    c_grey,
    c_third_grey,
    c_dark_white,
    c_primary_black
} from '../variables/colors';
import { perfectSize, globalStyle } from '../variables/styles';
import Entypo from '@expo/vector-icons/Entypo';
import Collapsible from 'react-native-collapsible';

export default function FAQItem({
    title,
    content,
    type
}) {
    const [expanable, setExpand] = useState(true);

    return (
        <View>
            <TouchableOpacity
                onPress={() => setExpand(!expanable)}
                style={[globalStyle.flexSpaceBetween, styles.container]}>
                <View
                    style={globalStyle.flexRow}>
                    <Text style={styles.qIcon}>
                        {'Q'}
                    </Text>
                    <Text
                        style={[globalStyle.regularText, { width: perfectSize(250) }]}>
                        {`[${type}] ${title}`}
                    </Text>
                </View>
                <Entypo
                    name={expanable ? "chevron-thin-down" : "chevron-thin-up"}
                    size={perfectSize(16)}
                    color={c_grey}
                />
            </TouchableOpacity>

            <Collapsible
                collapsed={expanable}
                style={styles.collapsedContainer}>
                <View style={[globalStyle.flexRow, styles.collapsedContent]}>
                    <Text style={[styles.qIcon, { color: c_primary_black }]}>
                        {'A'}
                    </Text>
                    <Text style={[globalStyle.smGreyText, { paddingTop: perfectSize(4), width: '90%' }]}>
                        {content}
                    </Text>
                </View>
            </Collapsible>
        </View>
    )
}

const styles = {
    container: {
        borderBottomWidth: 1,
        borderBottomColor: c_third_grey,
        paddingVertical: perfectSize(22),
        paddingHorizontal: perfectSize(15)
    },
    collapsedContent: {
        alignItems: 'flex-start',
        borderRadius: perfectSize(6),
        paddingHorizontal: perfectSize(15)
    },
    collapsedContainer: {
        paddingVertical: perfectSize(20),
        backgroundColor: c_dark_white,
    },
    qIcon: {
        fontSize: perfectSize(20),
        color: '#b8b8b8',
        marginRight: perfectSize(10)
    },
}