import axios from "axios";
import {HOST} from "../variables/constants";
import {Alert, Platform} from "react-native";
import Base64 from "Base64";
import moment from "moment";

var CryptoJS = require("crypto-js");
import AsyncStorage from "@react-native-async-storage/async-storage";

export const instance = axios.create({
    baseURL: HOST, //https://www.tickettong.net
    timeout: 40000,
    headers: {"Content-Type": "application/json"},
});

export function numberWithCommas(x) {
    if (x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    } else {
        return "0";
    }
}

export function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validateStrongPassword(password) {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/;
    return re.test(password);
}

export function makeTwoString(number) {
    return ("0" + number).slice(-2);
}

export const storeData = async (keyName, value) => {
    try {
        const jsonValue = JSON.stringify(value);
        await AsyncStorage.setItem(keyName, jsonValue);
    } catch (e) {
        // saving error
    }
};

export const getData = async (keyName) => {
    const value = await AsyncStorage.getItem(keyName);
    return JSON.parse(value);
};

export function get_time_diff(datetime) {
    var datetime =
        typeof datetime !== "undefined" ? datetime : "2014-01-01 01:02:03.123456";

    var datetime = new Date(datetime).getTime();
    console.log(datetime);
    var now = new Date().getTime();

    if (isNaN(datetime)) {
        return "";
    }

    console.log(datetime + " " + now);

    if (datetime < now) {
        var milisec_diff = now - datetime;
    } else {
        var milisec_diff = datetime - now;
    }

    var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24));

    var date_diff = new Date(milisec_diff);

    return (
        days +
        "일 " +
        date_diff.getHours() +
        "시간 " +
        date_diff.getMinutes() +
        "분 " +
        date_diff.getSeconds() +
        "초"
    );
}

export const formatDate = (date) => {
    return moment(date).format("YYYY.MM.DD");
};

export function getParameterByName(url) {
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
        params = {},
        match;
    while ((match = regex.exec(url))) {
        params[match[1]] = match[2];
    }

    return params;
}

export const oneAlert = (str, subStr, action) => {
    if (Platform.OS !== "web") {
        Alert.alert(str, subStr || "", [
            {
                text: "확인",
                onPress: action,
            },
        ]);
    } else {
        alert(str);
    }
};

export const twoAlert = (str, action) => {
    if (Platform.OS !== "web") {
        Alert.alert(str, "", [
            {
                text: "취소",
                onPress: () => {
                },
                style: "cancel",
            },
            {
                text: "확인",
                onPress: action,
                style: "destructive",
            },
        ]);
    } else {
        alert(str, [
            {
                text: "취소",
                onPress: () => {
                },
                style: "cancel",
            },
            {
                text: "확인",
                onPress: action,
                style: "destructive",
            },
        ]);
    }
};

export const DSDAlert = (str, action1, action2) => {
    Alert.alert(str, "", [
        {
            text: "DSD128 (Stereo)",
            onPress: action1,
            style: "cancel",
        },
        {
            text: "DSD64 (5.1)",
            onPress: action2,
            style: "destructive",
        },
    ]);
};

export const isCloseToBottom = ({
                                    layoutMeasurement,
                                    contentOffset,
                                    contentSize,
                                }) => {
    const paddingToBottom = 40;
    return (
        layoutMeasurement.height + contentOffset.y >=
        contentSize.height - paddingToBottom
    );
};
