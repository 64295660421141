import React, { useState } from 'react';
import {
    View,
    Text,
    TouchableOpacity
} from 'react-native';
import Entypo from '@expo/vector-icons/Entypo';
import Collapsible from 'react-native-collapsible';
import moment from 'moment';

import {
    c_white,
    c_grey,
    c_primary_red,
    c_third_grey,
    c_dark_white
} from '../variables/colors';
import { globalStyle, perfectSize } from '../variables/styles';
import Separator from './Separator';
import { numberWithCommas } from '../utils';

export default function CouponCard({
    data
}) {
    const [expanable, setExpand] = useState(true);
    const {
        name,
        discount_type,
        discount_amount,
        limit_amount,
        from,
        to,
        description
    } = data;

    return (
        <View style={styles.cardTopSection}>
            <View style={styles.headerRedLine}>
                <Separator
                    height={perfectSize(6)}
                    color={c_primary_red}
                />
            </View>
            <View style={styles.mainContent}>
                <Text style={styles.price}>
                    {discount_type === '할인율' ? discount_amount + '%할인' : numberWithCommas(discount_amount) + '원'}
                </Text>
                <Text style={globalStyle.normalBoldText}>
                    {name}
                </Text>
                <Text style={[globalStyle.greySmText,
                {
                    marginTop: perfectSize(10),
                    marginBottom: perfectSize(5)
                }]}>
                    {`최소 결제 금액 ${numberWithCommas(limit_amount)}원`}
                </Text>
                <Text style={globalStyle.greySmText}>
                    {`${moment(from).format('YYYY.MM.DD')} ~ ${moment(to).format('YYYY.MM.DD')}`}
                </Text>
                <Separator
                    height={1}
                    color={c_third_grey}
                    marginTop={perfectSize(12)}
                />
            </View>
            <TouchableOpacity
                onPress={() => setExpand(!expanable)}
                style={styles.button}>
                <View style={globalStyle.flexRow}>
                    <Text style={globalStyle.smText}>
                        {'쿠폰안내'}
                    </Text>
                    <View style={{ marginLeft: perfectSize(5) }}>
                        <Entypo name={expanable ? "chevron-thin-down" : "chevron-thin-up"} size={perfectSize(12)} />
                    </View>
                </View>
            </TouchableOpacity>
            <Collapsible
                collapsed={expanable}
                style={styles.collapsedContainer}>
                <View style={styles.collapsedContent}>
                    <Text style={globalStyle.smGreyText}>
                        {description}
                    </Text>
                </View>
            </Collapsible>
        </View>
    )
}

const styles = {
    cardTopSection: {
        elevation: 2,
        shadowColor: c_grey,
        shadowOffset: { x: 2, y: 6 },
        shadowRadius: 4,
        shadowOpacity: 0.3,
        marginBottom: perfectSize(10),
        marginHorizontal: perfectSize(15),
        backgroundColor: c_white,
        borderRadius: perfectSize(6),
    },
    headerRedLine: {
        borderTopLeftRadius: perfectSize(6),
        borderTopRightRadius: perfectSize(6),
        overflow: 'hidden'
    },
    price: {
        fontSize: perfectSize(20),
        letterSpacing: -0.2,
        color: c_primary_red,
        fontWeight: 'bold',
        marginBottom: perfectSize(5)
    },
    mainContent: {
        paddingTop: perfectSize(15),
        paddingHorizontal: perfectSize(15)
    },
    button: {
        alignItems: 'center',
        paddingTop: perfectSize(10),
        paddingBottom: perfectSize(14),
    },
    collapsedContent: {
        borderRadius: perfectSize(6),
        backgroundColor: c_dark_white,
        padding: perfectSize(12)
    },
    collapsedContainer: {
        paddingHorizontal: perfectSize(15),
        paddingBottom: perfectSize(15)
    },
}