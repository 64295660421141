import React, { useEffect, useMemo, useState } from "react";
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  SafeAreaView,
} from "react-native";
import { globalStyle, perfectSize } from "../../../variables/styles";
import { c_primary_blue } from "../../../variables/colors";
import Octicons from "@expo/vector-icons/Octicons";

export default function NFTBuyComplete({ navigation }) {
  useEffect(() => {
    navigation.setOptions({
      title: "결제",
      headerLeft: () => (
        <View style={globalStyle.flexRow}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../../../assets/images/ic_before_stroke.png")}
              style={globalStyle.headerLeftBack}
            />
          </TouchableOpacity>
        </View>
      ),
    });
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: perfectSize(40),
        paddingHorizontal: perfectSize(15),
      }}
    >
      <Octicons
        name="checklist"
        color={c_primary_blue}
        size={perfectSize(80)}
        style={{ alignSelf: "center", marginLeft: perfectSize(30) }}
      />
      <Text
        style={{
          fontSize: perfectSize(20),
          textAlign: "center",
          marginVertical: perfectSize(20),
          color: c_primary_blue,
          fontWeight: "bold",
        }}
      >
        {"결제 완료"}
      </Text>
      <Text
        style={{
          fontSize: perfectSize(18),
          textAlign: "center",
          marginTop: perfectSize(30),
          fontWeight: "bold",
        }}
      >
        {"NFT 구매가 완료되었습니다."}
      </Text>
      <Text
        style={{
          fontSize: perfectSize(16),
          textAlign: "center",
        }}
      >
        {"구매한 NFT는 티켓통>NFT 보관함에서\n확인 가능합니다."}
      </Text>
      <TouchableOpacity
        onPress={() => navigation.navigate("NFTDetail")}
        style={{
          backgroundColor: c_primary_blue,
          height: perfectSize(48),
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ color: "white", fontSize: perfectSize(16) }}>
          {"확인"}
        </Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: perfectSize(15),
  },
  activityLoading: {
    flex: 1,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000022",
  },
});
